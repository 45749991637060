import ToggleButton, { ToggleButtonProps } from '@material-ui/lab/ToggleButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import { isNil } from 'lodash'
import React, { useCallback } from 'react'
import { BooleanParam, useQueryParam } from 'use-query-params'

export type FilterButtonProps = Omit<
  ToggleButtonProps,
  'onClick' | 'selected' | 'value'
>

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: `rgba(255,255,255, 0.94)`,
      },
    },
    icon: {
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(1.5),
      },
    },
  })
)

export const FilterButton: React.FC<FilterButtonProps> = ({
  className,
  ...rest
}) => {
  const classes = useStyles()
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const [showFilters, setShowFilters] = useQueryParam(
    'showFilters',
    BooleanParam
  )

  const onClick = useCallback(() => {
    setShowFilters(isNil(showFilters) ? true : undefined, 'replaceIn')
  }, [setShowFilters, showFilters])

  return (
    <ToggleButton
      aria-label="filters toggle"
      className={clsx([classes.button, className])}
      onClick={onClick}
      selected={showFilters ?? false}
      value="true"
      {...rest}
    >
      <FilterListIcon
        className={classes.icon}
        color="primary"
        fontSize="small"
      />
      {mdUp ? 'Filters' : undefined}
    </ToggleButton>
  )
}
