import { plainToClass } from 'class-transformer'
import { MaxLength, MinLength, IsOptional, validateSync } from 'class-validator'
import { UpdateStoryMutationVariables } from '../../graphql'
import { getValidationErrors } from '../../../../util'

export class UpdateStoryDto {
  @MinLength(3, {
    message: 'Need should be at least $constraint1 characters long.',
  })
  @MaxLength(500, {
    message: 'Need should be at most $constraint1 characters long.',
  })
  @IsOptional()
  need!: string

  @MinLength(3, {
    message: 'Reason should be at least $constraint1 characters long.',
  })
  @MaxLength(500, {
    message: 'Reason should be at most $constraint1 characters long.',
  })
  @IsOptional()
  reason!: string

  @MinLength(3, {
    message: 'Role should be at least $constraint1 characters long.',
  })
  @MaxLength(500, {
    message: 'Role should be at most $constraint1 characters long.',
  })
  @IsOptional()
  role!: string
}

export const updateStoryValidate = (
  vars: Omit<UpdateStoryMutationVariables, 'id' | 'projectId'>
) => getValidationErrors(validateSync(plainToClass(UpdateStoryDto, vars)))
