import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  CreateStoryDocument,
  CreateStoryMutationResult,
  CreateStoryMutationVariables,
} from '../../graphql'
import { projectsSuccessMock } from '../../queries'

type CreateStoryMockResponse = MockResponse<
  CreateStoryMutationVariables,
  Pick<CreateStoryMutationResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const createStorySuccessMock = {
  request: {
    query: CreateStoryDocument,
    variables: {
      id: '348382c9-f5cb-40b3-9ce7-619244200501',
      projectId: projectsSuccessMock.result.data.projects.edges[0].node.id,
      role: 'Manager',
      need: 'I need something',
      reason: 'Because it would help me',
    },
  },
  result: {
    data: {
      createStory: {
        id: '348382c9-f5cb-40b3-9ce7-619244200501',
        archived: false,
        canDelete: true,
        canLove: true,
        canUpdate: true,
        createdBy: {
          id: '45295956-38b0-437a-80c3-7cc90d758e5a',
          displayName: 'Tester',
          firstName: 'Test',
          lastName: 'Run',
        },
        role: 'Manager',
        need: 'I need something',
        reason: 'Because it would help me',
        storyLove: [],
      },
    },
  },
}

export const createStoryLongNeedSuccessMock = {
  request: {
    query: CreateStoryDocument,
    variables: {
      ...createStorySuccessMock.request.variables,
      need:
        'This is an example of a longer need that will be truncated in certain areas.',
    },
  },
  result: {
    data: {
      createStory: {
        ...createStorySuccessMock.result.data.createStory,
        need:
          'This is an example of a longer need that will be truncated in certain areas.',
      },
    },
  },
}

export const createStoryFailureMock = {
  request: {
    query: CreateStoryDocument,
    variables: {
      id: 'aaea0f74-1fb6-4f25-adb1-2ee2bb21db86',
      projectId: projectsSuccessMock.result.data.projects.edges[1].node.id,
      role: 'Project owner',
      need: 'Some need',
      reason: 'Because',
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 409,
          error: 'Conflict',
          message: 'Project has already been created.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const createStoryMocks: CreateStoryMockResponse[] = [
  createStorySuccessMock,
  createStoryLongNeedSuccessMock,
  createStoryFailureMock,
]
