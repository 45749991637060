import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import { isNull, isNil, isUndefined } from 'lodash'
import React, { useCallback } from 'react'
import { projectPermissions } from '../../../config'
import { ProjectPermissionSelect, UserAvatar } from '../../../components'
import { ProjectPermission, ProjectUser, User } from '../../../middleware'

type ProjectUserProps = Pick<
  ProjectUser,
  'id' | 'acceptedAt' | 'canRemove' | 'canUpdate' | 'permission'
> & {
  user?: Pick<User, 'id' | 'displayName' | 'firstName' | 'lastName'> | null
}

export interface ProjectUserListItemProps {
  projectUser: ProjectUserProps
  onDelete: (projectUser: ProjectUserProps) => void
  onUpdate: (projectUser: ProjectUserProps) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      gridColumnGap: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '2fr repeat(2, 1fr)',
      },
      overflowX: 'hidden',
    },
    label: {
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'initial',
      },
    },
    first: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '1fr',
      gridRowGap: theme.spacing(1),
      overflowX: 'hidden',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        paddingLeft: theme.spacing(2),
      },
    },
    firstWithAvatar: {
      gridTemplateColumns: 'max-content 1fr',
      '& >:first-child': {
        gridRowEnd: 'span 2',
      },
    },
    last: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
      },
    },
  })
)

export const ProjectUserListItem: React.FC<ProjectUserListItemProps> = ({
  projectUser,
  onDelete,
  onUpdate,
}) => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const user = projectUser.user
  const label = isNil(user) ? 'User' : user.displayName
  const pending = isNull(projectUser.acceptedAt)

  const onDeleteClick = useCallback(() => {
    onDelete(projectUser)
  }, [onDelete, projectUser])

  const onPermissionClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onUpdate({
        ...projectUser,
        permission: event.target.value as ProjectPermission,
      })
    },
    [onUpdate, projectUser]
  )

  const avatarComponent =
    !isNil(user) && !isNil(user.firstName) && !isNil(user.lastName) ? (
      <ListItemAvatar>
        <UserAvatar loading={false} user={user} />
      </ListItemAvatar>
    ) : undefined

  const selectComponent = (
    <ProjectPermissionSelect
      disabled={!projectUser.canUpdate}
      FormHelperTextProps={{
        'aria-label': 'Permission helper text',
      }}
      id={`${label}-permission-field`}
      label="Permission"
      value={projectUser.permission ?? projectPermissions[0].value}
      variant="outlined"
      onChange={onPermissionClick}
      options={projectPermissions}
    />
  )

  return (
    <ListItem
      className={classes.container}
      ContainerComponent="div"
      disableGutters={true}
    >
      <div
        className={clsx(
          classes.first,
          isUndefined(avatarComponent) ? undefined : classes.firstWithAvatar
        )}
      >
        {avatarComponent}
        <div className={classes.label}>
          <Typography component="span">
            {label}
            {pending ? (
              <Typography color="textSecondary" component="span">
                {' (pending)'}
              </Typography>
            ) : undefined}
          </Typography>
        </div>
        {smUp ? undefined : selectComponent}
      </div>
      {smUp ? selectComponent : undefined}
      <div className={classes.last}>
        <IconButton
          aria-label={`Remove ${label}`}
          disabled={!projectUser.canRemove}
          edge={smUp ? undefined : 'end'}
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </ListItem>
  )
}
