import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { Fragment } from 'react'
import { AuthLayout, LoginForm } from '../../../components'

const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      marginBottom: theme.spacing(5),
    },
  })
)

export const LoginPage: React.FC = () => {
  const classes = useStyles()

  return (
    <AuthLayout>
      <Fragment>
        <Typography align="center" variant="h3" gutterBottom={true}>
          Welcome
        </Typography>
        <Typography align="center" className={classes.description}>
          Please login or sign up.
        </Typography>
        <LoginForm />
      </Fragment>
    </AuthLayout>
  )
}
