import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ScheduleIcon from '@material-ui/icons/Schedule'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { isNil } from 'lodash'
import React, { useCallback } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { SortStories } from '../../../middleware'

const useStyles = makeStyles((theme) =>
  createStyles({
    selectedValue: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      '& .MuiSelect-root': {
        backgroundColor: theme.palette.common.white,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          lineHeight: 0,
          padding: theme.spacing(2) + 1, // +1 for border compensation
        },
      },
      '& .MuiSelect-icon': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
  })
)

const options = [
  {
    icon: <FavoriteIcon color="secondary" fontSize="small" />,
    label: 'Most loved',
    value: SortStories.Love,
  },
  {
    icon: <ScheduleIcon color="primary" fontSize="small" />,
    label: 'Most recent',
    value: SortStories.Recent,
  },
]

const defaultOption = options[0]

export const SortForm: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles()
  const [sort, setSort] = useQueryParam('sort', StringParam)
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newSort = event.target.value

      setSort(newSort)
    },
    [setSort]
  )

  const renderValue = useCallback(
    (value: unknown) => {
      const option = options.find((n) => n.value === value) ?? defaultOption

      return mdUp ? (
        <div className={classes.selectedValue}>
          <ListItemIcon>{option.icon}</ListItemIcon>
          <ListItemText
            primary={option.label}
            primaryTypographyProps={{ variant: 'button' }}
          />
        </div>
      ) : (
        option.icon
      )
    },
    [classes.selectedValue, mdUp]
  )

  return (
    <TextField
      id="sort-stories"
      InputProps={{
        className: classes.input,
      }}
      onChange={onChange}
      select={true}
      SelectProps={{
        renderValue,
      }}
      value={isNil(sort) ? defaultOption.value : sort}
      variant="outlined"
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <ListItemIcon>{option.icon}</ListItemIcon>
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </TextField>
  )
}
