import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { Fragment, ReactNode } from 'react'

export interface FabNavLayoutProps {
  children?: React.ReactNode
  headerLeft?: ReactNode
  headerRight?: ReactNode
}

const useStyles = makeStyles((theme) =>
  createStyles({
    left: {
      position: 'absolute',
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(2),
      },
    },
    right: {
      position: 'absolute',
      right: 0,
      display: 'flex',
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(2),
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  })
)

export const FabNavLayout: React.FC<FabNavLayoutProps> = ({
  children,
  headerLeft,
  headerRight,
}) => {
  const classes = useStyles()

  return (
    <Fragment>
      <div className={classes.left}>{headerLeft}</div>
      <div className={classes.right}>{headerRight}</div>
      <div className={classes.container}>{children}</div>
    </Fragment>
  )
}
