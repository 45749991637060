import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import { currentUserSuccessMock } from '../../queries'
import {
  ProjectPermission,
  ProjectUsersDocument,
  ProjectUsersQueryResult,
  ProjectUsersQueryVariables,
} from '../../graphql'

type ProjectUsersMockResponse = MockResponse<
  ProjectUsersQueryVariables,
  Pick<ProjectUsersQueryResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

const projectId = 'ae5000b0-28ef-4c70-bfea-2471ea2f5d40'

export const projectUsersSuccessMock = {
  request: {
    query: ProjectUsersDocument,
    variables: {
      id: projectId,
    },
  },
  result: {
    data: {
      project: {
        id: projectId,
        projectUsers: [
          {
            id: 'af27e9ca-a639-4bd6-9d11-42b690c1230e',
            acceptedAt: '2020-06-28T16:51:28.258Z',
            canRemove: false,
            canUpdate: false,
            permission: ProjectPermission.Admin,
            user: {
              id: '881bb30f-6495-47d6-8820-7151dc66d208',
              displayName: 'Jane Doe',
              firstName: 'Jane',
              lastName: 'Doe',
            },
          },
          {
            id: '87ade55f-82b4-48c3-a8a7-4f4c297572a6',
            acceptedAt: null,
            canRemove: true,
            canUpdate: true,
            permission: ProjectPermission.Read,
            user: {
              id: '63dd0161-0f5c-4e07-8dd8-07f77188820b',
              displayName: 'x@example.com',
              firstName: null,
              lastName: null,
            },
          },
          {
            id: '78c2f499-4cf6-4c42-9d03-b7e19e3f7a21',
            acceptedAt: null,
            canRemove: true,
            canUpdate: true,
            permission: ProjectPermission.Admin,
            user: {
              ...currentUserSuccessMock.result.data.currentUser,
              displayName: 'John Doe',
            },
          },
        ],
      },
    },
  },
}

export const projectUsersCurrentUserReadOnlySuccessMock = {
  request: {
    query: ProjectUsersDocument,
    variables: {
      id: 'd8b3a18e-ba71-43e6-b35f-88d3d8559691',
    },
  },
  result: {
    data: {
      project: {
        id: projectId,
        projectUsers: [
          {
            id: 'af27e9ca-a639-4bd6-9d11-42b690c1230e',
            acceptedAt: '2020-06-28T16:51:28.258Z',
            canRemove: false,
            canUpdate: false,
            permission: ProjectPermission.Admin,
            user: {
              id: '881bb30f-6495-47d6-8820-7151dc66d208',
              displayName: 'Jane Doe',
              firstName: 'Jane',
              lastName: 'Doe',
            },
          },
          {
            id: '87ade55f-82b4-48c3-a8a7-4f4c297572a6',
            acceptedAt: null,
            canRemove: true,
            canUpdate: true,
            permission: ProjectPermission.Read,
            user: {
              id: '63dd0161-0f5c-4e07-8dd8-07f77188820b',
              displayName: 'x@example.com',
              firstName: null,
              lastName: null,
            },
          },
          {
            id: '78c2f499-4cf6-4c42-9d03-b7e19e3f7a21',
            acceptedAt: null,
            canRemove: true,
            canUpdate: true,
            permission: ProjectPermission.Read,
            user: {
              ...currentUserSuccessMock.result.data.currentUser,
              displayName: 'John Doe',
            },
          },
        ],
      },
    },
  },
}

export const projectUsersFailureMock = {
  request: {
    query: ProjectUsersDocument,
    variables: {
      id: '9a2e1c75-7147-4604-8d80-2ff18a715e68',
    },
  },
  result: {
    data: undefined,
    errors: [
      {
        message: {
          statusCode: 404,
          error: 'Not found',
          message: 'Project not found.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const projectUsersMocks: ProjectUsersMockResponse[] = [
  projectUsersSuccessMock,
  projectUsersCurrentUserReadOnlySuccessMock,
  projectUsersFailureMock,
]
