import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { Fragment } from 'react'

interface StorageProps {
  children?: React.ReactNode
  error: boolean
  loading: boolean
  onResetStorage: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export const Storage: React.FC<StorageProps> = ({
  children,
  error,
  loading,
  onResetStorage,
}) => {
  const classes = useStyles()

  return loading ? (
    <div className={classes.container}>
      <CircularProgress aria-label="Loading" />
    </div>
  ) : error ? (
    <Container className={classes.container} maxWidth="sm">
      <Alert
        severity="error"
        action={
          <Button color="inherit" onClick={onResetStorage} size="small">
            Reset storage
          </Button>
        }
      >
        <AlertTitle>Local storage failed to load</AlertTitle>
        If this persists, please try resetting your local storage. Note: any
        unsaved changes will be lost on storage reset.
      </Alert>
    </Container>
  ) : (
    <Fragment>{children}</Fragment>
  )
}
