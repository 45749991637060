import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  AddProjectUserDocument,
  AddProjectUserMutationResult,
  AddProjectUserMutationVariables,
  ProjectPermission,
} from '../../graphql'
import { projectSuccessMock } from '../../queries'

type AddProjectUserMockResponse = MockResponse<
  AddProjectUserMutationVariables,
  Pick<AddProjectUserMutationResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const addProjectUserSuccessMock = {
  request: {
    query: AddProjectUserDocument,
    variables: {
      id: 'b68a4c4f-736e-42dd-b41b-0cae21b4817c',
      email: 'example@test.com',
      permission: ProjectPermission.Admin,
      projectId: projectSuccessMock.request.variables.id,
    },
  },
  result: {
    data: {
      addProjectUser: {
        id: 'b68a4c4f-736e-42dd-b41b-0cae21b4817c',
        acceptedAt: null,
        canRemove: true,
        canUpdate: true,
        permission: ProjectPermission.Admin,
        project: {
          id: '1415d2c1-b129-42fb-9bac-e15f44f27969',
        },
        user: {
          id: '33f167d0-b0a1-4b77-aa2a-061bdfd1af6b',
          displayName: 'example@test.com',
          firstName: null,
          lastName: null,
        },
      },
    },
  },
}

export const addProjectUserFailureMock = {
  request: {
    query: AddProjectUserDocument,
    variables: {
      id: '75eb37fc-43c0-4833-bfca-5cd629451874',
      email: 'bad_example@test.com',
      permission: ProjectPermission.Admin,
      projectId: projectSuccessMock.request.variables.id,
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 409,
          error: 'Conflict',
          message: 'User already invited.',
        },
      },
    ],
  },
}

export const addProjectUserEmailValidationFailureMock = {
  request: {
    query: AddProjectUserDocument,
    variables: {
      ...addProjectUserSuccessMock.request.variables,
      email: 'x@y.com',
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 400,
          error: 'Bad Request',
          message: [
            {
              property: 'email',
              constraints: {
                minLength: 'Email should be at least 8 characters long.',
              },
            },
          ],
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const addProjectUserMocks: AddProjectUserMockResponse[] = [
  addProjectUserSuccessMock,
  addProjectUserFailureMock,
  addProjectUserEmailValidationFailureMock,
]
