import Container from '@material-ui/core/Container'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { config } from '../../config'
import { goToLogin, goToSignUp } from '../../middleware'

export interface HeroProps {
  title: string
  description: string
}

const interfaceImage = '/images/interface.png'
const backgroundImage = '/images/hero_background.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    background: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      padding: theme.spacing(7, 0),
      [theme.breakpoints.up('sm')]: {
        backgroundSize: 'cover',
        flex: 1,
        padding: theme.spacing(16, 0, 16),
      },
    },
    body: {
      marginBottom: theme.spacing(4),
    },
    column: {
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        maxWidth: 351,
      },
    },
    container: {
      display: 'flex',
      padding: theme.spacing(10, 0, 4, 0),
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        padding: theme.spacing(0),
      },
    },
    containerInner: {
      padding: theme.spacing(0, 3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0),
      },
    },
    image: {
      display: 'block',
      width: '100%',
      height: '100%',
      maxWidth: 713,
    },
    imageContainer: {
      padding: theme.spacing(0, 3),
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    signUp: {
      marginBottom: theme.spacing(2),
    },
    login: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

const buttonText = `Sign up (it's free)`

export const Hero: React.FC<HeroProps> = ({ description, title }) => {
  const history = useHistory()
  const classes = useStyles()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const onLoginClick = useCallback(() => {
    goToLogin(history)
  }, [history])

  const onSignUpClick = useCallback(() => {
    goToSignUp(history)
  }, [history])

  const image = (
    <div className={classes.background}>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={interfaceImage}
          alt={`${config.appName ?? 'App'} interface`}
        />
      </div>
    </div>
  )

  return (
    <Container maxWidth="lg">
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.containerInner}>
            <Typography component="h1" gutterBottom={smUp} variant="h3">
              {title}
            </Typography>
          </div>
          {smUp ? undefined : image}
          <div className={classes.containerInner}>
            <Typography className={classes.body} gutterBottom={true}>
              {description}
            </Typography>
            <Button
              className={classes.signUp}
              color="primary"
              fullWidth={true}
              onClick={onSignUpClick}
              variant="contained"
            >
              {buttonText}
            </Button>
            <div className={classes.login}>
              <Typography component="span">or&nbsp;</Typography>
              <Link component="button" onClick={onLoginClick} variant="body1">
                Login
              </Link>
            </div>
          </div>
        </div>
        {smUp ? image : undefined}
      </div>
    </Container>
  )
}
