import IconButton from '@material-ui/core/IconButton'
import Card, { CardProps } from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Zoom from '@material-ui/core/Zoom'
import AddIcon from '@material-ui/icons/Add'
import FavoriteIcon from '@material-ui/icons/Favorite'
import RemoveIcon from '@material-ui/icons/Remove'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import { clamp, debounce, first, gt, isNil, isUndefined, lt } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { config, loveAmount } from '../../../config'
import { Story, StoryLove, useLoveStory, User } from '../../../middleware'
import { StoryMoreMenu, UserAvatar } from '../../../components'
import { ApiErrors, getGraphQLError } from '../../../util'

type StoryProps = Pick<
  Story,
  | 'id'
  | 'archived'
  | 'canDelete'
  | 'canLove'
  | 'canUpdate'
  | 'need'
  | 'reason'
  | 'role'
  | 'updatedAt'
> & {
  createdBy?: Pick<User, 'id' | 'displayName' | 'firstName' | 'lastName'> | null
  storyLove: (Pick<StoryLove, 'id' | 'love'> & {
    user?: Pick<User, 'id' | 'firstName' | 'lastName'> | null
  })[]
}

export interface StoryCardProps extends CardProps {
  currentUser?: Pick<User, 'id'> | null
  loading: boolean
  projectId?: string
  /**
   * Allow size to be set when used in a list.
   */
  size?: 'desktop' | 'mobile'
  story?: StoryProps
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    maxWidth: 780,
  },
  content: {
    display: 'flex',
    flexDirection: 'column-reverse',
    position: 'relative', // for more button
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      paddingBottom: theme.spacing(7),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  more: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& >:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  fields: {
    flex: 1,
  },
  field: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  divider: {
    maxWidth: theme.spacing(4),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  loveContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      paddingTop: 'initial',
    },
  },
  loveControls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
  },
  love: {
    marginTop: theme.spacing(1),
    '& >*': {
      lineHeight: 1,
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(11),
      marginTop: 'initial',
      marginLeft: theme.spacing(1),
    },
  },
  loveIcon: {
    width: 40,
    height: 40,
  },
  footer: {
    backgroundColor: config.colours.darkGrey,
    color: config.colours.lightText,
    boxShadow: `inset 0px 1px 0px ${config.colours.darkBorder}`,
  },
  footerRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  createdBy: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  avatar: {
    width: 36,
    height: 36,
    ...theme.typography.body2,
  },
  createdByName: {
    marginLeft: theme.spacing(1),
  },
  lastUpdatedContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'initial',
      marginRight: 'initial',
    },
  },
  lastUpdated: {
    color: config.colours.greyText,
    lineHeight: 1.2,
  },
  lovedBy: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  avatarGroup: {
    zIndex: 0, // Prevent overlap with other components
    '& >*': {
      width: 36,
      height: 36,
      boxSizing: 'content-box', // temp until updated. See: https://github.com/mui-org/material-ui/issues/21700#issuecomment-654935690
      borderColor: config.colours.darkGrey,
      // temp until it can be overridden universally.
      // See: https://github.com/mui-org/material-ui/issues/21844
      fontSize: theme.typography.body2.fontSize,
      letterSpacing: theme.typography.body2.letterSpacing,
    },
  },
  skeletonTitle: {
    maxWidth: 160,
  },
  skeletonBody: {
    maxWidth: 476,
  },
  skeletonDark: {
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
  },
}))

export const StoryCard: React.FC<StoryCardProps> = ({
  className,
  currentUser,
  loading,
  projectId,
  story,
  size,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const currentUserLove = useMemo(
    () =>
      story?.storyLove.find((n) => n.user?.id === currentUser?.id)?.love ?? 0,
    [currentUser, story]
  )

  const [state, setState] = useState<{ love: number }>({
    love: currentUserLove,
  })
  const [loveStory] = useLoveStory()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  useEffect(() => {
    setState((state) => ({ ...state, love: currentUserLove }))
  }, [currentUserLove])

  const canAddLove = useMemo(
    () => story?.canLove === true && lt(state.love, loveAmount.max),
    [state.love, story]
  )

  const canRemoveLove = useMemo(
    () => story?.canLove === true && gt(state.love, loveAmount.min),
    [state.love, story]
  )

  const updateLove = useCallback(
    debounce((newLove: number) => {
      if (isUndefined(currentUser)) {
        gtag('event', 'exception', {
          description: `Failed to update love for story. Can't find user information.`,
          fatal: false,
        })
        enqueueSnackbar(
          `Failed to update love for story. Can't find user information.`,
          {
            variant: 'error',
          }
        )
        return
      }
      if (isUndefined(story)) {
        gtag('event', 'exception', {
          description: `Failed to update love for story. Can't find story information.`,
          fatal: false,
        })
        enqueueSnackbar(
          `Failed to update love for story. Can't find story information.`,
          {
            variant: 'error',
          }
        )
        return
      }

      const { id } = story

      loveStory({
        variables: {
          id,
          amount: newLove,
        },
      }).catch((e) => {
        const error = first(getGraphQLError(e as ApiErrors))
        const errorText = isUndefined(error) ? '' : ` ${error}`

        gtag('event', 'exception', {
          description: `Failed to update love for ${id} story.${errorText}`,
          fatal: false,
        })
        enqueueSnackbar(`Failed to update love for story.${errorText}`, {
          variant: 'error',
        })
      })
    }, 275),
    [currentUser, enqueueSnackbar, loveStory, story]
  )

  const onAddLove = useCallback(() => {
    const newLove = clamp(state.love + 1, loveAmount.min, loveAmount.max)

    setState({ ...state, love: newLove })
    updateLove(newLove)
  }, [state, updateLove])

  const onRemoveLove = useCallback(() => {
    const newLove = clamp(state.love - 1, loveAmount.min, loveAmount.max)

    setState({ ...state, love: newLove })
    updateLove(newLove)
  }, [state, updateLove])

  const desktop = isUndefined(size) ? smUp : size === 'desktop'

  const love =
    (story?.storyLove
      .filter((n) => n.user?.id !== currentUser?.id)
      .reduce(
        (prev, cur) => (isNil(cur.love) ? prev : (prev += cur.love)),
        0
      ) ?? 0) + state.love

  const updatedAt = story?.updatedAt

  const lastUpdatedComponent = isNil(updatedAt) ? undefined : (
    <div className={classes.lastUpdatedContainer}>
      <Typography className={classes.lastUpdated} variant="body2">
        Last updated
      </Typography>
      <Typography>{formatDistanceToNow(new Date(updatedAt))}</Typography>
    </div>
  )

  return (
    <Card
      className={clsx([classes.card, className])}
      variant="outlined"
      {...rest}
    >
      <CardContent className={classes.content}>
        {isUndefined(projectId) || isUndefined(story) ? undefined : (
          <div className={classes.more}>
            <Zoom appear={false} in={story.archived}>
              <Chip color="primary" label="Archived" />
            </Zoom>
            <StoryMoreMenu project={{ id: projectId }} story={story} />
          </div>
        )}
        <div className={classes.loveContainer}>
          <div className={classes.loveControls}>
            {loading ? undefined : (
              <IconButton
                aria-label={`Add love`}
                color="secondary"
                disabled={!canAddLove}
                onClick={onAddLove}
                size="small"
              >
                <AddIcon />
              </IconButton>
            )}
            <FavoriteIcon className={classes.loveIcon} color="secondary" />
            {loading ? undefined : (
              <IconButton
                aria-label={`Remove love`}
                color="secondary"
                disabled={!canRemoveLove}
                onClick={onRemoveLove}
                size="small"
              >
                <RemoveIcon />
              </IconButton>
            )}
          </div>
          <div className={classes.love}>
            {loading ? undefined : (
              <Typography color="secondary">{love}</Typography>
            )}
          </div>
        </div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <Typography color="primary">
              {loading ? (
                <Skeleton
                  className={classes.skeletonTitle}
                  title="Loading As a"
                />
              ) : (
                'As a'
              )}
            </Typography>
            <Typography>
              {loading ? (
                <Skeleton
                  className={classes.skeletonBody}
                  title="Loading As a text"
                />
              ) : (
                story?.role ?? ''
              )}
            </Typography>
          </div>
          {desktop ? undefined : <Divider className={classes.divider} />}
          <div className={classes.field}>
            <Typography color="primary">
              {loading ? (
                <Skeleton
                  className={classes.skeletonTitle}
                  title="Loading I need/want/expect"
                />
              ) : (
                'I need/want/expect'
              )}
            </Typography>
            <Typography>
              {loading ? (
                <Skeleton
                  className={classes.skeletonBody}
                  title="Loading I need/want/expect text"
                />
              ) : (
                story?.need ?? ''
              )}
            </Typography>
          </div>
          {desktop ? undefined : <Divider className={classes.divider} />}
          <Typography color="primary">
            {loading ? (
              <Skeleton
                className={classes.skeletonTitle}
                title="Loading So that"
              />
            ) : (
              'So that'
            )}
          </Typography>
          <Typography>
            {loading ? (
              <Skeleton
                className={classes.skeletonBody}
                title="Loading So that text"
              />
            ) : (
              story?.reason ?? ''
            )}
          </Typography>
        </div>
      </CardContent>
      <div className={classes.footer}>
        <div className={classes.footerRow}>
          <div className={classes.createdBy}>
            {loading ? (
              <Skeleton
                className={clsx(classes.avatar, classes.skeletonDark)}
                title="Loading created by avatar"
                variant="circle"
              />
            ) : (
              <UserAvatar className={classes.avatar} user={story?.createdBy} />
            )}
            <Typography className={classes.createdByName}>
              {loading ? (
                <Skeleton
                  className={clsx(classes.createdByName, classes.skeletonDark)}
                  title="Loading created by name"
                  width={80}
                />
              ) : (
                story?.createdBy?.displayName ?? 'User'
              )}
            </Typography>
          </div>
          {desktop ? lastUpdatedComponent : undefined}
          <div className={classes.lovedBy}>
            {love === 0 ? (
              <React.Fragment>
                <FavoriteIcon color="secondary" />
                &nbsp;
                <Typography variant="body2">
                  {loading ? (
                    <Skeleton
                      className={classes.skeletonDark}
                      title="Loading loved by"
                      width={80}
                    />
                  ) : (
                    'by no one yet!'
                  )}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FavoriteIcon color="secondary" />
                <Typography variant="body2">&nbsp;by&nbsp;</Typography>
                <AvatarGroup
                  className={classes.avatarGroup}
                  max={desktop ? 4 : 2}
                >
                  {story?.storyLove.map((lover) => (
                    <UserAvatar key={lover.id} user={lover.user} />
                  ))}
                </AvatarGroup>
              </React.Fragment>
            )}
          </div>
        </div>
        {!desktop && !isUndefined(lastUpdatedComponent) ? (
          <React.Fragment>
            <Divider />
            <div className={classes.footerRow}>{lastUpdatedComponent}</div>
          </React.Fragment>
        ) : undefined}
      </div>
    </Card>
  )
}
