import { GraphQLError } from 'graphql'
import { UpdateUserDocument } from '../../graphql'
import { currentUserSuccessMock } from '../../queries'

export const updateUserSuccessMock = {
  request: {
    query: UpdateUserDocument,
    variables: {
      email: 'example@example.com',
    },
  },
  result: {
    data: {
      updateUser: {
        id: currentUserSuccessMock.result.data.currentUser.id,
        archived: false,
        email: 'example@example.com',
        firstName: currentUserSuccessMock.result.data.currentUser.firstName,
        lastName: currentUserSuccessMock.result.data.currentUser.lastName,
      },
    },
  },
}

export const updateUserFailureMock = {
  request: {
    query: UpdateUserDocument,
    variables: {
      email: 'badExample@example.com',
    },
  },
  result: {
    data: null,
    errors: [new GraphQLError('User does not exist.')],
  },
}

export const updateUserMocks = [updateUserSuccessMock, updateUserFailureMock]
