import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DeleteIcon from '@material-ui/icons/Delete'
import { first, isUndefined, truncate } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import { Story, useDeleteStory } from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

export interface StoryDeleteDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  story: Pick<Story, 'id' | 'need'>
  onClose: () => void
}

export const StoryDeleteDialog: React.FC<StoryDeleteDialogProps> = ({
  story,
  onClose,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [deleteStory] = useDeleteStory()

  const storyDisplayName = truncate(story.need, {
    length: 35,
  })

  const onDelete = useCallback(() => {
    if (isUndefined(story)) return

    deleteStory({
      variables: {
        id: story.id,
      },
    })
      .then(() => {
        enqueueSnackbar(`Successfully deleted story "${storyDisplayName}".`, {
          variant: 'success',
        })
      })
      .catch((e) => {
        const error = first(getGraphQLError(e as ApiErrors))
        const errorText = isUndefined(error) ? '' : ` ${error}`

        gtag('event', 'exception', {
          description: `Failed to delete ${story.id} story.${errorText}`,
          fatal: false,
        })
        enqueueSnackbar(
          `Failed to delete "${storyDisplayName}" from project.${errorText}`,
          {
            variant: 'error',
          }
        )
      })
    onClose()
  }, [deleteStory, enqueueSnackbar, onClose, story, storyDisplayName])

  return (
    <Dialog
      aria-labelledby="delete-story-dialog-title"
      aria-describedby="delete-story-dialog-description"
      onClose={onClose}
      {...rest}
    >
      <DialogTitle id="delete-story-dialog-title">{`Delete story "${storyDisplayName}"?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-story-dialog-description">
          This will permanently delete the story and all data within it.
          Re-submitting the story will be the only way to restore.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus={true} onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={onDelete}
          startIcon={<DeleteIcon />}
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
