import Button, { ButtonProps } from '@material-ui/core/Button'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import SendIcon from '@material-ui/icons/Send'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import {
  ProjectPermissionSelect,
  ProjectPermissionSelectProps,
} from '../../../components'

type Form = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>

export interface ProjectInviteFormProps extends Form {
  emailField?: TextFieldProps & { disabledMessage?: string }
  permissionField?: ProjectPermissionSelectProps & { disabledMessage?: string }
  submitButton?: ButtonProps & { disabledMessage?: string }
  loading: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(1),
      width: '100%',
      alignItems: 'flex-start',
      '& >:first-child': {
        gridColumnEnd: 'span 2',
        [theme.breakpoints.up('sm')]: {
          gridColumnEnd: 'auto',
        },
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '2fr repeat(2, 1fr)',
      },
    },
    submit: {
      height: '100%',
    },
  })
)

export const ProjectInviteForm: React.FC<ProjectInviteFormProps> = ({
  emailField,
  permissionField,
  submitButton,
  loading,
  ...rest
}) => {
  const classes = useStyles()
  const { disabledMessage: emailDisabledMessage, ...emailProps } =
    emailField ?? {}
  const { disabledMessage: permissionDisabledMessage, ...permissionProps } =
    permissionField ?? {}
  const { disabledMessage: submitDisabledMessage, ...submitProps } =
    submitButton ?? {}

  return loading ? (
    <div className={classes.form}>
      <Skeleton width={'100%'} height={54} title="Loading email" />
      <Skeleton width={'100%'} height={54} title="Loading permission" />
      <Skeleton width={'100%'} height={54} title="Loading invite" />
    </div>
  ) : (
    <form className={classes.form} {...rest}>
      <Tooltip title={emailDisabledMessage ?? ''}>
        <TextField
          FormHelperTextProps={{
            'aria-label': 'Email helper text',
          }}
          id="email-field"
          label="Email"
          placeholder="e.g. friend@email.com"
          variant="outlined"
          {...emailProps}
        />
      </Tooltip>
      <Tooltip title={permissionDisabledMessage ?? ''}>
        <span>
          <ProjectPermissionSelect
            fullWidth={true}
            FormHelperTextProps={{
              'aria-label': 'Permission helper text',
            }}
            id="permission-field"
            label="Permission"
            options={[]}
            variant="outlined"
            {...permissionProps}
          />
        </span>
      </Tooltip>
      <Tooltip title={submitDisabledMessage ?? ''}>
        <span className={classes.submit}>
          <Button
            className={classes.submit}
            fullWidth={true}
            variant="outlined"
            startIcon={<SendIcon color="primary" />}
            type="submit"
            {...submitProps}
          >
            Invite
          </Button>
        </span>
      </Tooltip>
    </form>
  )
}
