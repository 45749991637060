import { GraphQLError } from 'graphql'
import { VerifyEmailDocument } from '../../graphql'
import { currentUserSuccessMock } from '../../queries'

export const verifyEmailSuccessMock = {
  request: {
    query: VerifyEmailDocument,
    variables: {
      verificationCode:
        '7fc64caf62757825a84ec933d09678cc63a79edd24ed642d35e52879a4d8059acdce2bd140456af974ed16d87cafa7eeafc1ef2d45a03f4690bccf64168f246d',
    },
  },
  result: {
    data: {
      verifyEmail: {
        id: currentUserSuccessMock.result.data.currentUser.id,
        emailVerified: true,
      },
    },
  },
}

export const verifyEmailFailureMock = {
  request: {
    query: VerifyEmailDocument,
    variables: {
      verificationCode: 'nope',
    },
  },
  result: {
    data: null,
    errors: [new GraphQLError('Email verification code does not match.')],
  },
}

export const verifyEmailMocks = [verifyEmailSuccessMock, verifyEmailFailureMock]
