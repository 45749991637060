import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { first, isEmpty, isNil, isUndefined, mapValues } from 'lodash'
import { useSnackbar } from 'notistack'
import {
  goToLogin,
  TokenQuery,
  TokenQueryVariables,
  TokenDocument,
  resetPasswordValidate,
  useResetPasswordMutation,
} from '../../../../middleware'
import { getGraphQLError, getGraphQLValidationErrors } from '../../../../util'
import { ResetPasswordForm, ResetPasswordFormProps } from '../ResetPasswordForm'

export type UserResetPasswordFormProps = Omit<
  ResetPasswordFormProps,
  'loading'
> & {
  verificationCode: string
}

interface Fields {
  email: string
  password: string
}

const initialState = {
  email: '',
  password: '',
}

export const UserResetPasswordForm: React.FC<UserResetPasswordFormProps> = ({
  verificationCode,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState<Fields>(initialState)

  const [
    resetPassword,
    { data: resetPasswordData, loading: signingUp, error: resetPasswordError },
  ] = useResetPasswordMutation({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to reset password.${errorText}`,
        fatal: true,
      })
    },
    update: (cache, { data }) => {
      if (isNil(data)) return

      const token = data.resetPassword

      cache.writeQuery<TokenQuery, TokenQueryVariables>({
        query: TokenDocument,
        data: {
          token,
        },
      })
    },
  })

  useEffect(() => {
    if (!isUndefined(resetPasswordData?.resetPassword.accessToken))
      setState(initialState)
  }, [resetPasswordData])

  const error = useMemo(() => {
    if (isUndefined(resetPasswordError)) return

    return first(getGraphQLError(resetPasswordError))
  }, [resetPasswordError])

  const errorsLocal = useMemo(() => {
    const errors = resetPasswordValidate(state)

    return mapValues(errors, (n) => first(n))
  }, [state])

  const errorsServer = useMemo(() => {
    if (isUndefined(resetPasswordError)) return {}

    const errors = first(getGraphQLValidationErrors(resetPasswordError))

    return mapValues(errors, (n) => first(n))
  }, [resetPasswordError])

  const onEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState({ ...state, email: event.currentTarget.value })
    },
    [state]
  )

  const onPasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState({ ...state, password: event.currentTarget.value })
    },
    [state]
  )

  const onLogin = useCallback(() => {
    goToLogin(history)
  }, [history])

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (!isEmpty(errorsLocal)) return

      resetPassword({
        variables: { verificationCode, ...state },
      })
        .then(() => {
          enqueueSnackbar('Password reset successful', {
            variant: 'success',
          })
        })
        .catch((_) => {
          setState({ ...state, password: '' })

          // Handled above in `resetPasswordError`
          // See: https://github.com/apollographql/apollo-client/issues/3963
        })
    },
    [enqueueSnackbar, errorsLocal, resetPassword, state, verificationCode]
  )

  return (
    <ResetPasswordForm
      error={error}
      emailField={{
        disabled: signingUp,
        error:
          (Boolean(state.email) && Boolean(errorsLocal.email)) ||
          Boolean(errorsServer.email),
        helperText:
          Boolean(state.email) && !isUndefined(errorsLocal.email)
            ? errorsLocal.email
            : errorsServer.email,
        value: state.email,
        onChange: onEmailChange,
      }}
      loading={signingUp}
      passwordField={{
        disabled: signingUp,
        error:
          (Boolean(state.password) && Boolean(errorsLocal.password)) ||
          Boolean(errorsServer.password),
        helperText:
          Boolean(state.password) && !isUndefined(errorsLocal.password)
            ? errorsLocal.password
            : errorsServer.password,
        value: state.password,
        onChange: onPasswordChange,
      }}
      loginButton={{ onClick: onLogin }}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}
