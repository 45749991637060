import { plainToClass } from 'class-transformer'
import {
  IsEmail,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
  validateSync,
} from 'class-validator'
import { UpdateUserMutationVariables } from '../../graphql'
import { getValidationErrors } from '../../../../util'

export class UpdateUserDto {
  @IsEmail(undefined, {
    message:
      'Please enter email in email address format. E.g. johnsmith@example.com.',
  })
  @MinLength(3, {
    message: 'Email should be at least $constraint1 characters long.',
  })
  @MaxLength(255, {
    message: 'Email should be at most $constraint1 characters long.',
  })
  @IsOptional()
  email!: string

  @IsString()
  @MinLength(1, {
    message: 'First name should be at least $constraint1 character long.',
  })
  @MaxLength(255, {
    message: 'First name should be at most $constraint1 characters long.',
  })
  @IsOptional()
  firstName!: string

  @IsString()
  @MinLength(1, {
    message: 'Last name should be at least $constraint1 character long.',
  })
  @MaxLength(255, {
    message: 'Last name should be at most $constraint1 characters long.',
  })
  @IsOptional()
  lastName!: string

  @IsString()
  @MinLength(12, {
    message: 'Password should be at least $constraint1 characters long.',
  })
  @MaxLength(64, {
    message: 'Password should be at most $constraint1 characters long.',
  })
  @IsOptional()
  password!: string
}

export const updateUserValidate = (vars: UpdateUserMutationVariables) =>
  getValidationErrors(validateSync(plainToClass(UpdateUserDto, vars)))
