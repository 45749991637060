import Avatar, { AvatarProps } from '@material-ui/core/Avatar'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import { compact, first, isNil, isUndefined } from 'lodash'
import React, { useMemo } from 'react'
import { config } from '../../../config'
import { User } from '../../../middleware'

interface UserAvatarProps extends AvatarProps {
  loading?: boolean
  user?: Pick<User, 'id' | 'firstName' | 'lastName'> | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeleton: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  })
)

const COLOURS = [config.colours.primary, '#2C4D9A', '#E6A92F', '#E6822F']

/**
 * Picks a pseudo random colour based off of a unique ID,
 * assigning the same colour with the same ID.
 *
 * @param id A string representing a unique ID.
 */
const pickColourById = (id: string) => {
  const index = Array.from(id)
    .map((n) => n.charCodeAt(0))
    .reduce((current, prev) => current + prev, 0)

  return COLOURS[index % COLOURS.length]
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  className,
  loading,
  style,
  user,
  ...rest
}) => {
  const classes = useStyles()
  const firstName = user?.firstName
  const lastName = user?.lastName

  const backgroundColor = useMemo(
    () => (isNil(user) ? undefined : pickColourById(user.id)),
    [user]
  )

  const initials =
    isNil(firstName) && isNil(lastName)
      ? '?'
      : compact([firstName, lastName].map((n) => first(n))).join('')

  if (isUndefined(user) && !isUndefined(loading) && loading)
    return (
      <Skeleton
        title="Loading avatar"
        className={clsx(classes.skeleton, className)}
        variant="circle"
      />
    )

  return (
    <Avatar
      className={className}
      style={{
        backgroundColor,
        ...style,
      }}
      {...rest}
    >
      {initials}
    </Avatar>
  )
}
