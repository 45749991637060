import { GraphQLError } from 'graphql'
import { CurrentUserDocument } from '../../graphql'

export const currentUserSuccessMock = {
  request: {
    query: CurrentUserDocument,
    variables: {},
  },
  result: {
    data: {
      currentUser: {
        id: '85aba80d-42a2-4267-8205-8721517ad339',
        canCreateProject: true,
        email: 'john@example.com',
        emailVerified: true,
        firstName: 'John',
        lastName: 'Doe',
      },
    },
  },
}

export const currentUserEmailUnverifiedSuccessMock = {
  request: {
    query: CurrentUserDocument,
    variables: {},
  },
  result: {
    data: {
      currentUser: {
        id: '753224ac-dcdc-42a3-8092-5ca1063408c7',
        canCreateProject: true,
        email: 'unverified@example.com',
        emailVerified: false,
        firstName: 'Un',
        lastName: 'Verified',
      },
    },
  },
}

export const currentUserNoCanCreateProjectCreateSuccessMock = {
  request: {
    query: CurrentUserDocument,
    variables: {},
  },
  result: {
    data: {
      currentUser: {
        id: 'a182ff4c-67a8-46a4-b0cb-8ab5b5894d5a',
        canCreateProject: false,
        email: 'john@example.com',
        emailVerified: false,
        firstName: 'John',
        lastName: 'Doe',
      },
    },
  },
}

export const currentUserFailureMock = {
  request: {
    query: CurrentUserDocument,
    variables: {},
  },
  result: {
    data: undefined,
    errors: [new GraphQLError('User not found.')],
  },
}

export const currentUserMocks = [currentUserSuccessMock]
