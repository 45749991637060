import { MockResponse, NestJsError } from '../../../../util'
import {
  DeleteProjectDocument,
  DeleteProjectMutationResult,
  DeleteProjectMutationVariables,
} from '../../graphql'
import { projectLongNameSuccessMock, projectsSuccessMock } from '../../queries'

type DeleteProjectMockResponse = MockResponse<
  DeleteProjectMutationVariables,
  Pick<DeleteProjectMutationResult, 'data'> & {
    errors?: { message: NestJsError }[]
  }
>

export const deleteProjectSuccessMock = {
  request: {
    query: DeleteProjectDocument,
    variables: {
      id: projectsSuccessMock.result.data.projects.edges[0].node.id,
    },
  },
  result: {
    data: {
      deleteProject: projectsSuccessMock.result.data.projects.edges[0].node,
    },
  },
}

export const deleteProjectLongNameSuccessMock = {
  request: {
    query: DeleteProjectDocument,
    variables: {
      id: projectLongNameSuccessMock.result.data.project.id,
    },
  },
  result: {
    data: {
      deleteProject: projectLongNameSuccessMock.result.data.project,
    },
  },
}

export const deleteProjectFailureMock = {
  request: {
    query: DeleteProjectDocument,
    variables: {
      id: projectsSuccessMock.result.data.projects.edges[1].node.id,
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 404,
          error: 'Not found',
          message: 'Project not found.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const deleteProjectMocks: DeleteProjectMockResponse[] = [
  deleteProjectSuccessMock,
  deleteProjectLongNameSuccessMock,
  deleteProjectFailureMock,
]
