import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  CreateSubscriptionDocument,
  CreateSubscriptionMutationResult,
  CreateSubscriptionMutationVariables,
} from '../../graphql'
import { currentUserSuccessMock } from '../../queries'

type CreateSubscriptionMockResponse = MockResponse<
  CreateSubscriptionMutationVariables,
  Pick<CreateSubscriptionMutationResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const createSubscriptionSuccessMock = {
  request: {
    query: CreateSubscriptionDocument,
    variables: {
      priceId: '2588cf1a-5a04-48a8-8636-3503c80136bc',
      quantity: 1,
      stripePaymentMethodId: 'pm_1HEdZeGjdkMBdvcrWQu0Z3aX',
    },
  },
  result: {
    data: {
      createSubscription: {
        id: currentUserSuccessMock.result.data.currentUser.id,
        contributorQuota: 9,
        contributorUsage: 9,
        projectQuota: 3,
        projectUsage: 2,
        storyQuota: 300,
        storyUsage: 20,
        subscriptions: [
          {
            id: 'b9b4ce9c-144a-436c-a579-82bc81a2f88d',
            cancelAtPeriodEnd: false,
            currentPeriodEnd: '2020-09-13T19:19:03.000Z',
            items: [
              {
                id: 'd696b4df-b318-4680-afc6-22fc930a5188',
                price: {
                  id: 'ac66d030-715c-4df4-8911-fc38ba89bc57',
                  unitAmount: 1,
                  product: {
                    id: '1a93252c-6add-4931-9fce-f86d54bf1eac',
                    name: 'Pro',
                  },
                },
                quantity: 1,
              },
            ],
          },
        ],
      },
    },
  },
}

export const createSubscriptionFailureMock = {
  request: {
    query: CreateSubscriptionDocument,
    variables: {
      priceId: 'e20713ae-d7ef-4bd4-93fe-efc8fabcb6f7',
      quantity: 1,
      stripePaymentMethodId: 'pm_1HEdZeGjdkMBdvcrWQu0Z3aX',
    },
  },
  result: {
    errors: [
      {
        message: {
          statusCode: 400,
          error: 'Bad Request',
          message: 'Quantity must be greater than 10 for this priceId.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const createSubscriptionMocks: CreateSubscriptionMockResponse[] = [
  createSubscriptionSuccessMock,
  createSubscriptionFailureMock,
]
