import Container from '@material-ui/core/Container'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { Fragment } from 'react'
import {
  BrochureLayout,
  CTA,
  Features,
  Hero,
  HowItWorks,
  SubscriptionCards,
} from '../../../components'

const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        maxWidth: 380,
      },
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(7),
      },
    },
    title: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(1.5),
      [theme.breakpoints.up('lg')]: {
        paddingBottom: theme.spacing(4),
      },
    },
  })
)

export const LandingPage: React.FC = () => {
  const classes = useStyles()

  return (
    <BrochureLayout>
      <Fragment>
        <Hero
          title="Discover requirements"
          description="Capture business requirements, wants, needs and ideas in one place
              from clients or from within your business and easily find the
              highest priorities using our voting system."
        />
        <HowItWorks />
        <CTA />
        <Features />
        <CTA />
        <Container maxWidth="md">
          <Typography align="center" className={classes.title} variant="h3">
            Need more?
          </Typography>
          <Typography align="center" className={classes.description}>
            It’s completely free to use, but you can upgrade to scale up or to
            make use of some of our premium features. Cancel anytime.
          </Typography>
        </Container>
        <SubscriptionCards />
      </Fragment>
    </BrochureLayout>
  )
}
