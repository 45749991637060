import * as ApolloReactHooks from '@apollo/client'
import { isNil } from 'lodash'
import {
  UpdateStoryMutation,
  UpdateStoryMutationVariables,
  Story,
  StoryFragmentDoc,
  useUpdateStoryMutation,
} from '../../graphql'

export const useUpdateStory = (
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStoryMutation,
    UpdateStoryMutationVariables
  >
) =>
  useUpdateStoryMutation({
    update: (cache, { data: updateData }) => {
      if (isNil(updateData)) return

      const { __typename, id, ...data } = updateData.updateStory

      const prev = cache.readFragment<Story>({
        id: `Story:${id}`,
        fragment: StoryFragmentDoc,
      })

      cache.writeFragment({
        id: `Story:${id}`,
        fragment: StoryFragmentDoc,
        data: { ...prev, ...data },
      })
    },
    ...baseOptions,
  })
