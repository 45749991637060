import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { first, isEmpty, isNil, isNull, isUndefined, mapValues } from 'lodash'
import { useSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import {
  createProjectValidate,
  goToProjectInvite,
  ProjectsQuery,
  ProjectsQueryVariables,
  ProjectsDocument,
  useCreateProjectMutation,
} from '../../../../middleware'
import {
  ApiErrors,
  getGraphQLError,
  getGraphQLValidationErrors,
} from '../../../../util'
import { CreateProjectForm, CreateProjectFormProps } from '../CreateProjectForm'

export type UserCreateProjectFormProps = Omit<CreateProjectFormProps, 'loading'>

interface Fields {
  name: string
}

const initialState = {
  name: '',
}

export const UserCreateProjectForm: React.FC<UserCreateProjectFormProps> = (
  props
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState<Fields>(initialState)

  const [
    createProject,
    { error: createProjectError },
  ] = useCreateProjectMutation({
    onCompleted: (data) => {
      if (!isNil(data)) goToProjectInvite(history, data.createProject.id)
    },
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      createProject: {
        __typename: 'Project',
        canDelete: true,
        canUpdate: true,
        ...variables,
      },
    }),
    update: (cache, { data }) => {
      const node = data?.createProject

      if (isNil(node)) return

      // Workaround for errors thown on uncached queries.
      // To be updated in future versions of apollo.
      // See: https://github.com/apollographql/apollo-feature-requests/issues/1
      let cacheData: ProjectsQuery | null = null
      try {
        cacheData = cache.readQuery<ProjectsQuery, ProjectsQueryVariables>({
          query: ProjectsDocument,
        })
      } catch {}

      if (isNull(cacheData)) return

      const edge = {
        __typename: 'ProjectEdge' as 'ProjectEdge',
        cursor: '',
        node,
      }
      const edges = [...cacheData.projects.edges, edge].sort((a, b) =>
        a.node.name.localeCompare(b.node.name)
      )
      const totalCount = cacheData.projects.totalCount + 1

      cache.writeQuery<ProjectsQuery, ProjectsQueryVariables>({
        query: ProjectsDocument,
        data: {
          ...cacheData,
          projects: {
            ...cacheData.projects,
            totalCount,
            edges,
          },
        },
      })
    },
  })

  const localValidationErrors = useMemo(() => {
    const errors = createProjectValidate(state)

    return mapValues(errors, (n) => first(n))
  }, [state])

  const serverValidationErrors = useMemo(() => {
    if (isUndefined(createProjectError)) return {}

    const errors = first(getGraphQLValidationErrors(createProjectError))

    return mapValues(errors, (n) => first(n))
  }, [createProjectError])

  const onCancel = useCallback(() => {
    history.goBack()
  }, [history])

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState({ ...state, name: event.currentTarget.value })
    },
    [state]
  )

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (!isEmpty(localValidationErrors)) return
      const id = uuid()

      setState(initialState)
      createProject({
        variables: {
          id,
          ...state,
        },
      }).catch((e) => {
        const error = first(getGraphQLError(e as ApiErrors))
        const errorText = isUndefined(error) ? '' : ` ${error}`

        gtag('event', 'exception', {
          description: `Failed to create ${id} project.${errorText}`,
          fatal: false,
        })
        enqueueSnackbar(
          `Failed to create "${state.name}" project.${errorText}`,
          {
            variant: 'error',
          }
        )
      })
    },
    [localValidationErrors, createProject, state, enqueueSnackbar]
  )

  return (
    <CreateProjectForm
      cancelButton={{
        onClick: onCancel,
      }}
      nameField={{
        error:
          (Boolean(state.name) && Boolean(localValidationErrors.name)) ||
          Boolean(serverValidationErrors.name),
        helperText:
          Boolean(state.name) && !isUndefined(localValidationErrors.name)
            ? localValidationErrors.name
            : serverValidationErrors.name,
        value: state.name,
        onChange: onNameChange,
      }}
      onSubmit={onSubmit}
      {...props}
    />
  )
}
