import Button, { ButtonProps } from '@material-ui/core/Button'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import { isUndefined } from 'lodash'
import React from 'react'
import { ArrayParam, BooleanParam, useQueryParams } from 'use-query-params'

type ClearFiltersButtonProps = ButtonProps

export const ClearFiltersButton: React.FC<ClearFiltersButtonProps> = (
  props
) => {
  const [filters, setFilters] = useQueryParams({
    createdBy: ArrayParam,
    lovedBy: ArrayParam,
    roles: ArrayParam,
    showArchived: BooleanParam,
  })

  const onChange = () => {
    setFilters({
      createdBy: undefined,
      lovedBy: undefined,
      roles: undefined,
      showArchived: undefined,
    })
  }

  return Object.values(filters).every(isUndefined) ? null : (
    <Button
      onClick={onChange}
      startIcon={<SettingsBackupRestoreIcon color="primary" />}
      variant="outlined"
      {...props}
    >
      Reset filters
    </Button>
  )
}
