import { GraphQLError } from 'graphql'
import { ProjectsDocument, Sort } from '../../graphql'

export const projectsSuccessMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      first: 50,
    },
  },
  result: {
    data: {
      projects: {
        totalCount: 2,
        pageInfo: {
          endCursor: 'MDIxMDhhMTMtODNlMS00MjM1LWI2ZTktMTY2YTcyMjkwZjNl',
          hasNextPage: false,
        },
        edges: [
          {
            cursor: 'MzI0ZGI0YmUtNDBhZS00YTQ4LWIyMDMtOWQwYTM5NTE4ZTZi',
            node: {
              id: '324db4be-40ae-4a48-b203-9d0a39518e6b',
              canDelete: true,
              canUpdate: true,
              name: 'Example project 1',
            },
          },
          {
            cursor: 'MDIxMDhhMTMtODNlMS00MjM1LWI2ZTktMTY2YTcyMjkwZjNl',
            node: {
              id: '02108a13-83e1-4235-b6e9-166a72290f3e',
              canDelete: true,
              canUpdate: true,
              name: 'Example project 2',
            },
          },
        ],
      },
    },
  },
}

export const recentProjectMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      first: 1,
      sort: Sort.CreatedAt,
    },
  },
  result: {
    data: {
      projects: {
        totalCount: 1,
        pageInfo: {
          endCursor: 'ZDVlOGU1ZDUtYzk5ZC00MzllLWE1N2MtYjZiZGEzZWRhYjE4',
          hasNextPage: false,
        },
        edges: [
          {
            cursor: 'ZDVlOGU1ZDUtYzk5ZC00MzllLWE1N2MtYjZiZGEzZWRhYjE4',
            node: {
              id: 'd5e8e5d5-c99d-439e-a57c-b6bda3edab18',
              canDelete: true,
              canUpdate: true,
              name: 'Recent project',
            },
          },
        ],
      },
    },
  },
}

export const recentProjectsMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      first: 2,
      sort: Sort.CreatedAt,
    },
  },
  result: {
    data: {
      projects: {
        totalCount: 1,
        pageInfo: {
          endCursor: 'ZmM1NDg4MDktMzMzMC00MGFjLWIxNjItMTEwMTczOGYyMzZh',
          hasNextPage: false,
        },
        edges: [
          {
            cursor: 'MWU3Yzg1NzAtYWMyZS00ZmU3LTg5OTktYjc5NWE1ZTFiYmUx',
            node: {
              id: '1e7c8570-ac2e-4fe7-8999-b795a5e1bbe1',
              canDelete: true,
              canUpdate: true,
              name: 'Recent project',
            },
          },
          {
            cursor: 'ZmM1NDg4MDktMzMzMC00MGFjLWIxNjItMTEwMTczOGYyMzZh',
            node: {
              id: 'fc548809-3330-40ac-b162-1101738f236a',
              canDelete: true,
              canUpdate: true,
              name: 'Recent project 2',
            },
          },
        ],
      },
    },
  },
}

export const recentProjectsErrorMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      first: 2,
      sort: Sort.CreatedAt,
    },
  },
  result: {
    data: undefined,
    errors: [new GraphQLError('Internal Server Error')],
  },
}

export const recentProjectEmptyMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      first: 1,
      sort: Sort.CreatedAt,
    },
  },
  result: {
    data: {
      projects: {
        totalCount: 0,
        pageInfo: {
          endCursor: null,
          hasNextPage: false,
        },
        edges: [],
      },
    },
  },
}

export const recentProjectErrorMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      first: 1,
      sort: Sort.CreatedAt,
    },
  },
  result: {
    data: undefined,
    errors: [new GraphQLError('Internal Server Error')],
  },
}

export const projectsPaginated1SuccessMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      first: 2,
    },
  },
  result: {
    data: {
      projects: {
        totalCount: 3,
        pageInfo: {
          endCursor: 'MDIxMDhhMTMtODNlMS00MjM1LWI2ZTktMTY2YTcyMjkwZjNl',
          hasNextPage: true,
        },
        edges: [
          {
            cursor: 'MzI0ZGI0YmUtNDBhZS00YTQ4LWIyMDMtOWQwYTM5NTE4ZTZi',
            node: {
              id: '324db4be-40ae-4a48-b203-9d0a39518e6b',
              canDelete: true,
              canUpdate: true,
              name: 'Example project 1',
            },
          },
          {
            cursor: 'MDIxMDhhMTMtODNlMS00MjM1LWI2ZTktMTY2YTcyMjkwZjNl',
            node: {
              id: '02108a13-83e1-4235-b6e9-166a72290f3e',
              canDelete: true,
              canUpdate: true,
              name: 'Example project 2',
            },
          },
        ],
      },
    },
  },
}

export const projectsPaginated2SuccessMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      after: projectsSuccessMock.result.data.projects.pageInfo.endCursor,
      first: 2,
    },
  },
  result: {
    data: {
      projects: {
        totalCount: 3,
        pageInfo: {
          endCursor: 'NDc3OWZlYjMtNTFlYS00ZTBmLTljZWYtMDM5ZDEyNjVjYmMw',
          hasNextPage: false,
        },
        edges: [
          {
            cursor: 'NDc3OWZlYjMtNTFlYS00ZTBmLTljZWYtMDM5ZDEyNjVjYmMw',
            node: {
              id: '4779feb3-51ea-4e0f-9cef-039d1265cbc0',
              canDelete: true,
              canUpdate: true,
              name: 'Example project 3',
            },
          },
        ],
      },
    },
  },
}

export const projectsFailureMock = {
  request: {
    query: ProjectsDocument,
    variables: {
      first: -1,
    },
  },
  result: {
    data: undefined,
    errors: [new GraphQLError('Bad Request')],
  },
}

export const projectsMocks = [
  projectsSuccessMock,
  projectsPaginated1SuccessMock,
  projectsPaginated2SuccessMock,
  projectsFailureMock,
]
