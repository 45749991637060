import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  UpdateProjectUserDocument,
  UpdateProjectUserMutationResult,
  UpdateProjectUserMutationVariables,
  ProjectPermission,
} from '../../graphql'
import { projectUsersSuccessMock } from '../../queries'

type UpdateProjectUserMockResponse = MockResponse<
  UpdateProjectUserMutationVariables,
  Pick<UpdateProjectUserMutationResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const updateProjectUserSuccessMock = {
  request: {
    query: UpdateProjectUserDocument,
    variables: {
      id: projectUsersSuccessMock.result.data.project.projectUsers[1].id,
      permission: ProjectPermission.Write,
    },
  },
  result: {
    data: {
      updateProjectUser: {
        id: projectUsersSuccessMock.result.data.project.projectUsers[1].id,
        permission: ProjectPermission.Write,
      },
    },
  },
}

export const updateProjectUserFailureMock = {
  request: {
    query: UpdateProjectUserDocument,
    variables: {
      id: projectUsersSuccessMock.result.data.project.projectUsers[1].id,
      permission: ProjectPermission.Admin,
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 500,
          error: 'Internal Sever Error',
          message: 'Could not save user permission at this time.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const updateProjectUserMocks: UpdateProjectUserMockResponse[] = [
  updateProjectUserSuccessMock,
  updateProjectUserFailureMock,
]
