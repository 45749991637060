import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { first, isUndefined, truncate } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { StoryDeleteDialog } from '../../../components'
import {
  goToUpdateStory,
  Project,
  Story,
  useUpdateStory,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

export interface StoryMoreMenuProps {
  project: Pick<Project, 'id'>
  story: Pick<
    Story,
    'id' | 'archived' | 'canDelete' | 'canUpdate' | 'need' | 'reason' | 'role'
  >
}

export const StoryMoreMenu: React.FC<StoryMoreMenuProps> = ({
  project,
  story,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const history = useHistory()
  const [state, setState] = useState<{
    anchorEl: HTMLElement | null
    deleteOpen: boolean
  }>({
    anchorEl: null,
    deleteOpen: false,
  })
  const { enqueueSnackbar } = useSnackbar()
  const [updateStory] = useUpdateStory()

  const onOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setState({ ...state, anchorEl: event.currentTarget })
    },
    [state]
  )

  const onClose = useCallback(() => {
    setState({ ...state, anchorEl: null })
  }, [state])

  const onArchiveClick = useCallback(() => {
    setState({ ...state, anchorEl: null })
    const archived = !story.archived
    const storyDisplayName = truncate(story.need, {
      length: 35,
    })

    updateStory({
      variables: {
        id: story.id,
        archived,
      },
      optimisticResponse: isUndefined(story)
        ? undefined
        : () => ({
            __typename: 'Mutation',
            updateStory: {
              __typename: 'Story',
              ...story,
              archived,
            },
          }),
    })
      .then(() => {
        enqueueSnackbar(
          `Successfully ${
            archived ? 'archived' : 'unarchived'
          } story "${storyDisplayName}".`,
          {
            variant: 'success',
          }
        )
      })
      .catch((e) => {
        const error = first(getGraphQLError(e as ApiErrors))
        const errorText = isUndefined(error) ? '' : ` ${error}`

        gtag('event', 'exception', {
          description: `Failed to ${archived ? 'archive' : 'unarchive'} ${
            story.id
          } story.${errorText}`,
          fatal: false,
        })
        enqueueSnackbar(
          `Failed to ${
            archived ? 'archive' : 'unarchive'
          } "${storyDisplayName}" story.${errorText}`,
          {
            variant: 'error',
          }
        )
      })
  }, [enqueueSnackbar, state, story, updateStory])

  const onEditClick = useCallback(() => {
    setState({ ...state, anchorEl: null })
    goToUpdateStory(history, project.id, story.id, {
      search: history.location.search, // keep current query
    })
  }, [history, project.id, state, story.id])

  const onDeleteOpen = useCallback(() => {
    setState({ ...state, anchorEl: null, deleteOpen: true })
  }, [state])

  const onDeleteClose = useCallback(() => {
    setState({ ...state, deleteOpen: false })
  }, [state])

  const { anchorEl, deleteOpen } = state

  return story.canDelete || story.canUpdate ? (
    <div>
      <IconButton
        aria-label="Story menu"
        aria-controls={`story-menu-${story.id}`}
        aria-haspopup="true"
        onClick={onOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`story-menu-${story.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {story.canUpdate ? (
          <MenuItem onClick={onEditClick}>Edit</MenuItem>
        ) : undefined}
        {story.canUpdate ? (
          <MenuItem onClick={onArchiveClick}>
            {story.archived ? 'Unarchive' : 'Archive'}
          </MenuItem>
        ) : undefined}
        {story.canDelete ? (
          <MenuItem onClick={onDeleteOpen}>Delete</MenuItem>
        ) : undefined}
      </Menu>
      <StoryDeleteDialog
        open={deleteOpen}
        story={story}
        onClose={onDeleteClose}
      />
    </div>
  ) : null
}
