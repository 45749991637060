import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { first, isUndefined } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Fragment, useCallback, useEffect } from 'react'
import {
  useResendEmailVerificationMutation,
  useVerifyEmailMutation,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

export interface EmailVerificationProgressProps {
  children?: React.ReactNode
  verificationCode: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      flex: 1,
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    description: {
      marginBottom: theme.spacing(5),
    },
  })
)

const defaultErrorMessage =
  'Please try a refresh. If this persists, please try closing all tabs with the site open and then returning in a new tab.'

export const EmailVerificationProgress: React.FC<EmailVerificationProgressProps> = ({
  children,
  verificationCode,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [verifyEmail, { data, error }] = useVerifyEmailMutation({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to verify email.${errorText}`,
        fatal: true,
      })
    },
    variables: {
      verificationCode,
    },
  })
  const [resendEmailVerification] = useResendEmailVerificationMutation()

  const onResend = useCallback(() => {
    resendEmailVerification()
      .then(() => {
        enqueueSnackbar(
          'Successfully resent email verification. Please check your emails.',
          {
            variant: 'success',
          }
        )
      })
      .catch((e) => {
        const error = first(getGraphQLError(e as ApiErrors))
        const errorText = isUndefined(error) ? '' : ` ${error}`

        gtag('event', 'exception', {
          description: `Failed to resend email verification.${errorText}`,
          fatal: true,
        })
        enqueueSnackbar(`Failed to resend email verification.${errorText}`, {
          variant: 'error',
        })
      })
  }, [enqueueSnackbar, resendEmailVerification])

  useEffect(() => {
    verifyEmail()
      .then(() => {
        enqueueSnackbar('Email verification successful', {
          variant: 'success',
        })
      })
      .catch(() => {}) // Error handled above
  }, [enqueueSnackbar, verifyEmail])

  return data?.verifyEmail.emailVerified !== true ? (
    !isUndefined(error) ? (
      <Container className={classes.container} maxWidth="sm">
        <Alert
          severity="error"
          action={
            <Button color="inherit" onClick={onResend} size="small">
              Resend email
            </Button>
          }
        >
          <AlertTitle>Email verification failed</AlertTitle>
          {first(getGraphQLError(error)) ?? defaultErrorMessage}
        </Alert>
      </Container>
    ) : (
      <div className={classes.container}>
        <Typography variant="h3" gutterBottom={true}>
          Verifying email
        </Typography>
        <CircularProgress aria-label="Loading" />
      </div>
    )
  ) : (
    <Fragment>{children}</Fragment>
  )
}
