import hotjar from '@hotjar/browser'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import Link from '@material-ui/core/Link'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { first, isNil, isUndefined, mapValues, omit } from 'lodash'
import React, { Fragment, useCallback, useState } from 'react'
import LinkedInTag from 'react-linkedin-insight'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { PasswordFormField, SubmitButton } from '../../../components'
import { config } from '../../../config'
import {
  goToLogin,
  termsOfUseUri,
  TokenQuery,
  TokenQueryVariables,
  TokenDocument,
  signUpValidate,
  useSignUpMutation,
} from '../../../middleware'
import { getGraphQLError } from '../../../util'

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      width: '100%',
      maxWidth: theme.spacing(35),
      marginBottom: theme.spacing(2),
    },
    form: {
      width: '100%',
    },
    fields: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      marginBottom: theme.spacing(3),
    },
    actions: {
      display: 'flex',
      '& >*': {
        flex: 1,
      },
      '& >:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  })
)

export const SignUpForm: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const [state, setState] = useState({
    email: '',
    firstName: '',
    legal: false,
    lastName: '',
  })

  const [signUp, { error }] = useSignUpMutation({
    onCompleted: () => {
      gtag('event', 'sign_up')

      fbq('track', 'CompleteRegistration', {
        content_name: 'Sign up',
        status: true,
      })

      if (LinkedInTag.verifyInit()) {
        LinkedInTag.track(config.linkedinEvents.signup)
        LinkedInTag.track(config.linkedinEvents.completeSignup)
      }

      if (hotjar.isReady()) hotjar.event('signedUp')
    },
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to sign up.${errorText}`,
        fatal: false,
      })
    },
    refetchQueries: ['currentUser'], // invalidate current user data
    update: (cache, { data }) => {
      if (isNil(data)) return

      const token = data.signUp

      cache.writeQuery<TokenQuery, TokenQueryVariables>({
        query: TokenDocument,
        data: {
          token,
        },
      })
    },
  })

  const onLogin = useCallback(() => {
    goToLogin(history)
  }, [history])

  return (
    <Fragment>
      {isUndefined(error) ? undefined : (
        <Alert className={classes.error} severity="error">
          <AlertTitle>Sign up failed</AlertTitle>
          {error.message}
        </Alert>
      )}
      <Formik
        initialValues={{ ...state, password: '' }}
        validate={(values) =>
          mapValues(signUpValidate(values), (n) => first(n))
        }
        onSubmit={async (values, { resetForm }) => {
          setState(values)

          await signUp({
            variables: omit(values, ['legal']),
          }).then(() => {
            resetForm()
          })
        }}
      >
        {({ errors, isSubmitting, submitForm }) => (
          <Form className={classes.form}>
            <div className={classes.fields}>
              <Field
                component={TextField}
                FormHelperTextProps={{
                  'aria-label': 'First name helper text',
                }}
                id="first-name-field"
                label="First name"
                margin="normal"
                name="firstName"
                variant="outlined"
              />
              <Field
                component={TextField}
                FormHelperTextProps={{
                  'aria-label': 'Last name helper text',
                }}
                id="last-name-field"
                label="Last name"
                margin="normal"
                name="lastName"
                variant="outlined"
              />
              <Field
                component={TextField}
                FormHelperTextProps={{
                  'aria-label': 'Email helper text',
                }}
                id="email-field"
                label="Email"
                margin="normal"
                name="email"
                variant="outlined"
              />
              <Field
                component={PasswordFormField}
                FormHelperTextProps={{
                  'aria-label': 'Password helper text',
                }}
                id="password-field"
                label="Password"
                margin="normal"
                name="password"
                variant="outlined"
              />
              <Field
                color="primary"
                component={CheckboxWithLabel}
                Label={{
                  label: (
                    <Fragment>
                      <span>I agree to the</span>&nbsp;
                      <Link component={RouterLink} to={termsOfUseUri()}>
                        Terms of use
                      </Link>
                    </Fragment>
                  ),
                }}
                name="legal"
                type="checkbox"
              />
              {isUndefined(errors.legal) ? undefined : (
                <FormHelperText error={true}>{errors.legal}</FormHelperText>
              )}
            </div>
            <div className={classes.actions}>
              <Button onClick={onLogin} variant="outlined">
                Login
              </Button>
              <SubmitButton
                color="primary"
                loading={isSubmitting}
                onClick={() => {
                  submitForm().catch(() => {
                    // handled above
                  })
                }}
                type="submit"
                variant="contained"
              >
                Sign up
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}
