import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { config } from '../../../config'
import {
  BillingCard,
  CurrentUserAccountMenu,
  FabNavLayout,
  Footer,
  SubscriptionCards,
  UpdateUserForm,
  UsageCard,
} from '../../../components'

const useStyles = makeStyles((theme) =>
  createStyles({
    accountContainer: {
      textAlign: 'center',
      paddingTop: theme.spacing(11),
      paddingBottom: theme.spacing(11),
      backgroundColor: 'white',
      borderBottom: `1px solid ${config.colours.darkBorder}`,
    },
    accountDescription: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(3),
    },
    description: {
      maxWidth: theme.spacing(35),
    },
    intro: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(4),
    },
    usage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(4),
    },
    usageCard: {
      flex: 1,
    },
  })
)

export const MyAccountPage: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()

  const onBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <FabNavLayout
      headerLeft={
        <Fab variant="extended" aria-label="Back" onClick={onBack}>
          <ArrowBackIosIcon style={{ fontSize: 18 }} />
          Back
        </Fab>
      }
      headerRight={<CurrentUserAccountMenu />}
    >
      <div className={classes.accountContainer}>
        <Container maxWidth="md">
          <Typography variant="h3" gutterBottom={true}>
            My Account
          </Typography>
          <Typography className={classes.accountDescription}>
            Edit your account information and plan type.
          </Typography>
          <UpdateUserForm />
        </Container>
      </div>
      <Container maxWidth="md">
        <div className={classes.usage}>
          <Typography variant="h3">Usage</Typography>
          <Typography className={classes.description}>
            Check usage for your projects against your current plan quota.
          </Typography>
        </div>
        <UsageCard className={classes.usageCard} />
      </Container>
      <Container maxWidth="md">
        <BillingCard />
      </Container>
      <Container maxWidth="md">
        <div className={classes.intro}>
          <Typography id="change-plan" variant="h3">
            Change plan
          </Typography>
          <Typography className={classes.description}>
            Manage your plan below to match the capacity you need.
          </Typography>
        </div>
      </Container>
      <SubscriptionCards />
      <Footer />
    </FabNavLayout>
  )
}
