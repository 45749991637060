import Button, { ButtonProps } from '@material-ui/core/Button'
import React from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

type Form = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>

export interface CreateProjectFormProps extends Form {
  cancelButton?: ButtonProps
  nameField?: TextFieldProps
  submitButton?: ButtonProps
}

const useStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      display: 'flex',
      '& >:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    cancelButton: {
      flex: 1,
    },
    createButton: {
      flex: 2,
    },
    form: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      width: '100%',
      maxWidth: theme.spacing(35),
    },
  })
)

export const CreateProjectForm: React.FC<CreateProjectFormProps> = ({
  cancelButton,
  nameField,
  submitButton,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <form className={classes.form} {...rest}>
      <TextField
        FormHelperTextProps={{
          'aria-label': 'Name helper text',
        }}
        id="name-field"
        label="Name"
        placeholder="E.g. My new project"
        variant="outlined"
        margin="normal"
        {...nameField}
      />
      <div className={classes.actions}>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          {...cancelButton}
        >
          Cancel
        </Button>
        <Button
          className={classes.createButton}
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          type="submit"
          {...submitButton}
        >
          Create project
        </Button>
      </div>
    </form>
  )
}
