import { LocationDescriptorObject } from 'history'
import React from 'react'
import { Redirect, RedirectProps, useLocation } from 'react-router-dom'

export interface FromRedirectProps extends Omit<RedirectProps, 'to'> {
  to: LocationDescriptorObject
}

/**
 * Redirects to a new URI, setting the previous URI in
 * the `from` state in the new URI.
 *
 * @param props Redirect props
 */
export const FromRedirect: React.FC<FromRedirectProps> = ({ to, ...rest }) => {
  const location = useLocation()
  const toWithFrom = {
    ...to,
    state: {
      ...to.state,
      from: location,
    },
  }

  return <Redirect to={toWithFrom} {...rest} />
}
