import * as ApolloReactHooks from '@apollo/client'
import { isNil } from 'lodash'
import {
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
  Project,
  ProjectFragmentDoc,
  useUpdateProjectMutation,
} from '../../graphql'

export const useUpdateProject = (
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >
) =>
  useUpdateProjectMutation({
    update: (cache, { data: updateData }) => {
      if (isNil(updateData)) return

      const { __typename, id, ...data } = updateData.updateProject

      const prev = cache.readFragment<Project>({
        id: `Project:${id}`,
        fragment: ProjectFragmentDoc,
      })

      cache.writeFragment({
        id: `Project:${id}`,
        fragment: ProjectFragmentDoc,
        data: { ...prev, ...data },
      })
    },
    ...baseOptions,
  })
