import { GraphQLError } from 'graphql'
import { CreatePaymentMethodDocument } from '../../graphql'

export const createPaymentMethodSuccessMock = {
  request: {
    query: CreatePaymentMethodDocument,
    variables: {
      stripePaymentMethodId: '20624866-7993-4643-955e-dd01e19c8eae',
    },
  },
  result: {
    data: {
      createPaymentMethod: {
        id: '89c71f20-ccb7-499e-847c-44b6a074ccf9',
        defaultPaymentMethod: {
          id: 'a3832d2a-5806-4639-9b4a-7e3efb8c25e4',
          brand: 'mastercard',
          expiry: '2021-05-01T00:00:00.000Z',
          last4: '4309',
        },
      },
    },
  },
}

export const createPaymentMethodFailureMock = {
  request: {
    query: CreatePaymentMethodDocument,
    variables: {
      stripePaymentMethodId: '5d052947-12cc-4c2f-82b2-0a2cb189c220',
    },
  },
  result: {
    data: null,
    errors: [new GraphQLError('User could not be found.')],
  },
}

export const createPaymentMethodMocks = [
  createPaymentMethodSuccessMock,
  createPaymentMethodFailureMock,
]
