import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {
  CenterLayout,
  CurrentUserAccountMenu,
  ListLayout,
  UserCreateProjectForm,
} from '../../../components'

const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      marginBottom: theme.spacing(5),
    },
  })
)

export const CreateProjectPage: React.FC = () => {
  const classes = useStyles()

  return (
    <ListLayout headerRight={<CurrentUserAccountMenu />}>
      <CenterLayout>
        <Typography variant="h3" gutterBottom={true}>
          New project
        </Typography>
        <Typography align="center" className={classes.description}>
          Give your new project a name
        </Typography>
        <UserCreateProjectForm />
      </CenterLayout>
    </ListLayout>
  )
}
