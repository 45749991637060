import { Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { first, pick, isNil, isUndefined } from 'lodash'
import { useSnackbar } from 'notistack'
import React, {
  Fragment,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  useProjectUsersQuery,
  useUpdateProjectUser,
} from '../../../../middleware'
import {
  ProjectUserRemoveDialog,
  ProjectUserRemoveDialogProps,
} from '../../../../components'
import { ApiErrors, getGraphQLError } from '../../../../util'
import {
  ProjectUsersListRow,
  ProjectUsersListRowProps,
} from './ProjectUsersListRow'
import { VirtualList } from '../VirtualList'

export interface ProjectUsersListProps extends HTMLAttributes<HTMLDivElement> {
  projectId: string
}

export const ProjectUsersList: React.FC<ProjectUsersListProps> = ({
  projectId,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const [state, setState] = useState<{
    deleteSelected?: ProjectUserRemoveDialogProps['projectUser']
  }>({})

  const {
    data: projectUsersData,
    loading: projectUsersLoading,
    error: projectUsersError,
  } = useProjectUsersQuery({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to read project users on ${projectId} project.${errorText}`,
        fatal: false,
      })
    },
    variables: {
      id: projectId,
    },
  })

  const [updateProjectUser] = useUpdateProjectUser()

  const loading = projectUsersLoading && isUndefined(projectUsersData)
  const projectUsers = isUndefined(projectUsersData)
    ? []
    : projectUsersData.project.projectUsers
  const itemCount = projectUsers.length

  const onDelete: ProjectUsersListRowProps['onDelete'] = useCallback(
    (projectUser) => {
      setState({ deleteSelected: projectUser })
    },
    []
  )

  const onUpdate: ProjectUsersListRowProps['onUpdate'] = useCallback(
    (projectUser) => {
      updateProjectUser({
        variables: pick(projectUser, ['id', 'permission']),
      }).catch((e) => {
        const error = first(getGraphQLError(e as ApiErrors))
        const errorText = isUndefined(error) ? '' : ` ${error}`
        const entityName = isNil(projectUser.user)
          ? ''
          : ` "${projectUser.user.displayName}"`

        gtag('event', 'exception', {
          description: `Failed to update permission for ${projectUser.id} user.${errorText}`,
          fatal: false,
        })
        enqueueSnackbar(
          `Failed to update permission for${entityName}.${errorText}`,
          {
            variant: 'error',
          }
        )
      })
    },
    [enqueueSnackbar, updateProjectUser]
  )

  const onDeleteClose = useCallback(() => {
    setState({})
  }, [])

  const error = useMemo(() => {
    if (isUndefined(projectUsersError)) return

    const error = first(getGraphQLError(projectUsersError))
    const errorText = isUndefined(error) ? '' : ` ${error}`

    return isUndefined(projectUsersError)
      ? undefined
      : `An error occured loading project users.${errorText}`
  }, [projectUsersError])

  return (
    <Fragment>
      <VirtualList
        error={error}
        hasNextPage={false}
        itemCount={itemCount}
        listProps={{
          itemData: {
            projectUsers,
            onDelete,
            onUpdate,
          },
          itemSize: smUp ? 70 : 102,
        }}
        loading={loading}
        loadMore={() => null}
        {...rest}
      >
        {ProjectUsersListRow}
      </VirtualList>
      <ProjectUserRemoveDialog
        projectUser={state.deleteSelected}
        onClose={onDeleteClose}
        {...rest}
      />
    </Fragment>
  )
}
