import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { first, isNil, isUndefined } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import {
  ProjectUser,
  User,
  useRemoveProjectUserMutation,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

export interface ProjectUserRemoveDialogProps
  extends Omit<DialogProps, 'children' | 'onClose' | 'open'> {
  projectUser?: Pick<ProjectUser, 'id'> & {
    user?: Pick<User, 'displayName'> | null
  }
  onClose: () => void
}

export const ProjectUserRemoveDialog: React.FC<ProjectUserRemoveDialogProps> = ({
  projectUser,
  onClose,
  ...rest
}) => {
  const displayName = projectUser?.user?.displayName
  const { enqueueSnackbar } = useSnackbar()
  const [removeProjectUser] = useRemoveProjectUserMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      removeProjectUser: {
        __typename: 'ProjectUser',
        ...variables,
      },
    }),
    update: (cache, { data }) => {
      if (isNil(data)) return

      const ref = cache.identify(data.removeProjectUser)
      cache.evict({ id: ref })
      cache.gc()
    },
  })

  const onRemove = useCallback(() => {
    if (isUndefined(projectUser)) return

    removeProjectUser({
      variables: {
        id: projectUser.id,
      },
    }).catch((e) => {
      const error = first(getGraphQLError(e as ApiErrors))
      const errorText = isUndefined(error) ? '' : ` ${error}`
      const entityName = isUndefined(displayName) ? '' : ` "${displayName}"`

      gtag('event', 'exception', {
        description: `Failed to remove ${projectUser.id} user from project.${errorText}`,
        fatal: false,
      })
      enqueueSnackbar(
        `Failed to remove${entityName} from project.${errorText}`,
        {
          variant: 'error',
        }
      )
    })
    onClose()
  }, [projectUser, removeProjectUser, onClose, displayName, enqueueSnackbar])

  const open = !isUndefined(projectUser)
  const projectUserTitle = isUndefined(displayName) ? '' : ` "${displayName}"`

  return (
    <Dialog
      aria-labelledby="remove-projectUser-dialog-title"
      aria-describedby="remove-projectUser-dialog-description"
      open={open}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle id="remove-projectUser-dialog-title">{`Remove${projectUserTitle} from project?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="remove-projectUser-dialog-description">
          This will remove project access and permissions for the user.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={onRemove}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}
