import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Skeleton from '@material-ui/lab/Skeleton'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import {
  first,
  isUndefined,
  mapValues,
  omitBy,
  reduce,
  truncate,
  isNil,
} from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Fragment, useMemo } from 'react'
import {
  Story,
  StoryFragmentDoc,
  updateStoryValidate,
  useStoryQuery,
  useUpdateStoryMutation,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

export interface StoryUpdateDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  projectId: string
  storyId: string
  onClose: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      marginTop: -theme.spacing(0.5),
    },
    cancelButton: {
      flex: 1,
    },
    dialogPaper: {
      maxWidth: theme.spacing(50),
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    error: {
      marginBottom: theme.spacing(2),
    },
    fields: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(1),
    },
    title: {
      flex: 1,
      marginRight: theme.spacing(4),
    },
    updateButton: {
      flex: 2,
    },
  })
)

export const StoryUpdateDialog: React.FC<StoryUpdateDialogProps> = ({
  onClose,
  projectId,
  storyId,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    data: storyData,
    loading: storyLoading,
    error: storyError,
  } = useStoryQuery({
    variables: {
      id: storyId,
    },
    onError: (e) => {
      const error = first(getGraphQLError(e))

      gtag('event', 'exception', {
        description: `Failed to read ${storyId} story.${
          isUndefined(error) ? '' : ` ${error}`
        }`,
        fatal: false,
      })
    },
  })
  const [updateStory] = useUpdateStoryMutation({
    update: (cache, { data }) => {
      if (isNil(data)) return

      const { __typename, id, ...rest } = data.updateStory

      const prev = cache.readFragment<Story>({
        id: `Story:${id}`,
        fragment: StoryFragmentDoc,
      })

      cache.writeFragment({
        id: `Story:${id}`,
        fragment: StoryFragmentDoc,
        data: { ...prev, ...rest },
      })

      // add new value only as old value might still exist
      cache.modify({
        id: `Project:${projectId}`,
        fields: {
          storyRoles(storyRoles: string[] = []) {
            return [
              ...storyRoles.filter((n) => n !== data.updateStory.role),
              data.updateStory.role,
            ]
          },
        },
      })
    },
  })

  const error = useMemo(
    () =>
      isUndefined(storyError) ? undefined : first(getGraphQLError(storyError)),
    [storyError]
  )

  const initialValues = isUndefined(storyData)
    ? {
        role: '',
        need: '',
        reason: '',
      }
    : {
        role: storyData.story.role,
        need: storyData.story.need,
        reason: storyData.story.reason,
      }

  return (
    <Dialog
      aria-labelledby="update-story-dialog-title"
      aria-describedby="update-story-dialog-description"
      PaperProps={{ className: classes.dialogPaper }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle
        id="update-story-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography className={classes.title} variant="h5">
          Edit story
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          edge="end"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={(values) =>
          mapValues(updateStoryValidate(values), (n) => first(n))
        }
        onSubmit={(values) => {
          const changedValues = reduce(
            values,
            (result, value, key) =>
              initialValues[key as keyof typeof values] === value
                ? result
                : { ...result, [key]: value },
            {}
          )

          const storyDisplayName = truncate(values.need, {
            length: 35,
          })

          updateStory({
            variables: {
              id: storyId,
              ...changedValues,
            },
            optimisticResponse: isUndefined(storyData)
              ? undefined
              : (variables) => ({
                  __typename: 'Mutation',
                  updateStory: {
                    __typename: 'Story',
                    ...storyData.story,
                    ...omitBy(variables, isNil),
                  },
                }),
          })
            .then(() => {
              enqueueSnackbar(
                `Successfully updated story "${storyDisplayName}".`,
                {
                  variant: 'success',
                }
              )
            })
            .catch((e) => {
              const error = first(getGraphQLError(e as ApiErrors))
              const errorText = isUndefined(error) ? '' : ` ${error}`

              gtag('event', 'exception', {
                description: `Failed to update ${storyId} story.${errorText}`,
                fatal: false,
              })
              enqueueSnackbar(
                `Failed to update "${storyDisplayName}" story.${errorText}`,
                {
                  variant: 'error',
                }
              )
            })

          onClose()
        }}
      >
        {({ dirty, submitForm, isSubmitting }) => (
          <Form>
            {
              <Fragment>
                <DialogContent>
                  <DialogContentText id="update-story-dialog-description">
                    Update the role, what is needed and why it from your own
                    perspective or on behalf of another.
                  </DialogContentText>
                  {isUndefined(storyData) && storyLoading ? (
                    <div className={classes.fields}>
                      <Skeleton
                        height={70}
                        title="Loading role"
                        variant="text"
                      />
                      <Skeleton
                        height={70}
                        title="Loading need"
                        variant="text"
                      />
                      <Skeleton
                        height={70}
                        title="Loading reason"
                        variant="text"
                      />
                    </div>
                  ) : (
                    <div className={classes.fields}>
                      {isUndefined(error) ? undefined : (
                        <Alert className={classes.error} severity="error">
                          <AlertTitle>Story failed to load</AlertTitle>
                          {error}
                        </Alert>
                      )}
                      <Field
                        component={TextField}
                        FormHelperTextProps={{
                          'aria-label': 'Role helper text',
                        }}
                        id="role-field"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="As a"
                        name="role"
                        placeholder="e.g. Designer"
                        variant="outlined"
                      />
                      <Field
                        component={TextField}
                        FormHelperTextProps={{
                          'aria-label': 'Need helper text',
                        }}
                        id="need-field"
                        inputProps={{
                          rowsMin: 2,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="I need/want/expect to"
                        name="need"
                        multiline={true}
                        placeholder="e.g. Capture requirements online"
                        variant="outlined"
                      />
                      <Field
                        component={TextField}
                        FormHelperTextProps={{
                          'aria-label': 'Reason helper text',
                        }}
                        id="reason-field"
                        inputProps={{
                          rowsMin: 2,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="So that"
                        name="reason"
                        multiline={true}
                        placeholder="e.g. We can keep in sync remotely"
                        variant="outlined"
                      />
                    </div>
                  )}
                </DialogContent>
              </Fragment>
            }
            <DialogActions>
              <Button
                className={classes.cancelButton}
                onClick={onClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className={classes.updateButton}
                color="primary"
                disabled={!dirty || isSubmitting}
                onClick={() => {
                  submitForm().catch(() => {
                    // handled above
                  })
                }}
                variant="contained"
              >
                Update
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
