import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import { fade, useTheme } from '@material-ui/core/styles'
import React from 'react'

// Stripe MUI intergration.
// See: https://github.com/mui-org/material-ui/issues/16037

export const StripeInput: React.FC<
  InputBaseComponentProps & { inputRef: React.Ref<unknown>; options: {} }
> = (props) => {
  const {
    component: Component,
    inputRef,
    'aria-invalid': ariaInvalid,
    'aria-describedby': ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    options,
    ...other
  } = props
  const theme = useTheme()
  const [mountNode, setMountNode] = React.useState<HTMLInputElement | null>(
    null
  )

  React.useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode?.focus(),
    }),
    [mountNode]
  )

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: `${theme.typography.fontSize}px`,
            fontFamily: theme.typography.fontFamily,
            '::placeholder': {
              color: fade(theme.palette.text.primary, 0.42),
            },
          },
          invalid: {
            color: theme.palette.text.primary,
          },
        },
      }}
      {...other}
    />
  )
}
