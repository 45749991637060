import { isUndefined } from 'lodash'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

export type FromRouteProps = RouteProps

/**
 * Redirects to `from` URI if this has been set in the router
 * state, otherwise loads children.
 *
 * @param props Route props
 */
export const FromRoute: React.FC<RouteProps> = ({
  children,
  location,
  ...rest
}) => {
  const from = (location?.state as { from?: string })?.from

  return (
    <Route location={location} {...rest}>
      {isUndefined(from) ? children : <Redirect to={from} />}
    </Route>
  )
}
