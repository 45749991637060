import { useApolloClient } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import axios from 'axios'
import { first, isNil, isNull, isUndefined } from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { config } from '../../../config'
import {
  getToken,
  goToProject,
  ProjectPermission,
  useCurrentUser,
  useProjectUsersQuery,
  ImportStoriesMutationHookResult,
  useImportStoriesRouteMatch,
} from '../../../middleware'
import { getRestError } from '../../../util'

export interface ImportStoriesDialogProps
  extends Omit<DialogProps, 'children' | 'onClose' | 'open'> {
  importStories?: ImportStoriesMutationHookResult[0]
  projectId: string
}

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    flex: 1,
  },
  closeButton: {
    marginTop: -theme.spacing(0.5),
  },
  dialogPaper: {
    maxWidth: theme.spacing(50),
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  importButton: {
    flex: 2,
  },
  input: {
    display: 'none',
  },
  title: {
    flex: 1,
    marginRight: theme.spacing(4),
  },
}))

const importStoriesSnackbarKey = 'import-stories'
const title = 'Import stories'
const intro =
  'Already collected user stories? No problem! Bulk import your stories with CSV file.'
const cancelButtonText = 'Cancel'
const importButtonText = 'Import CSV'

const importStoriesDefault = async ({
  variables: { file, projectId },
}: {
  variables: {
    file: File
    projectId: string
  }
}) => {
  const accessToken = getToken()?.accessToken
  const formData = new FormData()
  formData.append('file', file)
  formData.append('projectId', projectId)

  return await axios.post(config.rest.importStoriesUri, formData, {
    headers: {
      ...(!isUndefined(accessToken)
        ? { Authorization: `Bearer ${accessToken}` }
        : {}),
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const ImportStoriesDialog: React.FC<ImportStoriesDialogProps> = ({
  importStories: importStoriesOverride,
  projectId,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const history = useHistory()
  const client = useApolloClient()
  const importStoriesRouteMatch = useImportStoriesRouteMatch()
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()
  const currentUser = useCurrentUser().data?.currentUser
  const { data } = useProjectUsersQuery({
    variables: {
      id: projectId,
    },
  })

  const importStories = importStoriesOverride ?? importStoriesDefault

  const currentProjectUser =
    isUndefined(currentUser) || isUndefined(data)
      ? undefined
      : data.project.projectUsers.find((n) => n.user?.id === currentUser?.id)
          ?.permission
  const hide =
    isNil(currentProjectUser) || currentProjectUser === ProjectPermission.Read

  const onClose = useCallback(() => {
    goToProject(history, projectId, {
      search: history.location.search, // keep current query
    })
  }, [history, projectId])

  const onChange = useCallback(
    async ({
      target: { validity, files },
    }: React.ChangeEvent<HTMLInputElement>) => {
      const file = first(files)

      if (!validity.valid) {
        const errorText = 'Failed to import stories. File is invalid.'
        gtag('event', 'exception', {
          description: errorText,
          fatal: false,
        })
        enqueueSnackbar(errorText, {
          variant: 'error',
        })
      } else if (isUndefined(file)) {
        const errorText = 'Failed to import stories. File is missing.'
        gtag('event', 'exception', {
          description: errorText,
          fatal: false,
        })
        enqueueSnackbar(errorText, {
          variant: 'error',
        })
      } else {
        onClose()
        enqueueSnackbar(`Importing stories...`, {
          key: importStoriesSnackbarKey,
          persist: true,
        })

        try {
          await importStories({
            variables: {
              file,
              projectId,
            },
          })

          closeSnackbar(importStoriesSnackbarKey)
          enqueueSnackbar(`Successfully imported stories.`, {
            variant: 'success',
          })
          await client.refetchQueries({ include: ['stories'] })
        } catch (e) {
          closeSnackbar(importStoriesSnackbarKey)

          const error = getRestError(e)
          const errorText = isUndefined(error) ? '' : ` ${error}`

          gtag('event', 'exception', {
            description: `Failed to import stories.${errorText}`,
            fatal: false,
          })
          enqueueSnackbar(`Failed to import stories.${errorText}`, {
            variant: 'error',
          })
        }
      }
    },
    [client, closeSnackbar, enqueueSnackbar, importStories, onClose, projectId]
  )

  return (
    <Dialog
      aria-labelledby="import-stories-dialog-title"
      aria-describedby="import-stories-dialog-description"
      fullWidth={true}
      open={!hide && !isNull(importStoriesRouteMatch)}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle
        id="import-stories-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          edge="end"
          title="Close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="import-stories-dialog-description">
          <Typography gutterBottom>{intro}</Typography>
          <Typography>
            The top row should have the column names: &quot;<b>As a</b>&quot;,
            &quot;
            <b>I need/want/expect to</b>&quot; and &quot;<b>So that</b>&quot;,
            with each user story on its own row below this, lining up with the
            column names.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelButton}
          onClick={onClose}
          variant="outlined"
        >
          {cancelButtonText}
        </Button>
        <input
          accept=".csv"
          className={classes.input}
          id="upload-button"
          onChange={(arg) => {
            onChange(arg).catch((_) => {
              // handled above
            })
          }}
          // cannot currently set `required` due to testing bug: https://github.com/testing-library/user-event/issues/801
          type="file"
        />
        <label htmlFor="upload-button">
          <Button
            className={classes.importButton}
            color="primary"
            variant="contained"
            component="span"
          >
            {importButtonText}
          </Button>
        </label>
      </DialogActions>
    </Dialog>
  )
}
