import { GraphQLError } from 'graphql'
import { StoryDocument } from '../../graphql'

export const storySuccessMock = {
  request: {
    query: StoryDocument,
    variables: {
      id: '3cdb8a17-c835-4548-b243-317ac05e42d3',
    },
  },
  result: {
    data: {
      story: {
        id: '3cdb8a17-c835-4548-b243-317ac05e42d3',
        archived: false,
        role: 'Manager',
        need: 'I need something',
        reason: 'Because it would help me',
      },
    },
  },
}

export const storyFailureMock = {
  request: {
    query: StoryDocument,
    variables: {
      id: 'adc3f532-89ab-48b8-942d-cec513ad9bcd',
    },
  },
  result: {
    data: undefined,
    errors: [new GraphQLError('Story not found.')],
  },
}

export const storyMocks = [storySuccessMock, storyFailureMock]
