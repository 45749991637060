import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  CurrentUserBillingDocument,
  CurrentUserBillingQueryResult,
  CurrentUserBillingQueryVariables,
} from '../../graphql'

type CurrentUserBillingMockResponse = MockResponse<
  CurrentUserBillingQueryVariables,
  Pick<CurrentUserBillingQueryResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const currentUserBillingSuccessMock = {
  request: {
    query: CurrentUserBillingDocument,
    variables: {},
  },
  result: {
    data: {
      currentUser: {
        id: 'e137a0ae-8853-45d2-a75c-c21338673e8a',
        defaultPaymentMethod: {
          id: '6a200e40-c5de-4544-a8e7-8933614e90f1',
          brand: 'visa',
          expiry: '2020-03-01T00:00:00.000Z',
          last4: '7603',
        },
        subscriptions: [
          {
            id: 'b9b4ce9c-144a-436c-a579-82bc81a2f88d',
            cancelAtPeriodEnd: false,
            currentPeriodEnd: '2020-09-13T19:19:03.000Z',
            items: [
              {
                id: 'd696b4df-b318-4680-afc6-22fc930a5188',
                price: {
                  id: 'ac66d030-715c-4df4-8911-fc38ba89bc57',
                  unitAmount: 1,
                  product: {
                    id: '1a93252c-6add-4931-9fce-f86d54bf1eac',
                    name: 'Pro',
                  },
                },
                quantity: 1,
              },
            ],
          },
        ],
        upcomingInvoice: {
          id: '0a623f25-010d-4ef2-9d54-0ad15f7cfdd3',
          amountDue: 3999,
          currency: 'gbp',
          periodEnd: '2020-09-13T19:19:03.000Z',
        },
      },
    },
  },
}

export const currentUserBillingFailureMock = {
  request: {
    query: CurrentUserBillingDocument,
    variables: {},
  },
  result: {
    data: undefined,
    errors: [
      {
        message: {
          statusCode: 404,
          error: 'Not Found',
          message: 'User not found.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const currentUserBillingMocks: CurrentUserBillingMockResponse[] = [
  currentUserBillingSuccessMock,
]
