import { GraphQLError } from 'graphql'
import { ProjectsCountDocument } from '../../graphql'

export const projectsCountSomeMock = {
  request: {
    query: ProjectsCountDocument,
    variables: {},
  },
  result: {
    data: {
      projects: {
        totalCount: 2,
      },
    },
  },
}

export const projectsCountEmptyMock = {
  request: {
    query: ProjectsCountDocument,
    variables: {},
  },
  result: {
    data: {
      projects: {
        totalCount: 0,
      },
    },
  },
}

export const projectsCountFailureMock = {
  request: {
    query: ProjectsCountDocument,
    variables: {},
  },
  result: {
    data: undefined,
    errors: [new GraphQLError('Internal Server Error')],
  },
}

export const projectsCountMocks = [
  projectsCountSomeMock,
  projectsCountEmptyMock,
  projectsCountFailureMock,
]
