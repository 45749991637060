import { Buffer } from 'buffer'
import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  StoriesDocument,
  StoriesQueryResult,
  StoriesQueryVariables,
} from '../../graphql'

type StoriesMockResponse = MockResponse<
  StoriesQueryVariables,
  Pick<StoriesQueryResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

const story1 = {
  id: '0c737de6-e80e-4884-97ca-c9799f38cf81',
  archived: false,
  canDelete: true,
  canLove: true,
  canUpdate: true,
  role: 'Manager',
  need: 'I need something',
  reason: 'Because it would help me',
  storyLove: [],
}

const story2 = {
  id: '0afa07aa-16ec-463b-a841-99b0c82817c9',
  archived: false,
  canDelete: true,
  canLove: true,
  canUpdate: true,
  role: 'Product owner',
  need: 'Some need',
  reason: 'Because it would make it faster',
  storyLove: [],
}

const story3 = {
  id: 'b3690727-2716-4d71-86a8-836e54fbb342',
  archived: false,
  canDelete: true,
  canLove: true,
  canUpdate: true,
  role: 'Worker',
  need: 'Thing',
  reason: 'For help',
  storyLove: [],
}

export const storiesSuccessMock = {
  request: {
    query: StoriesDocument,
    variables: {
      first: 50,
    },
  },
  result: {
    data: {
      stories: {
        totalCount: 2,
        pageInfo: {
          endCursor: Buffer.from(story2.id).toString('base64'),
          hasNextPage: false,
        },
        edges: [
          {
            cursor: Buffer.from(story1.id).toString('base64'),
            node: story1,
          },
          {
            cursor: Buffer.from(story2.id).toString('base64'),
            node: story2,
          },
        ],
      },
    },
  },
}

export const storiesSearchSuccessMock = {
  request: {
    query: StoriesDocument,
    variables: {
      first: 50,
      search: story3.need,
    },
  },
  result: {
    data: {
      stories: {
        totalCount: 1,
        pageInfo: {
          endCursor: Buffer.from(story3.id).toString('base64'),
          hasNextPage: false,
        },
        edges: [
          {
            cursor: Buffer.from(story3.id).toString('base64'),
            node: story3,
          },
        ],
      },
    },
  },
}

export const storiesSearchEmptyMock = {
  request: {
    query: StoriesDocument,
    variables: {
      first: 50,
      search: 'no results',
    },
  },
  result: {
    data: {
      stories: {
        totalCount: 1,
        pageInfo: {
          hasNextPage: false,
        },
        edges: [],
      },
    },
  },
}

export const storiesPaginated1SuccessMock = {
  request: {
    query: StoriesDocument,
    variables: {
      first: 2,
    },
  },
  result: {
    data: {
      stories: {
        totalCount: 3,
        pageInfo: {
          endCursor: Buffer.from(story2.id).toString('base64'),
          hasNextPage: true,
        },
        edges: [
          {
            cursor: Buffer.from(story1.id).toString('base64'),
            node: story1,
          },
          {
            cursor: Buffer.from(story2.id).toString('base64'),
            node: story2,
          },
        ],
      },
    },
  },
}

export const storiesPaginated2SuccessMock = {
  request: {
    query: StoriesDocument,
    variables: {
      after: storiesSuccessMock.result.data.stories.pageInfo.endCursor,
      first: 2,
    },
  },
  result: {
    data: {
      stories: {
        totalCount: 3,
        pageInfo: {
          endCursor: Buffer.from(story3.id).toString('base64'),
          hasNextPage: false,
        },
        edges: [
          {
            cursor: Buffer.from(story3.id).toString('base64'),
            node: story3,
          },
        ],
      },
    },
  },
}

export const storiesFailureMock = {
  request: {
    query: StoriesDocument,
    variables: {
      first: -1,
    },
  },
  result: {
    data: undefined,
    errors: [
      {
        message: {
          statusCode: 400,
          error: 'Bad Request',
          message: [
            {
              property: 'first',
              constraints: {
                min: 'first must not be less than 1',
              },
            },
          ],
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const storiesMocks: StoriesMockResponse[] = [
  storiesSuccessMock,
  storiesSearchSuccessMock,
  storiesSearchEmptyMock,
  storiesPaginated1SuccessMock,
  storiesPaginated2SuccessMock,
  storiesFailureMock,
]
