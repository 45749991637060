import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { first, isUndefined } from 'lodash'
import React from 'react'
import { Redirect, RedirectProps } from 'react-router-dom'
import { Sort, useProjectsQuery } from '../../../middleware'
import { getGraphQLError } from '../../../util'

export type RecentProjectRedirectProps = Omit<RedirectProps, 'to'>

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export const RecentProjectRedirect: React.FC<RecentProjectRedirectProps> = (
  props
) => {
  const classes = useStyles()
  const { data, loading, error } = useProjectsQuery({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to read most recent project.${errorText}`,
        fatal: true,
      })
    },
    variables: {
      first: 1,
      sort: Sort.CreatedAt,
    },
  })
  const project = first(data?.projects.edges)?.node
  const to = isUndefined(project) ? '/no-projects' : `/project/${project.id}`

  return loading ? (
    <div className={classes.container}>
      <CircularProgress aria-label="Loading" />
    </div>
  ) : !isUndefined(error) ? (
    <Container className={classes.container} maxWidth="sm">
      <Alert severity="error">
        <AlertTitle>Most recent project failed to load</AlertTitle>
        Please try a refresh. If this persists, please try closing all tabs with
        the site open and then returning in a new tab.
      </Alert>
    </Container>
  ) : (
    <Redirect to={to} {...props} />
  )
}
