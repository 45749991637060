import React, { useCallback, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import {
  fieldToTextField,
  TextFieldProps as FormikTextFieldProps,
} from 'formik-material-ui'
import { isUndefined } from 'lodash'

type PasswordFieldProps = TextFieldProps

/**
 * Formik binding version.
 *
 * @param props
 */
export const PasswordFormField = (props: FormikTextFieldProps) => {
  const {
    form: { setFieldTouched, setFieldValue },
    field: { name },
  } = props
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target

      setFieldTouched(name) // show feedback as you type
      setFieldValue(name, isUndefined(value) ? '' : value)
    },
    [setFieldTouched, name, setFieldValue]
  )
  return <PasswordField {...fieldToTextField(props)} onChange={onChange} />
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  InputProps,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const onToggleShowPasswordClick = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  return (
    <TextField
      id="password"
      label="Password"
      {...rest}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              color="primary"
              edge="end"
              onClick={onToggleShowPasswordClick}
            >
              {showPassword ? (
                <Visibility titleAccess="Password visible" />
              ) : (
                <VisibilityOff titleAccess="Password non-visible" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
    />
  )
}
