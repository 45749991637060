import { GraphQLError } from 'graphql'
import { ProjectDocument, ProjectPermission } from '../../graphql'
import { projectsSuccessMock } from '../projects'

export const projectSuccessMock = {
  request: {
    query: ProjectDocument,
    variables: {
      id: projectsSuccessMock.result.data.projects.edges[0].node.id,
    },
  },
  result: {
    data: {
      project: {
        ...projectsSuccessMock.result.data.projects.edges[0].node,
        canAddUser: true,
        canAddUserRoles: [
          ProjectPermission.Read,
          ProjectPermission.Write,
          ProjectPermission.Admin,
        ],
        canCreateStory: true,
      },
    },
  },
}

export const project2SuccessMock = {
  request: {
    query: ProjectDocument,
    variables: {
      id: projectsSuccessMock.result.data.projects.edges[1].node.id,
    },
  },
  result: {
    data: {
      project: {
        ...projectsSuccessMock.result.data.projects.edges[1].node,
        canAddUser: true,
        canAddUserRoles: [
          ProjectPermission.Read,
          ProjectPermission.Write,
          ProjectPermission.Admin,
        ],
        canCreateStory: true,
      },
    },
  },
}

export const projectNoPermissionSuccessMock = {
  request: {
    query: ProjectDocument,
    variables: {
      id: '1a5159f6-fa89-4225-b8cf-16d82d14064c',
    },
  },
  result: {
    data: {
      project: {
        id: '1a5159f6-fa89-4225-b8cf-16d82d14064c',
        canAddUser: false,
        canAddUserRoles: [
          ProjectPermission.Read,
          ProjectPermission.Write,
          ProjectPermission.Admin,
        ],
        canCreateStory: false,
        name: 'No permission project',
      },
    },
  },
}

export const projectCollaboratorsUsedSuccessMock = {
  request: {
    query: ProjectDocument,
    variables: {
      id: 'b32ae703-e87c-45a5-936d-425f7bbba1c6',
    },
  },
  result: {
    data: {
      project: {
        id: 'b32ae703-e87c-45a5-936d-425f7bbba1c6',
        canAddUser: true,
        canAddUserRoles: [ProjectPermission.Read],
        canCreateStory: true,
        name: 'Collaborators quota used project',
      },
    },
  },
}

export const projectLongNameSuccessMock = {
  request: {
    query: ProjectDocument,
    variables: {
      id: '9cb00672-07d4-4b14-8ed4-0de7fe6dcd4a',
    },
  },
  result: {
    data: {
      project: {
        id: '9cb00672-07d4-4b14-8ed4-0de7fe6dcd4a',
        canAddUser: true,
        canAddUserRoles: [
          ProjectPermission.Read,
          ProjectPermission.Write,
          ProjectPermission.Admin,
        ],
        canCreateStory: true,
        name:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ex neque, consequat mattis ullamcorper vel, venenatis id urna. Maecenas pharetra tempus risus, nec imperdiet justo.',
      },
    },
  },
}

export const projectFailureMock = {
  request: {
    query: ProjectDocument,
    variables: {
      id: '12884205-6f53-4439-9195-ba1e2df80ba0',
    },
  },
  result: {
    data: undefined,
    errors: [new GraphQLError('Project not found.')],
  },
}

export const projectMocks = [
  projectSuccessMock,
  project2SuccessMock,
  projectNoPermissionSuccessMock,
  projectCollaboratorsUsedSuccessMock,
  projectLongNameSuccessMock,
  projectFailureMock,
]
