import { gql, MutationHookOptions } from '@apollo/client'
import { isNil } from 'lodash'
import {
  UpdateProjectUserMutation,
  UpdateProjectUserMutationVariables,
  useUpdateProjectUserMutation,
} from '../../graphql'

export const useUpdateProjectUser = (
  baseOptions?: MutationHookOptions<
    UpdateProjectUserMutation,
    UpdateProjectUserMutationVariables
  >
) =>
  useUpdateProjectUserMutation({
    optimisticResponse: ({ id, permission }) => ({
      __typename: 'Mutation',
      updateProjectUser: {
        __typename: 'ProjectUser',
        id,
        permission,
      },
    }),
    update: (cache, { data: updateData }) => {
      if (isNil(updateData)) return

      const { __typename, id, ...data } = updateData.updateProjectUser

      cache.writeFragment({
        id,
        fragment: gql`
          fragment ProjectUserUpdate on ProjectUser {
            permission
          }
        `,
        data,
      })
    },
    ...baseOptions,
  })
