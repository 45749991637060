import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  UpdateStoryDocument,
  UpdateStoryMutationResult,
  UpdateStoryMutationVariables,
} from '../../graphql'
import { storySuccessMock } from '../../queries'

type UpdateStoryMockResponse = MockResponse<
  UpdateStoryMutationVariables,
  Pick<UpdateStoryMutationResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const updateStorySuccessMock = {
  request: {
    query: UpdateStoryDocument,
    variables: {
      id: storySuccessMock.result.data.story.id,
      role: 'Consultant',
    },
  },
  result: {
    data: {
      updateStory: {
        id: storySuccessMock.result.data.story.id,
        archived: false,
        role: 'Consultant',
        need: storySuccessMock.result.data.story.need,
        reason: storySuccessMock.result.data.story.reason,
      },
    },
  },
}

export const updateStoryLongNeedSuccessMock = {
  request: {
    query: UpdateStoryDocument,
    variables: {
      id: storySuccessMock.result.data.story.id,
      need:
        'This is an example of a longer need that will be truncated in certain areas.',
    },
  },
  result: {
    data: {
      updateStory: {
        id: storySuccessMock.result.data.story.id,
        archived: false,
        role: storySuccessMock.result.data.story.role,
        need:
          'This is an example of a longer need that will be truncated in certain areas.',
        reason: storySuccessMock.result.data.story.reason,
      },
    },
  },
}

export const updateStoryFailureMock = {
  request: {
    query: UpdateStoryDocument,
    variables: {
      id: storySuccessMock.result.data.story.id,
      role: 'Project owner',
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 404,
          error: 'Not Found',
          message: 'Story does not exist.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const updateStoryMocks: UpdateStoryMockResponse[] = [
  updateStorySuccessMock,
  updateStoryLongNeedSuccessMock,
  updateStoryFailureMock,
]
