import { plainToClass } from 'class-transformer'
import { IsEmail, MaxLength, MinLength, validateSync } from 'class-validator'
import { ForgotPasswordMutationVariables } from '../../graphql'
import { getValidationErrors } from '../../../../util'

export class ForgotPasswordDto {
  @IsEmail(undefined, {
    message:
      'Please enter email in email address format. E.g. johnsmith@example.com.',
  })
  @MinLength(3, {
    message: 'Email should be at least $constraint1 characters long.',
  })
  @MaxLength(255, {
    message: 'Email should be at most $constraint1 characters long.',
  })
  email!: string
}

export const forgotPasswordValidate = (vars: ForgotPasswordMutationVariables) =>
  getValidationErrors(validateSync(plainToClass(ForgotPasswordDto, vars)))
