import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Skeleton from '@material-ui/lab/Skeleton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { first, isNil, isUndefined, mapValues, truncate } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Fragment, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import {
  createStoryValidate,
  goToAccountChangePlan,
  useCreateStory,
  useProjectQuery,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

export interface StoryCreateDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  projectId: string
  onClose: () => void
  onCreate: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      marginTop: -theme.spacing(0.5),
    },
    cancelButton: {
      flex: 1,
    },
    dialogPaper: {
      maxWidth: theme.spacing(50),
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    fields: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(1),
    },
    title: {
      flex: 1,
      marginRight: theme.spacing(4),
    },
    updateButton: {
      flex: 2,
    },
  })
)

const description = `Project owner's story quota has been reached. If you are the project
owner, please upgrade below to add more stories. Alternatively please
contact the project owner to increase their quota.`

export const StoryCreateDialog: React.FC<StoryCreateDialogProps> = ({
  onClose,
  onCreate,
  projectId,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { data, loading } = useProjectQuery({
    variables: {
      id: projectId,
    },
  })
  const [createStory] = useCreateStory({
    update: (cache, { data }) => {
      if (isNil(data)) return

      cache.modify({
        id: `Project:${projectId}`,
        fields: {
          storyRoles(storyRoles: string[] = []) {
            return [
              ...storyRoles.filter((n) => n !== data.createStory.role),
              data.createStory.role,
            ]
          },
        },
      })
    },
  })
  const canCreateStory = data?.project.canCreateStory
  const showSkeleton = loading && isUndefined(data)

  const onUpgradeClick = useCallback(() => {
    goToAccountChangePlan(history)
  }, [history])

  return isUndefined(canCreateStory) || canCreateStory ? (
    <Dialog
      aria-labelledby="create-story-dialog-title"
      aria-describedby="create-story-dialog-description"
      fullWidth={true}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle
        id="create-story-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography className={classes.title} variant="h5">
          {showSkeleton ? <Skeleton title="Loading title" /> : 'New story'}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          edge="end"
          title="Close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          role: '',
          need: '',
          reason: '',
        }}
        validate={(values) =>
          mapValues(createStoryValidate(values), (n) => first(n))
        }
        onSubmit={(values, { resetForm }) => {
          const storyDisplayName = truncate(values.need, {
            length: 35,
          })
          const id = uuid()

          createStory({
            variables: {
              id,
              projectId,
              ...values,
            },
          })
            .then(() => {
              enqueueSnackbar(
                `Successfully created story "${storyDisplayName}".`,
                {
                  variant: 'success',
                }
              )
            })
            .catch((e) => {
              const error = first(getGraphQLError(e as ApiErrors))
              const errorText = isUndefined(error) ? '' : ` ${error}`

              gtag('event', 'exception', {
                description: `Failed to create ${id} story.${errorText}`,
                fatal: false,
              })
              enqueueSnackbar(
                `Failed to create "${storyDisplayName}" story.${errorText}`,
                {
                  variant: 'error',
                }
              )
            })

          resetForm()
          onCreate()
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            {
              <Fragment>
                <DialogContent>
                  <DialogContentText id="create-story-dialog-description">
                    {showSkeleton ? (
                      <Fragment>
                        <Skeleton title="Loading description" />
                        <Skeleton width="80%" />
                      </Fragment>
                    ) : (
                      'Write out your role, what you want and why you want it from your own perspective.'
                    )}
                  </DialogContentText>
                  {showSkeleton ? (
                    <div className={classes.fields}>
                      <Skeleton
                        height={52}
                        title="Loading role"
                        variant="rect"
                      />
                      <Skeleton
                        height={71}
                        title="Loading need"
                        variant="rect"
                      />
                      <Skeleton
                        height={71}
                        title="Loading reason"
                        variant="rect"
                      />
                    </div>
                  ) : (
                    <div className={classes.fields}>
                      <Field
                        component={TextField}
                        FormHelperTextProps={{
                          'aria-label': 'Role helper text',
                        }}
                        id="role-field"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="As a"
                        name="role"
                        placeholder="e.g. Designer"
                        variant="outlined"
                      />
                      <Field
                        component={TextField}
                        FormHelperTextProps={{
                          'aria-label': 'Need helper text',
                        }}
                        id="need-field"
                        inputProps={{
                          rowsMin: 2,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="I need/want/expect to"
                        name="need"
                        multiline={true}
                        placeholder="e.g. Capture requirements online"
                        variant="outlined"
                      />
                      <Field
                        component={TextField}
                        FormHelperTextProps={{
                          'aria-label': 'Reason helper text',
                        }}
                        id="reason-field"
                        inputProps={{
                          rowsMin: 2,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="So that"
                        name="reason"
                        multiline={true}
                        placeholder="e.g. We can keep in sync remotely"
                        variant="outlined"
                      />
                    </div>
                  )}
                </DialogContent>
              </Fragment>
            }
            <DialogActions>
              <Button
                className={classes.cancelButton}
                onClick={onClose}
                variant="outlined"
              >
                Cancel
              </Button>
              {showSkeleton ? (
                <Skeleton
                  className={classes.updateButton}
                  height={44}
                  title="Loading create button"
                  variant="rect"
                />
              ) : (
                <Button
                  className={classes.updateButton}
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => {
                    submitForm().catch(() => {
                      // handled above
                    })
                  }}
                  variant="contained"
                >
                  Create
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  ) : (
    <Dialog
      aria-labelledby="upgrade-story-quota-dialog-title"
      aria-describedby="upgrade-story-quota-dialog-description"
      fullWidth={true}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle
        id="upgrade-story-quota-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography className={classes.title} variant="h5">
          Stories limit
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          edge="end"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="upgrade-story-quota-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelButton}
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={classes.updateButton}
          color="primary"
          onClick={onUpgradeClick}
          variant="contained"
        >
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  )
}
