import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import '@material-ui/lab/themeAugmentation'
import { Elements } from '@stripe/react-stripe-js'
import 'fontsource-raleway/400-normal.css'
import 'fontsource-raleway/500-normal.css'
import {
  createClient,
  persistor,
  privacyPolicyUri,
  Router,
  signUpUri,
  stripePromise,
} from './middleware'
import { SnackbarProvider } from 'notistack'
import React, { useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Redirect, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import {
  CookieConsentDialog,
  CreateProjectPage,
  EmailVerificationPage,
  ForgotPasswordPage,
  FromRedirect,
  FromRoute,
  HasEmailVerifiedRoute,
  HasProjectsRoute,
  JoinProjectPage,
  LandingPage,
  LoggedInRoute,
  LoginPage,
  MyAccountPage,
  NoProjectsPage,
  PrivacyPolicyPage,
  RecentProjectRedirect,
  ResetPasswordPage,
  SignUpPage,
  StoryPage,
  StorageProvider,
  TermsOfUsePage,
} from './components'
import { config } from './config'

const breakpoints = createBreakpoints({})

const useStyles = makeStyles({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      // minHeight: '100vh' doesn't work well on mobile
      // see: https://bugs.chromium.org/p/chromium/issues/detail?id=844848#c4
      height: '100%',
    },
  },
})

const darkBorder = 'rgba(0, 0, 0, 0.35)'

export const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F8F8F8',
    },
    primary: {
      main: config.colours.primary,
    },
    secondary: {
      main: '#E62E2E',
    },
    text: {
      primary: config.colours.darkGrey,
    },
  },
  typography: {
    fontFamily: ['Raleway', 'sans-serif'].join(','),
    // See: https://grtcalculator.com for values used.
    h3: { fontSize: '2.625rem', fontWeight: 400, lineHeight: 1.548 },
    h4: { fontSize: '2.063rem', fontWeight: 400, lineHeight: 1.576 },
    h5: { fontSize: '1.625rem', fontWeight: 400, lineHeight: 1.615 },
    h6: { fontSize: '1.25rem', fontWeight: 400, lineHeight: 1.65 },
    body1: {
      fontWeight: 400,
      lineHeight: 1.688,
      maxWidth: 476,
    },
    body2: {
      fontSize: '0.813rem',
      fontWeight: 400,
      lineHeight: 1.769,
    },
    button: {
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
      lineHeight: 1.71428571429,
      textTransform: 'none',
    },
  },
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: '27px 9px 9px',
          '& .MuiAutocomplete-input': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
    MuiAvatar: {
      root: {
        fontSize: '1rem', // 16px (same as button)
      },
      colorDefault: {
        backgroundColor: config.colours.primary,
      },
    },
    MuiButton: {
      root: {
        padding: `${config.spacingUnit * 1.25}px ${config.spacingUnit * 2}px`,
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlined: {
        color: config.colours.darkButtonText,
        borderColor: config.colours.darkBorder,
        padding: `${config.spacingUnit * 1.25 - 1}px ${
          config.spacingUnit * 2 - 1
        }px`,
      },
      startIcon: {
        height: 0,
        marginTop: -20,
      },
    },
    MuiChip: {
      root: {
        color: config.colours.darkGrey,
        backgroundColor: config.colours.midGrey,
        height: 36,
        '& .MuiChip-avatar': {
          color: 'white',
          width: 32,
          height: 32,
          marginLeft: 2,
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: `${config.spacingUnit * 4}px ${config.spacingUnit}px`,
      },
    },
    MuiDialogActions: {
      root: {
        paddingTop: config.spacingUnit * 5,
        paddingBottom: config.spacingUnit * 4,
        paddingLeft: config.spacingUnit * 2,
        paddingRight: config.spacingUnit * 2,
        [breakpoints.up('sm')]: {
          paddingLeft: config.spacingUnit * 4,
          paddingRight: config.spacingUnit * 4,
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${config.spacingUnit}px ${config.spacingUnit * 2}px`,
        [breakpoints.up('sm')]: {
          padding: `${config.spacingUnit}px ${config.spacingUnit * 4}px`,
        },
      },
    },
    MuiDialogContentText: {
      root: {
        marginBottom: config.spacingUnit * 2,
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: config.spacingUnit * 3,
        paddingBottom: config.spacingUnit * 2,
        paddingLeft: config.spacingUnit * 2,
        paddingRight: config.spacingUnit * 2,
        [breakpoints.up('sm')]: {
          paddingLeft: config.spacingUnit * 4,
          paddingRight: config.spacingUnit * 4,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: config.colours.darkBorder,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: `1px solid ${config.colours.darkBorder}`,
      },
    },
    MuiFab: {
      root: {
        color: config.colours.darkGrey,
        backgroundColor: 'white',
        '& svg': {
          fill: config.colours.primary,
        },
        boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.14)',
      },
      extended: {
        '& svg': {
          marginRight: config.spacingUnit,
        },
      },
      primary: {
        '& svg': {
          fill: 'currentColor',
        },
      },
      secondary: {
        '& svg': {
          fill: 'currentColor',
        },
      },
      sizeSmall: {
        width: 36,
        height: 36,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: config.spacingUnit * 2,
      },
    },
    MuiIconButton: {
      root: {
        color: config.colours.darkGrey,
      },
    },
    MuiInputLabel: {
      outlined: {
        color: config.colours.primary,
        '&$shrink': {
          transform: 'translate(14px, 8px) scale(0.857)',
        },
      },
    },
    MuiInputBase: {
      root: {
        maxWidth: 'initial',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($focused)': {
          '& $notchedOutline': {
            borderColor: darkBorder,
          },
        },
        '&$focused': {
          '& $notchedOutline': {
            borderColor: config.colours.primary,
            borderWidth: 1,
          },
          '& .MuiSelect-outlined ~ $notchedOutline': {
            borderColor: config.colours.darkBorder,
          },
          '&:hover .MuiSelect-outlined ~ $notchedOutline': {
            borderColor: darkBorder,
          },
        },
      },
      input: {
        padding: '23.5px 14px 9.5px',
      },
      multiline: { padding: '23.5px 14px 9.5px' },
      notchedOutline: {
        top: 0,
        borderColor: config.colours.darkBorder,
        '& legend': {
          display: 'none',
          '& span': {
            display: 'none',
          },
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 6,
      },
      colorPrimary: {
        borderRadius: 4,
        backgroundColor: config.colours.darkBorder,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36,
      },
    },
    MuiPaper: {
      outlined: {
        borderColor: config.colours.darkBorder,
      },
    },
    MuiSelect: {
      iconOutlined: {
        right: 12,
      },
      outlined: {
        '&.MuiSelect-outlined': {
          paddingRight: 45,
        },
      },
      select: {
        '&:focus': {
          borderRadius: 'inherit',
        },
      },
      selectMenu: {
        minHeight: 'initial',
      },
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked + .MuiSwitch-track': {
          backgroundColor: config.colours.darkGrey,
          opacity: 1,
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: '1.25rem',
        fontWeight: 400,
        lineHeight: 1.65,
        [breakpoints.up('sm')]: {
          fontSize: '1.625rem',
          fontWeight: 400,
          lineHeight: 1.615,
        },
      },
    },
    MuiToggleButton: {
      root: {
        color: config.colours.darkButtonText,
        border: `1px solid ${config.colours.darkBorder}`,
        padding: 16,
        [breakpoints.up('md')]: {
          paddingTop: 9.5,
          paddingBottom: 9.5,
          paddingLeft: config.spacingUnit * 2 - 1,
          paddingRight: config.spacingUnit * 2 - 1,
        },
        lineHeight: 1.71428571429,
        '&$selected': {
          color: config.colours.darkButtonText,
          backgroundColor: config.colours.darkSelected,
        },
        '&:hover': {
          borderColor: darkBorder,
        },
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: 'auto!important',
      },
    },
  },
})

const loggedOutRouteProps = {
  allowLoggedIn: false,
  redirectComponent: (
    <Switch>
      <FromRoute>
        <RecentProjectRedirect />
      </FromRoute>
    </Switch>
  ),
}

interface GraphQLProviderProps {
  children?: React.ReactNode
}

const GraphQLProvider: React.FC<GraphQLProviderProps> = ({ children }) => {
  // refresh hack for logout
  const [, setState] = useState(new Date())
  const reload = () => setState(new Date())

  const client = createClient(reload)

  return (
    <StorageProvider client={client} persistor={persistor}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </StorageProvider>
  )
}

export const App: React.FC = () => {
  useStyles()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={1} preventDuplicate>
        <CookiesProvider>
          <Router>
            <GraphQLProvider>
              <Elements
                options={{
                  fonts: [
                    {
                      cssSrc:
                        'https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap',
                    },
                  ],
                }}
                stripe={stripePromise}
              >
                <Route
                  // allow users to read privacy policy before accepting cookies
                  render={({ location }) =>
                    location.pathname.startsWith(privacyPolicyUri()) ? null : (
                      <CookieConsentDialog />
                    )
                  }
                />
                <QueryParamProvider ReactRouterRoute={Route}>
                  <Switch>
                    <LoggedInRoute
                      component={LandingPage}
                      exact={true}
                      path={'/'}
                      {...loggedOutRouteProps}
                    />
                    <LoggedInRoute
                      component={LoginPage}
                      path={'/login'}
                      {...loggedOutRouteProps}
                    />
                    <LoggedInRoute
                      component={SignUpPage}
                      path={'/signup'}
                      {...loggedOutRouteProps}
                    />
                    <LoggedInRoute
                      component={ForgotPasswordPage}
                      path={'/forgot-password'}
                      {...loggedOutRouteProps}
                    />
                    <LoggedInRoute
                      path={'/reset-password/:verificationCode'}
                      render={(p) => (
                        <ResetPasswordPage
                          verificationCode={
                            (p.match.params as Record<string, string>)
                              .verificationCode
                          }
                        />
                      )}
                      {...loggedOutRouteProps}
                    />
                    <Route path={'/privacy-policy'}>
                      <PrivacyPolicyPage />
                    </Route>
                    <Route path={'/terms-of-use'}>
                      <TermsOfUsePage />
                    </Route>
                    <LoggedInRoute
                      path={'/'}
                      redirectComponent={
                        <FromRedirect
                          to={{
                            pathname: signUpUri(),
                          }}
                        />
                      }
                    >
                      <Switch>
                        <Route
                          exact={true}
                          path={'/my-account'}
                          component={MyAccountPage}
                        />
                        <HasProjectsRoute
                          path={'/project/:projectId'}
                          redirectComponent={<Redirect to={'/no-projects'} />}
                        >
                          {(p) => (
                            <StoryPage
                              projectId={
                                (p.match?.params as Record<string, string>)
                                  .projectId
                              }
                            />
                          )}
                        </HasProjectsRoute>
                        <HasProjectsRoute
                          allowHasProjects={false}
                          exact={true}
                          path={'/no-projects'}
                          redirectComponent={<RecentProjectRedirect />}
                        >
                          <NoProjectsPage />
                        </HasProjectsRoute>
                        <Route
                          exact={true}
                          path={'/create-project'}
                          component={CreateProjectPage}
                        />
                        <HasEmailVerifiedRoute
                          allowHasEmailVerified={false}
                          exact={true}
                          path={'/verify-email/:verificationCode'}
                          redirectComponent={<RecentProjectRedirect />}
                        >
                          {(p) => (
                            <EmailVerificationPage
                              verificationCode={
                                (p.match?.params as Record<string, string>)
                                  .verificationCode
                              }
                            />
                          )}
                        </HasEmailVerifiedRoute>
                        <Route
                          exact={true}
                          path={'/join-project/:projectUserId'}
                          render={(p) => (
                            <JoinProjectPage
                              projectUserId={
                                (p.match.params as Record<string, string>)
                                  .projectUserId
                              }
                            />
                          )}
                        />
                        <RecentProjectRedirect />
                      </Switch>
                    </LoggedInRoute>
                    <FromRedirect
                      to={{
                        pathname: '/',
                      }}
                    />
                  </Switch>
                </QueryParamProvider>
              </Elements>
            </GraphQLProvider>
          </Router>
        </CookiesProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
