import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'
import React from 'react'

export interface SubmitButtonProps extends ButtonProps {
  circularProgressProps?: CircularProgressProps
  loading: boolean
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  circularProgressProps,
  loading,
  ...rest
}) => (
  <Button
    {...{
      ...rest,
      ...(loading
        ? {
            startIcon: undefined,
          }
        : {}),
    }}
  >
    {loading ? (
      <CircularProgress
        aria-label="Loading"
        color="inherit"
        size={20}
        {...circularProgressProps}
      />
    ) : (
      children
    )}
  </Button>
)
