import Card, { CardProps } from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import { format } from 'date-fns'
import { first, gt, isNil, isUndefined } from 'lodash'
import React, { Fragment, useCallback, useState } from 'react'
import { CreatePaymentMethodDialog } from '../../../components'
import { useCurrentUserBillingQuery } from '../../../middleware'

type BillingCardProps = CardProps

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2, 3),
      maxWidth: 768,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    description: {
      maxWidth: theme.spacing(35),
    },
    intro: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(4),
    },
    paymentMethod: {
      flex: 1,
    },
    paymentMethodCard: {
      textTransform: 'capitalize',
    },
    subscription: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      '& >:last-child': {
        marginLeft: theme.spacing(3),
      },
    },
    nextPayment: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      '& >:last-child': {
        marginLeft: theme.spacing(3),
      },
    },
    divider: {
      margin: theme.spacing(2, 0),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 2),
      },
    },
  })
)

export const BillingCard: React.FC<BillingCardProps> = (props) => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const [state, setState] = useState<{
    createPaymentMethodOpen: boolean
  }>({
    createPaymentMethodOpen: false,
  })
  const { data } = useCurrentUserBillingQuery()

  const defaultPaymentMethod = data?.currentUser?.defaultPaymentMethod
  const subscription = first(data?.currentUser?.subscriptions)
  const upcomingInvoice = data?.currentUser?.upcomingInvoice

  const onUpdatePaymentMethodClick = useCallback(() => {
    setState({ ...state, createPaymentMethodOpen: true })
  }, [state])

  const onUpdatePaymentMethodClose = useCallback(() => {
    setState({ ...state, createPaymentMethodOpen: false })
  }, [state])

  if (
    isNil(defaultPaymentMethod) &&
    isUndefined(subscription) &&
    isNil(upcomingInvoice)
  )
    return null

  const brand = defaultPaymentMethod?.brand
  const last4 = defaultPaymentMethod?.last4
  const expiry = defaultPaymentMethod?.expiry
  const productName = first(subscription?.items)?.price?.product.name
  const productQuantity = first(subscription?.items)?.quantity

  const currency = upcomingInvoice?.currency
  const nextInvoiceAmount = upcomingInvoice?.amountDue
  const nextInvoiceDue = upcomingInvoice?.periodEnd

  return (
    <Fragment>
      <div className={classes.intro}>
        <Typography variant="h3">Billing</Typography>
        <Typography className={classes.description}>
          Review your payment method and monthly payment.
        </Typography>
      </div>
      <Card
        variant="outlined"
        {...props}
        className={clsx([classes.card, props.className])}
      >
        <div className={classes.paymentMethod}>
          <Typography
            className={classes.paymentMethodCard}
            variant={smUp ? 'h5' : 'h6'}
          >
            <span>{isNil(brand) ? 'Card' : brand}</span>
            <span> **** </span>
            <span>{last4}</span>
          </Typography>
          {isNil(expiry) ? undefined : (
            <Typography>Expires {format(new Date(expiry), 'MM/yy')}</Typography>
          )}
          <Link
            component="button"
            onClick={onUpdatePaymentMethodClick}
            variant="body1"
          >
            Update payment method
          </Link>
        </div>
        <Divider
          className={classes.divider}
          orientation={smUp ? 'vertical' : 'horizontal'}
          flexItem={smUp}
        />
        <div>
          <div className={classes.subscription}>
            <Typography variant={smUp ? 'h5' : 'h6'}>{productName}</Typography>
            <Typography align="right">
              {productQuantity} {gt(productQuantity, 1) ? 'seats' : 'seat'}
            </Typography>
          </div>
          {isNil(nextInvoiceAmount) || isNil(currency) ? undefined : (
            <div className={classes.nextPayment}>
              <Typography>Next payment:</Typography>
              <Typography align="right" variant="h6">
                {
                  new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency,
                  }).format(nextInvoiceAmount / 100) // pounds to pennies
                }
              </Typography>
            </div>
          )}
          {isNil(nextInvoiceDue) ? undefined : (
            <div className={classes.nextPayment}>
              <Typography>Due on:</Typography>
              <Typography align="right">
                {format(new Date(nextInvoiceDue), 'do MMMM yyyy')}
              </Typography>
            </div>
          )}
        </div>
      </Card>
      <CreatePaymentMethodDialog
        open={state.createPaymentMethodOpen}
        onClose={onUpdatePaymentMethodClose}
      />
    </Fragment>
  )
}
