import { isUndefined } from 'lodash'
import React, { Fragment } from 'react'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button, { ButtonProps } from '@material-ui/core/Button'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { SubmitButton, SubmitButtonProps } from '../../../components'

export interface ForgotPasswordFormProps
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  error?: string
  emailField?: TextFieldProps
  loading: boolean
  submitButton?: SubmitButtonProps
  submitted?: boolean
  loginButton?: ButtonProps
}

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    description: {
      marginBottom: theme.spacing(5),
    },
    fields: {
      width: '100%',
      maxWidth: theme.spacing(35),
      display: 'grid',
      gridTemplateColumns: '1fr',
      marginBottom: theme.spacing(3),
    },
    actions: {
      width: '100%',
      maxWidth: theme.spacing(40),
      display: 'flex',
      '& >*': {
        flex: 1,
      },
      '& >:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  })
)

const description = `Please fill in your email and you'll recieve a password reset email
if the email has an account with us.`
const submittedDescription = `Password reset was successfully submitted. If the email matches an
account, we'll send out further instructions to that address. Please
check your emails.`

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  error,
  emailField,
  loading,
  submitButton,
  submitted,
  loginButton,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <form className={classes.form} {...rest}>
      {isUndefined(error) ? undefined : (
        <Alert severity="error">
          <AlertTitle>Forgot password request failed</AlertTitle>
          {error}
        </Alert>
      )}
      {!isUndefined(submitted) && submitted ? (
        <Fragment>
          <Typography variant="h3" gutterBottom={true}>
            Password reset submitted
          </Typography>
          <Typography align="center" paragraph={true}>
            {submittedDescription}
          </Typography>
          <div className={classes.actions}>
            <Button variant="outlined" {...loginButton}>
              Login
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant="h3" gutterBottom={true}>
            Forgot password?
          </Typography>
          <Typography align="center" className={classes.description}>
            {description}
          </Typography>
          <div className={classes.fields}>
            <TextField
              FormHelperTextProps={{
                'aria-label': 'Email helper text',
              }}
              id="email-field"
              label="Email"
              variant="outlined"
              margin="normal"
              {...emailField}
            />
          </div>
          <div className={classes.actions}>
            <Button variant="outlined" {...loginButton}>
              Login
            </Button>
            <SubmitButton
              color="primary"
              loading={loading}
              type="submit"
              variant="contained"
              {...submitButton}
            >
              Reset password
            </SubmitButton>
          </div>
        </Fragment>
      )}
    </form>
  )
}
