import Card from '@material-ui/core/Card'
import Container from '@material-ui/core/Container'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { BrochureLayout } from '../../../components'

const backgroundImage = '/images/hero_background.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(10, 8),
      },
    },
    container: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: `center ${theme.spacing(5)}px`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 200,
      width: '100%',
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(8),
        backgroundPosition: 'center top',
        backgroundSize: 600,
      },
    },
  })
)

interface AuthLayoutProps {
  children?: React.ReactNode
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <BrochureLayout>
      <div className={classes.container}>
        <Container maxWidth="xs">
          <Card className={classes.card} raised={true}>
            {children}
          </Card>
        </Container>
      </div>
    </BrochureLayout>
  )
}
