import * as ApolloReactHooks from '@apollo/client'
import { isNil } from 'lodash'
import {
  DeleteStoryMutation,
  DeleteStoryMutationVariables,
  useDeleteStoryMutation,
} from '../../graphql'

export const useDeleteStory = (
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteStoryMutation,
    DeleteStoryMutationVariables
  >
) =>
  useDeleteStoryMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      deleteStory: {
        __typename: 'Story',
        ...variables,
      },
    }),
    update: (cache, { data }) => {
      if (isNil(data)) return

      const ref = cache.identify(data.deleteStory)
      cache.evict({ id: ref })
      cache.gc()
    },
    ...baseOptions,
  })
