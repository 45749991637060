import { History, LocationDescriptorObject } from 'history'
import {
  accountUri,
  accountChangePlanUri,
  createProjectUri,
  emailSupportUri,
  forgotPasswordUri,
  importStoriesUri,
  loginUri,
  noProjectsUri,
  privacyPolicyUri,
  projectInviteUri,
  projectUri,
  signUpUri,
  termsOfUseUri,
  updateProjectUri,
  updateStoryUri,
} from './uris'

type Location = Omit<
  LocationDescriptorObject<History.PoorMansUnknown>,
  'pathname'
>

export const goToAccount = (history: History) => history.push(accountUri())

export const goToAccountChangePlan = (history: History) =>
  history.push(accountChangePlanUri())

export const goToLogin = (history: History) => history.push(loginUri())

export const goToNoProjects = (history: History) =>
  history.push(noProjectsUri())

export const goToPrivacyPolicy = (history: History) =>
  history.push(privacyPolicyUri())

export const goToSignUp = (history: History) => history.push(signUpUri())

export const goToEmailSupport = () => {
  window.location.href = emailSupportUri()
}

export const goToTermsOfUse = (history: History) =>
  history.push(termsOfUseUri())

export const goToForgotPassword = (history: History) =>
  history.push(forgotPasswordUri())

export const goToProject = (
  history: History,
  projectId: string,
  location?: Location
) => history.push({ pathname: projectUri(projectId), ...location })

export const goToImportStories = (
  history: History,
  projectId: string,
  location?: Location
) => history.push({ pathname: importStoriesUri(projectId), ...location })

export const goToCreateProject = (history: History) =>
  history.push(createProjectUri())

export const goToProjectInvite = (
  history: History,
  projectId: string,
  location?: Location
) => history.push({ pathname: projectInviteUri(projectId), ...location })

export const goToUpdateProject = (history: History, projectId: string) =>
  history.push(updateProjectUri(projectId))

export const goToUpdateStory = (
  history: History,
  projectId: string,
  storyId: string,
  location?: Location
) => history.push({ pathname: updateStoryUri(projectId, storyId), ...location })
