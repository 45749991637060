import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Skeleton from '@material-ui/lab/Skeleton'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import {
  first,
  isUndefined,
  mapValues,
  omitBy,
  reduce,
  truncate,
  isNil,
} from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { ProjectDeleteDialog } from '../../../components'
import {
  updateProjectValidate,
  useProjectQuery,
  useUpdateProject,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

export interface ProjectUpdateDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  projectId: string
  onClose: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      marginTop: -theme.spacing(0.5),
    },
    cancelButton: {
      flex: 1,
    },
    dialogPaper: {
      maxWidth: theme.spacing(50),
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    error: {
      marginBottom: theme.spacing(2),
    },
    fields: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(1),
    },
    title: {
      flex: 1,
      marginRight: theme.spacing(4),
    },
    updateButton: {
      flex: 2,
    },
  })
)

export const ProjectUpdateDialog: React.FC<ProjectUpdateDialogProps> = ({
  onClose,
  projectId,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState<{
    deleteOpen: boolean
  }>({
    deleteOpen: false,
  })

  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useProjectQuery({
    variables: {
      id: projectId,
    },
    onError: (e) => {
      const error = first(getGraphQLError(e))

      gtag('event', 'exception', {
        description: `Failed to read ${projectId} project.${
          isUndefined(error) ? '' : ` ${error}`
        }`,
        fatal: false,
      })
    },
  })
  const [updateProject] = useUpdateProject()

  const error = useMemo(
    () =>
      isUndefined(projectError)
        ? undefined
        : first(getGraphQLError(projectError)),
    [projectError]
  )

  const onDeleteOpen = useCallback(() => {
    setState({ ...state, deleteOpen: true })
  }, [state])

  const onDeleteClose = useCallback(() => {
    setState({ ...state, deleteOpen: false })
  }, [state])

  const initialValues = isUndefined(projectData)
    ? {
        name: '',
      }
    : {
        name: projectData.project.name,
      }

  return (
    <Dialog
      aria-labelledby="update-project-dialog-title"
      aria-describedby="update-project-dialog-description"
      PaperProps={{ className: classes.dialogPaper }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle
        id="update-project-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography className={classes.title} variant="h5">
          Edit project
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          edge="end"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={(values) =>
          mapValues(updateProjectValidate(values), (n) => first(n))
        }
        onSubmit={(values) => {
          const changedValues = reduce(
            values,
            (result, value, key) =>
              initialValues[key as keyof typeof values] === value
                ? result
                : { ...result, [key]: value },
            {}
          )

          const projectName = truncate(values.name, {
            length: 35,
          })

          updateProject({
            variables: {
              id: projectId,
              ...changedValues,
            },
            optimisticResponse: isUndefined(projectData)
              ? undefined
              : (variables) => ({
                  __typename: 'Mutation',
                  updateProject: {
                    __typename: 'Project',
                    ...projectData.project,
                    ...omitBy(variables, isNil),
                  },
                }),
          })
            .then(() => {
              enqueueSnackbar(
                `Successfully updated project "${projectName}".`,
                {
                  variant: 'success',
                }
              )
            })
            .catch((e) => {
              const error = first(getGraphQLError(e as ApiErrors))
              const errorText = isUndefined(error) ? '' : ` ${error}`

              gtag('event', 'exception', {
                description: `Failed to update ${projectId} project.${errorText}`,
                fatal: false,
              })
              enqueueSnackbar(
                `Failed to update "${projectName}" project.${errorText}`,
                {
                  variant: 'error',
                }
              )
            })

          onClose()
        }}
      >
        {({ dirty, submitForm, isSubmitting }) => (
          <Form>
            {
              <Fragment>
                <DialogContent>
                  <DialogContentText id="update-project-dialog-description">
                    Update the project name or delete the project.
                  </DialogContentText>
                  {isUndefined(projectData) && projectLoading ? (
                    <div className={classes.fields}>
                      <Skeleton
                        height={70}
                        title="Loading name"
                        variant="text"
                      />
                    </div>
                  ) : (
                    <div className={classes.fields}>
                      {isUndefined(error) ? undefined : (
                        <Alert className={classes.error} severity="error">
                          <AlertTitle>Project failed to load</AlertTitle>
                          {error}
                        </Alert>
                      )}
                      <Field
                        component={TextField}
                        FormHelperTextProps={{
                          'aria-label': 'Name helper text',
                        }}
                        id="name-field"
                        label="Name"
                        margin="normal"
                        name="name"
                        placeholder="E.g. My new project"
                        variant="outlined"
                      />
                    </div>
                  )}
                </DialogContent>
              </Fragment>
            }
            <DialogActions>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon color="error" />}
                onClick={onDeleteOpen}
              >
                Delete project
              </Button>
              <Button
                className={classes.cancelButton}
                onClick={onClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className={classes.updateButton}
                color="primary"
                disabled={!dirty || isSubmitting}
                onClick={() => {
                  submitForm().catch(() => {
                    // handled above
                  })
                }}
                variant="contained"
              >
                Update
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
      {isUndefined(projectData) ? undefined : (
        <ProjectDeleteDialog
          open={state.deleteOpen}
          project={projectData.project}
          onClose={onDeleteClose}
        />
      )}
    </Dialog>
  )
}
