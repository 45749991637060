import * as ApolloReactHooks from '@apollo/client'
import {
  CreateStoryMutation,
  CreateStoryMutationVariables,
  useCreateStoryMutation,
} from '../../graphql'

export const useCreateStory = (
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStoryMutation,
    CreateStoryMutationVariables
  >
) =>
  useCreateStoryMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      createStory: {
        __typename: 'Story',
        archived: false,
        canDelete: true,
        canLove: true,
        canUpdate: true,
        storyLove: [],
        ...variables,
      },
    }),

    // Invalidate stories as this can have many different filters and sort orderings.
    // apollo can't retrieve multiple versions of a query with different variables,
    // although there is a 'hacky' way to filter through and add each to a cached query
    // (but the sort order can't be addressed on a paginated list).
    // See: https://github.com/apollographql/react-apollo/issues/708
    refetchQueries: ['stories'],
    ...baseOptions,
  })
