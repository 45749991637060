import Button, { ButtonProps } from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import Popover, { PopoverProps } from '@material-ui/core/Popover'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import { isNil, isUndefined } from 'lodash'
import React, { Fragment, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ProjectsList } from '../../../components'
import {
  goToAccountChangePlan,
  goToCreateProject,
  useCurrentUser,
  useProjectQuery,
} from '../../../middleware'

export interface ProjectSelectProps
  extends Omit<ButtonProps, 'aria-describedby' | 'onClick'> {
  popoverProps?: PopoverProps
  projectId: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      minWidth: 0, // prevents taking over full screen
      maxWidth: 300,
    },
    list: {
      minWidth: 250,
      minHeight: 250,
      paddingTop: theme.spacing(1),
      paddingBootm: theme.spacing(1),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    openIcon: {
      transform: 'rotate(180deg)',
    },
    divider: {
      marginBottom: theme.spacing(2),
    },
    footer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    upgradeContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
    },
  })
)

export const ProjectSelect: React.FC<ProjectSelectProps> = ({
  popoverProps,
  projectId,
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const {
    data: currentUserData,
    loading: currentUserLoading,
  } = useCurrentUser()
  const { data: projectData, loading: projectLoading } = useProjectQuery({
    variables: {
      id: projectId,
    },
  })

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const onListClick = useCallback(() => {
    onClose()
  }, [])

  const onCreateProject = useCallback(() => {
    goToCreateProject(history)
    onClose()
  }, [history])

  const onUpgrade = useCallback(() => {
    goToAccountChangePlan(history)
    onClose()
  }, [history])

  const open = Boolean(anchorEl)
  const id = open ? 'project-select' : undefined
  const canCreateProject = currentUserData?.currentUser?.canCreateProject
  const disabledCreate = isNil(canCreateProject) ? false : !canCreateProject

  return (
    <Fragment>
      {isUndefined(projectData) && projectLoading ? (
        <Skeleton title="Loading project name" />
      ) : (
        <Button
          aria-describedby={id}
          className={classes.select}
          onClick={onOpen}
          {...rest}
        >
          <Typography variant="h5" component="h1" noWrap={true}>
            {isUndefined(projectData) ? 'Unknown' : projectData.project.name}
          </Typography>
          <ArrowDropDownIcon
            className={clsx([
              classes.icon,
              {
                [classes.openIcon]: open,
              },
            ])}
          />
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        {...popoverProps}
      >
        <ProjectsList className={classes.list} onClick={onListClick} />
        <div className={classes.footer}>
          <Divider className={classes.divider} />
          {isUndefined(currentUserData) && currentUserLoading ? (
            <Skeleton title="Loading create project" width="100%">
              <Button fullWidth={true} variant="contained">
                Create new project
              </Button>
            </Skeleton>
          ) : (
            <Button
              color="primary"
              disabled={disabledCreate}
              fullWidth={true}
              onClick={onCreateProject}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Create new project
            </Button>
          )}
          {disabledCreate ? (
            <div className={classes.upgradeContainer}>
              <Typography component="span" variant="body2">
                Project quota used.&nbsp;
              </Typography>
              <Link component="button" onClick={onUpgrade} variant="body2">
                Upgrade
              </Link>
            </div>
          ) : undefined}
        </div>
      </Popover>
    </Fragment>
  )
}
