import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'
import { ProjectUsersList, UserProjectInviteForm } from '../../../components'

export interface ProjectUsersDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  projectId: string
  onClose: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      marginTop: -theme.spacing(0.5),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    divider: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    list: {
      minHeight: 350,
      [theme.breakpoints.up('sm')]: {
        minHeight: 100,
      },
    },
    title: {
      flex: 1,
      marginRight: theme.spacing(4),
    },
  })
)

const description = `Invite stakeholders to collaborate on your project. Read access will
allow them to view the project and stories, but not alter them. Write
access will allow them to read and also add and ammend their own
stories. Admin will allow them to write and also take ownership of the
project and all of it's settings.`

export const ProjectUsersDialog: React.FC<ProjectUsersDialogProps> = ({
  onClose,
  projectId,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Dialog
      aria-labelledby="create-story-dialog-title"
      aria-describedby="create-story-dialog-description"
      fullScreen={!smUp}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      PaperProps={{ className: classes.dialogPaper }}
      {...rest}
    >
      <DialogTitle
        id="create-story-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography className={classes.title} variant="h5">
          Invite users
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          edge="end"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="create-story-dialog-description">
          {description}
        </DialogContentText>
        <UserProjectInviteForm projectId={projectId} />
        <Divider className={classes.divider} />
        <ProjectUsersList className={classes.list} projectId={projectId} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
