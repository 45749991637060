import * as ApolloReactHooks from '@apollo/client'
import { isNil } from 'lodash'
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
  UserFragmentDoc,
  useUpdateUserMutation,
} from '../../graphql'

export const useUpdateUser = (
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) =>
  useUpdateUserMutation({
    update: (cache, { data: updateData }) => {
      if (isNil(updateData)) return

      const { __typename, id, ...data } = updateData.updateUser

      const prev = cache.readFragment<User>({
        id: `User:${id}`,
        fragment: UserFragmentDoc,
      })

      cache.writeFragment({
        id: `User:${id}`,
        fragment: UserFragmentDoc,
        data: { ...prev, ...data },
      })
    },
    ...baseOptions,
  })
