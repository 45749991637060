import { isUndefined } from 'lodash'
import React from 'react'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button, { ButtonProps } from '@material-ui/core/Button'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { SubmitButton, SubmitButtonProps } from '../../../components'

export interface ResetPasswordFormProps
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  error?: string
  emailField?: TextFieldProps
  loading: boolean
  passwordField?: TextFieldProps
  loginButton?: ButtonProps
  submitButton?: SubmitButtonProps
}

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    description: {
      marginBottom: theme.spacing(5),
    },
    fields: {
      width: '100%',
      maxWidth: theme.spacing(35),
      display: 'grid',
      gridTemplateColumns: '1fr',
      marginBottom: theme.spacing(3),
    },
    actions: {
      width: '100%',
      maxWidth: theme.spacing(40),
      display: 'flex',
      '& >*': {
        flex: 1,
      },
      '& >:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  })
)

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  error,
  emailField,
  loading,
  passwordField,
  loginButton,
  submitButton,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <form className={classes.form} {...rest}>
      {isUndefined(error) ? undefined : (
        <Alert severity="error">
          <AlertTitle>Password reset failed</AlertTitle>
          {error}
        </Alert>
      )}
      <Typography variant="h3" gutterBottom={true}>
        Password reset
      </Typography>
      <Typography align="center" className={classes.description}>
        Please confirm your email address and enter your new password to reset
        your password.
      </Typography>
      <div className={classes.fields}>
        <TextField
          FormHelperTextProps={{
            'aria-label': 'Email helper text',
          }}
          id="email-field"
          label="Confirm email"
          variant="outlined"
          margin="normal"
          {...emailField}
        />
        <TextField
          FormHelperTextProps={{
            'aria-label': 'Password helper text',
          }}
          id="password-field"
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          {...passwordField}
        />
      </div>
      <div className={classes.actions}>
        <Button variant="outlined" {...loginButton}>
          Login
        </Button>
        <SubmitButton
          color="primary"
          loading={loading}
          type="submit"
          variant="contained"
          {...submitButton}
        >
          Reset password
        </SubmitButton>
      </div>
    </form>
  )
}
