import Button from '@material-ui/core/Button'
import Card, { CardProps } from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import clsx from 'clsx'
import { format } from 'date-fns'
import { clamp, first, gt, isNil, isUndefined } from 'lodash'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { config } from '../../../config'
import {
  goToSignUp,
  Product,
  Price,
  SubscriptionItem,
} from '../../../middleware'

type PriceFields = Omit<Price, 'product'> & {
  product: Pick<
    Product,
    | 'id'
    | 'name'
    | 'description'
    | 'quotaDescription'
    | 'projectQuotaBase'
    | 'projectQuotaPerSeat'
    | 'storyQuotaBase'
    | 'storyQuotaPerSeat'
    | 'contributorQuotaBase'
    | 'contributorQuotaPerSeat'
  >
}

export interface SubscriptionCardProps extends CardProps {
  currentUser?: {
    subscriptions: {
      cancelAtPeriodEnd?: boolean | null
      currentPeriodEnd?: string | null
      items: (Pick<SubscriptionItem, 'quantity'> & {
        price?:
          | (Pick<Price, 'id' | 'tiers' | 'unitAmount'> & {
              product: Pick<Product, 'id'>
            })
          | null
      })[]
    }[]
  } | null
  loading: boolean
  onUpgrade: (price: PriceFields, quantity: number) => void
  onDowngrade: (price?: PriceFields, quantity?: number) => void
  price: PriceFields
}

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      padding: theme.spacing(4),
    },
    currentPlan: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    currentPlanIcon: {
      fontSize: 20,
      marginRight: theme.spacing(2),
    },
    description: {
      height: 54,
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    listItem: {
      padding: theme.spacing(0.5, 1),
    },
    footer: {
      backgroundColor: config.colours.darkGrey,
      boxShadow: `inset 0px 1px 0px ${config.colours.darkBorder}`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    quantity: {
      marginTop: theme.spacing(5),
    },
    quotaList: {
      '& >:nth-child(even)': {
        backgroundColor: 'rgba(139, 139, 139, 0.06)',
      },
    },
    textFooter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: 76,
    },
  })
)

const Discounts: React.FC<Pick<SubscriptionCardProps, 'price'>> = ({
  price,
}) => {
  const classes = useStyles()

  return isNil(price.tiers) ? null : (
    <List
      aria-label={`${price.product.name} bulk deals`}
      subheader={
        <ListSubheader disableGutters={true}>Bulk deals:</ListSubheader>
      }
    >
      {price.tiers
        .filter((n) => n.unitAmount !== price.unitAmount)
        .map(({ from, unitAmount }) => (
          <ListItem
            alignItems="flex-start"
            className={classes.listItem}
            key={unitAmount}
          >
            <ListItemIcon>
              <LocalOfferIcon color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary={`${Math.round(
                ((price.unitAmount - unitAmount) / price.unitAmount) * 100
              )}% discount for ${isNil(from) ? 1 : from}+ seats`}
            />
          </ListItem>
        ))}
    </List>
  )
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  className,
  currentUser,
  onUpgrade,
  onDowngrade,
  loading,
  price,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [state, setState] = useState<{ quantity: number }>({
    quantity: price.minQuantity,
  })

  const onSignUpClick = useCallback(() => {
    goToSignUp(history)
  }, [history])

  const onUpgradeClick = useCallback(() => {
    onUpgrade(
      price,
      clamp(state.quantity, price.minQuantity, price.maxQuantity ?? Infinity)
    )
  }, [onUpgrade, price, state.quantity])

  const onDowngradeClick = useCallback(() => {
    onDowngrade(
      price,
      clamp(state.quantity, price.minQuantity, price.maxQuantity ?? Infinity)
    )
  }, [onDowngrade, price, state.quantity])

  const onQuantityBlur = useCallback(() => {
    setState({
      ...state,
      quantity: clamp(
        state.quantity,
        price.minQuantity,
        price.maxQuantity ?? Infinity
      ),
    })
  }, [price.maxQuantity, price.minQuantity, state])

  const onQuantityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = parseInt(event.currentTarget.value, 10)

      if (!isNaN(newValue))
        setState({
          ...state,
          quantity: newValue,
        })
    },
    [state]
  )

  const isLoggedIn = !isNil(currentUser)

  const currentSubscription = first(currentUser?.subscriptions)

  const currentSubscriptionItem = first(currentSubscription?.items)

  const hasSubscription =
    price.product.id === currentSubscriptionItem?.price?.product.id ||
    (isUndefined(currentSubscriptionItem) && price.unitAmount === 0)

  const isCancelled = currentSubscription?.cancelAtPeriodEnd === true
  const currentPeriodEnd = currentSubscription?.currentPeriodEnd

  useEffect(() => {
    if (!loading)
      setState({
        quantity: hasSubscription
          ? currentSubscriptionItem?.quantity ?? price.minQuantity
          : price.minQuantity,
      })
  }, [currentSubscriptionItem, hasSubscription, loading, price.minQuantity])

  return (
    <Card
      className={clsx([className, classes.card])}
      variant="outlined"
      {...rest}
    >
      <div className={classes.content}>
        <Typography align="center" variant="h4">
          {price.product.name}
        </Typography>
        <Typography align="center" className={classes.description}>
          {price.product.description}
        </Typography>
        <Typography align="center" variant="h4">
          {
            new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: price.currency,
            }).format(price.unitAmount / 100) // pounds to pennies
          }
        </Typography>
        <Typography align="center">
          {[
            ...(price.hasQuantity ? ['per seat'] : []),
            `per ${price.interval}`,
          ].join(', ')}
        </Typography>
        <Divider className={classes.divider} />
        <List
          aria-label={`${price.product.name} quota`}
          className={classes.quotaList}
          subheader={
            <ListSubheader disableGutters={true}>
              {price.hasQuantity
                ? `${price.product.name} plan (${state.quantity} seats) includes:`
                : `${price.product.name} plan includes:`}
            </ListSubheader>
          }
        >
          <ListItem alignItems="flex-start" className={classes.listItem}>
            <ListItemIcon>
              <CheckIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={`${
                price.hasQuantity && !isNil(price.product.projectQuotaPerSeat)
                  ? price.product.projectQuotaBase +
                    price.product.projectQuotaPerSeat * state.quantity
                  : price.product.projectQuotaBase
              } projects`}
            />
          </ListItem>
          <ListItem alignItems="flex-start" className={classes.listItem}>
            <ListItemIcon>
              <CheckIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={`${
                price.hasQuantity && !isNil(price.product.storyQuotaPerSeat)
                  ? price.product.storyQuotaBase +
                    price.product.storyQuotaPerSeat * state.quantity
                  : price.product.storyQuotaBase
              } stories`}
            />
          </ListItem>
          <ListItem alignItems="flex-start" className={classes.listItem}>
            <ListItemIcon>
              <CheckIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={`${
                price.hasQuantity &&
                !isNil(price.product.contributorQuotaPerSeat)
                  ? price.product.contributorQuotaBase +
                    price.product.contributorQuotaPerSeat * state.quantity
                  : price.product.contributorQuotaBase
              } contributors (admins & writers)`}
            />
          </ListItem>
        </List>
        <Discounts price={price} />
        {price.hasQuantity ? (
          <TextField
            className={classes.quantity}
            FormHelperTextProps={{
              'aria-label': 'Number of seats helper text',
            }}
            fullWidth={true}
            helperText={`Minimum ${price.minQuantity}`}
            id="number-of-seats-field"
            inputProps={{
              min: price.minQuantity,
              max: price.maxQuantity,
              step: '1',
            }}
            label="Number of seats"
            onBlur={onQuantityBlur}
            onChange={onQuantityChange}
            type="number"
            value={state.quantity}
            variant="outlined"
          />
        ) : undefined}
      </div>
      {hasSubscription &&
      (isUndefined(currentSubscriptionItem?.quantity)
        ? true
        : state.quantity === currentSubscriptionItem?.quantity) ? (
        <Fragment>
          <div className={classes.textFooter}>
            <div className={classes.currentPlan}>
              <CheckIcon color="primary" className={classes.currentPlanIcon} />
              <Typography>Current plan</Typography>
            </div>
            {isCancelled ? (
              isNil(currentPeriodEnd) ? (
                <Typography color="secondary" variant="body2">
                  Cancelled. Ends on current period end
                </Typography>
              ) : (
                <Typography color="secondary" variant="body2">
                  Cancelled. Ends:{' '}
                  {format(new Date(currentPeriodEnd), 'do MMMM yyyy')}
                </Typography>
              )
            ) : undefined}
          </div>
        </Fragment>
      ) : (
        <div className={classes.footer}>
          {!isLoggedIn ? (
            <Button
              color="primary"
              fullWidth={true}
              onClick={onSignUpClick}
              variant="contained"
            >
              Sign up
            </Button>
          ) : (isNil(currentSubscriptionItem?.price?.unitAmount) &&
              gt(price.unitAmount, 0)) ||
            gt(
              price.unitAmount * state.quantity,
              (currentSubscriptionItem?.price?.unitAmount ?? 0) *
                (currentSubscriptionItem?.quantity ?? 1)
            ) ? (
            <Button
              color="primary"
              fullWidth={true}
              onClick={onUpgradeClick}
              variant="contained"
            >
              Upgrade
            </Button>
          ) : (
            <Button
              color="primary"
              fullWidth={true}
              onClick={onDowngradeClick}
              variant="contained"
            >
              Downgrade
            </Button>
          )}
        </div>
      )}
    </Card>
  )
}
