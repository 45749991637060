import hotjar from '@hotjar/browser'
import Container from '@material-ui/core/Container'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { first, isUndefined, lt } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import LinkedInTag from 'react-linkedin-insight'
import SwipeableViews from 'react-swipeable-views'
import {
  DowngradeSubscriptionDialog,
  CheckoutDialog,
} from '../../../components'
import { config } from '../../../config'
import {
  useCancelSubscriptionMutation,
  usePricesQuery,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'
import { SubscriptionCard } from './SubscriptionCard'

const cardWidth = 306

const useStyles = makeStyles((theme) =>
  createStyles({
    cards: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridColumnGap: theme.spacing(1),
    },
    swipeableViews: {},
    tabs: {
      paddingBottom: theme.spacing(2),
    },
  })
)

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SubscriptionCards: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const [state, setState] = useState<{
    checkoutOpen: boolean
    confirmDowngradeOpen: boolean
    price?: React.ComponentProps<typeof SubscriptionCard>['price']
    quantity?: number
    slideFocus: number
  }>({
    checkoutOpen: false,
    confirmDowngradeOpen: false,
    slideFocus: 0,
  })
  const { data, loading } = usePricesQuery()
  const [cancelSubscription] = useCancelSubscriptionMutation()

  const onUpgrade: React.ComponentProps<
    typeof SubscriptionCard
  >['onUpgrade'] = useCallback(
    (price, quantity) => {
      const eventParams = {
        currency: price.currency,
        value: (price.unitAmount / 100) * quantity, // pounds to pennies
        items: [
          {
            id: price.id,
            name: price.product.name,
            price: price.unitAmount / 100, // pounds to pennies
            quantity,
          },
        ],
      }

      gtag('event', 'add_to_cart', eventParams)

      gtag('event', 'begin_checkout', eventParams)

      fbq('track', 'AddToCart', {
        content_category: 'Subscription',
        content_ids: [price.id],
        content_name: price.product.name,
        content_type: 'product',
        currency: price.currency,
        value: price.unitAmount / 100, // pounds to pennies
      })

      fbq('track', 'InitiateCheckout', {
        content_category: 'Subscription',
        content_ids: [price.id],
        contents: [
          {
            id: price.id,
            quantity,
          },
        ],
        currency: price.currency,
        num_items: 1,
        value: (price.unitAmount / 100) * quantity, // pounds to pennies
      })

      if (LinkedInTag.verifyInit()) {
        LinkedInTag.track(config.linkedinEvents.addToCart)
        LinkedInTag.track(config.linkedinEvents.startCheckout)
      }

      if (hotjar.isReady()) hotjar.event('addedToCart')

      setState({
        ...state,
        checkoutOpen: true,
        confirmDowngradeOpen: false,
        price,
        quantity,
      })
    },
    [state]
  )

  const onDowngrade: React.ComponentProps<
    typeof SubscriptionCard
  >['onDowngrade'] = useCallback(
    (price, quantity) => {
      setState({
        ...state,
        checkoutOpen: false,
        confirmDowngradeOpen: true,
        price,
        quantity,
      })
    },
    [state]
  )

  const onCreateSubscriptionClose = useCallback(() => {
    const price = state.price
    const quantity = state.quantity

    if (!isUndefined(price))
      gtag('event', 'remove_from_cart', {
        currency: price.currency,
        value: (price.unitAmount / 100) * (quantity ?? 1), // pounds to pennies
        items: [
          {
            id: price.id,
            name: price.product.name,
            value: price.unitAmount / 100, // pounds to pennies
            quantity,
          },
        ],
      })

    setState({ ...state, checkoutOpen: false })
  }, [state])

  const onCancelSubscriptionClose = useCallback(() => {
    setState({ ...state, confirmDowngradeOpen: false })
  }, [state])

  const subscription = first(data?.currentUser?.subscriptions)

  const onDowngradeConfirm = useCallback(() => {
    if (isUndefined(state.price) || state.price.unitAmount === 0) {
      if (isUndefined(subscription)) return

      cancelSubscription({
        variables: {
          subscriptionId: subscription.id,
        },
      })
        .then(() => {
          enqueueSnackbar(`Successfully downgraded subscription.`, {
            variant: 'success',
          })
        })
        .catch((e) => {
          const error = first(getGraphQLError(e as ApiErrors))
          const errorText = `Failed to downgrade subscription.${
            isUndefined(error) ? '' : ` ${error}`
          }`

          gtag('event', 'exception', {
            description: errorText,
            fatal: true,
          })
          enqueueSnackbar(errorText, {
            variant: 'error',
          })
        })

      setState({ ...state, confirmDowngradeOpen: false })
    } else
      setState({
        ...state,
        checkoutOpen: true,
        confirmDowngradeOpen: false,
      })
  }, [cancelSubscription, enqueueSnackbar, state, subscription])

  const onTabClick = useCallback(
    (_: React.ChangeEvent<{}>, slideFocus: number) => {
      setState({ ...state, slideFocus })
    },
    [state]
  )

  const onSlide = useCallback(
    (slideFocus: number) => {
      setState({ ...state, slideFocus })
    },
    [state]
  )

  useEffect(() => {
    const slideFocus =
      data?.prices.edges.findIndex((n) => n.node.product.hasSubscription) ?? 0

    setState((s) => ({ ...s, slideFocus: lt(slideFocus, 0) ? 0 : slideFocus }))
  }, [data, subscription])

  const cards = data?.prices.edges.map((n) => (
    <SubscriptionCard
      currentUser={data?.currentUser}
      key={n.node.id}
      loading={loading}
      onUpgrade={onUpgrade}
      onDowngrade={onDowngrade}
      price={n.node}
    />
  ))

  return (
    <Fragment>
      {mdUp ? (
        <Container maxWidth="md">
          <div className={classes.cards}>{cards}</div>
        </Container>
      ) : (
        // div needed. SwipeableViews collapses on Safari if parent is fragment
        <div>
          <Container maxWidth="md">
            <Tabs
              aria-label="Subscription tabs"
              className={classes.tabs}
              centered={true}
              indicatorColor="primary"
              onChange={onTabClick}
              value={state.slideFocus}
              variant={smUp ? 'standard' : 'fullWidth'}
            >
              {data?.prices.edges.map((n) => (
                <Tab key={n.node.product.id} label={n.node.product.name} />
              ))}
            </Tabs>
          </Container>
          <SwipeableViews
            animateHeight={true}
            enableMouseEvents={true}
            index={state.slideFocus}
            onChangeIndex={onSlide}
            resistance={true}
            style={{
              // Used to control slide size.
              // See: https://react-swipeable-views.com/demos/demos/#custom-width
              padding: `0 calc((100% - ${cardWidth}px) / 2)`,
            }}
            slideStyle={{
              width: cardWidth,
              paddingLeft: theme.spacing(0.5),
              paddingRight: theme.spacing(0.5),
            }}
          >
            {cards}
          </SwipeableViews>
        </div>
      )}
      {!isUndefined(state.price) && !isUndefined(state.quantity) ? (
        <CheckoutDialog
          currentSubscription={subscription}
          defaultPaymentMethod={data?.currentUser?.defaultPaymentMethod}
          open={state.checkoutOpen}
          onClose={onCreateSubscriptionClose}
          price={state.price}
          quantity={state.quantity}
        />
      ) : undefined}
      {!isUndefined(subscription) ? (
        <DowngradeSubscriptionDialog
          open={state.confirmDowngradeOpen}
          onDowngradeConfirm={onDowngradeConfirm}
          onClose={onCancelSubscriptionClose}
        />
      ) : undefined}
    </Fragment>
  )
}
