import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserAvatar, useStorageContext } from '../..'
import { goToAccount, goToEmailSupport, User } from '../../../middleware'

export interface UserAccountMenuProps {
  loading: boolean
  user?: Pick<User, 'id' | 'firstName' | 'lastName'> | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarWrapper: {
      backgroundColor: theme.palette.common.white,
      padding: 3,
      borderRadius: '50%',
      boxShadow: theme.shadows[1],
    },
  })
)

export const UserAccountMenu: React.FC<UserAccountMenuProps> = ({
  loading,
  user,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { resetStorage } = useStorageContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const onMyAccountClick = () => {
    goToAccount(history)
    onClose()
  }

  const onContactSupportClick = () => {
    goToEmailSupport()
    onClose()
  }

  const onLogoutClick = () => {
    gtag('event', 'logout')
    resetStorage()
    onClose()
  }

  return (
    <div>
      <IconButton
        aria-label="Account menu"
        aria-controls="account-menu"
        aria-haspopup="true"
        className={classes.avatarWrapper}
        onClick={onOpen}
        size="small"
      >
        <UserAvatar loading={loading} user={user} />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <MenuItem onClick={onMyAccountClick}>My Account</MenuItem>
        <MenuItem onClick={onContactSupportClick}>Contact Support</MenuItem>
        <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
      </Menu>
    </div>
  )
}
