import hotjar from '@hotjar/browser'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { first, isNil, isUndefined, mapValues } from 'lodash'
import React, { Fragment, useCallback, useState } from 'react'
import LinkedInTag from 'react-linkedin-insight'
import { useHistory } from 'react-router-dom'
import { PasswordFormField, SubmitButton } from '../../../components'
import { config } from '../../../config'
import {
  goToForgotPassword,
  goToSignUp,
  loginValidate,
  TokenQuery,
  TokenQueryVariables,
  TokenDocument,
  useLoginMutation,
} from '../../../middleware'
import { getGraphQLError } from '../../../util'

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      marginBottom: theme.spacing(2),
    },
    form: {
      width: '100%',
    },
    fields: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      marginBottom: theme.spacing(3),
    },
    link: {
      justifySelf: 'center',
    },
    actions: {
      display: 'flex',
      '& >*': {
        flex: 1,
      },
      '& >:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  })
)

export const LoginForm: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const [state, setState] = useState({
    email: '',
  })

  const [login, { error }] = useLoginMutation({
    onCompleted: () => {
      gtag('event', 'login')

      if (LinkedInTag.verifyInit())
        LinkedInTag.track(config.linkedinEvents.login)

      if (hotjar.isReady()) hotjar.event('loggedIn')
    },
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to login.${errorText}`,
        fatal: false,
      })
    },
    refetchQueries: ['currentUser'], // invalidate current user data
    update: (cache, { data }) => {
      if (isNil(data)) return

      const token = data.login

      cache.writeQuery<TokenQuery, TokenQueryVariables>({
        query: TokenDocument,
        data: {
          token,
        },
      })
    },
  })

  const onForgotPassword = useCallback(() => {
    goToForgotPassword(history)
  }, [history])

  const onSignUp = useCallback(() => {
    goToSignUp(history)
  }, [history])

  return (
    <Fragment>
      {isUndefined(error) ? undefined : (
        <Alert className={classes.error} severity="error">
          <AlertTitle>Sign up failed</AlertTitle>
          {error.message}
        </Alert>
      )}
      <Formik
        initialValues={{ ...state, password: '' }}
        validate={(values) => mapValues(loginValidate(values), (n) => first(n))}
        onSubmit={async (values, { resetForm }) => {
          setState(values)

          await login({
            variables: values,
          }).then(() => {
            resetForm()
          })
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className={classes.form}>
            <div className={classes.fields}>
              <Field
                component={TextField}
                FormHelperTextProps={{
                  'aria-label': 'Email helper text',
                }}
                id="email-field"
                label="Email"
                margin="normal"
                name="email"
                variant="outlined"
              />
              <Field
                component={PasswordFormField}
                FormHelperTextProps={{
                  'aria-label': 'Password helper text',
                }}
                id="password-field"
                label="Password"
                margin="normal"
                name="password"
                variant="outlined"
              />
              <Link
                className={classes.link}
                component="button"
                onClick={onForgotPassword}
                type="button"
                variant="body1"
              >
                Forgot password?
              </Link>
            </div>
            <div className={classes.actions}>
              <Button onClick={onSignUp} variant="outlined">
                Sign up
              </Button>
              <SubmitButton
                color="primary"
                loading={isSubmitting}
                onClick={() => {
                  submitForm().catch(() => {
                    // handled above
                  })
                }}
                type="submit"
                variant="contained"
              >
                Login
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}
