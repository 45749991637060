import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { first, isNil, isUndefined } from 'lodash'
import React from 'react'
import { Route, RouteChildrenProps, RouteProps } from 'react-router-dom'
import { useCurrentUser } from '../../../middleware'
import { getGraphQLError } from '../../../util'

export interface HasEmailVerifiedRouteProps extends RouteProps {
  allowHasEmailVerified?: boolean
  children?: ((props: RouteChildrenProps) => React.ReactNode) | React.ReactNode
  redirectComponent: React.ReactNode
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export const HasEmailVerifiedRoute: React.FC<HasEmailVerifiedRouteProps> = ({
  allowHasEmailVerified = true,
  children,
  redirectComponent,
  ...rest
}) => {
  const classes = useStyles()
  const { data, loading, error } = useCurrentUser()
  const emailVerified = data?.currentUser?.emailVerified
  const hasEmailVerified = isNil(emailVerified) ? false : emailVerified
  const canAccess = hasEmailVerified === allowHasEmailVerified

  return (
    <Route {...rest}>
      {loading ? (
        <div className={classes.container}>
          <CircularProgress aria-label="Loading" />
        </div>
      ) : !isUndefined(error) ? (
        <Container className={classes.container} maxWidth="sm">
          <Alert severity="error">
            <AlertTitle>User data failed to load</AlertTitle>
            {first(getGraphQLError(error)) ??
              'Please try a refresh. If this persists, please try closing all tabs with the site open and then returning in a new tab.'}
          </Alert>
        </Container>
      ) : canAccess ? (
        children
      ) : (
        redirectComponent
      )}
    </Route>
  )
}
