import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { goToPrivacyPolicy, goToTermsOfUse } from '../../../middleware'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: '100%',
    },
    divider: {
      margin: theme.spacing(0, 2),
    },
  })
)

export const Footer: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const onPrivacyPolicyClick = useCallback(() => {
    goToPrivacyPolicy(history)
  }, [history])

  const onTermsOfUseClick = useCallback(() => {
    goToTermsOfUse(history)
  }, [history])

  return (
    <Container maxWidth="sm">
      <div className={classes.container}>
        <Typography align="center" display="inline">
          Copyright © loveyourproject.com 2020
        </Typography>
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem={true}
        />
        <Link component="button" onClick={onPrivacyPolicyClick} variant="body1">
          Privacy policy
        </Link>
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem={true}
        />
        <Link component="button" onClick={onTermsOfUseClick} variant="body1">
          Terms of use
        </Link>
      </div>
    </Container>
  )
}
