import { createBrowserHistory } from 'history'
import React from 'react'
import { Router as ReactRouter } from 'react-router-dom'

export const history = createBrowserHistory()

export interface RouterProps {
  children?: React.ReactNode
}

export const Router: React.FC<RouterProps> = ({ children }) => (
  <ReactRouter history={history}>{children}</ReactRouter>
)
