import {
  ApolloClient,
  ApolloCache,
  gql,
  HttpLink,
  from,
  InMemoryCache,
  InMemoryCacheConfig,
} from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { isUndefined } from 'lodash'
import { config } from '../../config'
import { goToLogin, history, loginUri } from '..'
import { defaultLocalState, resolvers } from './localState'
import { StoryLove, TokenQuery, TokenQueryVariables, TokenDocument } from '.'

export const initCache = <T extends {}>(cache: ApolloCache<T>) => {
  cache.writeQuery({
    query: gql`
      query token {
        token {
          accessToken
        }
      }
    `,
    ...defaultLocalState,
  })

  return cache
}

export const createCache = (config?: InMemoryCacheConfig) =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          prices: relayStylePagination(),
          projects: relayStylePagination(['sort']),
          stories: relayStylePagination([
            'archived',
            'createdBy',
            'lovedBy',
            'projectId',
            'roles',
            'search',
            'sort',
          ]),
        },
      },
      Project: {
        fields: {
          canAddUserRoles: {
            merge(_: string[], incoming: string[]) {
              return incoming
            },
          },
          storyRoles: {
            merge(_: string[], incoming: string[]) {
              return incoming
            },
          },
        },
      },
      Story: {
        fields: {
          storyLove: {
            merge(_: StoryLove[], incoming: StoryLove[]) {
              return incoming
            },
          },
        },
      },
    },
    ...config,
  })

const cache = createCache()
initCache(cache)

export const getToken = () =>
  cache.readQuery<TokenQuery, TokenQueryVariables>({
    query: TokenDocument,
  })?.token

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
})

export const createClient = (reload: () => void) => {
  const httpLink = new HttpLink({
    uri: config.graphql.uri,
    credentials: 'same-origin',
  })

  const authLink = setContext((_, { headers }) => {
    const token = getToken()?.accessToken

    return {
      headers: {
        ...(headers as Record<string, string>),
        authorization: isUndefined(token) ? '' : `Bearer ${token}`,
      },
    }
  })

  const logout = async () => {
    // clear storage if not already cleared
    // note: without this check it get stuck in a loop
    if ((await persistor.getSize()) !== 0) {
      persistor.pause()
      await persistor.purge()
      await client.clearStore()
      persistor.resume()

      if (history.location.pathname !== loginUri()) goToLogin(history)

      // manually trigger reload as history can't be passed by hook.
      // (history via useHistory causes every url reload to re-initialise apollo and show spinner)
      reload()
    }
  }

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { response } = operation.getContext()

    // logout if http code or graphql returns unauthorised.
    // nest returns 200 for http, but "Unauthorized" in graphql.
    if (
      (response as { status?: number }).status === 401 ||
      graphQLErrors?.some((n) => n.message === 'Unauthorized') === true
    ) {
      // change this for promise once `onError` accepts `async`
      logout().catch((e) => {
        gtag('event', 'exception', {
          description: 'Error clearing storage on unauthorised error',
          fatal: true,
        })

        console.log('Error clearing storage on unauthorised error:', e)
      })
    }

    if (!isUndefined(graphQLErrors))
      graphQLErrors.forEach((graphQLError) => {
        // tslint:disable-next-line:no-console
        console.log('[GraphQL error]: ', graphQLError)
      })

    if (!isUndefined(networkError)) {
      gtag('event', 'exception', {
        description: `[Network error]: ${
          networkError?.name ?? 'Unknown error name'
        }: ${networkError?.message ?? 'Unknown error message'}`,
        fatal: false,
      })

      // tslint:disable-next-line:no-console
      console.log('[Network error]: ', networkError)
    }
  })

  const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache,
    defaultOptions: {
      watchQuery: {
        // fetch on load, then use cache to prevent story positions changing on mutation (i.e. on love)
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
    resolvers,
  })

  client.onClearStore(async () => await Promise.resolve(initCache(cache)))
  client.onResetStore(async () => await Promise.resolve(initCache(cache)))

  return client
}
