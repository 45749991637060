import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { first, isEmpty, isUndefined, mapValues } from 'lodash'
import { useSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import { projectPermissions } from '../../../../config'
import {
  addProjectUserValidate,
  ProjectPermission,
  useProjectQuery,
  useAddProjectUser,
} from '../../../../middleware'
import {
  ApiErrors,
  getGraphQLError,
  getGraphQLValidationErrors,
} from '../../../../util'
import { ProjectInviteForm, ProjectInviteFormProps } from '../ProjectInviteForm'

export interface UserProjectInviteFormProps
  extends Omit<ProjectInviteFormProps, 'loading'> {
  projectId: string
}

interface Fields {
  email: string
  permission: ProjectPermission
}

const initialState = {
  email: '',
  permission: ProjectPermission.Read,
}

export const UserProjectInviteForm: React.FC<UserProjectInviteFormProps> = ({
  projectId,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState<Fields>(initialState)
  const { data: projectData, loading: projectLoading } = useProjectQuery({
    variables: {
      id: projectId,
    },
  })
  const [
    projectInvite,
    { data, error: projectInviteError },
  ] = useAddProjectUser()

  useEffect(() => {
    if (!isUndefined(data)) setState(initialState)
  }, [data])

  const localValidationErrors = useMemo(() => {
    const errors = addProjectUserValidate(state)

    return mapValues(errors, (n) => first(n))
  }, [state])

  const serverValidationErrors = useMemo(() => {
    if (isUndefined(projectInviteError)) return {}

    const errors = first(getGraphQLValidationErrors(projectInviteError))

    return mapValues(errors, (n) => first(n))
  }, [projectInviteError])

  const onEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState({ ...state, email: event.currentTarget.value })
    },
    [state]
  )

  const onPermissionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState({
        ...state,
        permission: event.target.value as ProjectPermission,
      })
    },
    [state]
  )

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (!isEmpty(localValidationErrors)) return
      const id = uuid()

      setState(initialState)
      projectInvite({
        variables: {
          id,
          projectId,
          ...state,
        },
      })
        .then(() => {
          enqueueSnackbar(`Successfully invited "${state.email}".`, {
            variant: 'success',
          })
        })
        .catch((e) => {
          const error = first(getGraphQLError(e as ApiErrors))
          const errorText = isUndefined(error) ? '' : ` ${error}`

          gtag('event', 'exception', {
            description: `Failed to invite ${id}.${errorText}`,
            fatal: false,
          })
          enqueueSnackbar(`Failed to invite "${state.email}".${errorText}`, {
            variant: 'error',
          })
        })
    },
    [localValidationErrors, projectInvite, projectId, state, enqueueSnackbar]
  )

  const disabled = isUndefined(projectData)
    ? true
    : !projectData.project.canAddUser

  const disabledMessage =
    !isUndefined(projectData) && !projectData.project.canAddUser
      ? "You don't have permission to do this."
      : undefined

  const options = projectPermissions.map((n) => {
    const included = projectData?.project.canAddUserRoles.includes(
      n.value as ProjectPermission
    )

    return { ...n, disabled: isUndefined(included) ? included : !included }
  })

  return (
    <ProjectInviteForm
      emailField={{
        disabled,
        disabledMessage,
        error:
          (Boolean(state.email) && Boolean(localValidationErrors.email)) ||
          Boolean(serverValidationErrors.email),
        helperText:
          Boolean(state.email) && !isUndefined(localValidationErrors.email)
            ? localValidationErrors.email
            : serverValidationErrors.email,
        value: state.email,
        onChange: onEmailChange,
      }}
      permissionField={{
        disabled,
        disabledMessage,
        error:
          (Boolean(state.permission) &&
            Boolean(localValidationErrors.permission)) ||
          Boolean(serverValidationErrors.permission),
        helperText:
          Boolean(state.permission) &&
          !isUndefined(localValidationErrors.permission)
            ? localValidationErrors.permission
            : serverValidationErrors.permission,
        value: state.permission,
        onChange: onPermissionChange,
        options,
      }}
      submitButton={{
        disabled,
        disabledMessage,
      }}
      loading={isUndefined(projectData) && projectLoading}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}
