import ApolloReactHooks from '@apollo/client'
import { isNil, isNull, isUndefined } from 'lodash'
import { v4 as uuid } from 'uuid'
import {
  CurrentUserDocument,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  LoveStoryMutation,
  LoveStoryMutationVariables,
  StoryLoveFragment,
  StoryLoveFragmentDoc,
  useLoveStoryMutation,
} from '../../graphql'

export const useLoveStory = (
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoveStoryMutation,
    LoveStoryMutationVariables
  >
) =>
  useLoveStoryMutation({
    optimisticResponse: ({ amount, id }) => ({
      __typename: 'Mutation',
      loveStory: {
        __typename: 'StoryLove',
        id: uuid(), // temp id
        love: amount,
        story: { id },
      },
    }),
    update: (cache, { data }) => {
      if (isNil(data)) return

      const node = data?.loveStory

      if (isNil(node) || isNil(node.story)) return

      const story = cache.readFragment<StoryLoveFragment>({
        id: `Story:${node.story.id}`,
        fragment: StoryLoveFragmentDoc,
      })

      const currentUser = cache.readQuery<
        CurrentUserQuery,
        CurrentUserQueryVariables
      >({
        query: CurrentUserDocument,
      })?.currentUser

      if (isUndefined(currentUser) || isNull(story)) return

      const storyLove = [
        ...story.storyLove.filter((n) => n.user?.id !== currentUser?.id),
        { ...node, user: currentUser },
      ]

      cache.writeFragment({
        id: `Story:${node.story.id}`,
        fragment: StoryLoveFragmentDoc,
        data: {
          storyLove,
        },
      })
    },
    ...baseOptions,
  })
