import React from 'react'
import { UserAccountMenu, UserAccountMenuProps } from '../UserAccountMenu'
import { useCurrentUser } from '../../../../middleware'

export type CurrentUserAccountMenuProps = Omit<
  UserAccountMenuProps,
  'loading' | 'user'
>

export const CurrentUserAccountMenu: React.FC<CurrentUserAccountMenuProps> = () => {
  const { data, loading } = useCurrentUser()
  const user = data?.currentUser

  return <UserAccountMenu loading={loading} user={user} />
}
