import { plainToClass } from 'class-transformer'
import {
  IsEmail,
  IsString,
  MaxLength,
  MinLength,
  validateSync,
} from 'class-validator'
import { ResetPasswordMutationVariables } from '../../graphql'
import { getValidationErrors } from '../../../../util'

export class ResetPasswordDto {
  @IsEmail(undefined, {
    message:
      'Please enter email in email address format. E.g. johnsmith@example.com.',
  })
  @MinLength(3, {
    message: 'Email should be at least $constraint1 characters long.',
  })
  @MaxLength(255, {
    message: 'Email should be at most $constraint1 characters long.',
  })
  email!: string

  @IsString()
  @MinLength(12, {
    message: 'Password should be at least $constraint1 characters long.',
  })
  @MaxLength(64, {
    message: 'Password should be at most $constraint1 characters long.',
  })
  password!: string
}

export const resetPasswordValidate = (
  vars: Omit<ResetPasswordMutationVariables, 'verificationCode'>
) => getValidationErrors(validateSync(plainToClass(ResetPasswordDto, vars)))
