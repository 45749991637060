import React from 'react'
import { BrochureLayout, CenterLayout, UserForgotPasswordForm } from '../..'

export const ForgotPasswordPage: React.FC = () => (
  <BrochureLayout>
    <CenterLayout>
      <UserForgotPasswordForm />
    </CenterLayout>
  </BrochureLayout>
)
