import * as ApolloReactHooks from '@apollo/client'
import { isNil, isNull } from 'lodash'
import { v4 as uuid } from 'uuid'
import {
  AddProjectUserMutation,
  AddProjectUserMutationVariables,
  ProjectUsersQuery,
  ProjectUsersQueryVariables,
  ProjectUsersDocument,
  useAddProjectUserMutation,
} from '../../graphql'

export const useAddProjectUser = (
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddProjectUserMutation,
    AddProjectUserMutationVariables
  >
) =>
  useAddProjectUserMutation({
    optimisticResponse: ({ id, email, permission, projectId }) => ({
      __typename: 'Mutation',
      addProjectUser: {
        __typename: 'ProjectUser',
        id,
        acceptedAt: null,
        canRemove: true,
        canUpdate: true,
        permission,
        project: {
          __typename: 'Project',
          id: projectId,
        },
        user: {
          __typename: 'User',
          id: uuid(),
          displayName: email,
          firstName: null,
          lastName: null,
        },
      },
    }),
    update: (cache, { data }) => {
      if (isNil(data)) return

      const node = data?.addProjectUser

      if (isNil(node?.project)) return

      // Workaround for errors thown on uncached queries.
      // To be updated in future versions of apollo.
      // See: https://github.com/apollographql/apollo-feature-requests/issues/1
      let cacheData: ProjectUsersQuery | null = null
      try {
        cacheData = cache.readQuery<
          ProjectUsersQuery,
          ProjectUsersQueryVariables
        >({
          query: ProjectUsersDocument,
          variables: {
            id: node.project.id,
          },
        })
      } catch {}

      if (isNull(cacheData)) return

      cache.writeQuery<ProjectUsersQuery, ProjectUsersQueryVariables>({
        query: ProjectUsersDocument,
        data: {
          ...cacheData,
          project: {
            ...cacheData.project,
            projectUsers: [...cacheData.project.projectUsers, node],
          },
        },
      })
    },
    ...baseOptions,
  })
