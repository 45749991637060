import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import React, { Fragment, ReactNode, useCallback } from 'react'
import { BooleanParam, useQueryParam } from 'use-query-params'
import { config } from '../../../config'

export interface ListLayoutProps {
  children?: React.ReactNode
  filters?: ReactNode
  filterActions?: ReactNode
  headerLeft?: ReactNode
  headerCenter?: ReactNode
  headerRight?: ReactNode
  footer?: ReactNode
}

const drawerWidth = 370

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerContent: {
      padding: theme.spacing(0, 2),
    },
    drawerPaper: {
      top: 'initial',
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 1, 3, 2),
      justifyContent: 'space-between',
    },
    left: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        flex: 'initial',
      },
      [theme.breakpoints.up('lg')]: {
        flex: 1,
        justifyContent: 'flex-start',
        paddingRight: theme.spacing(1),
      },
    },
    right: {
      position: 'fixed',
      right: theme.spacing(1),
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      paddingLeft: theme.spacing(0.5),
      [theme.breakpoints.up('lg')]: {
        position: 'initial',
        paddingLeft: theme.spacing(1),
      },
    },
    center: {
      display: 'flex',
      '& >:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
        maxWidth: 780,
      },
    },
    container: {
      display: 'flex',
      flex: 1,
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
      },
    },
    contentShift: {
      [theme.breakpoints.up('md')]: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    },
    floating: {
      display: 'flex',
      padding: theme.spacing(1),
    },
    toolbar: {
      backgroundColor: config.colours.midGrey,
      borderTop: `1px solid ${config.colours.darkBorder}`,
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderTop: 'none',
        borderBottom: `1px solid ${config.colours.darkBorder}`,
      },
    },
    footerMenu: {
      '& >:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  })
)

export const ListLayout: React.FC<ListLayoutProps> = ({
  children,
  filters,
  filterActions,
  headerLeft,
  headerCenter,
  headerRight,
  footer,
}) => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const [showFilters, setShowFilters] = useQueryParam(
    'showFilters',
    BooleanParam
  )

  const onDrawerClose = useCallback(() => {
    setShowFilters(undefined, 'replaceIn')
  }, [setShowFilters])

  return (
    <Fragment>
      {smUp ? (
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>{headerLeft}</div>
          <div className={classes.center}>{headerCenter}</div>
          <div className={classes.right}>{headerRight}</div>
        </Toolbar>
      ) : (
        <div className={classes.floating}>
          <div className={classes.left}>{headerLeft}</div>
          <div className={classes.right}>{headerRight}</div>
        </div>
      )}
      <div className={classes.container}>
        {mdUp ? (
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={showFilters ?? false}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <Typography component="h2" variant="h5" noWrap={true}>
                Filters
              </Typography>
              <IconButton onClick={onDrawerClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.drawerContent}>
              {filters}
              {filterActions}
            </div>
          </Drawer>
        ) : (
          <Dialog
            aria-labelledby="filters-dialog"
            fullWidth={true}
            maxWidth="xs"
            onClose={onDrawerClose}
            open={showFilters ?? false}
          >
            <DialogTitle id="filters-dialog">Filters</DialogTitle>
            <DialogContent>{filters}</DialogContent>
            <DialogActions>{filterActions}</DialogActions>
          </Dialog>
        )}
        <div
          className={clsx(classes.content, {
            [classes.contentShift]: showFilters,
          })}
        >
          {children}
        </div>
      </div>
      {smUp ? undefined : (
        <Toolbar className={clsx([classes.toolbar, classes.footerMenu])}>
          {footer}
        </Toolbar>
      )}
    </Fragment>
  )
}
