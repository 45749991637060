import LinearProgress from '@material-ui/core/LinearProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { gte } from 'lodash'
import React from 'react'

interface UsageProgressProps extends React.HTMLAttributes<HTMLDivElement> {
  value: number
  limit: number
  limitWarning: number
  title: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    title: {
      flex: 1,
      paddingRight: theme.spacing(2),
    },
    hitLimitBar: {
      backgroundColor: theme.palette.error.main,
    },
    hitLimitWarningBar: {
      backgroundColor: theme.palette.warning.main,
    },
    hitLimitText: {
      marginTop: theme.spacing(1),
    },
    hitLimitWarningText: {
      color: theme.palette.warning.main,
      marginTop: theme.spacing(1),
    },
  })
)

export const UsageProgress: React.FC<UsageProgressProps> = ({
  value,
  limit,
  limitWarning,
  title,
  ...rest
}) => {
  const classes = useStyles()
  const hasHitLimit = gte(value, limit)
  const hasHitLimitWarning = gte(value, limitWarning)
  const progress = (value * 100) / limit

  return (
    <div {...rest}>
      <div className={classes.text}>
        <Typography className={classes.title} variant={'h6'}>
          {title}
        </Typography>
        <Typography>
          <span>{value}</span>
          <span>/</span>
          <span>{limit}</span>
        </Typography>
      </div>
      <LinearProgress
        classes={{
          ...(hasHitLimitWarning
            ? { barColorPrimary: classes.hitLimitWarningBar }
            : undefined),
          ...(hasHitLimit
            ? { barColorPrimary: classes.hitLimitBar }
            : undefined),
        }}
        variant="determinate"
        value={progress}
      />
      {hasHitLimit ? (
        <Typography
          className={classes.hitLimitText}
          color="error"
          variant="body2"
        >
          Limit reached
        </Typography>
      ) : hasHitLimitWarning ? (
        <Typography className={classes.hitLimitWarningText} variant="body2">
          {limit - value} remaining
        </Typography>
      ) : undefined}
    </div>
  )
}
