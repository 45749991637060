import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { isNull, isUndefined } from 'lodash'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { VirtuosoMethods } from 'react-virtuoso'
import { updateInLocation } from 'use-query-params'
import {
  ClearFiltersButton,
  CreatedBySelect,
  CreateStoryFab,
  CurrentUserAccountMenu,
  FilterButton,
  ImportButton,
  ImportStoriesDialog,
  ListLayout,
  LovedBySelect,
  ProjectSelect,
  ProjectUpdateDialog,
  ProjectUsersDialog,
  ProjectUsersAvatarGroup,
  StoryRolesSelect,
  SearchForm,
  ShowArchivedSwitch,
  SortForm,
  StoriesList,
  StoryCreateDialog,
  StoryUpdateDialog,
} from '../../../components'
import {
  goToProject,
  goToProjectInvite,
  SortStories,
} from '../../../middleware'

interface StoryPageProps {
  projectId: string
}

const addFabWidth = 48
const scrollToTopFabWidth = 36
const mobileNavHeight = 71

const useStyles = makeStyles((theme) =>
  createStyles({
    search: {
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.spacing(28),
        marginLeft: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.spacing(30),
      },
    },
    projectUsers: {
      borderRadius: theme.spacing(3),
      position: 'absolute',
      bottom: mobileNavHeight + theme.spacing(20),
      right: theme.spacing(2),
      minWidth: 0,
      padding: theme.spacing(0.5),
      [theme.breakpoints.up('sm')]: {
        bottom: theme.spacing(20),
      },
    },
    fab: {
      position: 'absolute',
      bottom: mobileNavHeight + theme.spacing(2),
      right: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        bottom: theme.spacing(2),
      },
    },
    filtersContainer: {
      '& >:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
    scrollToTop: {
      position: 'absolute',
      bottom: mobileNavHeight + theme.spacing(9),
      right: theme.spacing(2) + (addFabWidth - scrollToTopFabWidth) / 2,
      [theme.breakpoints.up('sm')]: {
        bottom: theme.spacing(9),
      },
    },
    showArchivedContainer: {
      justifyContent: 'flex-end',
    },
    clearFiltersBtn: {
      marginLeft: 'auto',
    },
  })
)

export const StoryPage: React.FC<StoryPageProps> = ({ projectId }) => {
  const history = useHistory()
  const classes = useStyles()
  const projectUpdate = useRouteMatch<{ projectId: string }>(
    '/project/:projectId/update'
  )
  const storyUpdate = useRouteMatch<{ projectId: string; storyId: string }>(
    '/project/:projectId/update-story/:storyId'
  )
  const projectUsers = useRouteMatch<{ projectId: string; storyId: string }>(
    '/project/:projectId/users'
  )
  const virtuoso = useRef<VirtuosoMethods>(null)
  const [state, setState] = useState<{
    storyCreateOpen: boolean
    storyId?: string
  }>({
    storyCreateOpen: false,
    storyId: undefined,
  })
  const storyId = storyUpdate?.params.storyId

  useEffect(() => {
    if (!isNull(storyId)) setState((state) => ({ ...state, storyId }))
  }, [storyId])

  const onProjectUsersClick = useCallback(() => {
    goToProjectInvite(history, projectId, {
      search: history.location.search, // keep current query
    })
  }, [history, projectId])

  const onAddStoryClick = useCallback(() => {
    setState({ ...state, storyCreateOpen: true })
  }, [state])

  const onAddStoryCloseClick = useCallback(() => {
    setState({ ...state, storyCreateOpen: false })
  }, [state])

  const onAddStorySubmitClick = useCallback(() => {
    const { search } = updateInLocation(
      { sort: SortStories.Recent },
      // warning - works, but `history` doesn't have all required props
      (history.location as unknown) as Location
    )
    goToProject(history, projectId, {
      search, // keep current query
    })
    setState({ ...state, storyCreateOpen: false })
    virtuoso.current?.scrollToIndex({
      index: 0,
      align: 'start',
    })
  }, [history, projectId, state])

  const onUpdateStoryCloseClick = useCallback(() => {
    goToProject(history, projectId, {
      search: history.location.search, // keep current query
    })
  }, [history, projectId])

  const onProjectUpdateCloseClick = onUpdateStoryCloseClick
  const onProjectUsersCloseClick = onUpdateStoryCloseClick

  const onScrollToTopClick = useCallback(() => {
    virtuoso.current?.scrollToIndex({
      index: 0,
      align: 'start',
      behavior: 'smooth',
    })
  }, [])

  return (
    <ListLayout
      filters={
        <div className={classes.filtersContainer}>
          <StoryRolesSelect projectId={projectId} />
          <CreatedBySelect projectId={projectId} />
          <LovedBySelect projectId={projectId} />
          <FormGroup className={classes.showArchivedContainer} row={true}>
            <FormControlLabel
              control={<ShowArchivedSwitch color="primary" />}
              label="Show archived"
              labelPlacement="start"
            />
          </FormGroup>
        </div>
      }
      filterActions={<ClearFiltersButton className={classes.clearFiltersBtn} />}
      headerLeft={<ProjectSelect projectId={projectId} />}
      headerCenter={
        <Fragment>
          <SortForm />
          <FilterButton />
          <ImportButton projectId={projectId} />
          <SearchForm className={classes.search} />
        </Fragment>
      }
      headerRight={<CurrentUserAccountMenu />}
      footer={
        <Fragment>
          <SearchForm className={classes.search} />
          <ImportButton projectId={projectId} />
          <SortForm />
          <FilterButton />
        </Fragment>
      }
    >
      <StoriesList projectId={projectId} ref={virtuoso} />
      <Button
        className={classes.projectUsers}
        onClick={onProjectUsersClick}
        size="small"
      >
        <ProjectUsersAvatarGroup projectId={projectId} />
      </Button>
      {/* Warning - can't zoom this in/out
      See: https://github.com/petyosi/react-virtuoso/issues/181 */}
      <Fab
        aria-label="Scroll to top"
        className={classes.scrollToTop}
        color="secondary"
        onClick={onScrollToTopClick}
        size="small"
      >
        <ArrowUpwardIcon />
      </Fab>
      <CreateStoryFab
        className={classes.fab}
        onClick={onAddStoryClick}
        projectId={projectId}
      >
        <AddIcon />
      </CreateStoryFab>
      <ProjectUpdateDialog
        onClose={onProjectUpdateCloseClick}
        open={!isNull(projectUpdate)}
        projectId={projectId}
      />
      <ProjectUsersDialog
        onClose={onProjectUsersCloseClick}
        open={!isNull(projectUsers)}
        projectId={projectId}
      />
      <ImportStoriesDialog projectId={projectId} />
      <StoryCreateDialog
        open={state.storyCreateOpen}
        onClose={onAddStoryCloseClick}
        onCreate={onAddStorySubmitClick}
        projectId={projectId}
      />
      {isUndefined(state.storyId) ? undefined : (
        <StoryUpdateDialog
          onClose={onUpdateStoryCloseClick}
          open={!isNull(storyUpdate)}
          projectId={projectId}
          storyId={state.storyId}
        />
      )}
    </ListLayout>
  )
}
