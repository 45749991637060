import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

export interface DowngradeSubscriptionDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  onClose: () => void
  onDowngradeConfirm: () => void
}

export const DowngradeSubscriptionDialog: React.FC<DowngradeSubscriptionDialogProps> = ({
  onClose,
  onDowngradeConfirm,
  ...rest
}) => {
  return (
    <Dialog
      aria-labelledby="downgrade-subscription-dialog-title"
      aria-describedby="downgrade-subscription-dialog-description"
      onClose={onClose}
      {...rest}
    >
      <DialogTitle id="downgrade-subscription-dialog-title">{`Downgrade subscription?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="downgrade-subscription-dialog-description">
          This will downgrade your account to a more limited plan and may limit
          access to functionality outside of your current plan.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus={true} onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onDowngradeConfirm}
          variant="contained"
        >
          Downgrade
        </Button>
      </DialogActions>
    </Dialog>
  )
}
