import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { StripeElementChangeEvent, StripeElementType } from '@stripe/stripe-js'
import {
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { first, isNull, isUndefined } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'
import { StripeTextField, SubmitButton } from '../../../components'
import { useCreatePaymentMethodMutation } from '../../../middleware'
import { getGraphQLError } from '../../../util'

export interface CreatePaymentMethodDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  onClose: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      marginTop: -theme.spacing(0.5),
    },
    cancelButton: {
      flex: 1,
    },
    dialogPaper: {
      maxWidth: theme.spacing(50),
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    error: {
      marginBottom: theme.spacing(2),
    },
    fields: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(1),
      '& >:first-child': {
        gridColumnEnd: 'span 2',
      },
    },
    title: {
      flex: 1,
      marginRight: theme.spacing(4),
    },
    updateButton: {
      flex: 2,
    },
  })
)

export const CreatePaymentMethodDialog: React.FC<CreatePaymentMethodDialogProps> = ({
  onClose,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState<{
    elementError: { [key in StripeElementType]?: string }
    stripeError?: string
    stripeLoading: boolean
  }>({ elementError: {}, stripeLoading: false })
  const [
    createPaymentMethod,
    { loading: createPaymentMethodLoading, error: createPaymentMethodError },
  ] = useCreatePaymentMethodMutation({
    onError: (e) => {
      const error = first(getGraphQLError(e))

      gtag('event', 'exception', {
        description: `Failed to create payment method.${
          isUndefined(error) ? '' : ` ${error}`
        }`,
        fatal: false,
      })
    },
  })

  const onCreate = useCallback(() => {
    const create = async () => {
      if (isNull(stripe) || isNull(elements)) {
        return
      }

      setState({ ...state, stripeLoading: true })
      const cardElement = elements.getElement(CardNumberElement)

      if (isNull(cardElement)) {
        const error = "Can't find card field."

        gtag('event', 'exception', {
          description: error,
          fatal: false,
        })
        setState({
          ...state,
          stripeError: error,
          stripeLoading: false,
        })
        return
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })

      if (!isUndefined(error)) {
        gtag('event', 'exception', {
          description: error.message,
          fatal: false,
        })
        setState({ ...state, stripeError: error.message, stripeLoading: false })
        return
      }

      if (isUndefined(paymentMethod)) {
        const error = "Can't create payment method."

        gtag('event', 'exception', {
          description: error,
          fatal: true,
        })
        setState({
          ...state,
          stripeError: error,
          stripeLoading: false,
        })
        return
      }

      await createPaymentMethod({
        variables: {
          stripePaymentMethodId: paymentMethod?.id,
        },
      }).catch((_) => {
        // Handled above in `createPaymentMethodError`
        // See: https://github.com/apollographql/apollo-client/issues/3963
      })

      setState({ ...state, stripeError: undefined, stripeLoading: false })

      enqueueSnackbar(`Successfully updated payment method.`, {
        variant: 'success',
      })
      onClose()
    }

    create().catch(() => {
      // handled above
    })
  }, [createPaymentMethod, elements, enqueueSnackbar, onClose, state, stripe])

  const onChange = (event: StripeElementChangeEvent) => {
    setState({
      ...state,
      elementError: {
        ...state.elementError,
        [event.elementType]: event.error?.message,
      },
    })
  }

  const submitting = state.stripeLoading || createPaymentMethodLoading
  const error = isUndefined(createPaymentMethodError)
    ? state.stripeError
    : first(getGraphQLError(createPaymentMethodError))

  return (
    <Dialog
      aria-labelledby="create-payment-method-dialog-title"
      aria-describedby="create-payment-method-dialog-description"
      PaperProps={{ className: classes.dialogPaper }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle
        id="create-payment-method-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography className={classes.title} variant="h5">
          Update payment method
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          edge="end"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isUndefined(error) ? undefined : (
          <Alert className={classes.error} severity="error">
            <AlertTitle>Payment method failed to update</AlertTitle>
            {error}
          </Alert>
        )}
        <DialogContentText id="create-payment-method-dialog-description">
          Update your default payment method below.
        </DialogContentText>
        <div className={classes.fields}>
          <StripeTextField
            error={Boolean(state.elementError.cardNumber)}
            helperText={state.elementError.cardNumber}
            label="Card Number"
            inputProps={{
              options: {
                showIcon: true,
              },
            }}
            onChange={onChange}
            stripeElement={CardNumberElement}
            variant={'outlined'}
          />
          <StripeTextField
            error={Boolean(state.elementError.cardExpiry)}
            helperText={state.elementError.cardExpiry}
            label="Expires"
            onChange={onChange}
            stripeElement={CardExpiryElement}
            variant={'outlined'}
          />
          <StripeTextField
            error={Boolean(state.elementError.cardCvc)}
            helperText={state.elementError.cardCvc}
            label="CVC Code"
            onChange={onChange}
            stripeElement={CardCvcElement}
            variant={'outlined'}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelButton}
          disabled={submitting}
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <SubmitButton
          className={classes.updateButton}
          color="primary"
          onClick={onCreate}
          loading={submitting}
          variant="contained"
        >
          Update
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}
