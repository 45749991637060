import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ListLayout } from '../../../components'

export const PrivacyPolicyPage: React.FC = () => {
  const history = useHistory()

  const onBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <ListLayout
      headerLeft={
        <Fab variant="extended" aria-label="Back" onClick={onBack}>
          <ArrowBackIosIcon style={{ fontSize: 18 }} />
          Back
        </Fab>
      }
    >
      <Container maxWidth="sm">
        <div
          dangerouslySetInnerHTML={{
            // See legal html doc for more (comment below isn't mine)
            __html: `
        <style>
        /*  The CSS below this comment is very important and must be added to the page that displays the document. 
            Without these styles, the numbering, and display of numbers doesn't work.
            Be careful with your own site styles, they may interfere with the display of your document.
            You might need to add to the styles below to get the style you'd like.
        */
        .part ol {
          counter-reset: sectioncounter partcounter;
          margin: 0px;
          padding: 0px;
        }
        ol.sections {
          counter-reset: sectioncounter;
          margin: 0px;
          padding: 0px;
        }
        ol.sections li {
          list-style: none;
          margin-left: 2rem;
          position: relative;
        }
        ol.units {
          counter-reset: unitcounter;
          margin: 0 0 2rem 0;
        }
        ol.sections li.partTitle {
          list-style: none;
          counter-increment: none;
          counter-reset: numberedpart;
          margin-left: 0;
        }
        ol.sections li.sectionTitle {
          list-style: none;
          counter-increment: none;
          counter-reset: numberedsection;
          margin-left: 0;
        }
        ol.sections li.unnumberedunit {
            margin-left: 0;
        }
        .sections li:before {
          position: absolute;
          left: -2rem;
        }
        .sections li.numberedpart:before {
          content: counter(partcounter, DECIMAL) ". ";
          counter-increment: partcounter;
        }
        .sections li.numberedsection:before {
          content: counter(sectioncounter, DECIMAL) ". ";
          counter-increment: sectioncounter;
        }
        .units li.numberedunit:before {
          content: counter(sectioncounter, DECIMAL) "." counter(unitcounter, DECIMAL) " ";
          counter-increment: unitcounter;
        }
        .unnumberedsection .units li.numberedunit:before {
          content: counter(unitcounter, DECIMAL) " ";
          counter-increment: unitcounter;
        }
        ol.sections li.definitionli {
          margin-left: 0;
        }
        li.unit ol,
        li.unit ol ol ol {
          counter-reset: loweralpha;
        }
        li.unit ol:not(.unnumbered) li:before,
        li.unit ol ol ol:not(.unnumbered) li:before {
          content: "(" counter(loweralpha, lower-alpha) ") ";
          counter-increment: loweralpha;
        }
        li.unit ol ol,
        li.unit ol ol ol ol {
          counter-reset: lowerroman;
        }
        li.unit ol ol:not(.unnumbered) li:before,
        li.unit ol ol ol ol:not(.unnumbered) li:before {
          content: "(" counter(lowerroman, lower-roman) ") ";
          counter-increment: lowerroman;
        }
        li.unit ol.unnumbered ol li:before,
        li.unit ol ol ol.unnumbered ol li:before {
          content: "(" counter(lowerroman, lower-alpha) ") ";
          counter-increment: lowerroman;
        }
        .part dfn {
          font-weight: bold;
        }
        </style>
        
        <h1>Privacy and cookies policy</h1>
        <section class="part unnumberedpart">
           <ol class="sections">
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Introduction</h3>
                    </li>
                    <li class="unit numberedunit">We are committed to safeguarding the privacy of our website visitors, service users, individual customers and customer personnel.</li>
                    <li class="unit numberedunit">This policy applies where we are acting as a data controller with respect to the personal data of such persons; in other words, where we determine the purposes and means of the processing of that personal data.</li>
                    <li class="unit numberedunit">We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.</li>
                    <li class="unit numberedunit">In this policy, "we", "us" and "our" refer to Neverglitch Ltd. For more information about us, see Section 17.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>The personal data that we collect</h3>
                    </li>
                    <li class="unit numberedunit">In this Section 2 we have set out the general categories of personal data that we process and, in the case of personal data that we did not obtain directly from you, information about the source and specific categories of that data.</li>
                    <li class="unit numberedunit">We may process data enabling us to get in touch with you ("<dfn>contact data</dfn>"). The contact data may include your name, email address, telephone number, postal address and/or social media account identifiers. The source of the contact data is you and/or your employer. If you log into our website using a social media account, we will obtain elements of the contact data from the relevant social media account provider.</li>
                    <li class="unit numberedunit">We may process your website user account data ("<dfn>account data</dfn>"). The account data may include your account identifier, name, email address, business name, account creation and modification dates, website settings and marketing preferences. The primary source of the account data is you and/or your employer, although some elements of the account data may be generated by our website. If you log into our website using a social media account, we will obtain elements of the account data from the relevant social media account provider.</li>
                    <li class="unit numberedunit">We may process your information included in your personal profile on our website ("<dfn>profile data</dfn>"). The profile data may include your name, address, telephone number, email address, profile pictures, gender, date of birth, relationship status, interests and hobbies, educational details and employment details. The source of the profile data is you and/or your employer. If you log into our website using a social media account, we will obtain elements of the profile data from the relevant social media account provider.</li>
                    <li class="unit numberedunit">We may process information relating to our customer relationships ("<dfn>customer relationship data</dfn>"). The customer relationship data may include your name, the name of your business or employer, your job title or role, your contact details, your classification / categorisation within our customer relationship management system and information contained in or relating to communications between us and you, or between us and your employer. The source of the customer relationship data is you and/or your employer.</li>
                    <li class="unit numberedunit">We may process your personal data that are provided in the course of the use of our services and generated by our services in the course of such use ("<dfn>service data</dfn>"). The source of the service data is you and/or your employer and/or our services.</li>
                    <li class="unit numberedunit">We may process information relating to transactions, including purchases of goods and/or services, that you enter into with us and/or through our website ("<dfn>transaction data</dfn>"). The transaction data may include your name, your contact details, your payment card details (or other payment details) and the transaction details. The source of the transaction data is you and/or our payment services provider.</li>
                    <li class="unit numberedunit">We may process information contained in or relating to any communication that you send to us or that we send to you ("<dfn>communication data</dfn>"). The communication data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms.</li>
                    <li class="unit numberedunit">We may process data about your use of our website and services ("<dfn>usage data</dfn>"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system.</li>
                    <li class="unit numberedunit">Please do not supply any other person's personal data to us, unless we prompt you to do so.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Purposes of processing and legal bases</h3>
                    </li>
                    <li class="unit numberedunit">In this Section 3, we have set out the purposes for which we may process personal data and the legal bases of the processing.</li>
                    <li class="unit numberedunit"><strong>Operations </strong>- We may process your personal data for the purposes of operating our website, the processing and fulfilment of orders, providing our services, supplying our goods, generating invoices, bills and other payment-related documentation, and credit control. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</li>
                    <li class="unit numberedunit"><strong>Publications </strong>- We may process account data, profile data and/or service data for the purposes of publishing such data on our website and elsewhere through our services in accordance with your express instructions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</li>
                    <li class="unit numberedunit"><strong>Relationships and communications</strong> - We may process contact data, account data, customer relationship data, transaction data and/or communication data for the purposes of managing our relationships, communicating with you (excluding communicating for the purposes of direct marketing) by email, SMS, post, fax and/or telephone, providing support services and complaint handling. The legal basis for this processing is our legitimate interests, namely communications with our website visitors, service users, individual customers and customer personnel, the maintenance of our relationships, enabling the use of our services, and the proper administration of our website, services and business.</li>
                    <li class="unit numberedunit"><strong>Personalisation </strong>- We may process account data, service data and/or usage data for the purposes of personalising the content and advertisements that you see on our website and through our services to ensure that you only see material that is relevant to you. The legal basis for this processing is our legitimate interests, namely offering the best possible experience for our website visitors and service users.</li>
                    <li class="unit numberedunit"><strong>Direct marketing </strong>- We may process contact data, account data, profile data, customer relationship data and/or transaction data for the purposes of creating, targeting and sending direct marketing communications by email, SMS, post and/or fax and making contact by telephone for marketing-related purposes. The legal basis for this processing is our legitimate interests, namely promoting our business and communicating marketing messages and offers to our website visitors and service users.</li>
                    <li class="unit numberedunit"><strong>Research and analysis </strong>- We may process usage data, service data and/or transaction data for the purposes of researching and analysing the use of our website and services, as well as researching and analysing other interactions with our business. The legal basis for this processing is our legitimate interests, namely monitoring, supporting, improving and securing our website, services and business generally.</li>
                    <li class="unit numberedunit"><strong>Record keeping </strong>- We may process your personal data for the purposes of creating and maintaining our databases, back-up copies of our databases and our business records generally. The legal basis for this processing is our legitimate interests, namely ensuring that we have access to all the information we need to properly and efficiently run our business in accordance with this policy.</li>
                    <li class="unit numberedunit"><strong>Security </strong>- We may process your personal data for the purposes of security and the prevention of fraud and other criminal activity. The legal basis of this processing is our legitimate interests, namely the protection of our website, services and business, and the protection of others.</li>
                    <li class="unit numberedunit"><strong>Insurance and risk management</strong> - We may process your personal data where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks and/or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</li>
                    <li class="unit numberedunit"><strong>Legal claims</strong> - We may process your personal data where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.</li>
                    <li class="unit numberedunit"><strong>Legal compliance and vital interests</strong> - We may also process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or in order to protect your vital interests or the vital interests of another natural person.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Providing your personal data to others</h3>
                    </li>
                    <li class="unit numberedunit">We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.</li>
                    <li class="unit numberedunit">We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice.</li>
                    <li class="unit numberedunit">Your personal data held in our website database will be stored on the servers of our hosting services providers.</li>
                    <li class="unit numberedunit">We may disclose data in the application to our suppliers or subcontractors insofar as reasonably necessary for debugging software issues to improve your experience.</li>
                    <li class="unit numberedunit">Financial transactions relating to our website and services are handled by our payment services providers, Stripe. We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. You can find information about the payment services providers' privacy policies and practices at <a href="https://stripe.com/gb/privacy">https://stripe.com/gb/privacy</a>.</li>
                    <li class="unit numberedunit">We may disclose contact data along with any other personal data contained in enquiries made through our website or services to one or more of those selected third party suppliers of goods and/or services identified on our website for the purpose of enabling them to contact you so that they can offer, market and sell to you relevant goods and/or services. Each such third party will act as a data controller in relation to the personal data that we supply to it; and upon contacting you, each such third party will supply to you a copy of its own privacy policy, which will govern that third party's use of your personal data.</li>
                    <li class="unit numberedunit">In addition to the specific disclosures of personal data set out in this Section 4, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise, or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Retaining and deleting personal data</h3>
                    </li>
                    <li class="unit numberedunit">This Section 5 sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</li>
                    <li class="unit numberedunit">Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</li>
                    <li class="unit numberedunit">
                       We will retain your personal data as follows:
                       <ol class="listitem  ">
                          <li>contact data will be retained for a minimum period of 26 months following the date of the most recent contact between you and us, and for a maximum period of indefinite following that date;</li>
                          <li>account data will be retained for a minimum period of 26 months following the date of closure of the relevant account, and for a maximum period of indefinite following that date;</li>
                          <li>profile data will be retained for a minimum period of 26 months following the date of deletion of the profile by you, and for a maximum period of indefinite following that date;</li>
                          <li>customer relationship data will be retained for a minimum period of 26 months following the date of termination of the relevant customer relationship and for a maximum period of indefinite following that date;</li>
                          <li>service data will be retained for a minimum period of 26 months following the date of termination of the relevant contract, and for a maximum period of indefinite following that date;</li>
                          <li>transaction data will be retained for a minimum period of 26 months following the date of the transaction, and for a maximum period of indefinite following that date;</li>
                          <li>communication data will be retained for a minimum period of 26 months following the date of the communication in question, and for a maximum period of indefinite following that date; and</li>
                          <li>usage data will be retained for 26 months following the date of collection.</li>
                       </ol>
                    </li>
                    <li class="unit numberedunit">
                       In some cases it is not possible for us to specify in advance the periods for which your personal data will be retained. In such cases, we will determine the period of retention based on the following criteria:
                       <ol class="patternitem  ">
                          <li>the period of retention of user accounts will be determined based on how long users keep their accounts.</li>
                       </ol>
                    </li>
                    <li class="unit numberedunit">If you grant to us a licence to publish any of your personal data, we may continue to retain and publish that personal data after the end of the relevant retention period specified in this Section 5 in accordance with the applicable licence terms, subject to your data subject rights. If we cease to publish such personal data after the end of the relevant retention period specified in this Section 5, that personal data will be retained for a minimum period of 26 months and a maximum period of indefinite following the date that publication ceases.</li>
                    <li class="unit numberedunit">Notwithstanding the other provisions of this Section 5, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Security of personal data</h3>
                    </li>
                    <li class="unit numberedunit">We will take appropriate technical and organisational precautions to secure your personal data and to prevent the loss, misuse or alteration of your personal data.</li>
                    <li class="unit numberedunit">We will store your personal data on secure servers, personal computers and mobile devices, and in secure manual record-keeping systems.</li>
                    <li class="unit numberedunit">The following personal data will be stored by us in encrypted form: your name, contact information, password(s) and cardholder data.</li>
                    <li class="unit numberedunit">Data relating to your enquiries and financial transactions that is sent from your web browser to our web server, or from our web server to your web browser, will be protected using encryption technology.</li>
                    <li class="unit numberedunit">You acknowledge that the transmission of unencrypted (or inadequately encrypted) data over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</li>
                    <li class="unit numberedunit">You should ensure that your password is not susceptible to being guessed, whether by a person or a computer program. You are responsible for keeping the password you use for accessing our website confidential and we will not ask you for your password (except when you log in to our website).</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Your rights</h3>
                    </li>
                    <li class="unit numberedunit">In this Section 7, we have listed the rights that you have under data protection law.</li>
                    <li class="unit numberedunit">
                       Your principal rights under data protection law are:
                       <ol class="listitem  ">
                          <li><strong>the right to access</strong> - you can ask for copies of your personal data;</li>
                          <li><strong>the right to rectification</strong> - you can ask us to rectify inaccurate personal data and to complete incomplete personal data;</li>
                          <li><strong>the right to erasure</strong> - you can ask us to erase your personal data;</li>
                          <li><strong>the right to restrict processing</strong> - you can ask us to restrict the processing of your personal data;</li>
                          <li><strong>the right to object to processing</strong> - you can object to the processing of your personal data;</li>
                          <li><strong>the right to data portability</strong> - you can ask that we transfer your personal data to another organisation or to you;</li>
                          <li><strong>the right to complain to a supervisory authority</strong> - you can complain about our processing of your personal data; and</li>
                          <li><strong>the right to withdraw consent</strong> - to the extent that the legal basis of our processing of your personal data is consent, you can withdraw that consent.</li>
                       </ol>
                    </li>
                    <li class="unit numberedunit">These rights are subject to certain limitations and exceptions. You can learn more about the rights of data subjects by visiting <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a>.</li>
                    <li class="unit numberedunit">You may exercise any of your rights in relation to your personal data by written notice to us, using the contact details set out below.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Third party websites</h3>
                    </li>
                    <li class="unit numberedunit">Our website includes hyperlinks to, and details of, third party websites.</li>
                    <li class="unit numberedunit">In general we have no control over, and are not responsible for, the privacy policies and practices of third parties.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Personal data of children</h3>
                    </li>
                    <li class="unit numberedunit">Our website and services are targeted at persons over the age of 18.</li>
                    <li class="unit numberedunit">If we have reason to believe that we hold personal data of a person under that age in our databases, we will delete that personal data.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Updating information</h3>
                    </li>
                    <li class="unit numberedunit">Please let us know if the personal information that we hold about you needs to be corrected or updated.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Acting as a data processor</h3>
                    </li>
                    <li class="unit numberedunit">In respect of the core application features, such as collecting project requirements and inviting users, we do not act as a data controller; instead, we act as a data processor.</li>
                    <li class="unit numberedunit">Insofar as we act as a data processor rather than a data controller, this policy shall not apply. Our legal obligations as a data processor are instead set out in the contract between us and the relevant data controller.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>About cookies</h3>
                    </li>
                    <li class="unit numberedunit">A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</li>
                    <li class="unit numberedunit">Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</li>
                    <li class="unit numberedunit">Cookies may not contain any information that personally identifies a user, but personal data that we store about you may be linked to the information stored in and obtained from cookies.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Cookies that we use</h3>
                    </li>
                    <li class="unit numberedunit">
                       We use cookies for the following purposes:
                       <ol class="listitem  ">
                          <li><strong>authentication and status</strong> - we use cookies to identify you when you visit our website and as you navigate our website, and to help us determine if you are logged into our website;</li>
                          <li><strong>personalisation</strong> - we use cookies to store information about your preferences and to personalise our website for you;</li>
                          <li><strong>security</strong> - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally;</li>
                          <li><strong>advertising</strong> - we use cookies to help us to display advertisements that will be relevant to you;</li>
                          <li><strong>analysis</strong> - we use cookies to help us to analyse the use and performance of our website and services; and</li>
                          <li><strong>cookie consent</strong> - we use cookies to store your preferences in relation to the use of cookies more generally.</li>
                       </ol>
                    </li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Cookies used by our service providers</h3>
                    </li>
                    <li class="unit numberedunit">Our service providers use cookies and those cookies may be stored on your computer when you visit our website.</li>
                    <li class="unit numberedunit">We use Google Analytics. Google Analytics gathers information about the use of our website by means of cookies. The information gathered is used to create reports about the use of our website. You can find out more about Google's use of information by visiting <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a> and you can review Google's privacy policy at <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</li>
                    <li class="unit numberedunit">We publish Google AdSense advertisements on our website. The advertisements may be personalised to reflect your interests. To help determine your interests Google and its partners use cookies. The cookies are used to track your previous visits to our website and your visits to other websites. You can opt out of Google's personalised advertising by visiting <a href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a> and you can opt out of third party cookies use for personalised advertising by visiting <a href="http://www.aboutads.info">http://www.aboutads.info</a>. You can review Google's privacy policy at <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</li>
                    <li class="unit numberedunit">We use a Facebook pixel on our website. Using the pixel, Facebook collects information about the users and use of our website. The information is used to personalise Facebook advertisements and to analyse the use of our website. To find out more about the Facebook pixel and about Facebook's use of personal data generally, see the Facebook cookie policy at <a href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a> and the Facebook privacy policy at <a href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>. The Facebook cookie policy includes information about controlling Facebook's use of cookies to show you advertisements. If you are a registered Facebook user, you can adjust how advertisements are targeted by following the instructions at <a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a>.</li>
                    <li class="unit numberedunit">We use a LinkedIn Insight Tag on our website. Using the tag, LinkedIn collects information about the users and use of our website. The information is used to personalise LinkedIn advertisements and to analyse the use of our website. To find out more about the LinkedIn Insight Tag, visit <a href="https://www.linkedin.com/help/lms/answer/a427660">https://www.linkedin.com/help/lms/answer/a427660</a> and to find out about LinkedIn's use of personal data generally, see the LinkedIn Data Processing Agreement at <a href="https://www.linkedin.com/legal/l/dpa">https://www.linkedin.com/legal/l/dpa</a>, Privacy Policy at <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a> and Cookie Policy at <a href="https://www.linkedin.com/legal/cookie-policy">https://www.linkedin.com/legal/cookie-policy</a>.</li>
                    <li class="unit numberedunit">We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf. For further details, please see the ‘about Hotjar’ section of <a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar">Hotjar’s support site</a>.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Managing cookies</h3>
                    </li>
                    <li class="unit numberedunit">
                       Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:
                       <ol class="listitem  ">
                          <li><a href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a> (Chrome);</li>
                          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a> (Firefox);</li>
                          <li><a href="https://help.opera.com/en/latest/security-and-privacy/">https://help.opera.com/en/latest/security-and-privacy/</a> (Opera);</li>
                          <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a> (Internet Explorer);</li>
                          <li><a href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a> (Safari); and</li>
                          <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a> (Edge).</li>
                       </ol>
                    </li>
                    <li class="unit numberedunit">Blocking all cookies will have a negative impact upon the usability of many websites.</li>
                    <li class="unit numberedunit">If you block cookies, you will not be able to use all the features on our website.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Amendments</h3>
                    </li>
                    <li class="unit numberedunit">We may update this policy from time to time by publishing a new version on our website.</li>
                    <li class="unit numberedunit">You should check this page occasionally to ensure you are happy with any changes to this policy.</li>
                    <li class="unit numberedunit">We may notify you of significant changes to this policy by email.</li>
                 </ol>
              </li>
              <li class="section numberedsection">
                 <ol class="units">
                    <li class="sectionTitle">
                       <h3>Our details</h3>
                    </li>
                    <li class="unit numberedunit">This website is owned and operated by Neverglitch Ltd.</li>
                    <li class="unit numberedunit">We are registered in England and Wales under registration number 10707455, and our registered office is at Neverglitch Ltd, Cheyenne House, West Street, Farnham, Surrey, GU9 7EQ, United Kingdom.</li>
                    <li class="unit numberedunit">Our principal place of business is at Neverglitch Ltd, Cheyenne House, West Street, Farnham, Surrey, GU9 7EQ, United Kingdom.</li>
                    <li class="unit numberedunit">
                       You can contact us:
                       <ol class="listitem  ">
                          <li>by post, to the postal address given above; or</li>
                          <li>by email, using <a href="mailto:support@loveyourproject.com">support@loveyourproject.com</a></li>
                       </ol>
                    </li>
                 </ol>
              </li>
           </ol>
        </section>
        `,
          }}
        />
      </Container>
    </ListLayout>
  )
}
