import TextField from '@material-ui/core/TextField'
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'
import React, { useCallback } from 'react'
import { ArrayParam, useQueryParam } from 'use-query-params'
import {
  ProjectStoryRolesQuery,
  useProjectStoryRolesQuery,
} from '../../../middleware'

type Option = ProjectStoryRolesQuery['project']['storyRoles'][0]

interface StoryRolesSelectProps
  extends Omit<
    AutocompleteProps<Option, true, false, false>,
    'options' | 'renderInput'
  > {
  projectId: string
}

export const StoryRolesSelect: React.FC<StoryRolesSelectProps> = ({
  projectId,
  ...rest
}) => {
  const [roles, setRoles] = useQueryParam('roles', ArrayParam)
  const { data, loading } = useProjectStoryRolesQuery({
    variables: {
      id: projectId,
    },
  })

  const value =
    data?.project.storyRoles.filter((n) => roles?.some((x) => x === n)) ?? []

  const onChange = useCallback(
    (_, value: ProjectStoryRolesQuery['project']['storyRoles'][0][] | null) => {
      setRoles(value ?? undefined)
    },
    [setRoles]
  )

  return (
    <Autocomplete
      id="roles-filter"
      loading={loading}
      multiple={true}
      onChange={onChange}
      options={data?.project.storyRoles ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Role"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          variant="outlined"
        />
      )}
      value={value}
      {...rest}
    />
  )
}
