import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  UpdateProjectDocument,
  UpdateProjectMutationResult,
  UpdateProjectMutationVariables,
} from '../../graphql'
import { projectSuccessMock } from '../../queries'

type UpdateProjectMockResponse = MockResponse<
  UpdateProjectMutationVariables,
  Pick<UpdateProjectMutationResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const updateProjectSuccessMock = {
  request: {
    query: UpdateProjectDocument,
    variables: {
      id: projectSuccessMock.result.data.project.id,
      name: 'New project name',
    },
  },
  result: {
    data: {
      updateProject: {
        id: projectSuccessMock.result.data.project.id,
        name: 'New project name',
      },
    },
  },
}

export const updateProjectLongNameSuccessMock = {
  request: {
    query: UpdateProjectDocument,
    variables: {
      id: projectSuccessMock.result.data.project.id,
      name:
        'Donec at nisi sed libero dictum feugiat. Nullam odio diam, auctor quis tincidunt nec, commodo et nisl.',
    },
  },
  result: {
    data: {
      updateProject: {
        id: projectSuccessMock.result.data.project.id,
        name:
          'Donec at nisi sed libero dictum feugiat. Nullam odio diam, auctor quis tincidunt nec, commodo et nisl.',
      },
    },
  },
}

export const updateProjectFailureMock = {
  request: {
    query: UpdateProjectDocument,
    variables: {
      id: projectSuccessMock.result.data.project.id,
      name: 'Nope',
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 404,
          error: 'Not Found',
          message: 'Project does not exist.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const updateProjectMocks: UpdateProjectMockResponse[] = [
  updateProjectSuccessMock,
  updateProjectLongNameSuccessMock,
  updateProjectFailureMock,
]
