export const projectPermissions = [
  {
    primaryLabel: 'Read',
    secondaryLabel: 'View project stories only',
    value: 'READ',
  },
  {
    primaryLabel: 'Write',
    secondaryLabel: 'View and create project stories',
    value: 'WRITE',
  },
  {
    primaryLabel: 'Admin',
    secondaryLabel: 'Admin project and stories',
    value: 'ADMIN',
  },
]
