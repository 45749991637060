import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { HTMLAttributes } from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    ...theme.mixins.gutters(),
  },
}))

export const CenterLayout: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  const classes = useStyles()

  return <div className={clsx(classes.container, className)}>{children}</div>
}
