import Container from '@material-ui/core/Container'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { config } from '../../../config'

const steps: {
  image: string
  title: string
  body: string
}[] = [
  {
    image: '/images/users.svg',
    title: 'Create',
    body:
      'Create a project and invite clients and team members as collaborators, viewers or admins.',
  },
  {
    image: '/images/post.svg',
    title: 'Share',
    body:
      'Add requirements, ideas, needs, wants and goals together in one place either as a user, or on behalf of one, for others to review.',
  },
  {
    image: '/images/raise.svg',
    title: 'Organise',
    body:
      'Users review and vote for requirements they care about to automatically order the best ideas to the top of the list.',
  },
]

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      padding: theme.spacing(10, 3),
    },
    image: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      marginBottom: theme.spacing(1.5),
    },
    steps: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridRowGap: theme.spacing(10),
      gridColumnGap: theme.spacing(3),
      justifyItems: 'center',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
    step: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 276,
    },
    stepTitle: {
      marginBottom: theme.spacing(1),
    },
    title: {
      marginBottom: theme.spacing(5),
    },
  })
)

export const HowItWorks: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography
        align="center"
        className={classes.title}
        component="h2"
        variant="h3"
      >
        How it works
      </Typography>
      <Container className={classes.steps} disableGutters={true} maxWidth="md">
        {steps.map((n) => (
          <div className={classes.step} key={n.title}>
            <img
              className={classes.image}
              src={n.image}
              alt={`${config.appName ?? ''} ${n.title}`}
            />
            <Typography
              align="center"
              className={classes.stepTitle}
              component="h3"
              variant="h5"
            >
              {n.title}
            </Typography>
            <Typography align="center">{n.body}</Typography>
          </div>
        ))}
      </Container>
    </div>
  )
}
