import { plainToClass } from 'class-transformer'
import { MaxLength, MinLength, validateSync } from 'class-validator'
import { CreateProjectMutationVariables } from '../../graphql'
import { getValidationErrors } from '../../../../util'

export class CreateProjectDto {
  @MinLength(3, {
    message: 'Name should be at least $constraint1 characters long.',
  })
  @MaxLength(500, {
    message: 'Name should be at most $constraint1 characters long.',
  })
  name!: string
}

export const createProjectValidate = (
  vars: Omit<CreateProjectMutationVariables, 'id'>
) => getValidationErrors(validateSync(plainToClass(CreateProjectDto, vars)))
