import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'
import { debounce, isEmpty, isNil } from 'lodash'
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { config } from '../../../config'

type SearchFormProps = HTMLAttributes<HTMLDivElement>

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    icon: {
      color: config.colours.darkButtonText,
      position: 'absolute',
      left: 14, // matches adornedStart padding
    },
    field: {
      flex: 1,
      '& label': {
        // match icon left distance + icon width + margin right
        transform: `translate(${38 + theme.spacing(1)}px, 18px) scale(1)`,
      },
      '& label:not(.MuiInputLabel-shrink)': {
        color: config.colours.darkButtonText,
      },
      '& label.MuiInputLabel-shrink': {
        // see above
        transform: `translate(${38 + theme.spacing(1)}px, 7px) scale(0.857)`,
      },
    },
    input: {
      backgroundColor: theme.palette.common.white,
      '& input': {
        paddingLeft: 38 + theme.spacing(1),
      },
    },
  })
)

export const SearchForm: React.FC<SearchFormProps> = ({
  className,
  ...rest
}) => {
  const classes = useStyles()
  const [state, setState] = useState('')
  const [search, setSearch] = useQueryParam('search', StringParam)

  const updateSearch = useCallback(
    debounce((newSearch: string) => {
      setSearch(isEmpty(newSearch) ? undefined : newSearch, 'replaceIn')
    }, 275),
    []
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newSearch = event.currentTarget.value

      setState(newSearch)
      updateSearch(newSearch)
    },
    [updateSearch]
  )

  const onClear = useCallback(() => {
    setState('')
    updateSearch('')
  }, [updateSearch])

  useEffect(() => {
    setState(isNil(search) ? '' : search)
  }, [search])

  return (
    <div className={clsx([classes.container, className])} {...rest}>
      <FormControl className={classes.field} variant="outlined">
        <InputLabel htmlFor="search">Search</InputLabel>
        <OutlinedInput
          className={classes.input}
          id="search"
          value={state}
          onChange={onChange}
          endAdornment={
            isEmpty(state) ? undefined : (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Clear search"
                  edge="end"
                  onClick={onClear}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>
      <SearchIcon className={classes.icon} />
    </div>
  )
}
