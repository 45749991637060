import React, { Fragment } from 'react'
import { Footer, NavBar } from '../../../components'

interface BrochureLayoutProps {
  children?: React.ReactNode
}

export const BrochureLayout: React.FC<BrochureLayoutProps> = ({ children }) => (
  <Fragment>
    <NavBar />
    {children}
    <Footer />
  </Fragment>
)
