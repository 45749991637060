import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { first, isNil, isUndefined } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import {
  projectUri,
  useAddProjectUserResponseMutation,
} from '../../../middleware'
import { getGraphQLError } from '../../../util'

export interface JoinProjectProgressProps {
  projectUserId: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      flex: 1,
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    description: {
      marginBottom: theme.spacing(5),
    },
  })
)

export const JoinProjectProgress: React.FC<JoinProjectProgressProps> = ({
  projectUserId,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [
    addProjectUserResponse,
    { data, loading, error },
  ] = useAddProjectUserResponseMutation({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to set project user response for ${projectUserId} project user.${errorText}`,
        fatal: false,
      })
    },
    variables: {
      accept: true,
      projectUserId,
    },
  })

  useEffect(() => {
    addProjectUserResponse()
      .then(() => {
        enqueueSnackbar('Invite accept successful', {
          variant: 'success',
        })
      })
      .catch(() => {}) // Error handled above
  }, [addProjectUserResponse, enqueueSnackbar])

  return !isUndefined(error) ? (
    <Container className={classes.container} maxWidth="sm">
      <Alert severity="error">
        <AlertTitle>Project join failed</AlertTitle>
        {first(getGraphQLError(error))}
      </Alert>
    </Container>
  ) : loading ? (
    <div className={classes.container}>
      <Typography variant="h3" gutterBottom={true}>
        Joining project
      </Typography>
      <CircularProgress aria-label="Loading" />
    </div>
  ) : !isNil(data) && !isNil(data.addProjectUserResponse.project) ? (
    <Redirect to={projectUri(data.addProjectUserResponse.project.id)} />
  ) : (
    <Typography variant="h3" gutterBottom={true}>
      Join successful
    </Typography>
  )
}
