import * as ApolloReactHooks from '@apollo/client'
import { first, isUndefined } from 'lodash'
import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
  useCurrentUserQuery,
} from '../../graphql'
import { getGraphQLError } from '../../../../util'

export const useCurrentUser = (
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) =>
  useCurrentUserQuery({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to read current user.${errorText}`,
        fatal: false,
      })
    },
    ...baseOptions,
  })
