import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Skeleton from '@material-ui/lab/Skeleton'
import { first, last, isNil, isNull, isUndefined, truncate } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CurrentUserDocument,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  goToProject,
  goToNoProjects,
  Project,
  ProjectsCountDocument,
  ProjectsCountQuery,
  ProjectsCountQueryVariables,
  ProjectsDocument,
  ProjectsQuery,
  ProjectsQueryVariables,
  Sort,
  useDeleteProjectMutation,
  useProjectsQuery,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

export interface ProjectDeleteDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  project?: Pick<Project, 'id' | 'name'>
  onClose: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      marginBottom: theme.spacing(2),
    },
  })
)

export const ProjectDeleteDialog: React.FC<ProjectDeleteDialogProps> = ({
  project,
  onClose,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { data, loading, error } = useProjectsQuery({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to read most recent projects.${errorText}`,
        fatal: true,
      })
    },
    variables: {
      first: 2,
      sort: Sort.CreatedAt,
    },
  })

  const projectName = isUndefined(project)
    ? ''
    : ` "${truncate(project.name, {
        length: 35,
      })}"`

  const [deleteProject] = useDeleteProjectMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      deleteProject: {
        __typename: 'Project',
        ...variables,
      },
    }),
    update: (cache, { data }) => {
      const id = data?.deleteProject?.id

      if (isNil(id)) return

      let currentUserData: CurrentUserQuery | null = null
      try {
        currentUserData = cache.readQuery<
          CurrentUserQuery,
          CurrentUserQueryVariables
        >({
          query: CurrentUserDocument,
        })
      } catch {}

      if (!isNull(currentUserData) && !isNil(currentUserData?.currentUser))
        cache.modify({
          id: `User:${currentUserData.currentUser.id}`,
          fields: {
            canCreateProject(_: boolean, { DELETE }) {
              return DELETE as unknown
            },
          },
        })

      // Workaround for errors thown on uncached queries.
      // To be updated in future versions of apollo.
      // See: https://github.com/apollographql/apollo-feature-requests/issues/1
      let projectsData: ProjectsQuery | null = null
      try {
        projectsData = cache.readQuery<ProjectsQuery, ProjectsQueryVariables>({
          query: ProjectsDocument,
        })
      } catch {}

      if (!isNull(projectsData)) {
        const edges = projectsData.projects.edges.filter(
          (n) => n.node.id !== id
        )
        const edgeCount = projectsData.projects.edges.length
        const lastEdge = last(edges)

        const totalCount = projectsData.projects.totalCount - 1
        const endCursor = lastEdge?.cursor ?? null
        const hasNextPage = totalCount > edgeCount

        cache.writeQuery<ProjectsQuery, ProjectsQueryVariables>({
          query: ProjectsDocument,
          data: {
            ...projectsData,
            projects: {
              ...projectsData.projects,
              totalCount,
              pageInfo: {
                ...projectsData.projects.pageInfo,
                endCursor,
                hasNextPage,
              },
              edges,
            },
          },
        })
      }

      let projectsCountData: ProjectsCountQuery | null = null
      try {
        projectsCountData = cache.readQuery<
          ProjectsCountQuery,
          ProjectsCountQueryVariables
        >({
          query: ProjectsCountDocument,
        })
      } catch {}
      if (!isNull(projectsCountData)) {
        cache.writeQuery<ProjectsCountQuery, ProjectsCountQueryVariables>({
          query: ProjectsCountDocument,
          data: {
            ...projectsCountData,
            projects: {
              ...projectsCountData.projects,
              totalCount: projectsCountData.projects.totalCount - 1,
            },
          },
        })
      }
    },
  })

  const onDelete = useCallback(() => {
    if (isUndefined(project)) return

    deleteProject({
      variables: {
        id: project.id,
      },
    })
      .then(() => {
        enqueueSnackbar(`Successfully deleted project${projectName}.`, {
          variant: 'success',
        })
      })
      .catch((e) => {
        const error = first(getGraphQLError(e as ApiErrors))
        const errorText = isUndefined(error) ? '' : ` ${error}`

        gtag('event', 'exception', {
          description: `Failed to delete ${project.id} project.${errorText}`,
          fatal: false,
        })
        enqueueSnackbar(`Failed to delete${projectName} project.${errorText}`, {
          variant: 'error',
        })
      })

    onClose()

    // most recent project that isn't the one being deleted
    const recentProject = first(
      data?.projects.edges.filter((n) => n.node.id !== project.id)
    )?.node

    if (isUndefined(recentProject)) goToNoProjects(history)
    else goToProject(history, recentProject.id)
  }, [
    data,
    deleteProject,
    enqueueSnackbar,
    history,
    onClose,
    project,
    projectName,
  ])

  return (
    <Dialog
      aria-labelledby="delete-project-dialog-title"
      aria-describedby="delete-project-dialog-description"
      fullWidth={true}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle id="delete-project-dialog-title">
        {loading ? (
          <Skeleton title="Loading title" />
        ) : (
          `Delete project${projectName}?`
        )}
      </DialogTitle>
      <DialogContent>
        {isUndefined(error) ? undefined : (
          <Alert className={classes.error} severity="error">
            <AlertTitle>Most recent projects failed to load</AlertTitle>
            {error.message}
          </Alert>
        )}
        <DialogContentText id="delete-project-dialog-description">
          {loading ? (
            <Skeleton title="Loading content" />
          ) : (
            'This will permanently delete the project and all data within it.'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus={true} onClick={onClose} variant="outlined">
          Cancel
        </Button>
        {loading ? (
          <Skeleton width={100} height={44} title="Loading delete" />
        ) : (
          <Button
            color="secondary"
            disabled={!isUndefined(error)}
            onClick={onDelete}
            startIcon={<DeleteIcon />}
            variant="contained"
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
