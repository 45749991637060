import { ImportStoriesDocument } from '../../graphql'
import { projectsSuccessMock } from '../../queries'

export const importStoriesSuccessFile = new File(
  [
    'As a,I need/want/expect to,So that',
    'Programmer,To have access to the internet,I can search for answers!',
  ],
  'hello.csv',
  { type: 'text/csv' }
)

export const importStoriesSuccessMock = {
  request: {
    query: ImportStoriesDocument,
    variables: {
      file: importStoriesSuccessFile,
      projectId: projectsSuccessMock.result.data.projects.edges[0].node.id,
    },
  },
  result: {
    data: {
      importStories: {
        totalCount: 1,
      },
    },
  },
}
