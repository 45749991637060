import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/client'
import * as ApolloReactHooks from '@apollo/client'
export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string
  /** The `UUID` scalar type represents UUID values as specified by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string
  /** CSV upload type. */
  CSV: File
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  brand?: Maybe<Scalars['String']>
  expiry?: Maybe<Scalars['DateTime']>
  expiryMonth?: Maybe<Scalars['Int']>
  expiryYear?: Maybe<Scalars['Int']>
  last4?: Maybe<Scalars['String']>
}

export type Invoice = {
  __typename?: 'Invoice'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  amountDue?: Maybe<Scalars['Int']>
  currency?: Maybe<Scalars['String']>
  periodEnd?: Maybe<Scalars['DateTime']>
}

export type Product = {
  __typename?: 'Product'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  contributorQuotaBase: Scalars['Int']
  contributorQuotaPerSeat?: Maybe<Scalars['Int']>
  description: Scalars['String']
  hasSubscription: Scalars['Boolean']
  name: Scalars['String']
  projectQuotaBase: Scalars['Int']
  projectQuotaPerSeat?: Maybe<Scalars['Int']>
  quotaDescription: Scalars['String']
  storyQuotaBase: Scalars['Int']
  storyQuotaPerSeat?: Maybe<Scalars['Int']>
}

export type PriceTier = {
  __typename?: 'PriceTier'
  from?: Maybe<Scalars['Int']>
  unitAmount: Scalars['Int']
  upTo?: Maybe<Scalars['Int']>
}

export type Price = {
  __typename?: 'Price'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  currency: Scalars['String']
  hasQuantity: Scalars['Boolean']
  interval: Scalars['String']
  maxQuantity?: Maybe<Scalars['Int']>
  minQuantity: Scalars['Int']
  product: Product
  tiers?: Maybe<Array<PriceTier>>
  unitAmount: Scalars['Int']
}

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  price?: Maybe<Price>
  quantity?: Maybe<Scalars['Int']>
}

export type SubscriptionItems = {
  __typename?: 'SubscriptionItems'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']>
  contributorQuota: Scalars['Int']
  contributorUsage: Scalars['Int']
  currentPeriodEnd?: Maybe<Scalars['DateTime']>
  endedAt?: Maybe<Scalars['DateTime']>
  items: Array<SubscriptionItem>
  projectQuota: Scalars['Int']
  projectUsage: Scalars['Int']
  storyQuota: Scalars['Int']
  storyUsage: Scalars['Int']
}

export type User = {
  __typename?: 'User'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  canCreateProject?: Maybe<Scalars['Boolean']>
  contributorQuota: Scalars['Int']
  contributorUsage: Scalars['Int']
  defaultPaymentMethod?: Maybe<PaymentMethod>
  displayName: Scalars['String']
  email?: Maybe<Scalars['String']>
  emailVerified?: Maybe<Scalars['Boolean']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  projectQuota: Scalars['Int']
  projectUsage: Scalars['Int']
  storyQuota: Scalars['Int']
  storyUsage: Scalars['Int']
  subscriptions: Array<SubscriptionItems>
  upcomingInvoice?: Maybe<Invoice>
}

export type Token = {
  __typename?: 'Token'
  accessToken: Scalars['String']
  user: User
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPrevPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
}

export type PriceEdge = {
  __typename?: 'PriceEdge'
  cursor: Scalars['String']
  node: Price
}

export type Prices = {
  __typename?: 'Prices'
  edges: Array<PriceEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StoryLove = {
  __typename?: 'StoryLove'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  love?: Maybe<Scalars['Int']>
  story?: Maybe<Story>
  user?: Maybe<User>
}

export type Story = {
  __typename?: 'Story'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  archived: Scalars['Boolean']
  canDelete: Scalars['Boolean']
  canLove: Scalars['Boolean']
  canUpdate: Scalars['Boolean']
  createdBy?: Maybe<User>
  need: Scalars['String']
  project: Project
  reason: Scalars['String']
  role: Scalars['String']
  storyLove: Array<StoryLove>
}

export type Project = {
  __typename?: 'Project'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  canAddUser: Scalars['Boolean']
  canAddUserRoles: Array<ProjectPermission>
  canCreateStory: Scalars['Boolean']
  canDelete: Scalars['Boolean']
  canUpdate: Scalars['Boolean']
  name: Scalars['String']
  projectUsers: Array<ProjectUser>
  stories: Array<Story>
  storyRoles: Array<Scalars['String']>
}

export enum ProjectPermission {
  Admin = 'ADMIN',
  Write = 'WRITE',
  Read = 'READ',
}

export type ProjectUser = {
  __typename?: 'ProjectUser'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  acceptedAt?: Maybe<Scalars['DateTime']>
  canRemove: Scalars['Boolean']
  canUpdate: Scalars['Boolean']
  permission?: Maybe<ProjectPermission>
  project?: Maybe<Project>
  user?: Maybe<User>
}

export type StoryEdge = {
  __typename?: 'StoryEdge'
  cursor: Scalars['String']
  node: Story
}

export type Stories = {
  __typename?: 'Stories'
  edges: Array<StoryEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProjectEdge = {
  __typename?: 'ProjectEdge'
  cursor: Scalars['String']
  node: Project
}

export type Projects = {
  __typename?: 'Projects'
  edges: Array<ProjectEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  currentUser?: Maybe<User>
  prices: Prices
  project: Project
  projects: Projects
  stories: Stories
  story: Story
  token?: Maybe<Token>
}

export type QueryPricesArgs = {
  after?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
}

export type QueryProjectArgs = {
  id: Scalars['String']
}

export type QueryProjectsArgs = {
  sort?: Maybe<Sort>
  after?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
}

export type QueryStoriesArgs = {
  after?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  archived?: Maybe<Scalars['Boolean']>
  createdBy?: Maybe<Array<Scalars['String']>>
  lovedBy?: Maybe<Array<Scalars['String']>>
  projectId?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Scalars['String']>>
  search?: Maybe<Scalars['String']>
  sort?: Maybe<SortStories>
}

export type QueryStoryArgs = {
  id: Scalars['String']
}

export enum Sort {
  Name = 'Name',
  CreatedAt = 'CreatedAt',
}

export enum SortStories {
  Love = 'Love',
  Recent = 'Recent',
}

export type Mutation = {
  __typename?: 'Mutation'
  cancelSubscription: User
  createPaymentMethod: User
  createSubscription: User
  deleteUser: User
  forgotPassword: Scalars['Boolean']
  login: Token
  resendEmailVerification: User
  resetPassword: Token
  signUp: Token
  updateSubscriptionItem: User
  updateUser: User
  verifyEmail: User
  createProject: Project
  deleteProject: Project
  updateProject: Project
  addProjectUser: ProjectUser
  addProjectUserResponse: ProjectUser
  removeProjectUser: ProjectUser
  updateProjectUser: ProjectUser
  createStory: Story
  deleteStory: Story
  importStories: Stories
  updateStory: Story
  loveStory: StoryLove
}

export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['String']
}

export type MutationCreatePaymentMethodArgs = {
  stripePaymentMethodId: Scalars['String']
}

export type MutationCreateSubscriptionArgs = {
  stripePaymentMethodId: Scalars['String']
  priceId: Scalars['String']
  quantity: Scalars['Int']
}

export type MutationDeleteUserArgs = {
  id: Scalars['String']
}

export type MutationForgotPasswordArgs = {
  email: Scalars['String']
}

export type MutationLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationResetPasswordArgs = {
  email: Scalars['String']
  password: Scalars['String']
  verificationCode: Scalars['String']
}

export type MutationSignUpArgs = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type MutationUpdateSubscriptionItemArgs = {
  priceId: Scalars['String']
  quantity: Scalars['Int']
  subscriptionItemId: Scalars['String']
}

export type MutationUpdateUserArgs = {
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type MutationVerifyEmailArgs = {
  verificationCode: Scalars['String']
}

export type MutationCreateProjectArgs = {
  id?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type MutationDeleteProjectArgs = {
  id: Scalars['String']
}

export type MutationUpdateProjectArgs = {
  name?: Maybe<Scalars['String']>
  id: Scalars['String']
}

export type MutationAddProjectUserArgs = {
  email: Scalars['String']
  id: Scalars['String']
  permission: ProjectPermission
  projectId: Scalars['String']
}

export type MutationAddProjectUserResponseArgs = {
  accept: Scalars['Boolean']
  projectUserId: Scalars['String']
}

export type MutationRemoveProjectUserArgs = {
  id: Scalars['String']
}

export type MutationUpdateProjectUserArgs = {
  id: Scalars['String']
  permission?: Maybe<ProjectPermission>
}

export type MutationCreateStoryArgs = {
  archived?: Maybe<Scalars['Boolean']>
  id: Scalars['String']
  need: Scalars['String']
  projectId: Scalars['String']
  reason: Scalars['String']
  role: Scalars['String']
}

export type MutationDeleteStoryArgs = {
  id: Scalars['String']
}

export type MutationImportStoriesArgs = {
  file: Scalars['CSV']
  projectId: Scalars['String']
  after?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
}

export type MutationUpdateStoryArgs = {
  id: Scalars['String']
  archived?: Maybe<Scalars['Boolean']>
  need?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
}

export type MutationLoveStoryArgs = {
  id: Scalars['String']
  amount: Scalars['Int']
}

export type ProjectFragment = {
  __typename?: 'Project'
  id: string
  name: string
}

export type StoryFragment = {
  __typename?: 'Story'
  id: string
  need: string
  reason: string
  role: string
}

export type StoryLoveFragment = {
  __typename?: 'Story'
  id: string
  storyLove: Array<{
    __typename?: 'StoryLove'
    id: string
    love?: Maybe<number>
    user?: Maybe<{ __typename?: 'User'; id: string }>
  }>
}

export type UserFragment = {
  __typename?: 'User'
  id: string
  email?: Maybe<string>
  firstName?: Maybe<string>
  lastName?: Maybe<string>
}

export type UserDefaultPaymentMethodFragment = {
  __typename?: 'User'
  defaultPaymentMethod?: Maybe<{
    __typename?: 'PaymentMethod'
    id: string
    brand?: Maybe<string>
    expiry?: Maybe<string>
    last4?: Maybe<string>
  }>
}

export type UserSubscriptionsFragment = {
  __typename?: 'User'
  subscriptions: Array<{
    __typename?: 'SubscriptionItems'
    id: string
    cancelAtPeriodEnd?: Maybe<boolean>
    currentPeriodEnd?: Maybe<string>
    endedAt?: Maybe<string>
    items: Array<{
      __typename?: 'SubscriptionItem'
      id: string
      quantity?: Maybe<number>
      price?: Maybe<{
        __typename?: 'Price'
        id: string
        unitAmount: number
        product: { __typename?: 'Product'; id: string; name: string }
      }>
    }>
  }>
}

export type UserUpcomingInvoiceFragment = {
  __typename?: 'User'
  upcomingInvoice?: Maybe<{
    __typename?: 'Invoice'
    id: string
    amountDue?: Maybe<number>
    currency?: Maybe<string>
    periodEnd?: Maybe<string>
  }>
}

export type UserUsageFragment = {
  __typename?: 'User'
  id: string
  contributorQuota: number
  contributorUsage: number
  projectQuota: number
  projectUsage: number
  storyQuota: number
  storyUsage: number
}

export type AddProjectUserMutationVariables = {
  id: Scalars['String']
  email: Scalars['String']
  projectId: Scalars['String']
  permission: ProjectPermission
}

export type AddProjectUserMutation = {
  __typename?: 'Mutation'
  addProjectUser: {
    __typename?: 'ProjectUser'
    id: string
    acceptedAt?: Maybe<string>
    canRemove: boolean
    canUpdate: boolean
    permission?: Maybe<ProjectPermission>
    project?: Maybe<{ __typename?: 'Project'; id: string }>
    user?: Maybe<{
      __typename?: 'User'
      id: string
      displayName: string
      firstName?: Maybe<string>
      lastName?: Maybe<string>
    }>
  }
}

export type AddProjectUserResponseMutationVariables = {
  accept: Scalars['Boolean']
  projectUserId: Scalars['String']
}

export type AddProjectUserResponseMutation = {
  __typename?: 'Mutation'
  addProjectUserResponse: {
    __typename?: 'ProjectUser'
    id: string
    project?: Maybe<{ __typename?: 'Project'; id: string }>
  }
}

export type CancelSubscriptionMutationVariables = {
  subscriptionId: Scalars['String']
}

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation'
  cancelSubscription: {
    __typename?: 'User'
    id: string
  } & UserSubscriptionsFragment
}

export type CreatePaymentMethodMutationVariables = {
  stripePaymentMethodId: Scalars['String']
}

export type CreatePaymentMethodMutation = {
  __typename?: 'Mutation'
  createPaymentMethod: {
    __typename?: 'User'
    id: string
    defaultPaymentMethod?: Maybe<{
      __typename?: 'PaymentMethod'
      id: string
      brand?: Maybe<string>
      expiry?: Maybe<string>
      last4?: Maybe<string>
    }>
  }
}

export type CreateProjectMutationVariables = {
  id: Scalars['String']
  name: Scalars['String']
}

export type CreateProjectMutation = {
  __typename?: 'Mutation'
  createProject: {
    __typename?: 'Project'
    id: string
    canDelete: boolean
    canUpdate: boolean
    name: string
  }
}

export type CreateStoryMutationVariables = {
  id: Scalars['String']
  projectId: Scalars['String']
  role: Scalars['String']
  need: Scalars['String']
  reason: Scalars['String']
}

export type CreateStoryMutation = {
  __typename?: 'Mutation'
  createStory: {
    __typename?: 'Story'
    id: string
    archived: boolean
    canDelete: boolean
    canLove: boolean
    canUpdate: boolean
    need: string
    reason: string
    role: string
    createdBy?: Maybe<{
      __typename?: 'User'
      id: string
      displayName: string
      firstName?: Maybe<string>
      lastName?: Maybe<string>
    }>
    storyLove: Array<{
      __typename?: 'StoryLove'
      id: string
      love?: Maybe<number>
      user?: Maybe<{
        __typename?: 'User'
        id: string
        firstName?: Maybe<string>
        lastName?: Maybe<string>
      }>
    }>
  }
}

export type CreateSubscriptionMutationVariables = {
  priceId: Scalars['String']
  quantity: Scalars['Int']
  stripePaymentMethodId: Scalars['String']
}

export type CreateSubscriptionMutation = {
  __typename?: 'Mutation'
  createSubscription: {
    __typename?: 'User'
    id: string
  } & UserDefaultPaymentMethodFragment &
    UserSubscriptionsFragment &
    UserUpcomingInvoiceFragment &
    UserUsageFragment
}

export type DeleteProjectMutationVariables = {
  id: Scalars['String']
}

export type DeleteProjectMutation = {
  __typename?: 'Mutation'
  deleteProject: { __typename?: 'Project'; id: string }
}

export type DeleteStoryMutationVariables = {
  id: Scalars['String']
}

export type DeleteStoryMutation = {
  __typename?: 'Mutation'
  deleteStory: { __typename?: 'Story'; id: string }
}

export type ForgotPasswordMutationVariables = {
  email: Scalars['String']
}

export type ForgotPasswordMutation = {
  __typename?: 'Mutation'
  forgotPassword: boolean
}

export type ImportStoriesMutationVariables = {
  file: Scalars['CSV']
  projectId: Scalars['String']
}

export type ImportStoriesMutation = {
  __typename?: 'Mutation'
  importStories: { __typename?: 'Stories'; totalCount: number }
}

export type LoginMutationVariables = {
  email: Scalars['String']
  password: Scalars['String']
}

export type LoginMutation = {
  __typename?: 'Mutation'
  login: { __typename?: 'Token'; accessToken: string }
}

export type LoveStoryMutationVariables = {
  id: Scalars['String']
  amount: Scalars['Int']
}

export type LoveStoryMutation = {
  __typename?: 'Mutation'
  loveStory: {
    __typename?: 'StoryLove'
    id: string
    love?: Maybe<number>
    story?: Maybe<{ __typename?: 'Story'; id: string }>
  }
}

export type RemoveProjectUserMutationVariables = {
  id: Scalars['String']
}

export type RemoveProjectUserMutation = {
  __typename?: 'Mutation'
  removeProjectUser: { __typename?: 'ProjectUser'; id: string }
}

export type ResendEmailVerificationMutationVariables = {}

export type ResendEmailVerificationMutation = {
  __typename?: 'Mutation'
  resendEmailVerification: { __typename?: 'User'; id: string }
}

export type ResetPasswordMutationVariables = {
  email: Scalars['String']
  password: Scalars['String']
  verificationCode: Scalars['String']
}

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword: { __typename?: 'Token'; accessToken: string }
}

export type SignUpMutationVariables = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type SignUpMutation = {
  __typename?: 'Mutation'
  signUp: { __typename?: 'Token'; accessToken: string }
}

export type UpdateProjectMutationVariables = {
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type UpdateProjectMutation = {
  __typename?: 'Mutation'
  updateProject: { __typename?: 'Project'; id: string; name: string }
}

export type UpdateProjectUserMutationVariables = {
  id: Scalars['String']
  permission?: Maybe<ProjectPermission>
}

export type UpdateProjectUserMutation = {
  __typename?: 'Mutation'
  updateProjectUser: {
    __typename?: 'ProjectUser'
    id: string
    permission?: Maybe<ProjectPermission>
  }
}

export type UpdateStoryMutationVariables = {
  id: Scalars['String']
  archived?: Maybe<Scalars['Boolean']>
  role?: Maybe<Scalars['String']>
  need?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}

export type UpdateStoryMutation = {
  __typename?: 'Mutation'
  updateStory: {
    __typename?: 'Story'
    id: string
    archived: boolean
    need: string
    reason: string
    role: string
    updatedAt?: Maybe<string>
  }
}

export type UpdateSubscriptionItemMutationVariables = {
  subscriptionItemId: Scalars['String']
  priceId: Scalars['String']
  quantity: Scalars['Int']
}

export type UpdateSubscriptionItemMutation = {
  __typename?: 'Mutation'
  updateSubscriptionItem: {
    __typename?: 'User'
    id: string
  } & UserDefaultPaymentMethodFragment &
    UserSubscriptionsFragment &
    UserUpcomingInvoiceFragment &
    UserUsageFragment
}

export type UpdateUserMutationVariables = {
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'User'
    id: string
    firstName?: Maybe<string>
    lastName?: Maybe<string>
    email?: Maybe<string>
  }
}

export type VerifyEmailMutationVariables = {
  verificationCode: Scalars['String']
}

export type VerifyEmailMutation = {
  __typename?: 'Mutation'
  verifyEmail: {
    __typename?: 'User'
    id: string
    emailVerified?: Maybe<boolean>
  }
}

export type CurrentUserQueryVariables = {}

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{
    __typename?: 'User'
    id: string
    canCreateProject?: Maybe<boolean>
    email?: Maybe<string>
    emailVerified?: Maybe<boolean>
    firstName?: Maybe<string>
    lastName?: Maybe<string>
  }>
}

export type CurrentUserBillingQueryVariables = {}

export type CurrentUserBillingQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<
    { __typename?: 'User'; id: string } & UserDefaultPaymentMethodFragment &
      UserSubscriptionsFragment &
      UserUpcomingInvoiceFragment
  >
}

export type CurrentUserUsageQueryVariables = {}

export type CurrentUserUsageQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{ __typename?: 'User'; id: string } & UserUsageFragment>
}

export type PricesQueryVariables = {}

export type PricesQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<
    {
      __typename?: 'User'
      id: string
      subscriptions: Array<{
        __typename?: 'SubscriptionItems'
        id: string
        cancelAtPeriodEnd?: Maybe<boolean>
        currentPeriodEnd?: Maybe<string>
        items: Array<{
          __typename?: 'SubscriptionItem'
          id: string
          quantity?: Maybe<number>
          price?: Maybe<{
            __typename?: 'Price'
            id: string
            unitAmount: number
            product: { __typename?: 'Product'; id: string }
          }>
        }>
      }>
    } & UserDefaultPaymentMethodFragment
  >
  prices: {
    __typename?: 'Prices'
    edges: Array<{
      __typename?: 'PriceEdge'
      node: {
        __typename?: 'Price'
        id: string
        currency: string
        hasQuantity: boolean
        interval: string
        maxQuantity?: Maybe<number>
        minQuantity: number
        unitAmount: number
        product: {
          __typename?: 'Product'
          id: string
          description: string
          hasSubscription: boolean
          name: string
          contributorQuotaBase: number
          contributorQuotaPerSeat?: Maybe<number>
          projectQuotaBase: number
          projectQuotaPerSeat?: Maybe<number>
          quotaDescription: string
          storyQuotaBase: number
          storyQuotaPerSeat?: Maybe<number>
        }
        tiers?: Maybe<
          Array<{
            __typename?: 'PriceTier'
            from?: Maybe<number>
            unitAmount: number
            upTo?: Maybe<number>
          }>
        >
      }
    }>
  }
}

export type ProjectQueryVariables = {
  id: Scalars['String']
}

export type ProjectQuery = {
  __typename?: 'Query'
  project: {
    __typename?: 'Project'
    id: string
    canAddUser: boolean
    canAddUserRoles: Array<ProjectPermission>
    canCreateStory: boolean
    name: string
  }
}

export type ProjectStoryRolesQueryVariables = {
  id: Scalars['String']
}

export type ProjectStoryRolesQuery = {
  __typename?: 'Query'
  project: { __typename?: 'Project'; id: string; storyRoles: Array<string> }
}

export type ProjectUsersQueryVariables = {
  id: Scalars['String']
}

export type ProjectUsersQuery = {
  __typename?: 'Query'
  project: {
    __typename?: 'Project'
    id: string
    projectUsers: Array<{
      __typename?: 'ProjectUser'
      id: string
      acceptedAt?: Maybe<string>
      canRemove: boolean
      canUpdate: boolean
      permission?: Maybe<ProjectPermission>
      user?: Maybe<{
        __typename?: 'User'
        id: string
        displayName: string
        firstName?: Maybe<string>
        lastName?: Maybe<string>
      }>
    }>
  }
}

export type ProjectsQueryVariables = {
  after?: Maybe<Scalars['String']>
  first: Scalars['Int']
  sort?: Maybe<Sort>
}

export type ProjectsQuery = {
  __typename?: 'Query'
  projects: {
    __typename?: 'Projects'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: Maybe<string>
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'ProjectEdge'
      cursor: string
      node: {
        __typename?: 'Project'
        id: string
        canDelete: boolean
        canUpdate: boolean
        name: string
      }
    }>
  }
}

export type ProjectsCountQueryVariables = {}

export type ProjectsCountQuery = {
  __typename?: 'Query'
  projects: { __typename?: 'Projects'; totalCount: number }
}

export type StoriesQueryVariables = {
  after?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  createdBy?: Maybe<Array<Scalars['String']>>
  first: Scalars['Int']
  lovedBy?: Maybe<Array<Scalars['String']>>
  projectId?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Scalars['String']>>
  search?: Maybe<Scalars['String']>
  sort?: Maybe<SortStories>
}

export type StoriesQuery = {
  __typename?: 'Query'
  stories: {
    __typename?: 'Stories'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: Maybe<string>
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'StoryEdge'
      cursor: string
      node: {
        __typename?: 'Story'
        id: string
        archived: boolean
        canDelete: boolean
        canLove: boolean
        canUpdate: boolean
        need: string
        reason: string
        role: string
        updatedAt?: Maybe<string>
        createdBy?: Maybe<{
          __typename?: 'User'
          id: string
          displayName: string
          firstName?: Maybe<string>
          lastName?: Maybe<string>
        }>
        storyLove: Array<{
          __typename?: 'StoryLove'
          id: string
          love?: Maybe<number>
          user?: Maybe<{
            __typename?: 'User'
            id: string
            firstName?: Maybe<string>
            lastName?: Maybe<string>
          }>
        }>
      }
    }>
  }
}

export type StoryQueryVariables = {
  id: Scalars['String']
}

export type StoryQuery = {
  __typename?: 'Query'
  story: {
    __typename?: 'Story'
    id: string
    archived: boolean
    need: string
    reason: string
    role: string
  }
}

export type TokenQueryVariables = {}

export type TokenQuery = {
  __typename?: 'Query'
  token?: Maybe<{ __typename?: 'Token'; accessToken: string }>
}

export const ProjectFragmentDoc = gql`
  fragment Project on Project {
    id
    name
  }
`
export const StoryFragmentDoc = gql`
  fragment Story on Story {
    id
    need
    reason
    role
  }
`
export const StoryLoveFragmentDoc = gql`
  fragment StoryLove on Story {
    id
    storyLove {
      id
      love
      user {
        id
      }
    }
  }
`
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    firstName
    lastName
  }
`
export const UserDefaultPaymentMethodFragmentDoc = gql`
  fragment UserDefaultPaymentMethod on User {
    defaultPaymentMethod {
      id
      brand
      expiry
      last4
    }
  }
`
export const UserSubscriptionsFragmentDoc = gql`
  fragment UserSubscriptions on User {
    subscriptions {
      id
      cancelAtPeriodEnd
      currentPeriodEnd
      endedAt
      items {
        id
        price {
          id
          unitAmount
          product {
            id
            name
          }
        }
        quantity
      }
    }
  }
`
export const UserUpcomingInvoiceFragmentDoc = gql`
  fragment UserUpcomingInvoice on User {
    upcomingInvoice {
      id
      amountDue
      currency
      periodEnd
    }
  }
`
export const UserUsageFragmentDoc = gql`
  fragment UserUsage on User {
    id
    contributorQuota
    contributorUsage
    projectQuota
    projectUsage
    storyQuota
    storyUsage
  }
`
export const AddProjectUserDocument = gql`
  mutation addProjectUser(
    $id: String!
    $email: String!
    $projectId: String!
    $permission: ProjectPermission!
  ) {
    addProjectUser(
      id: $id
      email: $email
      projectId: $projectId
      permission: $permission
    ) {
      id
      acceptedAt
      canRemove
      canUpdate
      permission
      project {
        id
      }
      user {
        id
        displayName
        firstName
        lastName
      }
    }
  }
`
export type AddProjectUserMutationFn = ApolloReactCommon.MutationFunction<
  AddProjectUserMutation,
  AddProjectUserMutationVariables
>

/**
 * __useAddProjectUserMutation__
 *
 * To run a mutation, you first call `useAddProjectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectUserMutation, { data, loading, error }] = useAddProjectUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      projectId: // value for 'projectId'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useAddProjectUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddProjectUserMutation,
    AddProjectUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddProjectUserMutation,
    AddProjectUserMutationVariables
  >(AddProjectUserDocument, baseOptions)
}
export type AddProjectUserMutationHookResult = ReturnType<
  typeof useAddProjectUserMutation
>
export type AddProjectUserMutationResult = ApolloReactCommon.MutationResult<
  AddProjectUserMutation
>
export type AddProjectUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddProjectUserMutation,
  AddProjectUserMutationVariables
>
export const AddProjectUserResponseDocument = gql`
  mutation addProjectUserResponse($accept: Boolean!, $projectUserId: String!) {
    addProjectUserResponse(accept: $accept, projectUserId: $projectUserId) {
      id
      project {
        id
      }
    }
  }
`
export type AddProjectUserResponseMutationFn = ApolloReactCommon.MutationFunction<
  AddProjectUserResponseMutation,
  AddProjectUserResponseMutationVariables
>

/**
 * __useAddProjectUserResponseMutation__
 *
 * To run a mutation, you first call `useAddProjectUserResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectUserResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectUserResponseMutation, { data, loading, error }] = useAddProjectUserResponseMutation({
 *   variables: {
 *      accept: // value for 'accept'
 *      projectUserId: // value for 'projectUserId'
 *   },
 * });
 */
export function useAddProjectUserResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddProjectUserResponseMutation,
    AddProjectUserResponseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddProjectUserResponseMutation,
    AddProjectUserResponseMutationVariables
  >(AddProjectUserResponseDocument, baseOptions)
}
export type AddProjectUserResponseMutationHookResult = ReturnType<
  typeof useAddProjectUserResponseMutation
>
export type AddProjectUserResponseMutationResult = ApolloReactCommon.MutationResult<
  AddProjectUserResponseMutation
>
export type AddProjectUserResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddProjectUserResponseMutation,
  AddProjectUserResponseMutationVariables
>
export const CancelSubscriptionDocument = gql`
  mutation cancelSubscription($subscriptionId: String!) {
    cancelSubscription(subscriptionId: $subscriptionId) {
      id
      ...UserSubscriptions
    }
  }
  ${UserSubscriptionsFragmentDoc}
`
export type CancelSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, baseOptions)
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>
export type CancelSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  CancelSubscriptionMutation
>
export type CancelSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>
export const CreatePaymentMethodDocument = gql`
  mutation createPaymentMethod($stripePaymentMethodId: String!) {
    createPaymentMethod(stripePaymentMethodId: $stripePaymentMethodId) {
      id
      defaultPaymentMethod {
        id
        brand
        expiry
        last4
      }
    }
  }
`
export type CreatePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >(CreatePaymentMethodDocument, baseOptions)
}
export type CreatePaymentMethodMutationHookResult = ReturnType<
  typeof useCreatePaymentMethodMutation
>
export type CreatePaymentMethodMutationResult = ApolloReactCommon.MutationResult<
  CreatePaymentMethodMutation
>
export type CreatePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>
export const CreateProjectDocument = gql`
  mutation createProject($id: String!, $name: String!) {
    createProject(id: $id, name: $name) {
      id
      canDelete
      canUpdate
      name
    }
  }
`
export type CreateProjectMutationFn = ApolloReactCommon.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, baseOptions)
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<
  CreateProjectMutation
>
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>
export const CreateStoryDocument = gql`
  mutation createStory(
    $id: String!
    $projectId: String!
    $role: String!
    $need: String!
    $reason: String!
  ) {
    createStory(
      id: $id
      projectId: $projectId
      role: $role
      need: $need
      reason: $reason
    ) {
      id
      archived
      canDelete
      canLove
      canUpdate
      createdBy {
        id
        displayName
        firstName
        lastName
      }
      need
      reason
      role
      storyLove {
        id
        love
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`
export type CreateStoryMutationFn = ApolloReactCommon.MutationFunction<
  CreateStoryMutation,
  CreateStoryMutationVariables
>

/**
 * __useCreateStoryMutation__
 *
 * To run a mutation, you first call `useCreateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryMutation, { data, loading, error }] = useCreateStoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *      role: // value for 'role'
 *      need: // value for 'need'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateStoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStoryMutation,
    CreateStoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateStoryMutation,
    CreateStoryMutationVariables
  >(CreateStoryDocument, baseOptions)
}
export type CreateStoryMutationHookResult = ReturnType<
  typeof useCreateStoryMutation
>
export type CreateStoryMutationResult = ApolloReactCommon.MutationResult<
  CreateStoryMutation
>
export type CreateStoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStoryMutation,
  CreateStoryMutationVariables
>
export const CreateSubscriptionDocument = gql`
  mutation createSubscription(
    $priceId: String!
    $quantity: Int!
    $stripePaymentMethodId: String!
  ) {
    createSubscription(
      priceId: $priceId
      quantity: $quantity
      stripePaymentMethodId: $stripePaymentMethodId
    ) {
      id
      ...UserDefaultPaymentMethod
      ...UserSubscriptions
      ...UserUpcomingInvoice
      ...UserUsage
    }
  }
  ${UserDefaultPaymentMethodFragmentDoc}
  ${UserSubscriptionsFragmentDoc}
  ${UserUpcomingInvoiceFragmentDoc}
  ${UserUsageFragmentDoc}
`
export type CreateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      priceId: // value for 'priceId'
 *      quantity: // value for 'quantity'
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(CreateSubscriptionDocument, baseOptions)
}
export type CreateSubscriptionMutationHookResult = ReturnType<
  typeof useCreateSubscriptionMutation
>
export type CreateSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  CreateSubscriptionMutation
>
export type CreateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: String!) {
    deleteProject(id: $id) {
      id
    }
  }
`
export type DeleteProjectMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, baseOptions)
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<
  DeleteProjectMutation
>
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>
export const DeleteStoryDocument = gql`
  mutation deleteStory($id: String!) {
    deleteStory(id: $id) {
      id
    }
  }
`
export type DeleteStoryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteStoryMutation,
  DeleteStoryMutationVariables
>

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteStoryMutation,
    DeleteStoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteStoryMutation,
    DeleteStoryMutationVariables
  >(DeleteStoryDocument, baseOptions)
}
export type DeleteStoryMutationHookResult = ReturnType<
  typeof useDeleteStoryMutation
>
export type DeleteStoryMutationResult = ApolloReactCommon.MutationResult<
  DeleteStoryMutation
>
export type DeleteStoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteStoryMutation,
  DeleteStoryMutationVariables
>
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, baseOptions)
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<
  ForgotPasswordMutation
>
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>
export const ImportStoriesDocument = gql`
  mutation importStories($file: CSV!, $projectId: String!) {
    importStories(file: $file, projectId: $projectId) {
      totalCount
    }
  }
`
export type ImportStoriesMutationFn = ApolloReactCommon.MutationFunction<
  ImportStoriesMutation,
  ImportStoriesMutationVariables
>

/**
 * __useImportStoriesMutation__
 *
 * To run a mutation, you first call `useImportStoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportStoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importStoriesMutation, { data, loading, error }] = useImportStoriesMutation({
 *   variables: {
 *      file: // value for 'file'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useImportStoriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportStoriesMutation,
    ImportStoriesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ImportStoriesMutation,
    ImportStoriesMutationVariables
  >(ImportStoriesDocument, baseOptions)
}
export type ImportStoriesMutationHookResult = ReturnType<
  typeof useImportStoriesMutation
>
export type ImportStoriesMutationResult = ApolloReactCommon.MutationResult<
  ImportStoriesMutation
>
export type ImportStoriesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportStoriesMutation,
  ImportStoriesMutationVariables
>
export const LoginDocument = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
      @connection(key: "login", filter: ["email"]) {
      accessToken
    }
  }
`
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = ApolloReactCommon.MutationResult<
  LoginMutation
>
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
export const LoveStoryDocument = gql`
  mutation loveStory($id: String!, $amount: Int!) {
    loveStory(id: $id, amount: $amount) {
      id
      love
      story {
        id
      }
    }
  }
`
export type LoveStoryMutationFn = ApolloReactCommon.MutationFunction<
  LoveStoryMutation,
  LoveStoryMutationVariables
>

/**
 * __useLoveStoryMutation__
 *
 * To run a mutation, you first call `useLoveStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoveStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loveStoryMutation, { data, loading, error }] = useLoveStoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useLoveStoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoveStoryMutation,
    LoveStoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    LoveStoryMutation,
    LoveStoryMutationVariables
  >(LoveStoryDocument, baseOptions)
}
export type LoveStoryMutationHookResult = ReturnType<
  typeof useLoveStoryMutation
>
export type LoveStoryMutationResult = ApolloReactCommon.MutationResult<
  LoveStoryMutation
>
export type LoveStoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoveStoryMutation,
  LoveStoryMutationVariables
>
export const RemoveProjectUserDocument = gql`
  mutation removeProjectUser($id: String!) {
    removeProjectUser(id: $id) {
      id
    }
  }
`
export type RemoveProjectUserMutationFn = ApolloReactCommon.MutationFunction<
  RemoveProjectUserMutation,
  RemoveProjectUserMutationVariables
>

/**
 * __useRemoveProjectUserMutation__
 *
 * To run a mutation, you first call `useRemoveProjectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectUserMutation, { data, loading, error }] = useRemoveProjectUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProjectUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveProjectUserMutation,
    RemoveProjectUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveProjectUserMutation,
    RemoveProjectUserMutationVariables
  >(RemoveProjectUserDocument, baseOptions)
}
export type RemoveProjectUserMutationHookResult = ReturnType<
  typeof useRemoveProjectUserMutation
>
export type RemoveProjectUserMutationResult = ApolloReactCommon.MutationResult<
  RemoveProjectUserMutation
>
export type RemoveProjectUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveProjectUserMutation,
  RemoveProjectUserMutationVariables
>
export const ResendEmailVerificationDocument = gql`
  mutation resendEmailVerification {
    resendEmailVerification {
      id
    }
  }
`
export type ResendEmailVerificationMutationFn = ApolloReactCommon.MutationFunction<
  ResendEmailVerificationMutation,
  ResendEmailVerificationMutationVariables
>

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendEmailVerificationMutation,
    ResendEmailVerificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResendEmailVerificationMutation,
    ResendEmailVerificationMutationVariables
  >(ResendEmailVerificationDocument, baseOptions)
}
export type ResendEmailVerificationMutationHookResult = ReturnType<
  typeof useResendEmailVerificationMutation
>
export type ResendEmailVerificationMutationResult = ApolloReactCommon.MutationResult<
  ResendEmailVerificationMutation
>
export type ResendEmailVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendEmailVerificationMutation,
  ResendEmailVerificationMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation resetPassword(
    $email: String!
    $password: String!
    $verificationCode: String!
  ) {
    resetPassword(
      email: $email
      password: $password
      verificationCode: $verificationCode
    ) @connection(key: "resetPassword", filter: ["email"]) {
      accessToken
    }
  }
`
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, baseOptions)
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<
  ResetPasswordMutation
>
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const SignUpDocument = gql`
  mutation signUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    signUp(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) @connection(key: "signUp", filter: ["email", "firstName", "lastName"]) {
      accessToken
    }
  }
`
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    baseOptions
  )
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = ApolloReactCommon.MutationResult<
  SignUpMutation
>
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>
export const UpdateProjectDocument = gql`
  mutation updateProject($id: String!, $name: String) {
    updateProject(id: $id, name: $name) {
      id
      name
    }
  }
`
export type UpdateProjectMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, baseOptions)
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>
export type UpdateProjectMutationResult = ApolloReactCommon.MutationResult<
  UpdateProjectMutation
>
export type UpdateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>
export const UpdateProjectUserDocument = gql`
  mutation updateProjectUser($id: String!, $permission: ProjectPermission) {
    updateProjectUser(id: $id, permission: $permission) {
      id
      permission
    }
  }
`
export type UpdateProjectUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProjectUserMutation,
  UpdateProjectUserMutationVariables
>

/**
 * __useUpdateProjectUserMutation__
 *
 * To run a mutation, you first call `useUpdateProjectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectUserMutation, { data, loading, error }] = useUpdateProjectUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useUpdateProjectUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProjectUserMutation,
    UpdateProjectUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProjectUserMutation,
    UpdateProjectUserMutationVariables
  >(UpdateProjectUserDocument, baseOptions)
}
export type UpdateProjectUserMutationHookResult = ReturnType<
  typeof useUpdateProjectUserMutation
>
export type UpdateProjectUserMutationResult = ApolloReactCommon.MutationResult<
  UpdateProjectUserMutation
>
export type UpdateProjectUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProjectUserMutation,
  UpdateProjectUserMutationVariables
>
export const UpdateStoryDocument = gql`
  mutation updateStory(
    $id: String!
    $archived: Boolean
    $role: String
    $need: String
    $reason: String
  ) {
    updateStory(
      id: $id
      archived: $archived
      role: $role
      need: $need
      reason: $reason
    ) {
      id
      archived
      need
      reason
      role
      updatedAt
    }
  }
`
export type UpdateStoryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStoryMutation,
  UpdateStoryMutationVariables
>

/**
 * __useUpdateStoryMutation__
 *
 * To run a mutation, you first call `useUpdateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryMutation, { data, loading, error }] = useUpdateStoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archived: // value for 'archived'
 *      role: // value for 'role'
 *      need: // value for 'need'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useUpdateStoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStoryMutation,
    UpdateStoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateStoryMutation,
    UpdateStoryMutationVariables
  >(UpdateStoryDocument, baseOptions)
}
export type UpdateStoryMutationHookResult = ReturnType<
  typeof useUpdateStoryMutation
>
export type UpdateStoryMutationResult = ApolloReactCommon.MutationResult<
  UpdateStoryMutation
>
export type UpdateStoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStoryMutation,
  UpdateStoryMutationVariables
>
export const UpdateSubscriptionItemDocument = gql`
  mutation updateSubscriptionItem(
    $subscriptionItemId: String!
    $priceId: String!
    $quantity: Int!
  ) {
    updateSubscriptionItem(
      subscriptionItemId: $subscriptionItemId
      priceId: $priceId
      quantity: $quantity
    ) {
      id
      ...UserDefaultPaymentMethod
      ...UserSubscriptions
      ...UserUpcomingInvoice
      ...UserUsage
    }
  }
  ${UserDefaultPaymentMethodFragmentDoc}
  ${UserSubscriptionsFragmentDoc}
  ${UserUpcomingInvoiceFragmentDoc}
  ${UserUsageFragmentDoc}
`
export type UpdateSubscriptionItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSubscriptionItemMutation,
  UpdateSubscriptionItemMutationVariables
>

/**
 * __useUpdateSubscriptionItemMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionItemMutation, { data, loading, error }] = useUpdateSubscriptionItemMutation({
 *   variables: {
 *      subscriptionItemId: // value for 'subscriptionItemId'
 *      priceId: // value for 'priceId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateSubscriptionItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSubscriptionItemMutation,
    UpdateSubscriptionItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSubscriptionItemMutation,
    UpdateSubscriptionItemMutationVariables
  >(UpdateSubscriptionItemDocument, baseOptions)
}
export type UpdateSubscriptionItemMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionItemMutation
>
export type UpdateSubscriptionItemMutationResult = ApolloReactCommon.MutationResult<
  UpdateSubscriptionItemMutation
>
export type UpdateSubscriptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSubscriptionItemMutation,
  UpdateSubscriptionItemMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser(
    $email: String
    $firstName: String
    $lastName: String
    $password: String
  ) @connection(key: "updateUser", filter: ["email", "firstName", "lastName"]) {
    updateUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      id
      firstName
      lastName
      email
    }
  }
`
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, baseOptions)
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserMutation
>
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const VerifyEmailDocument = gql`
  mutation verifyEmail($verificationCode: String!) {
    verifyEmail(verificationCode: $verificationCode) {
      id
      emailVerified
    }
  }
`
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >(VerifyEmailDocument, baseOptions)
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<
  VerifyEmailMutation
>
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      id
      canCreateProject
      email
      emailVerified
      firstName
      lastName
    }
  }
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions
  )
}
export function useCurrentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >(CurrentUserDocument, baseOptions)
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>
export const CurrentUserBillingDocument = gql`
  query currentUserBilling {
    currentUser {
      id
      ...UserDefaultPaymentMethod
      ...UserSubscriptions
      ...UserUpcomingInvoice
    }
  }
  ${UserDefaultPaymentMethodFragmentDoc}
  ${UserSubscriptionsFragmentDoc}
  ${UserUpcomingInvoiceFragmentDoc}
`

/**
 * __useCurrentUserBillingQuery__
 *
 * To run a query within a React component, call `useCurrentUserBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserBillingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserBillingQuery,
    CurrentUserBillingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CurrentUserBillingQuery,
    CurrentUserBillingQueryVariables
  >(CurrentUserBillingDocument, baseOptions)
}
export function useCurrentUserBillingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserBillingQuery,
    CurrentUserBillingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CurrentUserBillingQuery,
    CurrentUserBillingQueryVariables
  >(CurrentUserBillingDocument, baseOptions)
}
export type CurrentUserBillingQueryHookResult = ReturnType<
  typeof useCurrentUserBillingQuery
>
export type CurrentUserBillingLazyQueryHookResult = ReturnType<
  typeof useCurrentUserBillingLazyQuery
>
export type CurrentUserBillingQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserBillingQuery,
  CurrentUserBillingQueryVariables
>
export const CurrentUserUsageDocument = gql`
  query currentUserUsage {
    currentUser {
      id
      ...UserUsage
    }
  }
  ${UserUsageFragmentDoc}
`

/**
 * __useCurrentUserUsageQuery__
 *
 * To run a query within a React component, call `useCurrentUserUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserUsageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserUsageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserUsageQuery,
    CurrentUserUsageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CurrentUserUsageQuery,
    CurrentUserUsageQueryVariables
  >(CurrentUserUsageDocument, baseOptions)
}
export function useCurrentUserUsageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserUsageQuery,
    CurrentUserUsageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CurrentUserUsageQuery,
    CurrentUserUsageQueryVariables
  >(CurrentUserUsageDocument, baseOptions)
}
export type CurrentUserUsageQueryHookResult = ReturnType<
  typeof useCurrentUserUsageQuery
>
export type CurrentUserUsageLazyQueryHookResult = ReturnType<
  typeof useCurrentUserUsageLazyQuery
>
export type CurrentUserUsageQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserUsageQuery,
  CurrentUserUsageQueryVariables
>
export const PricesDocument = gql`
  query prices {
    currentUser {
      id
      ...UserDefaultPaymentMethod
      subscriptions {
        id
        cancelAtPeriodEnd
        currentPeriodEnd
        items {
          id
          price {
            id
            product {
              id
            }
            unitAmount
          }
          quantity
        }
      }
    }
    prices {
      edges {
        node {
          id
          currency
          hasQuantity
          interval
          maxQuantity
          minQuantity
          product {
            id
            description
            hasSubscription
            name
            contributorQuotaBase
            contributorQuotaPerSeat
            projectQuotaBase
            projectQuotaPerSeat
            quotaDescription
            storyQuotaBase
            storyQuotaPerSeat
          }
          tiers {
            from
            unitAmount
            upTo
          }
          unitAmount
        }
      }
    }
  }
  ${UserDefaultPaymentMethodFragmentDoc}
`

/**
 * __usePricesQuery__
 *
 * To run a query within a React component, call `usePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PricesQuery,
    PricesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PricesQuery, PricesQueryVariables>(
    PricesDocument,
    baseOptions
  )
}
export function usePricesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PricesQuery,
    PricesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PricesQuery, PricesQueryVariables>(
    PricesDocument,
    baseOptions
  )
}
export type PricesQueryHookResult = ReturnType<typeof usePricesQuery>
export type PricesLazyQueryHookResult = ReturnType<typeof usePricesLazyQuery>
export type PricesQueryResult = ApolloReactCommon.QueryResult<
  PricesQuery,
  PricesQueryVariables
>
export const ProjectDocument = gql`
  query project($id: String!) {
    project(id: $id) {
      id
      canAddUser
      canAddUserRoles
      canCreateStory
      name
    }
  }
`

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProjectQuery,
    ProjectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    baseOptions
  )
}
export function useProjectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProjectQuery,
    ProjectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    baseOptions
  )
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>
export type ProjectQueryResult = ApolloReactCommon.QueryResult<
  ProjectQuery,
  ProjectQueryVariables
>
export const ProjectStoryRolesDocument = gql`
  query projectStoryRoles($id: String!) {
    project(id: $id) {
      id
      storyRoles
    }
  }
`

/**
 * __useProjectStoryRolesQuery__
 *
 * To run a query within a React component, call `useProjectStoryRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStoryRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStoryRolesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectStoryRolesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProjectStoryRolesQuery,
    ProjectStoryRolesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ProjectStoryRolesQuery,
    ProjectStoryRolesQueryVariables
  >(ProjectStoryRolesDocument, baseOptions)
}
export function useProjectStoryRolesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProjectStoryRolesQuery,
    ProjectStoryRolesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ProjectStoryRolesQuery,
    ProjectStoryRolesQueryVariables
  >(ProjectStoryRolesDocument, baseOptions)
}
export type ProjectStoryRolesQueryHookResult = ReturnType<
  typeof useProjectStoryRolesQuery
>
export type ProjectStoryRolesLazyQueryHookResult = ReturnType<
  typeof useProjectStoryRolesLazyQuery
>
export type ProjectStoryRolesQueryResult = ApolloReactCommon.QueryResult<
  ProjectStoryRolesQuery,
  ProjectStoryRolesQueryVariables
>
export const ProjectUsersDocument = gql`
  query projectUsers($id: String!) {
    project(id: $id) {
      id
      projectUsers {
        id
        acceptedAt
        canRemove
        canUpdate
        permission
        user {
          id
          displayName
          firstName
          lastName
        }
      }
    }
  }
`

/**
 * __useProjectUsersQuery__
 *
 * To run a query within a React component, call `useProjectUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProjectUsersQuery,
    ProjectUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ProjectUsersQuery,
    ProjectUsersQueryVariables
  >(ProjectUsersDocument, baseOptions)
}
export function useProjectUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProjectUsersQuery,
    ProjectUsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ProjectUsersQuery,
    ProjectUsersQueryVariables
  >(ProjectUsersDocument, baseOptions)
}
export type ProjectUsersQueryHookResult = ReturnType<
  typeof useProjectUsersQuery
>
export type ProjectUsersLazyQueryHookResult = ReturnType<
  typeof useProjectUsersLazyQuery
>
export type ProjectUsersQueryResult = ApolloReactCommon.QueryResult<
  ProjectUsersQuery,
  ProjectUsersQueryVariables
>
export const ProjectsDocument = gql`
  query projects($after: String, $first: Int!, $sort: Sort) {
    projects(after: $after, first: $first, sort: $sort)
      @connection(key: "projects", filter: ["sort"]) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          canDelete
          canUpdate
          name
        }
      }
    }
  }
`

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProjectsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProjectsQuery,
    ProjectsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    baseOptions
  )
}
export function useProjectsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProjectsQuery,
    ProjectsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    baseOptions
  )
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>
export type ProjectsLazyQueryHookResult = ReturnType<
  typeof useProjectsLazyQuery
>
export type ProjectsQueryResult = ApolloReactCommon.QueryResult<
  ProjectsQuery,
  ProjectsQueryVariables
>
export const ProjectsCountDocument = gql`
  query projectsCount {
    projects {
      totalCount
    }
  }
`

/**
 * __useProjectsCountQuery__
 *
 * To run a query within a React component, call `useProjectsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProjectsCountQuery,
    ProjectsCountQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ProjectsCountQuery,
    ProjectsCountQueryVariables
  >(ProjectsCountDocument, baseOptions)
}
export function useProjectsCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProjectsCountQuery,
    ProjectsCountQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ProjectsCountQuery,
    ProjectsCountQueryVariables
  >(ProjectsCountDocument, baseOptions)
}
export type ProjectsCountQueryHookResult = ReturnType<
  typeof useProjectsCountQuery
>
export type ProjectsCountLazyQueryHookResult = ReturnType<
  typeof useProjectsCountLazyQuery
>
export type ProjectsCountQueryResult = ApolloReactCommon.QueryResult<
  ProjectsCountQuery,
  ProjectsCountQueryVariables
>
export const StoriesDocument = gql`
  query stories(
    $after: String
    $archived: Boolean
    $createdBy: [String!]
    $first: Int!
    $lovedBy: [String!]
    $projectId: String
    $roles: [String!]
    $search: String
    $sort: SortStories
  ) {
    stories(
      after: $after
      archived: $archived
      createdBy: $createdBy
      first: $first
      lovedBy: $lovedBy
      projectId: $projectId
      roles: $roles
      search: $search
      sort: $sort
    )
      @connection(
        key: "stories"
        filter: [
          "archived"
          "createdBy"
          "lovedBy"
          "projectId"
          "roles"
          "search"
          "sort"
        ]
      ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          archived
          canDelete
          canLove
          canUpdate
          createdBy {
            id
            displayName
            firstName
            lastName
          }
          need
          reason
          role
          storyLove {
            id
            love
            user {
              id
              firstName
              lastName
            }
          }
          updatedAt
        }
      }
    }
  }
`

/**
 * __useStoriesQuery__
 *
 * To run a query within a React component, call `useStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      archived: // value for 'archived'
 *      createdBy: // value for 'createdBy'
 *      first: // value for 'first'
 *      lovedBy: // value for 'lovedBy'
 *      projectId: // value for 'projectId'
 *      roles: // value for 'roles'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useStoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StoriesQuery,
    StoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<StoriesQuery, StoriesQueryVariables>(
    StoriesDocument,
    baseOptions
  )
}
export function useStoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StoriesQuery,
    StoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<StoriesQuery, StoriesQueryVariables>(
    StoriesDocument,
    baseOptions
  )
}
export type StoriesQueryHookResult = ReturnType<typeof useStoriesQuery>
export type StoriesLazyQueryHookResult = ReturnType<typeof useStoriesLazyQuery>
export type StoriesQueryResult = ApolloReactCommon.QueryResult<
  StoriesQuery,
  StoriesQueryVariables
>
export const StoryDocument = gql`
  query story($id: String!) {
    story(id: $id) {
      id
      archived
      need
      reason
      role
    }
  }
`

/**
 * __useStoryQuery__
 *
 * To run a query within a React component, call `useStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StoryQuery,
    StoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<StoryQuery, StoryQueryVariables>(
    StoryDocument,
    baseOptions
  )
}
export function useStoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StoryQuery,
    StoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<StoryQuery, StoryQueryVariables>(
    StoryDocument,
    baseOptions
  )
}
export type StoryQueryHookResult = ReturnType<typeof useStoryQuery>
export type StoryLazyQueryHookResult = ReturnType<typeof useStoryLazyQuery>
export type StoryQueryResult = ApolloReactCommon.QueryResult<
  StoryQuery,
  StoryQueryVariables
>
export const TokenDocument = gql`
  query token {
    token @client {
      accessToken
    }
  }
`

/**
 * __useTokenQuery__
 *
 * To run a query within a React component, call `useTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TokenQuery,
    TokenQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TokenQuery, TokenQueryVariables>(
    TokenDocument,
    baseOptions
  )
}
export function useTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TokenQuery,
    TokenQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TokenQuery, TokenQueryVariables>(
    TokenDocument,
    baseOptions
  )
}
export type TokenQueryHookResult = ReturnType<typeof useTokenQuery>
export type TokenLazyQueryHookResult = ReturnType<typeof useTokenLazyQuery>
export type TokenQueryResult = ApolloReactCommon.QueryResult<
  TokenQuery,
  TokenQueryVariables
>

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string
      name: string
      possibleTypes: {
        name: string
      }[]
    }[]
  }
}
const result: IntrospectionResultData = {
  __schema: {
    types: [],
  },
}
export default result
