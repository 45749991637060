import { GraphQLError } from 'graphql'
import { SignUpDocument } from '../../graphql'

export const signUpSuccessMock = {
  request: {
    query: SignUpDocument,
    variables: {
      email: 'example@test.com',
      firstName: 'Example',
      lastName: 'Test',
      password: 'examplePassword',
    },
  },
  result: {
    data: {
      signUp: {
        accessToken:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMDAwMDAwMDAwIiwibmFtZSI6IkV4YW1wbGUiLCJpYXQiOjE1MTYyMzkwMjJ9.MfE2a_HsDG6gGaR-gwRfSk7MLAkK1DLfcOmXj6mVfzk',
      },
    },
  },
}

export const signUpFailureMock = {
  request: {
    query: SignUpDocument,
    variables: {
      email: 'bademail@test.com',
      firstName: 'Example',
      lastName: 'Test',
      password: 'examplePassword',
    },
  },
  result: {
    data: null,
    errors: [new GraphQLError('Email address has already been registered.')],
  },
}

export const signUpMocks = [signUpSuccessMock, signUpFailureMock]
