import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import { isNull } from 'lodash'
import React from 'react'
import { config } from '../../../config'

const data: {
  title: string
  description: string
  image: string
  imageTablet: string
  imageDesktop: string
  nextPath: string | null
}[] = [
  {
    title: 'Capture collaboratively',
    description:
      'Capture company, project, and client requirements and ideas together in an online portal.',
    image: '/images/create_story_mobile.svg',
    imageTablet: '/images/create_story_tablet.svg',
    imageDesktop: '/images/create_story.png',
    nextPath: '/images/features_path1.svg',
  },
  {
    title: 'Show some love',
    description:
      'Vote for the needs that you, your colleagues or customers feel strongly about by giving love towards them to help prioritise goals that have the biggest impact.',
    image: '/images/vote_mobile.svg',
    imageTablet: '/images/vote_tablet.svg',
    imageDesktop: '/images/vote.png',
    nextPath: '/images/features_path2.svg',
  },
  {
    title: 'Bring some order',
    description:
      'Automatically order needs by love count or most recent and use the filters if you need an extra focused list.',
    image: '/images/sort_mobile.svg',
    imageTablet: '/images/sort_tablet.svg',
    imageDesktop: '/images/sort.png',
    nextPath: '/images/features_path3.svg',
  },
  {
    title: 'Find stuff, fast',
    description:
      'Filter down requirements by what roles they’d benefit, who’s loved it and who came up with it to group requirements and add focus.',
    image: '/images/filter_mobile.svg',
    imageTablet: '/images/filter_tablet.svg',
    imageDesktop: '/images/filter.png',
    nextPath: '/images/features_path4.svg',
  },
  {
    title: 'Fine grained control',
    description:
      'Add users with read, write or admin roles to empower user’s with a level of control.',
    image: '/images/permissions_mobile.svg',
    imageTablet: '/images/permissions_tablet.svg',
    imageDesktop: '/images/permissions.png',
    nextPath: null,
  },
]

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      padding: theme.spacing(7, 0, 10),
    },
    feature: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(10),
      },
      [theme.breakpoints.up('md')]: {
        '&:not(:last-child)': {
          marginBottom: 0,
        },
      },
      [theme.breakpoints.up('lg')]: {
        '&:not(:last-child)': {
          marginBottom: theme.spacing(15),
        },
      },
    },
    featureEven: {
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    featureOdd: {
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row-reverse',
      },
    },
    image: {
      display: 'block',
      width: 226,
      height: 226,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        height: '100%',
        marginBottom: 0,
        position: 'absolute',
        top: 0,
        bottom: 0,
      },
    },
    imageEven: {
      [theme.breakpoints.up('sm')]: {
        right: 0,
      },
    },
    imageOdd: {
      [theme.breakpoints.up('sm')]: {
        left: 0,
      },
    },
    imageContainer: {
      flex: 5,
      overflowX: 'hidden',
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        height: 400,
      },
      [theme.breakpoints.up('md')]: {
        flex: 6,
        height: 600,
      },
    },
    path: {
      position: 'absolute',
      top: '50%',
      left: '30%',
      [theme.breakpoints.up('lg')]: {
        left: '40%',
      },
    },
    text: {
      maxWidth: theme.spacing(47.5),
    },
    textContainer: {
      display: 'flex',
      flex: 7,
      margin: theme.spacing(0, 3),
      [theme.breakpoints.up('md')]: {
        flex: 6,
      },
    },
    textContainerEven: {
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 3, 0, 8),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(12.5),
      },
    },
    textContainerOdd: {
      justifyContent: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 8, 0, 3),
      },
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(12.5),
      },
    },
    title: {
      padding: theme.spacing(0, 3),
      marginBottom: theme.spacing(5),
    },
    subTitle: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1.5),
      },
    },
  })
)

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Features: React.FC = () => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <div className={classes.container}>
      <Typography
        align="center"
        className={classes.title}
        component="h2"
        variant={smUp ? 'h3' : 'h4'}
      >
        Features
      </Typography>
      {data.map(
        (
          { title, description, image, imageTablet, imageDesktop, nextPath },
          i
        ) => {
          const isEven = i % 2 === 0

          return (
            <div
              className={clsx([
                classes.feature,
                isEven ? classes.featureEven : classes.featureOdd,
              ])}
              key={title}
            >
              {mdUp && !isNull(nextPath) ? (
                <img
                  className={classes.path}
                  src={nextPath}
                  alt={`${config.appName ?? ''} ${title} path`}
                />
              ) : undefined}
              <div className={classes.imageContainer}>
                <img
                  className={clsx([
                    classes.image,
                    isEven ? classes.imageEven : classes.imageOdd,
                  ])}
                  src={smUp ? (mdUp ? imageDesktop : imageTablet) : image}
                  alt={`${config.appName ?? ''} ${title}`}
                />
              </div>
              <div
                className={clsx([
                  classes.textContainer,
                  isEven ? classes.textContainerEven : classes.textContainerOdd,
                ])}
              >
                <div className={classes.text}>
                  <Typography
                    align={smUp ? 'left' : 'center'}
                    className={classes.subTitle}
                    component="h3"
                    variant={smUp ? 'h4' : 'h5'}
                  >
                    {title}
                  </Typography>
                  <Typography align={smUp ? 'left' : 'center'}>
                    {description}
                  </Typography>
                </div>
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}
