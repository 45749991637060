import { config } from '../../config'

export const accountUri = () => '/my-account'

export const accountChangePlanUri = () => `${accountUri()}#change-plan`

export const emailSupportUri = () => `mailto:${config.email.support}`

export const loginUri = () => '/login'

export const signUpUri = () => '/signup'

export const termsOfUseUri = () => '/terms-of-use'

export const forgotPasswordUri = () => '/forgot-password'

export const noProjectsUri = () => '/no-projects'

export const privacyPolicyUri = () => '/privacy-policy'

export const projectUri = (projectId: string) => `/project/${projectId}`

export const importStoriesUri = (projectId: string) =>
  `/project/${projectId}/import-stories`

export const createProjectUri = () => '/create-project'

export const projectInviteUri = (projectId: string) =>
  `/project/${projectId}/users`

export const updateProjectUri = (projectId: string) =>
  `/project/${projectId}/update`

export const updateStoryUri = (projectId: string, storyId: string) =>
  `/project/${projectId}/update-story/${storyId}`
