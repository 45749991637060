import { ApolloError } from '@apollo/client'
import { isAxiosError } from 'axios'
import { ValidationError } from 'class-validator'
import { GraphQLError } from 'graphql'
import {
  isArray,
  isString,
  isUndefined,
  keyBy,
  mapValues,
  values,
} from 'lodash'

export interface NestJsError {
  statusCode: number
  error: string
  message?: string
}

export interface NestJsValidationError extends Omit<NestJsError, 'message'> {
  message: {
    property: string
    constraints: { [key: string]: string }
  }[]
}

type ErrorMessage = string | NestJsError | NestJsValidationError

interface ApiError extends Omit<GraphQLError, 'message'> {
  message: ErrorMessage
}

export interface ApiErrors extends Omit<ApolloError, 'graphQLErrors'> {
  graphQLErrors: readonly ApiError[]
}

const isNestJSError = (error: ErrorMessage): error is NestJsError => {
  const nestJsError = error as NestJsError

  return (
    Boolean(nestJsError.error) &&
    Boolean(nestJsError.statusCode) &&
    (isUndefined(nestJsError.message) || isString(nestJsError.message))
  )
}

const isNestJsValidationError = (
  error: ErrorMessage
): error is NestJsValidationError => {
  const nestJsError = error as NestJsValidationError

  return (
    isArray(nestJsError.message) && Boolean(nestJsError.message[0].property)
  )
}

export const getValidationErrors = (e: ValidationError[]) => {
  const keyed = keyBy(e, 'property')
  return mapValues(keyed, (v) => values(v.constraints))
}

export const getGraphQLValidationErrors = (e: ApiErrors) =>
  e.graphQLErrors.reduce<{ [key: string]: string[] }[]>((acc, n) => {
    if (!isNestJsValidationError(n.message)) return acc

    const keyed = keyBy(n.message.message, 'property')
    const fieldErrors = mapValues(keyed, (v) => values(v.constraints))

    return [...acc, fieldErrors]
  }, [])

export const getGraphQLError = (e: ApiErrors): string[] => {
  try {
    return e.graphQLErrors.reduce<string[]>(
      (acc, n) =>
        isString(n.message)
          ? [...acc, n.message]
          : isNestJSError(n.message) && !isUndefined(n.message.message)
          ? [...acc, n.message.message]
          : acc,
      []
    )
  } catch (e) {
    return ['An unknown error occurred.']
  }
}

/**
 * Gets the error message from the REST API response.
 *
 * @param e Error thrown
 */
export const getRestError = (e: unknown) => {
  if (isAxiosError(e)) {
    const nestError = e.response?.data as NestJsError

    return nestError.message ?? nestError.error
  }

  return 'An unknown error occurred.'
}
