import Chip from '@material-ui/core/Chip'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'
import { compact } from 'lodash'
import React, { useCallback } from 'react'
import { ArrayParam, useQueryParam } from 'use-query-params'
import { UserAvatar } from '../../../components'
import { ProjectUsersQuery, useProjectUsersQuery } from '../../../middleware'

type Option = ProjectUsersQuery['project']['projectUsers'][0]

interface LovedBySelectProps
  extends Omit<
    AutocompleteProps<Option, true, false, false>,
    'options' | 'renderInput'
  > {
  projectId: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: theme.typography.body2.fontSize,
      letterSpacing: theme.typography.body2.letterSpacing,
    },
  })
)

export const LovedBySelect: React.FC<LovedBySelectProps> = ({
  projectId,
  ...rest
}) => {
  const classes = useStyles()
  const [lovedBy, setLovedBy] = useQueryParam('lovedBy', ArrayParam)
  const { data, loading } = useProjectUsersQuery({
    variables: {
      id: projectId,
    },
  })

  const value =
    data?.project.projectUsers.filter((n) =>
      lovedBy?.some((x) => x === n.user?.id)
    ) ?? []

  const onChange = useCallback(
    (_, value: ProjectUsersQuery['project']['projectUsers'][0][] | null) => {
      setLovedBy(compact(value?.map((n) => n.user?.id)) ?? undefined)
    },
    [setLovedBy]
  )

  return (
    <Autocomplete
      getOptionLabel={(option) => option.user?.displayName ?? 'Unknown user'}
      id="loved-by-filter"
      loading={loading}
      multiple={true}
      onChange={onChange}
      options={data?.project.projectUsers ?? []}
      renderInput={(params) => (
        <TextField {...params} label="Loved by" variant="outlined" />
      )}
      renderOption={(option) => (
        <React.Fragment>
          <ListItemAvatar>
            <UserAvatar className={classes.avatar} user={option.user} />
          </ListItemAvatar>
          <ListItemText primary={option.user?.displayName ?? 'Unknown user'} />
        </React.Fragment>
      )}
      renderTags={(
        value: ProjectUsersQuery['project']['projectUsers'][0][],
        getTagProps
      ) =>
        value.map((option, index) => (
          <Chip
            avatar={<UserAvatar user={option.user} />}
            key={option.user?.id}
            label={option.user?.displayName}
            {...getTagProps({ index })}
          />
        ))
      }
      value={value}
      {...rest}
    />
  )
}
