import hotjar from '@hotjar/browser'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { StripeElementChangeEvent, StripeElementType } from '@stripe/stripe-js'
import {
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { format } from 'date-fns'
import { first, gt, isEmpty, isNil, isNull, isUndefined } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'
import LinkedInTag from 'react-linkedin-insight'
import { StripeTextField, SubmitButton } from '../../../components'
import { config } from '../../../config'
import {
  PaymentMethod,
  Price,
  Product,
  SubscriptionItem,
  SubscriptionItems,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionItemMutation,
} from '../../../middleware'
import { getGraphQLError } from '../../../util'

export interface CheckoutDialogProps
  extends Omit<DialogProps, 'children' | 'onClose'> {
  currentSubscription?: Pick<SubscriptionItems, 'id'> & {
    items: Pick<SubscriptionItem, 'id'>[]
  }
  defaultPaymentMethod?: PaymentMethod | null
  onClose: () => void
  price: Pick<Price, 'id' | 'currency' | 'unitAmount'> & {
    product: Pick<Product, 'name'>
  }
  quantity: number
}

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      marginTop: -theme.spacing(0.5),
    },
    cancelButton: {
      flex: 1,
    },
    dialogPaper: {
      maxWidth: theme.spacing(50),
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    divider: {
      margin: theme.spacing(3, 0),
    },
    error: {
      marginBottom: theme.spacing(2),
    },
    fields: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(1),
      '& >:first-child': {
        gridColumnEnd: 'span 2',
      },
    },
    paymentMethod: {
      flex: 1,
    },
    paymentMethodCard: {
      textTransform: 'capitalize',
    },
    subscription: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      '& >:last-child': {
        marginLeft: theme.spacing(3),
      },
    },
    title: {
      flex: 1,
      marginRight: theme.spacing(4),
    },
    total: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      '& >:last-child': {
        marginLeft: theme.spacing(3),
      },
    },
    updateButton: {
      flex: 2,
    },
  })
)

export const CheckoutDialog: React.FC<CheckoutDialogProps> = ({
  currentSubscription,
  defaultPaymentMethod,
  onClose,
  price,
  quantity,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState<{
    elementError: { [key in StripeElementType]?: string }
    stripeError?: string
    stripeLoading: boolean
  }>({ elementError: {}, stripeLoading: false })

  const onCompleted = useCallback(() => {
    gtag('event', 'purchase', {
      currency: price.currency,
      value: (price.unitAmount / 100) * quantity, // pounds to pennies
      items: [
        {
          id: price.id,
          name: price.product.name,
          value: price.unitAmount / 100, // pounds to pennies
          quantity,
        },
      ],
    })

    fbq('track', 'Purchase', {
      content_ids: [price.id],
      content_name: price.product.name,
      content_type: 'product',
      contents: [
        {
          id: price.id,
          quantity,
        },
      ],
      currency: price.currency,
      num_items: 1,
      value: (price.unitAmount / 100) * quantity, // pounds to pennies
    })

    fbq('track', 'Subscribe', {
      currency: price.currency,
      value: (price.unitAmount / 100) * quantity, // pounds to pennies
    })

    if (LinkedInTag.verifyInit())
      LinkedInTag.track(config.linkedinEvents.purchase)

    if (hotjar.isReady()) hotjar.event('purchased')
  }, [price.currency, price.id, price.product.name, price.unitAmount, quantity])

  const [
    createSubscription,
    { loading: createSubscriptionLoading, error: createSubscriptionError },
  ] = useCreateSubscriptionMutation({
    onCompleted,
    onError: (e) => {
      const error = first(getGraphQLError(e))

      gtag('event', 'exception', {
        description: `Failed to create subscription.${
          isUndefined(error) ? '' : ` ${error}`
        }`,
        fatal: false,
      })
    },
  })

  const [
    updateSubscriptionItem,
    {
      loading: updateSubscriptionItemLoading,
      error: updateSubscriptionItemError,
    },
  ] = useUpdateSubscriptionItemMutation({
    onCompleted,
    onError: (e) => {
      const error = first(getGraphQLError(e))

      gtag('event', 'exception', {
        description: `Failed to update subscription.${
          isUndefined(error) ? '' : ` ${error}`
        }`,
        fatal: false,
      })
    },
  })

  const onCreate = useCallback(async () => {
    if (isNull(stripe) || isNull(elements)) {
      return
    }

    setState({ ...state, stripeLoading: true })
    const cardElement = elements.getElement(CardNumberElement)

    if (isNull(cardElement)) {
      const error = "Can't find card field."

      gtag('event', 'exception', {
        description: error,
        fatal: true,
      })
      setState({
        ...state,
        stripeError: error,
        stripeLoading: false,
      })
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (!isUndefined(error)) {
      gtag('event', 'exception', {
        description: error.message,
        fatal: false,
      })
      setState({ ...state, stripeError: error.message, stripeLoading: false })
      return
    }

    if (isUndefined(paymentMethod)) {
      const error = "Can't create subscription."

      gtag('event', 'exception', {
        description: error,
        fatal: true,
      })
      setState({
        ...state,
        stripeError: error,
        stripeLoading: false,
      })
      return
    }

    await createSubscription({
      variables: {
        priceId: price.id,
        quantity,
        stripePaymentMethodId: paymentMethod?.id,
      },
    }).catch((_) => {
      // Handled above in `checkoutError`
      // See: https://github.com/apollographql/apollo-client/issues/3963
    })

    setState({ ...state, stripeError: undefined, stripeLoading: false })

    enqueueSnackbar(`Successfully updated subscription.`, {
      variant: 'success',
    })
    onClose()
  }, [
    createSubscription,
    elements,
    enqueueSnackbar,
    onClose,
    price,
    quantity,
    state,
    stripe,
  ])

  const onUpdate = useCallback(() => {
    if (isUndefined(currentSubscription)) return

    const subscriptionItem = first(currentSubscription.items)

    if (isUndefined(subscriptionItem)) return

    updateSubscriptionItem({
      variables: {
        priceId: price.id,
        quantity,
        subscriptionItemId: subscriptionItem.id,
      },
    })
      .then(() => {
        enqueueSnackbar(`Successfully updated subscription.`, {
          variant: 'success',
        })
        onClose()
      })
      .catch((_) => {
        // Handled above in `checkoutError`
        // See: https://github.com/apollographql/apollo-client/issues/3963
      })
  }, [
    currentSubscription,
    enqueueSnackbar,
    onClose,
    price.id,
    quantity,
    updateSubscriptionItem,
  ])

  const onChange = (event: StripeElementChangeEvent) => {
    if (LinkedInTag.verifyInit())
      LinkedInTag.track(config.linkedinEvents.addBillingInfo)

    if (hotjar.isReady()) hotjar.event('addedBillingInfo')

    setState({
      ...state,
      elementError: {
        ...state.elementError,
        [event.elementType]: event.error?.message,
      },
    })
  }

  const hasSubscription = !isEmpty(currentSubscription)

  const submitting =
    state.stripeLoading ||
    createSubscriptionLoading ||
    updateSubscriptionItemLoading
  const error = !isUndefined(createSubscriptionError)
    ? first(getGraphQLError(createSubscriptionError))
    : !isUndefined(updateSubscriptionItemError)
    ? first(getGraphQLError(updateSubscriptionItemError))
    : state.stripeError

  return (
    <Dialog
      aria-labelledby="create-payment-method-dialog-title"
      aria-describedby="create-payment-method-dialog-description"
      PaperProps={{ className: classes.dialogPaper }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle
        id="create-payment-method-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography className={classes.title} variant="h5">
          Order confirmation
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          edge="end"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isUndefined(error) ? undefined : (
          <Alert className={classes.error} severity="error">
            <AlertTitle>Subscription failed to create</AlertTitle>
            {error}
          </Alert>
        )}
        <DialogContentText id="create-payment-method-dialog-description">
          Please review your order below and enter payment details to subscribe.
        </DialogContentText>
        <div className={classes.subscription}>
          <Typography variant="h5">{price.product.name}</Typography>
          <Typography align="right">
            {quantity} {gt(quantity, 1) ? 'seats' : 'seat'}
          </Typography>
        </div>
        <div className={classes.total}>
          <Typography>Total:</Typography>
          <div>
            <Typography align="right" variant="h6">
              {
                new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: price.currency,
                }).format(price.unitAmount / 100) // pounds to pennies
              }
            </Typography>
            <Typography align="right">per month</Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        {isNil(defaultPaymentMethod) ? (
          <div className={classes.fields}>
            <StripeTextField
              error={Boolean(state.elementError.cardNumber)}
              helperText={state.elementError.cardNumber}
              label="Card Number"
              inputProps={{
                options: {
                  showIcon: true,
                },
              }}
              onChange={onChange}
              stripeElement={CardNumberElement}
              variant={'outlined'}
            />
            <StripeTextField
              error={Boolean(state.elementError.cardExpiry)}
              helperText={state.elementError.cardExpiry}
              label="Expires"
              onChange={onChange}
              stripeElement={CardExpiryElement}
              variant={'outlined'}
            />
            <StripeTextField
              error={Boolean(state.elementError.cardCvc)}
              helperText={state.elementError.cardCvc}
              label="CVC Code"
              onChange={onChange}
              stripeElement={CardCvcElement}
              variant={'outlined'}
            />
          </div>
        ) : (
          <div className={classes.paymentMethod}>
            <Typography className={classes.paymentMethodCard} variant="h5">
              <span>
                {isNil(defaultPaymentMethod.brand)
                  ? 'Card'
                  : defaultPaymentMethod.brand}
              </span>
              <span> **** </span>
              <span>{defaultPaymentMethod.last4}</span>
            </Typography>
            {isNil(defaultPaymentMethod.expiry) ? undefined : (
              <Typography>
                Expires {format(new Date(defaultPaymentMethod.expiry), 'MM/yy')}
              </Typography>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelButton}
          disabled={submitting}
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <SubmitButton
          className={classes.updateButton}
          color="primary"
          onClick={hasSubscription ? onUpdate : onCreate}
          loading={submitting}
          variant="contained"
        >
          Subscribe
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}
