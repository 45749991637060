import { plainToClass } from 'class-transformer'
import {
  Equals,
  IsBoolean,
  IsEmail,
  IsString,
  MaxLength,
  MinLength,
  validateSync,
} from 'class-validator'
import { SignUpMutationVariables } from '../../graphql'
import { getValidationErrors } from '../../../../util'

export class SignUpDto {
  @IsEmail(undefined, {
    message:
      'Please enter email in email address format. E.g. johnsmith@example.com.',
  })
  @MinLength(3, {
    message: 'Email should be at least $constraint1 characters long.',
  })
  @MaxLength(255, {
    message: 'Email should be at most $constraint1 characters long.',
  })
  email!: string

  @IsString()
  @MinLength(1, {
    message: 'First name should be at least $constraint1 character long.',
  })
  @MaxLength(255, {
    message: 'First name should be at most $constraint1 characters long.',
  })
  firstName!: string

  @IsString()
  @MinLength(1, {
    message: 'Last name should be at least $constraint1 character long.',
  })
  @MaxLength(255, {
    message: 'Last name should be at most $constraint1 characters long.',
  })
  lastName!: string

  @IsBoolean()
  @Equals(true, {
    message: 'Terms of service must be agreed to sign up.',
  })
  legal!: boolean

  @IsString()
  @MinLength(12, {
    message: 'Password should be at least $constraint1 characters long.',
  })
  @MaxLength(64, {
    message: 'Password should be at most $constraint1 characters long.',
  })
  password!: string
}

export const signUpValidate = (vars: SignUpMutationVariables) =>
  getValidationErrors(validateSync(plainToClass(SignUpDto, vars)))
