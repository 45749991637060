import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { first, gt, isUndefined } from 'lodash'
import React from 'react'
import { Route, RouteChildrenProps, RouteProps } from 'react-router-dom'
import { useProjectsCountQuery } from '../../../middleware'
import { getGraphQLError } from '../../../util'

export interface HasProjectsRouteProps extends RouteProps {
  allowHasProjects?: boolean
  children?: ((props: RouteChildrenProps) => React.ReactNode) | React.ReactNode
  redirectComponent: React.ReactNode
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export const HasProjectsRoute: React.FC<HasProjectsRouteProps> = ({
  allowHasProjects = true,
  children,
  redirectComponent,
  ...rest
}) => {
  const classes = useStyles()
  const { data, loading, error } = useProjectsCountQuery({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to read projects count.${errorText}`,
        fatal: true,
      })
    },
  })
  const projectsCount = data?.projects.totalCount
  const hasProjects = isUndefined(projectsCount) ? false : gt(projectsCount, 0)
  const canAccess = hasProjects === allowHasProjects

  return (
    <Route {...rest}>
      {loading ? (
        <div className={classes.container}>
          <CircularProgress aria-label="Loading" />
        </div>
      ) : !isUndefined(error) ? (
        <Container className={classes.container} maxWidth="sm">
          <Alert severity="error">
            <AlertTitle>Projects failed to load</AlertTitle>
            Please try a refresh. If this persists, please try closing all tabs
            with the site open and then returning in a new tab.
          </Alert>
        </Container>
      ) : canAccess ? (
        children
      ) : (
        redirectComponent
      )}
    </Route>
  )
}
