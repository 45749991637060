import React from 'react'
import {
  CenterLayout,
  CurrentUserAccountMenu,
  EmailVerificationProgress,
  ListLayout,
  RecentProjectRedirect,
} from '../..'

interface EmailVerificationPageProps {
  verificationCode: string
}

export const EmailVerificationPage: React.FC<EmailVerificationPageProps> = ({
  verificationCode,
}) => (
  <ListLayout headerRight={<CurrentUserAccountMenu />}>
    <CenterLayout>
      <EmailVerificationProgress verificationCode={verificationCode}>
        <RecentProjectRedirect />
      </EmailVerificationProgress>
    </CenterLayout>
  </ListLayout>
)
