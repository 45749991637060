import Switch, { SwitchProps } from '@material-ui/core/Switch'
import { BooleanParam, useQueryParam } from 'use-query-params'
import React from 'react'

type ShowArchivedSwitchProps = Omit<SwitchProps, 'checked' | 'onChange'>

export const ShowArchivedSwitch: React.FC<ShowArchivedSwitchProps> = (
  props
) => {
  const [showArchived, setShowArchived] = useQueryParam(
    'showArchived',
    BooleanParam
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowArchived(event.target.checked ? true : undefined)
  }

  return (
    <Switch
      checked={showArchived ?? false}
      onChange={onChange}
      name="showArchived"
      inputProps={{ 'aria-label': 'Show archived' }}
      {...props}
    />
  )
}
