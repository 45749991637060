import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  PricesDocument,
  PricesQueryResult,
  PricesQueryVariables,
} from '../../graphql'
import { currentUserBillingSuccessMock } from '../../queries'

type PricesMockResponse = MockResponse<
  PricesQueryVariables,
  Pick<PricesQueryResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const pricesSuccessMock = {
  request: {
    query: PricesDocument,
    variables: {},
  },
  result: {
    data: {
      currentUser: {
        id: 'ee0e59c3-a9b0-480a-9b23-29dfd65c3c42',
        defaultPaymentMethod:
          currentUserBillingSuccessMock.result.data.currentUser
            .defaultPaymentMethod,
        subscriptions: [
          {
            id: '56140a52-995e-496b-a57e-dce255d0be5d',
            cancelAtPeriodEnd: false,
            currentPeriodEnd: new Date().toString(),
            items: [
              {
                id: '11e58243-ca1b-485c-a2e8-64a6ab6e3c1a',
                product: {
                  id: 'f76671aa-d04a-4247-96b2-1910b05d8697',
                },
              },
            ],
          },
        ],
      },
      prices: {
        edges: [
          {
            node: {
              id: 'a5cbb72f-a40f-4057-a213-cc29c5bc1789',
              currency: 'gbp',
              hasQuantity: false,
              interval: 'month',
              maxQuantity: null,
              minQuantity: 1,
              product: {
                id: 'f7af4874-ad53-4627-87ff-d8ff9e73ba18',
                description: 'For those just starting out.',
                hasSubscription: false,
                name: 'Free',
                contributorQuotaBase: 9,
                contributorQuotaPerSeat: null,
                projectQuotaBase: 3,
                projectQuotaPerSeat: null,
                quotaDescription: 'Free forever includes:',
                storyQuotaBase: 300,
                storyQuotaPerSeat: null,
              },
              tiers: null,
              unitAmount: 0,
            },
          },
          {
            node: {
              id: '11e58243-ca1b-485c-a2e8-64a6ab6e3c1a',
              currency: 'gbp',
              hasQuantity: true,
              interval: 'month',
              maxQuantity: null,
              minQuantity: 10,
              product: {
                id: 'f76671aa-d04a-4247-96b2-1910b05d8697',
                description: 'Great for businesses that scale.',
                hasSubscription: true,
                name: 'Enterprise',
                contributorQuotaBase: 9,
                contributorQuotaPerSeat: 1,
                projectQuotaBase: 3,
                projectQuotaPerSeat: 1,
                quotaDescription: 'Everything in pro, plus (per seat):',
                storyQuotaBase: 300,
                storyQuotaPerSeat: 100,
              },
              tiers: [
                {
                  from: null,
                  unitAmount: 599,
                  upTo: 100,
                },
                {
                  from: 101,
                  unitAmount: 399,
                  upTo: 200,
                },
                {
                  from: 201,
                  unitAmount: 199,
                  upTo: null,
                },
              ],
              unitAmount: 599,
            },
          },
        ],
      },
    },
  },
}

export const pricesFailureMock = {
  request: {
    query: PricesDocument,
    variables: {},
  },
  result: {
    data: undefined,
    errors: [
      {
        message: {
          statusCode: 404,
          error: 'Not Found',
          message: 'Prices not found.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const pricesMocks: PricesMockResponse[] = [pricesSuccessMock]
