import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Container from '@material-ui/core/Container'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import {
  compact,
  first as firstItem,
  isEmpty,
  isString,
  isUndefined,
} from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import mergeRefs from 'react-merge-refs'
import { Virtuoso, VirtuosoMethods, VirtuosoProps } from 'react-virtuoso'
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParam,
} from 'use-query-params'
import {
  SortStories,
  useCurrentUser,
  useStoriesQuery,
} from '../../../middleware'
import { getGraphQLError } from '../../../util'
import { StoryCard } from '../..'

export interface StoriesListProps
  extends Omit<VirtuosoProps, 'totalCount' | 'item'> {
  first?: number
  projectId: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    emptyContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 3),
    },
    emptyImage: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      marginBottom: theme.spacing(3),
    },
    emptyTitle: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1.5),
      },
    },
    emptyDescription: {
      maxWidth: 276,
      marginBottom: theme.spacing(3),
    },
    error: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    firstItem: {
      paddingTop: theme.spacing(2),
    },
    list: {
      width: '100%',
      flex: 1,
    },
    loadingContainer: {
      position: 'relative',
      width: '100%',
      flex: 1,
      overflowY: 'scroll',
    },
    loadingInnerContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    loadingMore: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: theme.spacing(2),
    },
  })
)

const postStoryText = 'Post a story'
const postImage = '/images/post.svg'
const storiesAddPathImage = '/images/stories_add_path.svg'

const Empty: React.FC = () => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  return (
    <div className={classes.emptyContainer}>
      <img className={classes.emptyImage} src={postImage} alt={postStoryText} />
      <Typography
        align="center"
        className={classes.emptyTitle}
        component="h2"
        variant={smUp ? 'h3' : 'h4'}
      >
        No stories yet
      </Typography>
      <Typography align="center" className={classes.emptyDescription}>
        Add the very first story by pressing the “+” create button at the bottom
        right.
      </Typography>
      <img src={storiesAddPathImage} alt={postStoryText} />
    </div>
  )
}

const NoResults: React.FC = () => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  return (
    <div className={classes.emptyContainer}>
      <img className={classes.emptyImage} src={postImage} alt={postStoryText} />
      <Typography
        align="center"
        className={classes.emptyTitle}
        component="h2"
        variant={smUp ? 'h3' : 'h4'}
      >
        No results found
      </Typography>
      <Typography align="center" className={classes.emptyDescription}>
        Try broadening your search filters or adding a story by pressing the “+”
        create button at the bottom right.
      </Typography>
      <img src={storiesAddPathImage} alt={postStoryText} />
    </div>
  )
}

export const StoriesList = React.forwardRef<VirtuosoMethods, StoriesListProps>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function StoriesList({ first = 5, projectId, ...rest }, forwardedRef) {
    const classes = useStyles()
    const virtuoso = useRef<VirtuosoMethods>(null)
    const [createdBy] = useQueryParam('createdBy', ArrayParam)
    const [lovedBy] = useQueryParam('lovedBy', ArrayParam)
    const [roles] = useQueryParam('roles', ArrayParam)
    const [showArchived] = useQueryParam('showArchived', BooleanParam)
    const [search] = useQueryParam('search', StringParam)
    const [sort] = useQueryParam<SortStories>('sort')
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
    const currentUser = useCurrentUser().data?.currentUser

    const createdByItems = useMemo(() => compact(createdBy), [createdBy])
    const lovedByItems = useMemo(() => compact(lovedBy), [lovedBy])
    const rolesItems = useMemo(() => compact(roles), [roles])
    const isFiltered =
      !isEmpty(createdByItems) ||
      !isEmpty(lovedByItems) ||
      !isEmpty(rolesItems) ||
      !isEmpty(search)

    // scroll to top of list when results change
    useEffect(() => {
      virtuoso.current?.scrollToIndex({
        // note `behavior: 'smooth'` stops list from rendering more on first scroll down
        index: 0,
        align: 'start',
      })
    }, [
      createdByItems,
      lovedByItems,
      projectId,
      rolesItems,
      search,
      showArchived,
      sort,
    ])

    const {
      data: storiesData,
      loading: storiesLoading,
      error: storiesError,
      fetchMore,
    } = useStoriesQuery({
      // fetch on load, then use cache to prevent story positions changing on mutation (i.e. on love)
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: (e) => {
        const error = firstItem(getGraphQLError(e))
        const errorText = isUndefined(error) ? '' : ` ${error}`

        gtag('event', 'exception', {
          description: `Failed to read stories for ${projectId} project.${errorText}`,
          fatal: false,
        })
      },
      variables: {
        archived: showArchived ?? false ? undefined : false,
        createdBy: isEmpty(createdByItems) ? undefined : createdByItems,
        lovedBy: isEmpty(lovedByItems) ? undefined : lovedByItems,
        first,
        projectId,
        roles: isEmpty(rolesItems) ? undefined : rolesItems,
        search,
        sort,
      },
    })

    const hasNextPage = storiesData?.stories.pageInfo.hasNextPage ?? false
    const after = storiesData?.stories.pageInfo.endCursor
    const stories = useMemo(
      () => storiesData?.stories.edges.map((n) => n.node),
      [storiesData]
    )
    const itemCount = stories?.length

    const onLoadMore = useCallback(async () => {
      if (storiesLoading || !hasNextPage || !isString(after)) return

      // Workaround for unmount before promise resolves
      // See: https://github.com/apollographql/apollo-client/issues/4114
      try {
        return await fetchMore({
          variables: {
            after,
          },
        })
      } catch (e) {
        console.log(e)
        return await Promise.resolve()
      }
    }, [after, fetchMore, hasNextPage, storiesLoading])

    const error = useMemo(() => {
      if (isUndefined(storiesError)) return

      const error = firstItem(getGraphQLError(storiesError))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      return isUndefined(storiesError)
        ? undefined
        : `An error occured loading project users.${errorText}`
    }, [storiesError])

    const Item = useCallback(
      (index: number) => {
        const story: NonNullable<typeof stories>[0] | undefined = isUndefined(
          stories
        )
          ? undefined
          : stories[index]

        return (
          <Container
            className={clsx([
              classes.item,
              { [classes.firstItem]: index === 0 },
            ])}
          >
            <StoryCard
              currentUser={currentUser}
              loading={isUndefined(story)} // extra undefined items are added when loading
              projectId={projectId}
              story={story}
              size={smUp ? 'desktop' : 'mobile'}
            />
          </Container>
        )
      },
      [classes.firstItem, classes.item, currentUser, projectId, smUp, stories]
    )

    return isUndefined(error) ? (
      itemCount === 0 ? (
        isFiltered ? (
          <NoResults />
        ) : (
          <Empty />
        )
      ) : (
        <Virtuoso
          // `atBottomStateChange` workaround for `endReached` issue.
          // See: https://github.com/petyosi/react-virtuoso/issues/182
          atBottomStateChange={() => {
            onLoadMore().catch(() => {
              // handled above
            })
          }}
          className={classes.list}
          overscan={500}
          totalCount={
            isUndefined(itemCount)
              ? 3
              : storiesLoading && hasNextPage
              ? itemCount + 1
              : itemCount
          }
          item={Item}
          ref={mergeRefs([forwardedRef, virtuoso])}
          {...rest}
        />
      )
    ) : (
      <Container className={classes.error}>
        <Alert severity="error">
          <AlertTitle>Stories failed to load</AlertTitle>
          {error}
        </Alert>
      </Container>
    )
  }
)
