import hotjar from '@hotjar/browser'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Link from '@material-ui/core/Link'
import { isUndefined } from 'lodash'
import React, { useCallback } from 'react'
import LinkedInTag from 'react-linkedin-insight'
import { Link as RouterLink } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { config } from '../../../config'
import { privacyPolicyUri } from '../../../middleware'

type CookieConsentDialogProps = Omit<DialogProps, 'open'>

export const CookieConsentDialog: React.FC<CookieConsentDialogProps> = (
  props
) => {
  const [cookies, setCookie] = useCookies([config.cookieConsentKey])
  const cookie = cookies[config.cookieConsentKey] as string | undefined

  if (!isUndefined(cookie) && cookie === 'true' && config.enableTracking) {
    // Google Analytics
    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    })

    // Facebook Pixel
    fbq('consent', 'grant')

    if (!isUndefined(config.linkedinPartnerId))
      LinkedInTag.init(config.linkedinPartnerId, 'px')
    if (LinkedInTag.verifyInit()) LinkedInTag.track()

    // Hotjar
    if (
      !isUndefined(config.hotjar.id) &&
      !isUndefined(config.hotjar.snippetVersion)
    )
      hotjar.init(config.hotjar.id, config.hotjar.snippetVersion)
  }

  const onAccept = useCallback(() => {
    setCookie(config.cookieConsentKey, true, { path: '/' })
  }, [setCookie])

  const onDecline = useCallback(() => {
    setCookie(config.cookieConsentKey, false, { path: '/' })
  }, [setCookie])

  return (
    <Dialog
      aria-labelledby="cookie-consent-dialog-title"
      aria-describedby="cookie-consent-dialog-description"
      open={isUndefined(cookie)}
      {...props}
    >
      <DialogTitle id="cookie-consent-dialog-title">
        Our site uses cookies
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="cookie-consent-dialog-description">
          We use cookies and similar tools to enable services and enhance the
          user experience. To learn more, please read our&nbsp;
          <Link component={RouterLink} to={privacyPolicyUri()} variant="body1">
            Privacy policy
          </Link>
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onDecline} variant="outlined">
          Decline
        </Button>
        <Button color="primary" onClick={onAccept} variant="contained">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  )
}
