import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ProjectUserListItem, ProjectUserListItemProps } from '../../..'
import { VirtualListItemProps } from '../VirtualList'

export interface ProjectUsersListRowProps
  extends Omit<ProjectUserListItemProps, 'projectUser'> {
  projectUsers: ProjectUserListItemProps['projectUser'][]
}

const useStyles = makeStyles(() =>
  createStyles({
    loadingContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export const ProjectUsersListRow = ({
  data,
  index,
  loading,
  style,
}: VirtualListItemProps<ProjectUsersListRowProps>) => {
  const classes = useStyles()
  const { projectUsers } = data

  return (
    <div style={style as React.CSSProperties} key={index}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress aria-label="Loading more" />
        </div>
      ) : (
        <ProjectUserListItem projectUser={projectUsers[index]} {...data} />
      )}
    </div>
  )
}
