import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { config } from '../../../config'
import { goToSignUp } from '../../../middleware'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: config.colours.darkGrey,
      padding: theme.spacing(2, 3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4, 3),
      },
    },
    innerContainer: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '1fr',
      gridRowGap: theme.spacing(2),
      gridColumnGap: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '2fr 1fr',
      },
    },
    text: {
      color: 'white',
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
      },
    },
  })
)

const buttonText = "Sign up (it's free)"

export const CTA: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()

  const onSignUpClick = useCallback(() => {
    goToSignUp(history)
  }, [history])

  return (
    <div className={classes.container}>
      <Container
        className={classes.innerContainer}
        disableGutters={true}
        maxWidth="md"
      >
        <Typography className={classes.text} component="h3" variant="h5">
          Start building your brief online today
        </Typography>
        <Button
          color="primary"
          fullWidth={true}
          onClick={onSignUpClick}
          variant="contained"
        >
          {buttonText}
        </Button>
      </Container>
    </div>
  )
}
