import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { first, isEmpty, isNil, isUndefined, mapValues } from 'lodash'
import {
  forgotPasswordValidate,
  goToLogin,
  useForgotPasswordMutation,
} from '../../../../middleware'
import { getGraphQLError, getGraphQLValidationErrors } from '../../../../util'
import {
  ForgotPasswordForm,
  ForgotPasswordFormProps,
} from '../ForgotPasswordForm'

export type UserForgotPasswordFormProps = Omit<
  ForgotPasswordFormProps,
  'loading'
>

interface Fields {
  email: string
}

const initialState = {
  email: '',
}

export const UserForgotPasswordForm: React.FC<UserForgotPasswordFormProps> = (
  props
) => {
  const history = useHistory()
  const [state, setState] = useState<Fields>(initialState)

  const [
    forgotPassword,
    { data, loading, error: forgotPasswordError },
  ] = useForgotPasswordMutation({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to request forgot password.${errorText}`,
        fatal: true,
      })
    },
  })

  const submitted = !isNil(data) && data.forgotPassword

  const error = useMemo(() => {
    if (isUndefined(forgotPasswordError)) return

    return first(getGraphQLError(forgotPasswordError))
  }, [forgotPasswordError])

  const errorsLocal = useMemo(() => {
    const errors = forgotPasswordValidate(state)

    return mapValues(errors, (n) => first(n))
  }, [state])

  const errorsServer = useMemo(() => {
    if (isUndefined(forgotPasswordError)) return {}

    const errors = first(getGraphQLValidationErrors(forgotPasswordError))

    return mapValues(errors, (n) => first(n))
  }, [forgotPasswordError])

  const onEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState({ ...state, email: event.currentTarget.value })
    },
    [state]
  )

  const onLogin = useCallback(() => {
    goToLogin(history)
  }, [history])

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (!isEmpty(errorsLocal)) return

      forgotPassword({
        variables: state,
      }).catch((_) => {
        setState(initialState)

        // Handled above in `forgotPasswordError`
        // See: https://github.com/apollographql/apollo-client/issues/3963
      })
    },
    [errorsLocal, forgotPassword, state]
  )

  return (
    <ForgotPasswordForm
      error={error}
      emailField={{
        disabled: loading,
        error:
          (Boolean(state.email) && Boolean(errorsLocal.email)) ||
          Boolean(errorsServer.email),
        helperText:
          Boolean(state.email) && !isUndefined(errorsLocal.email)
            ? errorsLocal.email
            : errorsServer.email,
        value: state.email,
        onChange: onEmailChange,
      }}
      loading={loading}
      loginButton={{
        onClick: onLogin,
      }}
      submitted={submitted}
      onSubmit={onSubmit}
      {...props}
    />
  )
}
