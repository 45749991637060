import { isEmpty, isNaN, isUndefined } from 'lodash'
export * from './loveAmount'
export * from './projectPermissions'

const apiUriBase = process.env.REACT_APP_API_URI

if (isUndefined(apiUriBase) || isEmpty(apiUriBase))
  throw new Error(
    'REACT_APP_API_URI env var has not been set. E.g. REACT_APP_API_URI=https://api.loveyourproject.com'
  )

const hotjarId = Number(process.env.REACT_APP_HOTJAR_ID)
const hotjarSnippetVersion = Number(
  process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
)

export const config = {
  appName: process.env.REACT_APP_APP_NAME,
  colours: {
    darkBorder: 'rgba(0, 0, 0, 0.15)',
    darkButtonText: 'rgba(0, 0, 0, 0.6)',
    darkSelected: 'rgba(0, 0, 0, 0.06)',
    lightText: 'rgba(255,255,255, 0.85)',
    midGrey: '#F1F1F1',
    darkGrey: '#4D4D4D',
    primary: '#1C8A8A',
    greyText: '#888888',
  },
  cookieConsentKey: 'loveyourproject-cookie-consent',
  email: {
    support:
      process.env.REACT_APP_SUPPORT_EMAIL ?? 'support@loveyourproject.com',
  },
  enableTracking: process.env.REACT_APP_ENABLE_TRACKING === 'true',
  env: process.env.NODE_ENV,
  fbPixelId: process.env.REACT_APP_FB_PIXEL_ID,
  gaMeasurementId: process.env.REACT_APP_GA_MEASUREMENT_ID,
  hotjar: {
    id: !isNaN(hotjarId) ? hotjarId : undefined,
    snippetVersion: !isNaN(hotjarSnippetVersion)
      ? hotjarSnippetVersion
      : undefined,
  },
  linkedinPartnerId: process.env.REACT_APP_LINKEDIN_PARTNER_ID,
  linkedinEvents: {
    addBillingInfo: '13635818',
    addToCart: '13635826',
    completeSignup: '13540770',
    login: '13635882',
    purchase: '13635834',
    signup: '13635810',
    startCheckout: '13635842',
  },
  rest: {
    importStoriesUri: `${apiUriBase}/import/stories`,
  },
  spacingUnit: 8,
  graphql: {
    uri: `${apiUriBase}/graphql`,
  },
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
}
