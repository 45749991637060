import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { Fragment } from 'react'
import { AuthLayout, SignUpForm } from '../../../components'

const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      marginBottom: theme.spacing(5),
    },
  })
)

export const SignUpPage: React.FC = () => {
  const classes = useStyles()

  return (
    <AuthLayout>
      <Fragment>
        <Typography align="center" variant="h3" gutterBottom={true}>
          Sign up
        </Typography>
        <Typography align="center" className={classes.description}>
          Please fill in the information below to sign up.
        </Typography>
        <SignUpForm />
      </Fragment>
    </AuthLayout>
  )
}
