import { NormalizedCacheObject, ApolloClient } from '@apollo/client'
import { CachePersistor } from 'apollo3-cache-persist'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Storage } from '../Storage'

export interface StorageProviderProps {
  children?: React.ReactNode
  client: ApolloClient<NormalizedCacheObject>
  persistor: CachePersistor<NormalizedCacheObject>
}

interface State {
  error: boolean
  loading: boolean
}

export const StorageContext = createContext({
  resetStorage: () => {},
})

const defaultState = {
  error: false,
  loading: true,
}

export const StorageProvider: React.FC<StorageProviderProps> = ({
  client,
  persistor,
  ...rest
}) => {
  const [{ error, loading }, setState] = useState<State>(defaultState)

  const resetStorage = useCallback(async () => {
    setState(defaultState)

    try {
      persistor.pause()
      await persistor.purge()
      await client.clearStore()
      persistor.resume()

      setState({ error: false, loading: false })
    } catch (e) {
      gtag('event', 'exception', {
        description: `Failed to reset storage.${String(e)}`,
        fatal: true,
      })
      console.log(e)
      setState({ error: true, loading: false })
    }
  }, [client, persistor])

  useEffect(() => {
    persistor
      .restore()
      .then(() => {
        setState({ error: false, loading: false })
      })
      .catch((e) => {
        gtag('event', 'exception', {
          description: `Failed to reset storage.${String(e)}`,
          fatal: true,
        })
        console.log(e)
        setState({ error: true, loading: false })
      })
  }, [persistor])

  return (
    <StorageContext.Provider
      value={{
        resetStorage: () => {
          resetStorage().catch(() => {
            // handled above
          })
        },
      }}
    >
      <Storage
        error={error}
        loading={loading}
        // eslint-disable-next-line sonarjs/no-identical-functions
        onResetStorage={() => {
          resetStorage().catch(() => {
            // handled above
          })
        }}
        {...rest}
      />
    </StorageContext.Provider>
  )
}

export const useStorageContext = () => useContext(StorageContext)
