import { first as firstItem, isString, isUndefined } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  goToProject,
  goToUpdateProject,
  useProjectsQuery,
} from '../../../../middleware'
import { getGraphQLError } from '../../../../util'
import { ProjectsListRow, ProjectsListRowProps } from './ProjectsListRow'
import { VirtualList, VirtualListProps } from '../VirtualList'

export interface ProjectsListProps
  extends Omit<
    VirtualListProps<ProjectsListRowProps>,
    | 'children'
    | 'hasNextPage'
    | 'itemCount'
    | 'listProps'
    | 'loading'
    | 'loadMore'
    | 'onClick'
  > {
  first?: number
  onClick: (project: { id: string }) => void
}

export const ProjectsList: React.FC<ProjectsListProps> = ({
  first = 50,
  onClick,
  ...rest
}) => {
  const history = useHistory()
  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
    fetchMore,
  } = useProjectsQuery({
    onError: (e) => {
      const error = firstItem(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to read projects.${errorText}`,
        fatal: false,
      })
    },
    variables: {
      first,
    },
  })

  const hasNextPage = projectsData?.projects.pageInfo.hasNextPage ?? false
  const after = projectsData?.projects.pageInfo.endCursor
  const loading = projectsLoading && isUndefined(projectsData)
  const projects = projectsData?.projects.edges.map((n) => n.node) ?? []
  const itemCount = projects.length

  const onLoadMore = useCallback(async () => {
    if (projectsLoading || !hasNextPage || !isString(after)) return

    // Workaround for unmount before promise resolves
    // See: https://github.com/apollographql/apollo-client/issues/4114
    try {
      return await fetchMore({
        variables: {
          after,
        },
      })
    } catch (e) {
      console.log(e)
      return await Promise.resolve()
    }
  }, [after, fetchMore, hasNextPage, projectsLoading])

  const onProjectClick: ProjectsListRowProps['onClick'] = useCallback(
    (project) => {
      goToProject(history, project.id)
      onClick(project)
    },
    [history, onClick]
  )

  const onEdit: ProjectsListRowProps['onEdit'] = useCallback(
    (project) => {
      goToUpdateProject(history, project.id)
      onClick(project)
    },
    [history, onClick]
  )

  const error = useMemo(() => {
    if (isUndefined(projectsError)) return

    return isUndefined(projectsError)
      ? undefined
      : 'An error occured loading projects.'
  }, [projectsError])

  return (
    <VirtualList
      error={error}
      hasNextPage={hasNextPage}
      itemCount={itemCount}
      listProps={{
        itemData: {
          projects,
          onClick: onProjectClick,
          onEdit,
        },
        itemSize: 48,
      }}
      loading={loading}
      loadMore={onLoadMore}
      {...rest}
    >
      {ProjectsListRow}
    </VirtualList>
  )
}
