import Button from '@material-ui/core/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Skeleton from '@material-ui/lab/Skeleton'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useMemo } from 'react'
import { first, isNil, isUndefined, mapValues, omitBy, reduce } from 'lodash'
import { useSnackbar } from 'notistack'
import {
  updateUserValidate,
  useCurrentUser,
  useUpdateUser,
} from '../../../middleware'
import { ApiErrors, getGraphQLError } from '../../../util'

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      width: '100%',
      maxWidth: theme.spacing(45),
      marginBottom: theme.spacing(2),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    fields: {
      width: '100%',
      maxWidth: theme.spacing(45),
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(2),
      marginBottom: theme.spacing(5),
      '& >:first-child': {
        gridColumnEnd: 'span 2',
      },
    },
    actions: {
      width: '100%',
      display: 'flex',
      maxWidth: theme.spacing(45),
      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.spacing(35),
      },
      '& >*': {
        flex: 2,
      },
      '& >:not(:last-child)': {
        flex: 1,
        marginRight: theme.spacing(1),
      },
    },
  })
)

// eslint-disable-next-line sonarjs/cognitive-complexity
export const UpdateUserForm: React.FC = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useCurrentUser()
  const [updateUser] = useUpdateUser()

  const error = useMemo(
    () =>
      isUndefined(userError) ? undefined : first(getGraphQLError(userError)),
    [userError]
  )

  const initialValues = isUndefined(userData)
    ? {
        email: '',
        firstName: '',
        lastName: '',
      }
    : {
        email: userData.currentUser?.email,
        firstName: userData.currentUser?.firstName,
        lastName: userData.currentUser?.lastName,
      }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={(values) =>
        mapValues(updateUserValidate(values), (n) => first(n))
      }
      onSubmit={(values) => {
        const changedValues = reduce(
          values,
          (result, value, key) =>
            initialValues[key as keyof typeof values] === value
              ? result
              : { ...result, [key]: value },
          {}
        )

        const currentUser = userData?.currentUser
        const currentUserId = userData?.currentUser?.id

        updateUser({
          variables: changedValues,
          optimisticResponse: isNil(currentUser)
            ? undefined
            : (variables) => ({
                __typename: 'Mutation',
                updateUser: {
                  __typename: 'User',
                  ...currentUser,
                  ...omitBy(variables, isNil),
                },
              }),
        })
          .then(() => {
            enqueueSnackbar(`Successfully updated your user information.`, {
              variant: 'success',
            })
          })
          .catch((e) => {
            const error = first(getGraphQLError(e as ApiErrors))
            const errorText = isUndefined(error) ? '' : ` ${error}`
            const currentUserIdText = !isUndefined(currentUserId)
              ? ` ${currentUserId}`
              : ''

            gtag('event', 'exception', {
              description: `Failed to update${currentUserIdText} user.${errorText}`,
              fatal: false,
            })
            enqueueSnackbar(
              `Failed to update your user information.${errorText}`,
              {
                variant: 'error',
              }
            )
          })
      }}
    >
      {({ dirty, resetForm, submitForm, isSubmitting }) => (
        <Form className={classes.form}>
          {isUndefined(error) ? undefined : (
            <Alert className={classes.error} severity="error">
              <AlertTitle>User failed to load</AlertTitle>
              {error}
            </Alert>
          )}
          {isUndefined(userData) && userLoading ? (
            <div className={classes.fields}>
              <Skeleton height={52} title="Loading email" variant="text" />
              <Skeleton height={52} title="Loading first name" variant="text" />
              <Skeleton height={52} title="Loading lastName" variant="text" />
            </div>
          ) : (
            <div className={classes.fields}>
              <Field
                component={TextField}
                FormHelperTextProps={{
                  'aria-label': 'Email helper text',
                }}
                id="email-field"
                label="Email"
                name="email"
                variant="outlined"
              />
              <Field
                component={TextField}
                FormHelperTextProps={{
                  'aria-label': 'First name helper text',
                }}
                id="first-name-field"
                label="First name"
                name="firstName"
                variant="outlined"
              />
              <Field
                component={TextField}
                FormHelperTextProps={{
                  'aria-label': 'Last name helper text',
                }}
                id="last-name-field"
                label="Last name"
                name="lastName"
                variant="outlined"
              />
            </div>
          )}
          <div className={classes.actions}>
            <Button
              variant="outlined"
              disabled={!dirty || isSubmitting}
              onClick={() => resetForm()}
            >
              Undo
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!dirty || isSubmitting}
              onClick={() => {
                submitForm().catch(() => {
                  // handled above
                })
              }}
            >
              Update
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
