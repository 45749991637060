import AvatarGroup, { AvatarGroupProps } from '@material-ui/lab/AvatarGroup'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import { isUndefined } from 'lodash'
import React from 'react'
import { UserAvatar } from '../..'
import { useProjectUsersQuery } from '../../../middleware'

interface ProjectUsersAvatarGroupProps
  extends Omit<AvatarGroupProps, 'children'> {
  projectId: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    avatarGroup: {
      zIndex: 0, // Prevent overlap with other components
      '& >*': {
        width: 36,
        height: 36,
        boxSizing: 'content-box', // temp until updated. See: https://github.com/mui-org/material-ui/issues/21700#issuecomment-654935690
        borderColor: theme.palette.common.white,
        // temp until it can be overridden universally.
        // See: https://github.com/mui-org/material-ui/issues/21844
        fontSize: theme.typography.body2.fontSize,
        letterSpacing: theme.typography.body2.letterSpacing,
        boxShadow: theme.shadows[1],
      },
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      '& >:first-child': {
        marginTop: 0,
      },
      '& >*': {
        marginLeft: 0,
        marginTop: theme.spacing(-1),
      },
    },
  })
)

export const ProjectUsersAvatarGroup: React.FC<ProjectUsersAvatarGroupProps> = ({
  projectId,
  ...rest
}) => {
  const classes = useStyles()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { data } = useProjectUsersQuery({
    variables: {
      id: projectId,
    },
  })

  const projectUsers = data?.project.projectUsers

  return isUndefined(projectUsers) ? null : (
    <AvatarGroup
      className={clsx([classes.avatarGroup, { [classes.vertical]: !smUp }])}
      max={smUp ? 4 : 3}
      {...rest}
    >
      {projectUsers.map((projectUser) => (
        <UserAvatar key={projectUser.id} user={projectUser.user} />
      ))}
    </AvatarGroup>
  )
}
