import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { first, isUndefined } from 'lodash'
import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useTokenQuery } from '../../../middleware'
import { getGraphQLError } from '../../../util'

export interface LoggedInRouteProps extends RouteProps {
  allowLoggedIn?: boolean
  children?: React.ReactNode
  redirectComponent: React.ReactNode
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export const LoggedInRoute: React.FC<LoggedInRouteProps> = ({
  allowLoggedIn = true,
  children,
  redirectComponent,
  ...rest
}) => {
  const classes = useStyles()
  const { data, loading, error } = useTokenQuery({
    onError: (e) => {
      const error = first(getGraphQLError(e))
      const errorText = isUndefined(error) ? '' : ` ${error}`

      gtag('event', 'exception', {
        description: `Failed to read token.${errorText}`,
        fatal: true,
      })
    },
  })
  const loggedIn = Boolean(data?.token?.accessToken)
  const canAccess = loggedIn === allowLoggedIn

  return (
    <Route {...rest}>
      {loading ? (
        <div className={classes.container}>
          <CircularProgress aria-label="Loading" />
        </div>
      ) : !isUndefined(error) ? (
        <Container className={classes.container} maxWidth="sm">
          <Alert severity="error">
            <AlertTitle>Logged in status failed to load</AlertTitle>
            Please try a refresh. If this persists, please try closing all tabs
            with the site open and then returning in a new tab.
          </Alert>
        </Container>
      ) : canAccess ? (
        children
      ) : (
        redirectComponent
      )}
    </Route>
  )
}
