import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { config } from '../../../config'
import { goToLogin, goToSignUp } from '../../../middleware'

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      padding: theme.spacing(1, 0),
      pointerEvents: 'none',
    },
    appBarButton: {
      pointerEvents: 'auto',
    },
    headerRight: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridColumnGap: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        gridColumnGap: theme.spacing(2),
      },
    },
    logoContainer: {
      display: 'contents',
      width: '100%',
      maxWidth: 171,
      maxHeight: 35,
      pointerEvents: 'auto',
    },
    logo: {
      width: '100%',
      maxWidth: 171,
      maxHeight: 35,
    },
    toolbar: {
      display: 'grid',
      gridColumnGap: 24,
      gridTemplateColumns: '1fr fit-content(179px)',
    },
  })
)

const logoWithName = '/images/logo_with_name.svg'

export const NavBar = () => {
  const classes = useStyles()
  const history = useHistory()

  const onLoginClick = useCallback(() => {
    goToLogin(history)
  }, [history])

  const onSignUpClick = useCallback(() => {
    goToSignUp(history)
  }, [history])

  return (
    <AppBar
      className={classes.appBar}
      color="transparent"
      elevation={0}
      position="fixed"
    >
      <Container disableGutters={true} maxWidth="lg">
        <Toolbar className={classes.toolbar}>
          <Link className={classes.logoContainer} to={'/'}>
            <img
              className={classes.logo}
              src={logoWithName}
              alt={`${config.appName ?? ''}`}
            />
          </Link>
          <div className={classes.headerRight}>
            <Fab
              aria-label="Login"
              className={classes.appBarButton}
              variant="extended"
              onClick={onLoginClick}
            >
              Login
            </Fab>
            <Fab
              aria-label="Sign up"
              className={classes.appBarButton}
              color="primary"
              variant="extended"
              onClick={onSignUpClick}
            >
              Sign up
            </Fab>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
