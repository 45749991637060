import { MockResponse, NestJsError } from '../../../../util'
import {
  DeleteStoryDocument,
  DeleteStoryMutationResult,
  DeleteStoryMutationVariables,
} from '../../graphql'
import { storiesSuccessMock } from '../../queries'

type DeleteStoryMockResponse = MockResponse<
  DeleteStoryMutationVariables,
  Pick<DeleteStoryMutationResult, 'data'> & {
    errors?: { message: NestJsError }[]
  }
>

export const deleteStorySuccessMock = {
  request: {
    query: DeleteStoryDocument,
    variables: {
      id: storiesSuccessMock.result.data.stories.edges[0].node.id,
    },
  },
  result: {
    data: {
      deleteStory: storiesSuccessMock.result.data.stories.edges[0].node,
    },
  },
}

export const deleteStoryFailureMock = {
  request: {
    query: DeleteStoryDocument,
    variables: {
      id: storiesSuccessMock.result.data.stories.edges[1].node.id,
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 404,
          error: 'Not found',
          message: 'Story not found.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const deleteStoryMocks: DeleteStoryMockResponse[] = [
  deleteStorySuccessMock,
  deleteStoryFailureMock,
]
