import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ListLayout } from '../../../components'

export const TermsOfUsePage: React.FC = () => {
  const history = useHistory()

  const onBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <ListLayout
      headerLeft={
        <Fab variant="extended" aria-label="Back" onClick={onBack}>
          <ArrowBackIosIcon style={{ fontSize: 18 }} />
          Back
        </Fab>
      }
    >
      <Container maxWidth="sm">
        <div
          dangerouslySetInnerHTML={{
            // See legal html doc for more (comment below isn't mine)
            __html: `
        
			<style>
      /*  The CSS below this comment is very important and must be added to the page that displays the document. 
      Without these styles, the numbering, and display of numbers doesn't work.
      Be careful with your own site styles, they may interfere with the display of your document.
      You might need to add to the styles below to get the style you'd like.
      */
      .part ol {
      counter-reset: sectioncounter partcounter;
      margin: 0px;
      padding: 0px;
      }
      ol.sections {
      counter-reset: sectioncounter;
      margin: 0px;
      padding: 0px;
      }
      ol.sections li {
      list-style: none;
      margin-left: 2rem;
      position: relative;
      }
      ol.units {
      counter-reset: unitcounter;
      margin: 0 0 2rem 0;
      }
      ol.sections li.partTitle {
      list-style: none;
      counter-increment: none;
      counter-reset: numberedpart;
      margin-left: 0;
      }
      ol.sections li.sectionTitle {
      list-style: none;
      counter-increment: none;
      counter-reset: numberedsection;
      margin-left: 0;
      }
      ol.sections li.unnumberedunit {
      margin-left: 0;
      }
      .sections li:before {
      position: absolute;
      left: -2rem;
      }
      .sections li.numberedpart:before {
      content: counter(partcounter, DECIMAL) ". ";
      counter-increment: partcounter;
      }
      .sections li.numberedsection:before {
      content: counter(sectioncounter, DECIMAL) ". ";
      counter-increment: sectioncounter;
      }
      .units li.numberedunit:before {
      content: counter(sectioncounter, DECIMAL) "." counter(unitcounter, DECIMAL) " ";
      counter-increment: unitcounter;
      }
      .unnumberedsection .units li.numberedunit:before {
      content: counter(unitcounter, DECIMAL) " ";
      counter-increment: unitcounter;
      }
      ol.sections li.definitionli {
      margin-left: 0;
      }
      li.unit ol,
      li.unit ol ol ol {
      counter-reset: loweralpha;
      }
      li.unit ol:not(.unnumbered) li:before,
      li.unit ol ol ol:not(.unnumbered) li:before {
      content: "(" counter(loweralpha, lower-alpha) ") ";
      counter-increment: loweralpha;
      }
      li.unit ol ol,
      li.unit ol ol ol ol {
      counter-reset: lowerroman;
      }
      li.unit ol ol:not(.unnumbered) li:before,
      li.unit ol ol ol ol:not(.unnumbered) li:before {
      content: "(" counter(lowerroman, lower-roman) ") ";
      counter-increment: lowerroman;
      }
      li.unit ol.unnumbered ol li:before,
      li.unit ol ol ol.unnumbered ol li:before {
      content: "(" counter(lowerroman, lower-alpha) ") ";
      counter-increment: lowerroman;
      }
      .part dfn {
      font-weight: bold;
      }
   </style>

   <h1>Love your project service terms and conditions</h1>
   <section class="part unnumberedpart">
      <ol class="sections">
         <li class="partTitle">
            <h2>Terms and Conditions</h2>
         </li>
         <li class="section unnumberedsection">
            <ol class="units">
               <li class="unit unnumberedunit">Please read these Terms and Conditions carefully. All contracts that the Provider may enter into from time to time for the provision of the Hosted Services and related services shall be governed by these Terms and Conditions, and the Provider will ask for the Customer's express written acceptance of these Terms and Conditions before providing any such services to the Customer.</li>
            </ol>
         </li>
      </ol>
   </section>
   <section class="part unnumberedpart">
      <ol class="sections">
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Definitions</h3>
               </li>
               <li class="unit numberedunit">
                  In these Terms and Conditions:
                  <ol class="definitions unnumbered">
                     <li class="definitionli">"<dfn>Access Credentials</dfn>" means the usernames, passwords and other credentials enabling access to the Hosted Services, including both access credentials for the User Interface and access credentials for the API;</li>
                     <li class="definitionli">"<dfn>Agreement</dfn>" means a contract made under these Terms and Conditions between the Provider and the Customer;</li>
                     <li class="definitionli">"<dfn>API</dfn>" means the application programming interface for the Hosted Services defined by the Provider and made available by the Provider to the Customer;</li>
                     <li class="definitionli">"<dfn>Business Day</dfn>" means any weekday other than a bank or public holiday in England;</li>
                     <li class="definitionli">"<dfn>Business Hours</dfn>" means the hours of 09:00 to 17:00 GMT/BST on a Business Day;</li>
                     <li class="definitionli">"<dfn>Charges</dfn>" means the amounts specified in My account page or email invoices from time to time;</li>
                     <li class="definitionli">"<dfn>Customer</dfn>" means the person or entity identified as such in the Services Order Form;</li>
                     <li class="definitionli">"<dfn>Customer Data</dfn>" means all data, works and materials: uploaded to or stored on the Platform by the Customer; transmitted by the Platform at the instigation of the Customer; supplied by the Customer to the Provider for uploading to, transmission by or storage on the Platform; or generated by the Platform as a result of the use of the Hosted Services by the Customer (but excluding analytics data relating to the use of the Platform and server log files);</li>
                     <li class="definitionli">"<dfn>Customer Personal Data</dfn>" means any Personal Data that is processed by the Provider on behalf of the Customer in relation to the Agreement;</li>
                     <li class="definitionli">"<dfn>Data Protection Laws</dfn>" means all applicable laws relating to the processing of Personal Data including, while they are in force and applicable, the United Kingdom's Data Protection Act 2018 and the General Data Protection Regulation (Regulation (EU) 2016/679);</li>
                     <li class="definitionli">"<dfn>Documentation</dfn>" means the documentation for the Hosted Services produced by the Provider and delivered or made available by the Provider to the Customer;</li>
                     <li class="definitionli">"<dfn>Effective Date</dfn>" means following the Customer completing and submitting the online Services Order Form published by the Provider on the Provider's website, the date upon which the Provider sends to the Customer an order confirmation;</li>
                     <li class="definitionli">"<dfn>Force Majeure Event</dfn>" means an event, or a series of related events, that is outside the reasonable control of the party affected (including failures of the internet or any public telecommunications network, hacker attacks, denial of service attacks, virus or other malicious software attacks or infections, power failures, industrial disputes affecting any third party, changes to the law, disasters, explosions, fires, floods, riots, terrorist attacks and wars);</li>
                     <li class="definitionli">"<dfn>Hosted Services</dfn>" means loveyourproject.com, which will be made available by the Provider to each Customer as a service via the internet in accordance with these Terms and Conditions;</li>
                     <li class="definitionli">"<dfn>Intellectual Property Rights</dfn>" means all intellectual property rights wherever in the world, whether registrable or unregistrable, registered or unregistered, including any application or right of application for such rights (and these "intellectual property rights" include copyright and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trade marks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility models, semi-conductor topography rights and rights in designs);</li>
                     <li class="definitionli">"<dfn>Mobile App</dfn>" means the mobile application known as loveyourproject.com that is made available by the Provider through loveyourproject.com;</li>
                     <li class="definitionli">"<dfn>Personal Data</dfn>" has the meaning given to it in the Data Protection Laws applicable in the United Kingdom from time to time;</li>
                     <li class="definitionli">"<dfn>Platform</dfn>" means the platform managed by the Provider and used by the Provider to provide the Hosted Services, including the application and database software for the Hosted Services, the system and server software used to provide the Hosted Services, and the computer hardware on which that application, database, system and server software is installed;</li>
                     <li class="definitionli">"<dfn>Provider</dfn>" means Neverglitch Ltd, a company incorporated in England and Wales (registration number 10707455) having its registered office at Neverglitch Ltd, Cheyenne House, West Street, Farnham, Surrey, GU9 7EQ, United Kingdom;</li>
                     <li class="definitionli">"<dfn>Services</dfn>" means any services that the Provider provides to the Customer, or has an obligation to provide to the Customer, under these Terms and Conditions;</li>
                     <li class="definitionli">"<dfn>Services Order Form</dfn>" means an online order form published by the Provider and completed and submitted by the Customer incorporating these Terms and Conditions by reference;</li>
                     <li class="definitionli">"<dfn>Support Services</dfn>" means support in relation to the use of, and the identification and resolution of errors in, the Hosted Services, but shall not include the provision of training services;</li>
                     <li class="definitionli">"<dfn>Supported Web Browser</dfn>" means the current release from time to time of Microsoft Edge, Mozilla Firefox, Google Chrome or Apple Safari, or any other web browser that the Provider agrees in writing shall be supported;</li>
                     <li class="definitionli">"<dfn>Term</dfn>" means the term of the Agreement, commencing in accordance with Clause 2.1 and ending in accordance with Clause 2.2;</li>
                     <li class="definitionli">"<dfn>Terms and Conditions</dfn>" means all the documentation containing the provisions of the Agreement, namely the main body of these Terms and Conditions and Schedule 1 (Acceptable Use Policy), including any amendments to that documentation from time to time; and</li>
                     <li class="definitionli">"<dfn>User Interface</dfn>" means the interface for the Hosted Services designed to allow individual human users to access and use the Hosted Services.</li>
                  </ol>
               </li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Term</h3>
               </li>
               <li class="unit numberedunit">The Agreement shall come into force upon the Effective Date.</li>
               <li class="unit numberedunit">The Agreement shall continue in force indefinitely, subject to termination in accordance with Clause 14 or any other provision of these Terms and Conditions.</li>
               <li class="unit numberedunit">Unless the parties expressly agree otherwise in writing, each Services Order Form shall create a distinct contract under these Terms and Conditions.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Hosted Services</h3>
               </li>
               <li class="unit numberedunit">The Provider hereby grants to the Customer a non-exclusive licence to use the Hosted Services by means of a the User Interface and the API in accordance with the Documentation during the Term.</li>
               <li class="unit numberedunit">
                  The licence granted by the Provider to the Customer under Clause 3.1 is subject to the following limitations:
                  <ol class="listitem  ">
                     <li>the User Interface may only be used through a Supported Web Browser or the Mobile App;</li>
                     <li>the User Interface may only be used by the named users identified in project user invites, providing that the Customer may change, add or remove a designated named user in accordance with the procedure set out therein;</li>
                     <li>the User Interface must not be used at any point in time by more than the number of concurrent users specified in project user invites, providing that the Customer may add or remove concurrent user licences in accordance with the procedure set out therein; and</li>
                     <li class="">the API may only be used by an application or applications controlled by the Customer.</li>
                  </ol>
               </li>
               <li class="unit numberedunit">
                  Except to the extent expressly permitted in these Terms and Conditions or required by law on a non-excludable basis, the licence granted by the Provider to the Customer under Clause 3.1 is subject to the following prohibitions:
                  <ol class="listitem  ">
                     <li>the Customer must not sub-license its right to access and use the Hosted Services;</li>
                     <li>the Customer must not permit any unauthorised person or application to access or use the Hosted Services;</li>
                     <li>the Customer must not use the Hosted Services to provide services to third parties;</li>
                     <li>the Customer must not republish or redistribute any content or material from the Hosted Services;</li>
                     <li>the Customer must not make any alteration to the Platform, except as permitted by the Documentation; and</li>
                     <li>the Customer must not conduct or request that any other person conduct any load testing or penetration testing on the Platform or Hosted Services without the prior written consent of the Provider.</li>
                  </ol>
               </li>
               <li class="unit numberedunit">The Customer shall implement and maintain reasonable security measures relating to the Access Credentials to ensure that no unauthorised person or application may gain access to the Hosted Services by means of the Access Credentials.</li>
               <li class="unit numberedunit">The Provider shall use reasonable endeavours to maintain the availability of the Hosted Services to the Customer at the gateway between the public internet and the network of the hosting services provider for the Hosted Services, but does not guarantee 100% availability.</li>
               <li class="unit numberedunit">The Customer must comply with Schedule 1 (Acceptable Use Policy), and must ensure that all persons using the Hosted Services with the authority of the Customer or by means of the Access Credentials comply with Schedule 1 (Acceptable Use Policy).</li>
               <li class="unit numberedunit">The Customer must not use the Hosted Services in any way that causes, or may cause, damage to the Hosted Services or Platform or impairment of the availability or accessibility of the Hosted Services.</li>
               <li class="unit numberedunit">The Customer must not use the Hosted Services in any way that uses excessive Platform resources and as a result is liable to cause a material degradation in the services provided by the Provider to its other customers using the Platform; and the Customer acknowledges that the Provider may use reasonable technical measures to limit the use of Platform resources by the Customer for the purpose of assuring services to its customers generally.</li>
               <li class="unit numberedunit">
                  The Customer must not use the Hosted Services:
                  <ol class="listitem  ">
                     <li>in any way that is unlawful, illegal, fraudulent or harmful; or</li>
                     <li>in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</li>
                  </ol>
               </li>
               <li class="unit numberedunit">For the avoidance of doubt, the Customer has no right to access the software code (including object code, intermediate code and source code) of the Platform, either during or after the Term.</li>
               <li class="unit numberedunit">The Provider may suspend the provision of the Hosted Services if any amount due to be paid by the Customer to the Provider under the Agreement is overdue, and the Provider has given to the Customer at least 30 days' written notice, following the amount becoming overdue, of its intention to suspend the Hosted Services on this basis.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Customer Data</h3>
               </li>
               <li class="unit numberedunit">The Customer hereby grants to the Provider a non-exclusive licence to copy, reproduce, store, distribute, publish, export, adapt, edit and translate the Customer Data to the extent reasonably required for the performance of the Provider's obligations and the exercise of the Provider's rights under the Agreement. The Customer also grants to the Provider the right to sub-license these rights to its hosting, connectivity and telecommunications service providers, subject to any express restrictions elsewhere in the Agreement.</li>
               <li class="unit numberedunit">The Customer warrants to the Provider that the Customer Data will not infringe the Intellectual Property Rights or other legal rights of any person, and will not breach the provisions of any law, statute or regulation, in any jurisdiction and under any applicable law.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Support Services</h3>
               </li>
               <li class="unit numberedunit">The Provider may provide the Support Services to the Customer during the Term, but shall have no obligation to do so; any such Support Services shall be subject to this Clause 5.</li>
               <li class="unit numberedunit">The Provider may make available to the Customer an email-based helpdesk.</li>
               <li class="unit numberedunit">The Provider shall provide the Support Services with reasonable skill and care.</li>
               <li class="unit numberedunit">The Customer may use the helpdesk for the purposes of requesting and, where applicable, receiving the Support Services; and the Customer must not use the helpdesk for any other purpose.</li>
               <li class="unit numberedunit">The Provider shall respond promptly to all requests for Support Services made by the Customer through the helpdesk.</li>
               <li class="unit numberedunit">The Provider may suspend the provision of the Support Services if any amount due to be paid by the Customer to the Provider under the Agreement is overdue, and the Provider has given to the Customer at least 30 days' written notice, following the amount becoming overdue, of its intention to suspend the Support Services on this basis.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>No assignment of Intellectual Property Rights</h3>
               </li>
               <li class="unit numberedunit">Nothing in these Terms and Conditions shall operate to assign or transfer any Intellectual Property Rights from the Provider to the Customer, or from the Customer to the Provider.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Charges</h3>
               </li>
               <li class="unit numberedunit">The Customer shall pay the Charges to the Provider in accordance with these Terms and Conditions.</li>
               <li class="unit numberedunit">All amounts stated in or in relation to these Terms and Conditions are, unless the context requires otherwise, stated exclusive of any applicable value added taxes, which will be added to those amounts and payable by the Customer to the Provider.</li>
               <li class="unit numberedunit">The Provider may elect to vary any element of the Charges by giving to the Customer not less than 30 days' written notice of the variation.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Payments</h3>
               </li>
               <li class="unit numberedunit">The Customer must pay the Charges to the Provider in advance of the period to which they relate.</li>
               <li class="unit numberedunit">
                  If the Customer does not pay any amount properly due to the Provider under these Terms and Conditions, the Provider may:
                  <ol class="listitem  ">
                     <li>charge the Customer interest on the overdue amount at the rate of 8% per annum above the Bank of England base rate from time to time (which interest will accrue daily until the date of actual payment and be compounded at the end of each calendar month); or</li>
                     <li>claim interest and statutory compensation from the Customer pursuant to the Late Payment of Commercial Debts (Interest) Act 1998.</li>
                  </ol>
               </li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Data protection</h3>
               </li>
               <li class="unit numberedunit">Each party shall comply with the Data Protection Laws with respect to the processing of the Customer Personal Data.</li>
               <li class="unit numberedunit">The Customer warrants to the Provider that it has the legal right to disclose all Personal Data that it does in fact disclose to the Provider under or in connection with the Agreement.</li>
               <li class="unit numberedunit">The Customer shall only supply to the Provider, and the Provider shall only process, in each case under or in relation to the Agreement, the Personal Data of users of the following types: emails, photos, first and last names; and the Provider shall only process the Customer Personal Data for the following purposes: identification and collaboration.</li>
               <li class="unit numberedunit">The Provider shall only process the Customer Personal Data during the Term and for not more than 30 days following the end of the Term, subject to the other provisions of this Clause 9.</li>
               <li class="unit numberedunit">The Provider shall only process the Customer Personal Data on the documented instructions of the Customer (including with regard to transfers of the Customer Personal Data to any place outside the European Economic Area and the United Kingdom), as set out in these Terms and Conditions or any other document agreed by the parties in writing.</li>
               <li class="unit numberedunit">The Provider shall promptly inform the Customer if, in the opinion of the Provider, an instruction of the Customer relating to the processing of the Customer Personal Data infringes the Data Protection Laws.</li>
               <li class="unit numberedunit">Notwithstanding any other provision of the Agreement, the Provider may process the Customer Personal Data if and to the extent that the Provider is required to do so by applicable law. In such a case, the Provider shall inform the Customer of the legal requirement before processing, unless that law prohibits such information on important grounds of public interest.</li>
               <li class="unit numberedunit">The Provider shall ensure that persons authorised to process the Customer Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality.</li>
               <li class="unit numberedunit">The Provider and the Customer shall each implement appropriate technical and organisational measures to ensure an appropriate level of security for the Customer Personal Data, including the measures specified in the information security policy of the Provider (as it may be updated by the Provider from time to time).</li>
               <li class="unit numberedunit">The Provider must not engage any third party to process the Customer Personal Data without the prior specific or general written authorisation of the Customer. In the case of a general written authorisation, the Provider shall inform the Customer at least 14 days in advance of any intended changes concerning the addition or replacement of any third party processor, and if the Customer objects to any such changes before their implementation, then the Customer may terminate the Agreement on 7 days' written notice to the Provider, providing that such notice must be given within the period of 7 days following the date that the Provider informed the Customer of the intended changes. The Provider shall ensure that each third party processor is subject to equivalent legal obligations as those imposed on the Provider by this Clause 9.</li>
               <li class="unit numberedunit">As at the Effective Date, the Provider is hereby authorised by the Customer to engage, as sub-processors with respect to Customer Personal Data, the following third parties and third parties within the following categories: Google, Facebook, LinkedIn, Hotjar, Mailgun and Stripe for analytics, marketing, communication and payment management.</li>
               <li class="unit numberedunit">The Provider shall, insofar as possible and taking into account the nature of the processing, take appropriate technical and organisational measures to assist the Customer with the fulfilment of the Customer's obligation to respond to requests exercising a data subject's rights under the Data Protection Laws.</li>
               <li class="unit numberedunit">The Provider shall assist the Customer in ensuring compliance with the obligations relating to the security of processing of personal data, the notification of personal data breaches to the supervisory authority, the communication of personal data breaches to the data subject, data protection impact assessments and prior consultation in relation to high-risk processing under the Data Protection Laws. The Provider may charge the Customer at its standard time-based charging rates for any work performed by the Provider at the request of the Customer pursuant to this Clause 9.13.</li>
               <li class="unit numberedunit">The Provider must notify the Customer of any Personal Data breach affecting the Customer Personal Data without undue delay.</li>
               <li class="unit numberedunit">The Provider shall make available to the Customer all information necessary to demonstrate the compliance of the Provider with its obligations under this Clause 9 and the Data Protection Laws. The Provider may charge the Customer at its standard time-based charging rates for any work performed by the Provider at the request of the Customer pursuant to this Clause 9.15, providing that no such charges shall be levied with respect to the completion by the Provider (at the reasonable request of the Customer, not more than once per calendar year) of the standard information security questionnaire of the Customer.</li>
               <li class="unit numberedunit">The Provider shall, at the choice of the Customer, delete or return all of the Customer Personal Data to the Customer after the provision of services relating to the processing, and shall delete existing copies save to the extent that applicable law requires storage of the relevant Personal Data.</li>
               <li class="unit numberedunit">The Provider shall allow for and contribute to audits, including inspections, conducted by the Customer or another auditor mandated by the Customer in respect of the compliance of the Provider's processing of Customer Personal Data with the Data Protection Laws and this Clause 9. The Provider may charge the Customer at its standard time-based charging rates for any work performed by the Provider at the request of the Customer pursuant to this Clause 9.17, providing that no such charges shall be levied where the request to perform the work arises out of any breach by the Provider of the Agreement or any security breach affecting the systems of the Provider.</li>
               <li class="unit numberedunit">If any changes or prospective changes to the Data Protection Laws result or will result in one or both parties not complying with the Data Protection Laws in relation to processing of Personal Data carried out under the Agreement, then the parties shall use their best endeavours promptly to agree such variations to the Agreement as may be necessary to remedy such non-compliance.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Warranties</h3>
               </li>
               <li class="unit numberedunit">The Customer warrants to the Provider that it has the legal right and authority to enter into the Agreement and to perform its obligations under these Terms and Conditions.</li>
               <li class="unit numberedunit">All of the parties' warranties and representations in respect of the subject matter of the Agreement are expressly set out in these Terms and Conditions. To the maximum extent permitted by applicable law, no other warranties or representations concerning the subject matter of the Agreement will be implied into the Agreement or any related contract.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Acknowledgements and warranty limitations</h3>
               </li>
               <li class="unit numberedunit">The Customer acknowledges that complex software is never wholly free from defects, errors and bugs; and subject to the other provisions of these Terms and Conditions, the Provider gives no warranty or representation that the Hosted Services will be wholly free from defects, errors and bugs.</li>
               <li class="unit numberedunit">The Customer acknowledges that complex software is never entirely free from security vulnerabilities; and subject to the other provisions of these Terms and Conditions, the Provider gives no warranty or representation that the Hosted Services will be entirely secure.</li>
               <li class="unit numberedunit">The Customer acknowledges that the Hosted Services are designed to be compatible only with that software and those systems specified as compatible in Supported web browser; and the Provider does not warrant or represent that the Hosted Services will be compatible with any other software or systems.</li>
               <li class="unit numberedunit">The Customer acknowledges that the Provider will not provide any legal, financial, accountancy or taxation advice under these Terms and Conditions or in relation to the Hosted Services; and, except to the extent expressly provided otherwise in these Terms and Conditions, the Provider does not warrant or represent that the Hosted Services or the use of the Hosted Services by the Customer will not give rise to any legal liability on the part of the Customer or any other person.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Limitations and exclusions of liability</h3>
               </li>
               <li class="unit numberedunit">
                  Nothing in these Terms and Conditions will:
                  <ol class="listitem  ">
                     <li>limit or exclude any liability for death or personal injury resulting from negligence;</li>
                     <li>limit or exclude any liability for fraud or fraudulent misrepresentation;</li>
                     <li>limit any liabilities in any way that is not permitted under applicable law; or</li>
                     <li>exclude any liabilities that may not be excluded under applicable law.</li>
                  </ol>
               </li>
               <li class="unit numberedunit">
                  The limitations and exclusions of liability set out in this Clause 12 and elsewhere in these Terms and Conditions: 
                  <ol class="listitem  ">
                     <li>are subject to Clause 12.1; and</li>
                     <li>govern all liabilities arising under these Terms and Conditions or relating to the subject matter of these Terms and Conditions, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty, except to the extent expressly provided otherwise in these Terms and Conditions.</li>
                  </ol>
               </li>
               <li class="unit numberedunit">The Provider shall not be liable to the Customer in respect of any losses arising out of a Force Majeure Event.</li>
               <li class="unit numberedunit">The Provider will not be liable to the Customer in respect of any loss of profits or anticipated savings.</li>
               <li class="unit numberedunit">The Provider will not be liable to the Customer in respect of any loss of revenue or income.</li>
               <li class="unit numberedunit">The Provider will not be liable to the Customer in respect of any loss of use or production.</li>
               <li class="unit numberedunit">The Provider will not be liable to the Customer in respect of any loss of business, contracts or opportunities.</li>
               <li class="unit numberedunit">The Provider will not be liable to the Customer in respect of any loss or corruption of any data, database or software.</li>
               <li class="unit numberedunit">The Provider will not be liable to the Customer in respect of any special, indirect or consequential loss or damage.</li>
               <li class="unit numberedunit">
                  The liability of the Provider to the Customer under the Agreement in respect of any event or series of related events shall not exceed the greater of:
                  <ol class="listitem  ">
                     <li>Customer's previously paid monthly subscription cost; and</li>
                     <li>the total amount paid and payable by the Customer to the Provider under the Agreement in the 12 month period preceding the commencement of the event or events.</li>
                  </ol>
               </li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Force Majeure Event</h3>
               </li>
               <li class="unit numberedunit">If a Force Majeure Event gives rise to a failure or delay in either party performing any obligation under the Agreement (other than any obligation to make a payment), that obligation will be suspended for the duration of the Force Majeure Event.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Termination</h3>
               </li>
               <li class="unit numberedunit">Either party may terminate the Agreement by giving to the other party at least 30 days' written notice of termination.</li>
               <li class="unit numberedunit">Either party may terminate the Agreement immediately by giving written notice of termination to the other party if the other party commits a material breach of these Terms and Conditions.</li>
               <li class="unit numberedunit">
                  Either party may terminate the Agreement immediately by giving written notice of termination to the other party if:
                  <ol class="listitem  ">
                     <li>
                        the other party:
                        <ol class="listitem  ">
                           <li>is dissolved;</li>
                           <li>ceases to conduct all (or substantially all) of its business;</li>
                           <li>is or becomes unable to pay its debts as they fall due;</li>
                           <li>is or becomes insolvent or is declared insolvent; or</li>
                           <li>convenes a meeting or makes or proposes to make any arrangement or composition with its creditors;</li>
                        </ol>
                     </li>
                     <li>an administrator, administrative receiver, liquidator, receiver, trustee, manager or similar is appointed over any of the assets of the other party;</li>
                     <li>an order is made for the winding up of the other party, or the other party passes a resolution for its winding up (other than for the purpose of a solvent company reorganisation where the resulting entity will assume all the obligations of the other party under the Agreement); or</li>
                     <li>
                        if that other party is an individual:
                        <ol class="listitem  ">
                           <li>that other party dies;</li>
                           <li>as a result of illness or incapacity, that other party becomes incapable of managing his or her own affairs; or</li>
                           <li>that other party is the subject of a bankruptcy petition or order.</li>
                        </ol>
                     </li>
                  </ol>
               </li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Effects of termination</h3>
               </li>
               <li class="unit numberedunit">Upon the termination of the Agreement, all of the provisions of these Terms and Conditions shall cease to have effect, save that the following provisions of these Terms and Conditions shall survive and continue to have effect (in accordance with their express terms or otherwise indefinitely): Clauses 1, 3.10, 8, 9, 12, 15, 18 and 19.</li>
               <li class="unit numberedunit">Except to the extent that these Terms and Conditions expressly provides otherwise, the termination of the Agreement shall not affect the accrued rights of either party.</li>
               <li class="unit numberedunit">
                  Within 30 days following the termination of the Agreement for any reason:
                  <ol class="listitem  ">
                     <li>the Customer must pay to the Provider any Charges in respect of Services provided to the Customer before the termination of the Agreement; and</li>
                     <li>the Provider must refund to the Customer any Charges paid by the Customer to the Provider in respect of Services that were to be provided to the Customer after the termination of the Agreement,</li>
                  </ol>
                  without prejudice to the parties' other legal rights.
               </li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Notices</h3>
               </li>
               <li class="unit numberedunit">
                  Any notice from one party to the other party under these Terms and Conditions must be given by one of the following methods:   
                  <ol class="listitem  ">
                     <li>sent by email to the relevant email address specified through the Hosted Services, in which case the notice shall be deemed to be received upon receipt of the email by the recipient's email server; or</li>
                     <li>sent using the contractual notice mechanism incorporated into the Hosted Services, in which case the notice shall be deemed to be received upon dispatch,</li>
                  </ol>
                  providing that, if the stated time of deemed receipt is not within Business Hours, then the time of deemed receipt shall be when Business Hours next begin after the stated time.
               </li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Subcontracting</h3>
               </li>
               <li class="unit numberedunit">Subject to any express restrictions elsewhere in these Terms and Conditions, the Provider may subcontract any of its obligations under the Agreement.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>General</h3>
               </li>
               <li class="unit numberedunit">No breach of any provision of the Agreement shall be waived except with the express written consent of the party not in breach.</li>
               <li class="unit numberedunit">If any provision of the Agreement is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions of the Agreement will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the parties, in which case the entirety of the relevant provision will be deemed to be deleted).</li>
               <li class="unit numberedunit">The Provider may vary the Agreement by giving to the Customer at least 30 days' written notice of the variation. Subject to this, the Agreement may only be varied by a written document signed by or on behalf of each of the parties.</li>
               <li class="unit numberedunit">The Customer hereby agrees that the Provider may assign the Provider's contractual rights and obligations under the Agreement to any third party. Save to the extent expressly permitted by applicable law, the Customer must not without the prior written consent of the Provider assign, transfer or otherwise deal with any of the Customer's contractual rights or obligations under the Agreement.</li>
               <li class="unit numberedunit">The Agreement is made for the benefit of the parties, and is not intended to benefit any third party or be enforceable by any third party. The rights of the parties to terminate, rescind, or agree any amendment, waiver, variation or settlement under or relating to the Agreement are not subject to the consent of any third party.</li>
               <li class="unit numberedunit">Subject to Clause 12.1, a Services Order Form, together with these Terms and Conditions and any Schedules, shall constitute the entire agreement between the parties in relation to the subject matter of that Services Order Form, and shall supersede all previous agreements, arrangements and understandings between the parties in respect of that subject matter. </li>
               <li class="unit numberedunit">The Agreement shall be governed by and construed in accordance with English law.</li>
               <li class="unit numberedunit">The courts of England shall have exclusive jurisdiction to adjudicate any dispute arising under or in connection with the Agreement.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Interpretation</h3>
               </li>
               <li class="unit numberedunit">
                  In these Terms and Conditions, a reference to a statute or statutory provision includes a reference to: 
                  <ol class="listitem  ">
                     <li>that statute or statutory provision as modified, consolidated and/or re-enacted from time to time; and</li>
                     <li>any subordinate legislation made under that statute or statutory provision.</li>
                  </ol>
               </li>
               <li class="unit numberedunit">The Clause headings do not affect the interpretation of these Terms and Conditions.</li>
               <li class="unit numberedunit">References in these Terms and Conditions to "calendar months" are to the 12 named periods (January, February and so on) into which a year is divided.</li>
               <li class="unit numberedunit">In these Terms and Conditions, general words shall not be given a restrictive interpretation by reason of being preceded or followed by words indicating a particular class of acts, matters or things.</li>
            </ol>
         </li>
      </ol>
   </section>
   <section class="part numberedpart">
      <ol class="sections">
         <li class="partTitle">
            <h2>Schedule 1 (Acceptable Use Policy)</h2>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Introduction</h3>
               </li>
               <li class="unit numberedunit">
                  This acceptable use policy (the "<dfn>Policy</dfn>") sets out the rules governing:
                  <ol class="listitem  ">
                     <li>the use of the website at <em>[loveyourproject.com, any websites on subdomains of that website]</em>, any successor website, and the services available on that website or any successor website (the "<dfn>Services</dfn>"); and</li>
                     <li>the transmission, storage and processing of content by you, or by any person on your behalf, using the Services ("<dfn>Content</dfn>").</li>
                  </ol>
               </li>
               <li class="unit numberedunit">References in this Policy to "you" are to any customer for the Services and any individual user of the Services (and "your" should be construed accordingly); and references in this Policy to "us" are to Neverglitch Ltd (and "we" and "our" should be construed accordingly).</li>
               <li class="unit numberedunit">By using the Services, you agree to the rules set out in this Policy.</li>
               <li class="unit numberedunit">We will ask for your express agreement to the terms of this Policy before you upload or submit any Content or otherwise use the Services.</li>
               <li class="unit numberedunit">You must be at least 18 years of age to use the Services; and by using the Services, you warrant and represent to us that you are at least 18 years of age.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>General usage rules</h3>
               </li>
               <li class="unit numberedunit">You must not use the Services in any way that causes, or may cause, damage to the Services or impairment of the availability or accessibility of the Services.</li>
               <li class="unit numberedunit">
                  You must not use the Services:
                  <ol class="listitem  ">
                     <li>in any way that is unlawful, illegal, fraudulent, deceptive or harmful; or</li>
                     <li>in connection with any unlawful, illegal, fraudulent, deceptive or harmful purpose or activity.</li>
                  </ol>
               </li>
               <li class="unit numberedunit">You must ensure that all Content complies with the provisions of this Policy.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Unlawful Content</h3>
               </li>
               <li class="unit numberedunit">Content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable law).</li>
               <li class="unit numberedunit">
                  Content, and the use of Content by us in any manner licensed or otherwise authorised by you, must not:
                  <ol class="listitem  ">
                     <li>be libellous or maliciously false;</li>
                     <li>be obscene or indecent;</li>
                     <li>infringe any copyright, moral right, database right, trade mark right, design right, right in passing off, or other intellectual property right;</li>
                     <li>infringe any right of confidence, right of privacy or right under data protection legislation;</li>
                     <li>constitute negligent advice or contain any negligent statement;</li>
                     <li>constitute an incitement to commit a crime, instructions for the commission of a crime or the promotion of criminal activity;</li>
                     <li>be in contempt of any court, or in breach of any court order;</li>
                     <li>constitute a breach of racial or religious hatred or discrimination legislation;</li>
                     <li class="">be blasphemous;</li>
                     <li>constitute a breach of official secrets legislation; or</li>
                     <li>constitute a breach of any contractual obligation owed to any person.</li>
                  </ol>
               </li>
               <li class="unit numberedunit">You must ensure that Content is not and has never been the subject of any threatened or actual legal proceedings or other similar complaint.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Graphic material</h3>
               </li>
               <li class="unit numberedunit">Content must be appropriate for all persons who have access to or are likely to access the Content in question.</li>
               <li class="unit numberedunit">Content must not depict violence in an explicit, graphic or gratuitous manner.</li>
               <li class="unit numberedunit">Content must not be pornographic or sexually explicit.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Factual accuracy</h3>
               </li>
               <li class="unit numberedunit">Content must not be untrue, false, inaccurate or misleading.</li>
               <li class="unit numberedunit">Statements of fact contained in Content and relating to persons (legal or natural) must be true; and statements of opinion contained in Content and relating to persons (legal or natural) must be reasonable, be honestly held and indicate the basis of the opinion.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Negligent advice</h3>
               </li>
               <li class="unit numberedunit">Content must not consist of or contain any legal, financial, investment, taxation, accountancy, medical or other professional advice, and you must not use the Services to provide any legal, financial, investment, taxation, accountancy, medical or other professional advisory services.</li>
               <li class="unit numberedunit">Content must not consist of or contain any advice, instructions or other information that may be acted upon and could, if acted upon, cause death, illness or personal injury, damage to property, or any other loss or damage.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Etiquette</h3>
               </li>
               <li class="unit numberedunit">Content must be appropriate, civil and tasteful, and accord with generally accepted standards of etiquette and behaviour on the internet.</li>
               <li class="unit numberedunit">Content must not be offensive, deceptive, threatening, abusive, harassing, menacing, hateful, discriminatory or inflammatory.</li>
               <li class="unit numberedunit">Content must not be liable to cause annoyance, inconvenience or needless anxiety.</li>
               <li class="unit numberedunit">You must not use the Services to send any hostile communication or any communication intended to insult, including such communications directed at a particular person or group of people.</li>
               <li class="unit numberedunit">You must not use the Services for the purpose of deliberately upsetting or offending others.</li>
               <li class="unit numberedunit">You must not unnecessarily flood the Services with material relating to a particular subject or subject area, whether alone or in conjunction with others.</li>
               <li class="unit numberedunit">You must ensure that Content does not duplicate other content available through the Services.</li>
               <li class="unit numberedunit">You must ensure that Content is appropriately categorised.</li>
               <li class="unit numberedunit">You should use appropriate and informative titles for all Content.</li>
               <li class="unit numberedunit">You must at all times be courteous and polite to other users of the Services.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Marketing and spam</h3>
               </li>
               <li class="unit numberedunit">You must not without our written permission use the Services for any purpose relating to the marketing, advertising, promotion, sale or supply of any product, service or commercial offering.</li>
               <li class="unit numberedunit">Content must not constitute or contain spam, and you must not use the Services to store or transmit spam - which for these purposes shall include all unlawful marketing communications and unsolicited commercial communications.</li>
               <li class="unit numberedunit">You must not send any spam or other marketing communications to any person using any email address or other contact details made available through the Services or that you find using the Services.</li>
               <li class="unit numberedunit">You must not use the Services to promote, host or operate any chain letters, Ponzi schemes, pyramid schemes, matrix programs, multi-level marketing schemes, "get rich quick" schemes or similar letters, schemes or programs.</li>
               <li class="unit numberedunit">You must not use the Services in any way which is liable to result in the blacklisting of any of our IP addresses.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Regulated businesses</h3>
               </li>
               <li class="unit numberedunit">You must not use the Services for any purpose relating to gambling, gaming, betting, lotteries, sweepstakes, prize competitions or any gambling-related activity.</li>
               <li class="unit numberedunit">You must not use the Services for any purpose relating to the offering for sale, sale or distribution of drugs or pharmaceuticals.</li>
               <li class="unit numberedunit">You must not use the Services for any purpose relating to the offering for sale, sale or distribution of knives, guns or other weapons.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Monitoring</h3>
               </li>
               <li class="unit numberedunit">You acknowledge that we may actively monitor the Content and the use of the Services.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Data mining</h3>
               </li>
               <li class="unit numberedunit">You must not conduct any systematic or automated data scraping, data mining, data extraction or data harvesting, or other systematic or automated data collection activity, by means of or in relation to the Services.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Hyperlinks</h3>
               </li>
               <li class="unit numberedunit">You must not link to any material using or by means of the Services that would, if it were made available through the Services, breach the provisions of this Policy.</li>
            </ol>
         </li>
         <li class="section numberedsection">
            <ol class="units">
               <li class="sectionTitle">
                  <h3>Harmful software</h3>
               </li>
               <li class="unit numberedunit">The Content must not contain or consist of, and you must not promote, distribute or execute by means of the Services, any viruses, worms, spyware, adware or other harmful or malicious software, programs, routines, applications or technologies.</li>
               <li class="unit numberedunit">The Content must not contain or consist of, and you must not promote, distribute or execute by means of the Services, any software, programs, routines, applications or technologies that will or may have a material negative effect upon the performance of a computer or introduce material security risks to a computer.</li>
            </ol>
         </li>
      </ol>
   </section>
        `,
          }}
        />
      </Container>
    </ListLayout>
  )
}
