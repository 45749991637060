import { plainToClass } from 'class-transformer'
import { IsOptional, MaxLength, MinLength, validateSync } from 'class-validator'
import { UpdateProjectMutationVariables } from '../../graphql'
import { getValidationErrors } from '../../../../util'

export class UpdateProjectDto {
  @MinLength(3, {
    message: 'Name should be at least $constraint1 characters long.',
  })
  @MaxLength(500, {
    message: 'Name should be at most $constraint1 characters long.',
  })
  @IsOptional()
  name!: string
}

export const updateProjectValidate = (
  vars: Omit<UpdateProjectMutationVariables, 'id'>
) => getValidationErrors(validateSync(plainToClass(UpdateProjectDto, vars)))
