import React from 'react'
import { BrochureLayout, CenterLayout, UserResetPasswordForm } from '../..'

interface ResetPasswordPageProps {
  verificationCode: string
}

export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({
  verificationCode,
}) => (
  <BrochureLayout>
    <CenterLayout>
      <UserResetPasswordForm verificationCode={verificationCode} />
    </CenterLayout>
  </BrochureLayout>
)
