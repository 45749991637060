import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  AddProjectUserResponseDocument,
  AddProjectUserResponseMutationResult,
  AddProjectUserResponseMutationVariables,
} from '../../graphql'
import {
  addProjectUserSuccessMock,
  addProjectUserFailureMock,
} from '../../mutations'

type AddProjectUserResponseMockResponse = MockResponse<
  AddProjectUserResponseMutationVariables,
  Pick<AddProjectUserResponseMutationResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const addProjectUserResponseSuccessMock = {
  request: {
    query: AddProjectUserResponseDocument,
    variables: {
      projectUserId: addProjectUserSuccessMock.request.variables.id,
      accept: true,
    },
  },
  result: {
    data: {
      addProjectUserResponse: {
        id: addProjectUserSuccessMock.request.variables.id,
        project: {
          id: addProjectUserSuccessMock.request.variables.projectId,
        },
      },
    },
  },
}

export const addProjectUserResponseNoProjectSuccessMock = {
  request: {
    query: AddProjectUserResponseDocument,
    variables: {
      projectUserId: 'f14f2e6b-cc7a-4aac-bfaa-b79358359057',
      accept: true,
    },
  },
  result: {
    data: {
      addProjectUserResponse: {
        id: 'f14f2e6b-cc7a-4aac-bfaa-b79358359057',
      },
    },
  },
}

export const addProjectUserResponseFailureMock = {
  request: {
    query: AddProjectUserResponseDocument,
    variables: {
      projectUserId: addProjectUserFailureMock.request.variables.id,
      accept: true,
    },
  },
  result: {
    errors: [
      {
        message: {
          statusCode: 401,
          error: 'Unauthorized',
          message:
            'Your email address must be verified before the project invite can be accepted.',
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const addProjectUserResponseMocks: AddProjectUserResponseMockResponse[] = [
  addProjectUserResponseSuccessMock,
  addProjectUserResponseNoProjectSuccessMock,
  addProjectUserResponseFailureMock,
]
