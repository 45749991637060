import Card, { CardProps } from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import { isUndefined, lt } from 'lodash'
import React from 'react'
import { UsageProgress } from '../../../components'
import { useCurrentUserUsageQuery } from '../../../middleware'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      padding: theme.spacing(4, 2),
      maxWidth: 768,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        padding: theme.spacing(2, 4),
      },
    },
    divider: {
      margin: theme.spacing(0, 4),
    },
    progress: {
      flex: 1,
      minHeight: 80,
    },
  })
)

/**
 * Gets the limit warning value. This
 * is either 75% of the limit or the
 * limit minus 1 (whichever is lesser).
 *
 * @param limit Max value allowed.
 */
const getLimitWarning = (limit: number) => {
  const highPercentage = limit * 0.75
  const highValue = limit - 1

  return lt(highValue, highPercentage) ? highValue : highPercentage
}

export const UsageCard: React.FC<CardProps> = (props) => {
  const classes = useStyles()
  const { data } = useCurrentUserUsageQuery()
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const currentUser = data?.currentUser ?? undefined

  return isUndefined(currentUser) ? null : (
    <Card
      variant="outlined"
      {...props}
      className={clsx([classes.card, props.className])}
    >
      <UsageProgress
        className={classes.progress}
        title="Projects"
        value={currentUser.projectUsage}
        limit={currentUser.projectQuota}
        limitWarning={getLimitWarning(currentUser.projectQuota)}
      />
      {smUp ? (
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem={true}
        />
      ) : undefined}
      <UsageProgress
        className={classes.progress}
        title="Stories"
        value={currentUser.storyUsage}
        limit={currentUser.storyQuota}
        limitWarning={getLimitWarning(currentUser.storyQuota)}
      />
      {smUp ? (
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem={true}
        />
      ) : undefined}
      <UsageProgress
        className={classes.progress}
        title="Contributors"
        value={currentUser.contributorUsage}
        limit={currentUser.contributorQuota}
        limitWarning={getLimitWarning(currentUser.contributorQuota)}
      />
    </Card>
  )
}
