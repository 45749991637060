import clsx from 'clsx'
import { isUndefined } from 'lodash'
import { ComponentType, HTMLAttributes, useCallback } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import {
  FixedSizeList,
  FixedSizeListProps,
  ListChildComponentProps,
} from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from 'react-virtualized-auto-sizer'

export interface VirtualListItemProps<T>
  extends Omit<ListChildComponentProps, 'data'> {
  data: T
  loading: boolean
}

export interface VirtualListProps<T> extends HTMLAttributes<HTMLDivElement> {
  children: ComponentType<VirtualListItemProps<T>>
  error?: string
  hasNextPage: boolean
  infiniteLoaderProps?: Partial<InfiniteLoader['props']>
  itemCount: number
  listProps: Omit<
    FixedSizeListProps,
    'children' | 'width' | 'height' | 'itemData' | 'itemCount'
  > & {
    itemData: T
  } & Partial<Pick<FixedSizeListProps, 'itemCount'>>
  loading: boolean
  loadMore: InfiniteLoader['props']['loadMoreItems']
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      flex: 1,
    },
    loadingContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export const VirtualList = <T extends {}>({
  children: Children,
  className,
  error,
  hasNextPage,
  infiniteLoaderProps,
  itemCount,
  listProps,
  loading,
  loadMore,
  ...rest
}: VirtualListProps<T>) => {
  const classes = useStyles()

  const loaderItemCount = hasNextPage ? itemCount + 1 : itemCount

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < itemCount,
    [hasNextPage, itemCount]
  )

  const rowRenderer = useCallback(
    (props: Omit<VirtualListItemProps<T>, 'loading'>) => {
      const itemLoading = !isItemLoaded(props.index)

      return <Children loading={itemLoading} {...props} />
    },
    [Children, isItemLoaded]
  )

  return loading ? (
    <div className={clsx(classes.loadingContainer, className)} {...rest}>
      <CircularProgress aria-label="Loading" />
    </div>
  ) : isUndefined(error) ? (
    <div className={clsx(classes.container, className)} {...rest}>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={loaderItemCount}
            loadMoreItems={loadMore}
            {...infiniteLoaderProps}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={height}
                width={width}
                onItemsRendered={onItemsRendered}
                ref={ref}
                itemCount={loaderItemCount}
                {...listProps}
              >
                {rowRenderer}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  ) : (
    <Container
      className={clsx(classes.container, className)}
      maxWidth="sm"
      {...rest}
    >
      <Alert severity="error">
        <AlertTitle>Failed to load</AlertTitle>
        {error}
      </Alert>
    </Container>
  )
}
