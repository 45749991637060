import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import React, { useCallback } from 'react'

interface Option {
  disabled?: boolean
  primaryLabel: string
  secondaryLabel: string
  value: string
}

export type ProjectPermissionSelectProps = Omit<TextFieldProps, 'children'> & {
  options: Option[]
}

export const ProjectPermissionSelect: React.FC<ProjectPermissionSelectProps> = ({
  options,
  ...props
}) => {
  const renderValue = useCallback(
    (value: unknown) => options.find((n) => n.value === value)?.primaryLabel,
    [options]
  )

  return (
    <TextField
      id="outlined-select-currency"
      select={true}
      SelectProps={{
        renderValue,
      }}
      label="Select"
      variant="outlined"
      value={''} // To remove warn in tests without value
      {...props}
    >
      {options.map((option) => (
        <MenuItem
          disabled={option.disabled}
          key={option.value}
          value={option.value}
        >
          <ListItemText
            primary={option.primaryLabel}
            secondary={option.secondaryLabel}
          />
        </MenuItem>
      ))}
    </TextField>
  )
}
