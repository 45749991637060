import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import {
  CenterLayout,
  CurrentUserAccountMenu,
  EmailVerificationProgress,
  HasEmailVerifiedRoute,
  JoinProjectProgress,
  ListLayout,
} from '../..'

interface JoinProjectPageProps {
  projectUserId: string
}

export const JoinProjectPage: React.FC<JoinProjectPageProps> = ({
  projectUserId,
}) => {
  const [emailVerification] = useQueryParam('emailVerification', StringParam)

  return (
    <ListLayout headerRight={<CurrentUserAccountMenu />}>
      <CenterLayout>
        <HasEmailVerifiedRoute
          redirectComponent={
            <EmailVerificationProgress
              verificationCode={emailVerification ?? ''}
            >
              <JoinProjectProgress projectUserId={projectUserId} />
            </EmailVerificationProgress>
          }
        >
          <JoinProjectProgress projectUserId={projectUserId} />
        </HasEmailVerifiedRoute>
      </CenterLayout>
    </ListLayout>
  )
}
