import { GraphQLError } from 'graphql'
import { ResendEmailVerificationDocument } from '../../graphql'
import { currentUserSuccessMock } from '../../queries'

export const resendEmailVerificationSuccessMock = {
  request: {
    query: ResendEmailVerificationDocument,
    variables: {},
  },
  result: {
    data: {
      resendEmailVerification: {
        id: currentUserSuccessMock.result.data.currentUser.id,
      },
    },
  },
}

export const resendEmailVerificationFailureMock = {
  request: {
    query: ResendEmailVerificationDocument,
    variables: {},
  },
  result: {
    data: null,
    errors: [new GraphQLError('Email verification code failed to send.')],
  },
}

export const resendEmailVerificationMocks = [
  resendEmailVerificationSuccessMock,
  resendEmailVerificationFailureMock,
]
