import Fab, { FabProps } from '@material-ui/core/Fab'
import Zoom from '@material-ui/core/Zoom'
import AddIcon from '@material-ui/icons/Add'
import { isNil, isUndefined } from 'lodash'
import React from 'react'
import {
  ProjectPermission,
  useCurrentUser,
  useProjectUsersQuery,
} from '../../../middleware'

export interface CreateStoryFabProps extends Omit<FabProps, 'children'> {
  children?: React.ReactNode
  projectId: string
}

export const CreateStoryFab: React.FC<CreateStoryFabProps> = ({
  projectId,
  ...rest
}) => {
  const currentUser = useCurrentUser().data?.currentUser
  const { data } = useProjectUsersQuery({
    variables: {
      id: projectId,
    },
  })

  const currentProjectUser =
    isUndefined(currentUser) || isUndefined(data)
      ? undefined
      : data.project.projectUsers.find((n) => n.user?.id === currentUser?.id)
          ?.permission
  const hideCreateStory =
    isNil(currentProjectUser) || currentProjectUser === ProjectPermission.Read

  return (
    <Zoom in={!hideCreateStory}>
      <Fab aria-label="Add story" color="primary" size="medium" {...rest}>
        <AddIcon />
      </Fab>
    </Zoom>
  )
}
