import { makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import ToggleButton, { ToggleButtonProps } from '@material-ui/lab/ToggleButton'
import clsx from 'clsx'
import { isNil, isNull, isUndefined } from 'lodash'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  goToImportStories,
  ProjectPermission,
  useCurrentUser,
  useImportStoriesRouteMatch,
  useProjectUsersQuery,
} from '../../../middleware'

export interface ImportButtonProps
  extends Omit<ToggleButtonProps, 'onClick' | 'selected' | 'value'> {
  projectId: string
}

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: `rgba(255,255,255, 0.94)`,
    },
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1.5),
    },
  },
}))

export const ImportButton: React.FC<ImportButtonProps> = ({
  className,
  projectId,
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()
  const importStoriesRouteMatch = useImportStoriesRouteMatch()
  const currentUser = useCurrentUser().data?.currentUser
  const { data } = useProjectUsersQuery({
    variables: {
      id: projectId,
    },
  })
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const currentProjectUser =
    isUndefined(currentUser) || isUndefined(data)
      ? undefined
      : data.project.projectUsers.find((n) => n.user?.id === currentUser?.id)
          ?.permission
  const hide =
    isNil(currentProjectUser) || currentProjectUser === ProjectPermission.Read

  const onClick = useCallback(() => {
    goToImportStories(history, projectId, {
      search: history.location.search, // keep current query
    })
  }, [history, projectId])

  return !hide ? (
    <ToggleButton
      aria-label="import toggle"
      className={clsx([classes.button, className])}
      onClick={onClick}
      selected={!isNull(importStoriesRouteMatch)}
      value="true"
      {...rest}
    >
      <ImportExportIcon
        className={classes.icon}
        color="primary"
        fontSize="small"
      />
      {mdUp ? 'Import' : undefined}
    </ToggleButton>
  ) : null
}
