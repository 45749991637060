import Button from '@material-ui/core/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CenterLayout,
  CurrentUserAccountMenu,
  ListLayout,
} from '../../../components'
import { goToCreateProject } from '../../../middleware'

const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      marginBottom: theme.spacing(5),
    },
  })
)

const description = `You've not got any projects yet. Press "Create new project” to add your very
first project!`

export const NoProjectsPage: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()

  const onCreateProjectClick = useCallback(() => {
    goToCreateProject(history)
  }, [history])

  return (
    <ListLayout headerRight={<CurrentUserAccountMenu />}>
      <CenterLayout>
        <Typography variant="h3" gutterBottom={true}>
          Let’s get started!
        </Typography>
        <Typography align="center" className={classes.description}>
          {description}
        </Typography>
        <Button
          variant="outlined"
          onClick={onCreateProjectClick}
          startIcon={<AddIcon color="primary" />}
        >
          Create new project
        </Button>
      </CenterLayout>
    </ListLayout>
  )
}
