import {
  MockResponse,
  NestJsError,
  NestJsValidationError,
} from '../../../../util'
import {
  ResetPasswordDocument,
  ResetPasswordMutationResult,
  ResetPasswordMutationVariables,
} from '../../graphql'

type ResetPasswordMockResponse = MockResponse<
  ResetPasswordMutationVariables,
  Pick<ResetPasswordMutationResult, 'data'> & {
    errors?: { message: NestJsError | NestJsValidationError }[]
  }
>

export const resetPasswordSuccessMock = {
  request: {
    query: ResetPasswordDocument,
    variables: {
      email: 'example@test.com',
      password: 'examplePassword',
      verificationCode:
        '7fc64caf62757825a84ec933d09678cc63a79edd24ed642d35e52879a4d8059acdce2bd140456af974ed16d87cafa7eeafc1ef2d45a03f4690bccf64168f246d',
    },
  },
  result: {
    data: {
      resetPassword: {
        accessToken:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMDAwMDAwMDAwIiwibmFtZSI6IkV4YW1wbGUiLCJpYXQiOjE1MTYyMzkwMjJ9.MfE2a_HsDG6gGaR-gwRfSk7MLAkK1DLfcOmXj6mVfzk',
      },
    },
  },
}

export const resetPasswordFailureMock = {
  request: {
    query: ResetPasswordDocument,
    variables: {
      email: 'bademail@test.com',
      password: 'examplePassword',
      verificationCode:
        '74a9ba873b07ca10a8b48524490f0400275f3c1cdcbbda12590b089e8706fe0ff298f742254442e3c84a3652e7c238edc0cfd1a129a2a974ed78d32c5bc8f8de',
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 409,
          error: 'Conflict',
          message: 'Password has already been used.',
        },
      },
    ],
  },
}

export const resetPasswordEmailValidationFailureMock = {
  request: {
    query: ResetPasswordDocument,
    variables: {
      ...resetPasswordSuccessMock.request.variables,
      email: 'bad@t.com',
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 400,
          error: 'Bad Request',
          message: [
            {
              property: 'email',
              constraints: {
                minLength: 'Email should be at least 10 characters long.',
              },
            },
          ],
        },
      },
    ],
  },
}

export const resetPasswordPasswordValidationFailureMock = {
  request: {
    query: ResetPasswordDocument,
    variables: {
      ...resetPasswordSuccessMock.request.variables,
      password: 'examplePasswo',
    },
  },
  result: {
    data: null,
    errors: [
      {
        message: {
          statusCode: 400,
          error: 'Bad Request',
          message: [
            {
              property: 'password',
              constraints: {
                minLength: 'Password should be at least 13 characters long.',
              },
            },
          ],
        },
      },
    ],
  },
}

// Check types here to leave object literal properties easily accessable in tests
export const resetPasswordMocks: ResetPasswordMockResponse[] = [
  resetPasswordSuccessMock,
  resetPasswordFailureMock,
  resetPasswordEmailValidationFailureMock,
  resetPasswordPasswordValidationFailureMock,
]
