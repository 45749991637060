import React, { useCallback } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import IconButton from '@material-ui/core/IconButton'
import { Project } from '../../../middleware'

type ProjectProps = Pick<Project, 'id' | 'canDelete' | 'canUpdate' | 'name'>

export interface ProjectListItemProps {
  project: ProjectProps
  onClick: (project: ProjectProps) => void
  onEdit: (project: ProjectProps) => void
}

export const ProjectListItem: React.FC<ProjectListItemProps> = ({
  project,
  onClick,
  onEdit,
}) => {
  const onItemClick = useCallback(() => {
    onClick(project)
  }, [onClick, project])

  const onEditClick = useCallback(() => {
    onEdit(project)
  }, [onEdit, project])

  return (
    <ListItem ContainerComponent="div" button={true} onClick={onItemClick}>
      <ListItemText
        primary={<Typography noWrap={true}>{project.name}</Typography>}
      />
      {project.canDelete && project.canUpdate ? (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label={`Edit ${project.name}`}
            onClick={onEditClick}
          >
            <SettingsIcon />
          </IconButton>
        </ListItemSecondaryAction>
      ) : undefined}
    </ListItem>
  )
}
